import { Button } from '@this/src/components/shared/ui/inputs/button';
import type Trip from '@this/src/domain/trip/trip';
import React, { useCallback, useMemo, useState } from 'react';

import Notification from '@this/src/notification';
import { Flex } from '@this/src/components/shared/ui/layout/flex';
import { Text } from '@this/components/shared/ui/data_displays/typography';
import { Fetcher, HTTPError } from '@this/src/util';

type Props = {
  trip: Trip;
  onReloadTrip: () => void;
};

export const ArrangementCompleteButton = ({ trip, onReloadTrip }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const disabled = useMemo(() => !trip.isFixed(), [trip]);

  const handleClick = useCallback(async () => {
    if (disabled || submitting) {
      return;
    }

    setSubmitting(true);
    try {
      await Fetcher.put(`/arrangement/trips/${trip.id}`, { status: 7 });
      onReloadTrip();
    } catch (e) {
      const msg =
        e instanceof HTTPError && e.response?.data?.errors
          ? e.response.data.errors
          : '通信環境が不安定です。画面をリロードしてからもう一度お試しください。';

      Notification.error(msg);
    } finally {
      setSubmitting(false);
    }
  }, [disabled, submitting, trip, onReloadTrip]);

  return (
    <Flex flexDirection="column" gap="4px">
      <Button loading={submitting} disabled={disabled} onClick={handleClick}>
        完了
      </Button>
      {disabled && <Text color="danger">※完了できるのはステータスが「予約確定」の時のみです</Text>}
    </Flex>
  );
};

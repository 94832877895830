import { Fetcher } from '@this/src/util';
/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';

import moment from 'moment';
import type { RouteComponentProps } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { Button } from '@this/components/shared/ui/inputs/button';
import FlashMessage from '@this/shared/flash_message/flash_message';
import { styled } from '@this/constants/themes';
import { Datepicker } from '@this/shared/ui/inputs/datepicker';
import { Flex } from '@this/shared/ui/layout/flex';
import ShareholderTicketHistories from '@this/components/arrangement/shareholder_tickets/shareholder_ticket_histories/shareholder_ticket_histories';

import Modal from '../../shared/modal/modal';
import Confirm from '../../shared/confirm/confirm';
import ShareholderBlock from './sharefolder_block/shareholder_block';
import SimpleLoading from '../../shared/simple_loading/simple_loading';
import ShareholderTicketList from '../../../domain/shareholder_ticket_list/index';
import ShareholderTicket from '../../../domain/shareholder_ticket';
import OtherServiceShareholderInfoList from '../../../domain/other_service_shareholder_info_list';
import OtherServiceShareholderInfo from '../../../domain/other_service_shareholder_info';
import OtherServiceShareholderInfoBlock from './other_service_shareholder_info_block/other_service_shareholder_info_block';

const Tab = {
  LIST: '一覧',
  HISTORY: '使用履歴'
} as const;
export type TabType = keyof typeof Tab;
const AllTab: TabType[] = _.keys(Tab) as TabType[];

interface ShareholderTicketGetResponse {
  tickets: ShareholderTicket[];
}

interface ShareholderTicketPostResponse {
  id: number;
}

interface ShareholderTicketPutResponse {
  id: number;
}

interface OtherServiceShareholderInfoGetResponse {
  shareholder_infos: OtherServiceShareholderInfo[];
}

interface OtherServiceShareholderInfoPostResponse {
  id: number;
}

interface OtherServiceShareholderInfoPutResponse {
  id: number;
}

type CompanyType = 'ANA' | 'JAL' | 'SFJ';

interface State {
  tickets: ShareholderTicketList;
  loading: boolean;
  editingTicket: ShareholderTicket | null;
  saving: boolean;
  saveError: string | null;
  deletingTicket: ShareholderTicket;
  expireAt: number;
  status: number;
  isDeleted: number;
  companyType: CompanyType | null;
  price: number | null;
  fromDate: Date | null;
  toDate: Date | null;
  selectedOption: TabType;
  currentTabType: string;
  priceError: string | null;
  otherServiceShareHolderInfos: OtherServiceShareholderInfoList;
  editingOtherServiceShareHolderInfo: OtherServiceShareholderInfo | null;
  deletingOtherServiceShareHolderInfo: OtherServiceShareholderInfo;
  showUploadModal: boolean;
  uploading: boolean;
  file: File | null;
  uploadErrors: string[];
}

interface ShareholderTicketObj {
  setCompanyType: ShareholderTicket;
  setPrice: ShareholderTicket;
  setNumber: ShareholderTicket;
  setPassword: ShareholderTicket;
  setRemarks: ShareholderTicket;
}

interface OtherServiceShareholderInfoObj {
  setFrom: OtherServiceShareholderInfo;
  setTo: OtherServiceShareholderInfo;
  setPurchasePriceAna: OtherServiceShareholderInfo;
  setPurchasePriceJal: OtherServiceShareholderInfo;
  setPurchasePriceSfj: OtherServiceShareholderInfo;
}

interface Props extends RouteComponentProps<any> {
  serviceId: number;
}

class ShareholderTickets extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let expireAt = 0;
    let status = 0;
    let isDeleted = 0;
    if (utils.getParam('expired_at')) {
      expireAt = utils.getParam('expired_at');
    }
    if (utils.getParam('status')) {
      status = utils.getParam('status');
    }
    if (utils.getParam('is_deleted')) {
      isDeleted = utils.getParam('is_deleted');
    }
    const companyType = utils.getParam('company_type');
    const price = utils.getParam('price');
    this.state = {
      loading: false,
      tickets: new ShareholderTicketList([]),
      editingTicket: null,
      saving: false,
      saveError: null,
      deletingTicket: new ShareholderTicket(),
      expireAt,
      status,
      isDeleted,
      companyType,
      price,
      fromDate: null,
      toDate: null,
      selectedOption: utils.getParam('tab') || 'LIST',
      currentTabType: '自社購入',
      priceError: null,
      // eslint-disable-next-line react/no-unused-state
      otherServiceShareHolderInfos: new OtherServiceShareholderInfoList({
        shareholder_infos: []
      }),
      editingOtherServiceShareHolderInfo: new OtherServiceShareholderInfo(),
      deletingOtherServiceShareHolderInfo: new OtherServiceShareholderInfo(),
      showUploadModal: false,
      uploading: false,
      file: null,
      uploadErrors: []
    };
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleDeleteAlertClose = this.handleDeleteAlertClose.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleOtherServiceShareholderInfoCancelClick =
      this.handleOtherServiceShareholderInfoCancelClick.bind(this);
    this.handleOtherServiceShareholderInfoEditClick = this.handleOtherServiceShareholderInfoEditClick.bind(this);
    this.handleOtherServiceShareholderInfoDeleteClick =
      this.handleOtherServiceShareholderInfoDeleteClick.bind(this);
    this.handleOtherServiceShareholderInfoFieldChange =
      this.handleOtherServiceShareholderInfoFieldChange.bind(this);
    this.handleOtherServiceShareholderInfoFormSubmit = this.handleOtherServiceShareholderInfoFormSubmit.bind(this);
    this.handleOtherServiceShareholderInfoDeleteAlertClose =
      this.handleOtherServiceShareholderInfoDeleteAlertClose.bind(this);
    this.handleOtherServiceShareholderInfoDeleteConfirm =
      this.handleOtherServiceShareholderInfoDeleteConfirm.bind(this);
    this.handleAddOtherServiceShareholderInfoClick = this.handleAddOtherServiceShareholderInfoClick.bind(this);
    this.handleShowUploadModal = this.handleShowUploadModal.bind(this);
    this.handleCloseUploadModal = this.handleCloseUploadModal.bind(this);
    this.handleUploadCsv = this.handleUploadCsv.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  getSupplierType() {
    return this.state.currentTabType === 'アリーズ' ? 1 : 2;
  }

  async fetch(): Promise<void> {
    if (this.state.currentTabType === '自社購入') {
      this.fetchShareholderTickets();
    } else {
      this.fetchOtherServiceShareholderInfos(this.getSupplierType());
    }
  }

  async fetchShareholderTickets(): Promise<void> {
    this.setState({ loading: true });
    const status = this.state.status;
    const expireAt = this.state.expireAt;
    const isDeleted = this.state.isDeleted;
    const fromDate = this.state.fromDate && moment(this.state.fromDate);
    const toDate = this.state.toDate && moment(this.state.toDate);
    const fetchUrl = '/arrangement/shareholder_tickets.json';
    const response = await Fetcher.get<ShareholderTicketGetResponse>(fetchUrl, {
      status,
      expire_at: expireAt,
      is_deleted: isDeleted,
      company_type: this.state.companyType,
      price: this.state.price,
      from_date: fromDate?.format('YYYY-MM-DD'),
      to_date: toDate?.format('YYYY-MM-DD')
    });
    return this.setState({
      tickets: new ShareholderTicketList(response.tickets),
      loading: false
    });
  }

  async fetchOtherServiceShareholderInfos(supplierType: number): Promise<void> {
    this.setState({ loading: true });
    const fetchUrl = '/arrangement/other_service_shareholder_infos.json';
    const response = await Fetcher.get<OtherServiceShareholderInfoGetResponse>(fetchUrl, {
      supplier_type: supplierType
    });
    return this.setState({
      otherServiceShareHolderInfos: new OtherServiceShareholderInfoList(response),
      loading: false
    });
  }

  handleExpiredAtChange(value: number) {
    this.setState({ expireAt: value });
  }

  handleStatusChange(value: number) {
    this.setState({ status: value });
  }

  handleFromDateChange(date: Date | null) {
    this.setState({ fromDate: date });
  }

  handleToDateChange(date: Date | null) {
    this.setState({ toDate: date });
  }

  handleIsDeletedChange(value: number) {
    this.setState({ isDeleted: value });
  }

  getQueryString() {
    const { status, expireAt, isDeleted, companyType, price, fromDate, toDate } = this.state;
    const args = { status, expireAt, isDeleted };
    const params = _.map(args, (v, k) => `${k}=${v}`);
    if (companyType) {
      params.push(`company_type=${companyType}`);
    }
    if (price) {
      params.push(`price=${price}`);
    }
    if (fromDate) {
      params.push(`from_date=${moment(fromDate).format('YYYY-MM-DD')}`);
    }
    if (toDate) {
      params.push(`to_date=${moment(toDate).format('YYYY-MM-DD')}`);
    }
    return params.join('&');
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.history.push(
      `/arrangement/shareholder_tickets?tab=${this.state.selectedOption}&${this.getQueryString()}`
    );
    this.fetch();
    return false;
  }

  handleAddClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    const t = new ShareholderTicket();
    t.setCompanyType('1');
    this.state.tickets.unshift(t);
    this.setState({
      editingTicket: t
    });
  }

  handleFieldChange(
    name: keyof ShareholderTicketObj,
    e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>
  ) {
    e.preventDefault();
    if (this.state.editingTicket) {
      this.state.editingTicket[name](e.target.value);
    }
  }

  handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!this.validatePrice()) {
      return;
    }

    this.setState({
      saving: true,
      saveError: null,
      priceError: null
    });
    if (this.state.editingTicket && this.state.editingTicket.id) {
      this.submitUpdate();
    } else {
      this.submitCreate();
    }
  }

  validatePrice = () => {
    const { editingTicket } = this.state;
    if (editingTicket) {
      const price = editingTicket.price;
      if (isNaN(price) || price > 9999) {
        this.setState({ priceError: '9999以下の数字を入力してください' });
        return false;
      }
    }
    this.setState({ priceError: null });
    return true;
  };

  submitCreate() {
    if (this.state.editingTicket) {
      return Fetcher.post<ShareholderTicketPostResponse>(
        '/arrangement/shareholder_tickets',
        this.state.editingTicket.updateParams()
      ).then(
        res => {
          _.assign(this.state.editingTicket, { id: res.id });
          if (this.state.editingTicket) {
            const isExpired = this.state.editingTicket.expireAt <= moment();
            if (isExpired) {
              this.state.tickets.removeExpired(this.state.editingTicket);
            }
            this.fetch();
            this.setState({
              editingTicket: null,
              saving: false
            });
          }
        },
        error => {
          const state: {
            saving: boolean;
            saveError: string | null;
          } = {
            saving: false,
            saveError: null
          };
          if (error.response.status === 400) {
            state.saveError = error.response.data.errors;
          } else {
            state.saveError = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
          }
          this.setState(state);
        }
      );
    }
    return false;
  }

  submitUpdate() {
    if (this.state.editingTicket) {
      return Fetcher.put<ShareholderTicketPutResponse>(
        `/arrangement/shareholder_tickets/${this.state.editingTicket.id}`,
        this.state.editingTicket.updateParams()
      ).then(
        () => {
          if (this.state.editingTicket) {
            this.state.tickets.update(this.state.editingTicket);
            this.fetch();
            this.setState({
              editingTicket: null,
              saving: false
            });
          }
        },
        error => {
          const state = {
            saving: false,
            saveError: ''
          };
          if (error.response.status === 400) {
            state.saveError = error.response.data.errors;
          } else {
            state.saveError = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
          }
          this.setState(state);
        }
      );
    }
    return false;
  }

  handleFlashMessageClose() {
    this.setState({ saveError: null });
  }

  handleCancelClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (this.state.editingTicket && !this.state.editingTicket.id) {
      this.state.tickets.remove(this.state.editingTicket);
    }
    this.setState({ editingTicket: null, priceError: null });
  }

  handleEditClick(t: ShareholderTicket, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ editingTicket: _.cloneDeep(t), priceError: null });
  }

  handleDeleteClick(t: ShareholderTicket, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ deletingTicket: t });
  }

  handleDeleteAlertClose() {
    this.setState({ deletingTicket: new ShareholderTicket() });
  }

  handleDeleteConfirm() {
    this.state.deletingTicket.setIsDelete(true);
    return Fetcher.put(
      `/arrangement/shareholder_tickets/${this.state.deletingTicket.id}`,
      this.state.deletingTicket.updateParams()
    ).then(
      () => {
        this.state.tickets.remove(this.state.deletingTicket);
        this.setState({
          deletingTicket: new ShareholderTicket(),
          saving: false
        });
        this.fetch();
      },
      error => {
        const state = {
          saving: false,
          saveError: ''
        };
        if (error.response.status === 400) {
          state.saveError = error.response.data.errors;
        } else {
          state.saveError = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        }
        this.setState(state);
      }
    );
  }

  handleCsvDownload() {
    location.href = `/arrangement/shareholder_tickets.csv?${this.getQueryString()}`;
  }

  handleShowUploadModal = () => {
    this.setState({ showUploadModal: true });
  };

  handleCloseUploadModal = () => {
    this.setState({ showUploadModal: false, file: null });
  };

  handleUploadCsv = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!this.state.file) {
      return;
    }
    this.setState({ uploading: true });
    const data = new FormData();
    data.append('file', this.state.file);
    Fetcher.upload('/arrangement/shareholder_tickets/import', data).then(
      () => {
        this.setState({ uploading: false, showUploadModal: false }, () => {
          this.fetch();
        });
      },
      error => {
        const state = {
          uploading: false,
          uploadErrors: Array<string>()
        };
        if (error.response.status === 400) {
          state.uploadErrors = error.response.data.errors;
        } else {
          state.uploadErrors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
        }
        this.setState(state);
      }
    );
  };

  handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ file: e.target.files ? e.target.files[0] : null });
  };

  handleOptionChange = (val: TabType) => {
    this.setState({ selectedOption: val });
  };

  tabLabels = ['自社購入', 'アリーズ', 'フロンティア'];

  async handleSearchTabClick(type: string): Promise<void> {
    if (type === this.state.currentTabType || this.tabLabels.indexOf(type) === -1) {
      return;
    }

    this.setState(
      {
        loading: true,
        currentTabType: type
      },
      () => {
        this.fetch();
      }
    );
  }

  handleAddOtherServiceShareholderInfoClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    const m = new OtherServiceShareholderInfo();
    this.state.otherServiceShareHolderInfos.unshift(m);
    this.setState({
      editingOtherServiceShareHolderInfo: m
    });
  }

  handleOtherServiceShareholderInfoFieldChange(
    name: keyof OtherServiceShareholderInfoObj,
    e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>
  ) {
    e.preventDefault();
    if (this.state.editingOtherServiceShareHolderInfo) {
      this.state.editingOtherServiceShareHolderInfo[name](e.target.value);
    }
  }

  handleOtherServiceShareholderInfoDeleteClick(m: OtherServiceShareholderInfo, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ deletingOtherServiceShareHolderInfo: m });
  }

  handleOtherServiceShareholderInfoDeleteAlertClose() {
    this.setState({ deletingOtherServiceShareHolderInfo: new OtherServiceShareholderInfo() });
  }

  handleOtherServiceShareholderInfoDeleteConfirm() {
    return Fetcher.delete(
      `/arrangement/other_service_shareholder_infos/${this.state.deletingOtherServiceShareHolderInfo.id}`,
      this.state.deletingOtherServiceShareHolderInfo.updateParams(this.getSupplierType())
    ).then(
      () => {
        this.state.otherServiceShareHolderInfos.remove(this.state.deletingOtherServiceShareHolderInfo);
        this.setState({
          deletingOtherServiceShareHolderInfo: new OtherServiceShareholderInfo(),
          saving: false
        });
        this.fetchOtherServiceShareholderInfos(this.getSupplierType());
      },
      error => {
        const state = {
          saving: false,
          saveError: ''
        };
        if (error.response.status === 400) {
          state.saveError = error.response.data.errors;
        } else {
          state.saveError = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        }
        this.setState(state);
      }
    );
  }

  handleOtherServiceShareholderInfoCancelClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (this.state.editingOtherServiceShareHolderInfo && !this.state.editingOtherServiceShareHolderInfo.id) {
      this.state.otherServiceShareHolderInfos.remove(this.state.editingOtherServiceShareHolderInfo);
    }
    this.setState({ editingOtherServiceShareHolderInfo: new OtherServiceShareholderInfo() });
  }

  handleOtherServiceShareholderInfoEditClick(m: OtherServiceShareholderInfo, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ editingOtherServiceShareHolderInfo: _.cloneDeep(m) });
  }

  handleOtherServiceShareholderInfoFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({
      saving: true,
      saveError: null
    });
    if (this.state.editingOtherServiceShareHolderInfo && this.state.editingOtherServiceShareHolderInfo.id) {
      this.otherServiceShareholderInfoSubmitUpdate(String(this.state.editingOtherServiceShareHolderInfo.id));
    } else {
      this.otherServiceShareholderInfoSubmitCreate(this.getSupplierType());
    }
  }

  otherServiceShareholderInfoSubmitCreate(supplierType: number) {
    if (this.state.editingOtherServiceShareHolderInfo) {
      const updateParams = this.state.editingOtherServiceShareHolderInfo.updateParams(supplierType);
      const warningMessage: string = this.checkDateRanges(this.state.otherServiceShareHolderInfos.list, {
        id: this.state.editingOtherServiceShareHolderInfo.id,
        fromDate: String(this.state.editingOtherServiceShareHolderInfo.from),
        toDate: String(this.state.editingOtherServiceShareHolderInfo.to)
      });

      if (warningMessage === '') {
        // warningMessage が空の場合、無条件で後続処理を実行
        return this.executeShareholderInfoSubmitCreate(supplierType, updateParams);
      }
      // warningMessage が空でない場合、ユーザーに確認を求める
      const userConfirmation = window.confirm(`${warningMessage}\n処理を続行しますか？`);
      if (userConfirmation) {
        return this.executeShareholderInfoSubmitCreate(supplierType, updateParams);
      }
      this.setState({
        saving: false
      });
    }
    return false;
  }

  executeShareholderInfoSubmitCreate(
    supplierType: number,
    updateParams: {
      from: string | undefined;
      to: string | undefined;
      purchase_price_ana: number | null | undefined;
      purchase_price_jal: number | null | undefined;
      purchase_price_sfj: number | null | undefined;
      supplier_type: number;
    }
  ) {
    return Fetcher.post<OtherServiceShareholderInfoPostResponse>(
      '/arrangement/other_service_shareholder_infos',
      updateParams
    ).then(
      res => {
        _.assign(this.state.editingOtherServiceShareHolderInfo, { id: res.id });
        if (this.state.editingOtherServiceShareHolderInfo) {
          this.fetchOtherServiceShareholderInfos(this.getSupplierType());
          this.setState({
            editingOtherServiceShareHolderInfo: new OtherServiceShareholderInfo(),
            saving: false
          });
        }
      },
      error => {
        const state: {
          saving: boolean;
          saveError: string | null;
        } = {
          saving: false,
          saveError: null
        };
        if (error.response.status === 400) {
          state.saveError = error.response.data.errors;
        } else {
          state.saveError = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        }
        this.setState(state);
      }
    );
  }

  otherServiceShareholderInfoSubmitUpdate(id: string) {
    if (this.state.editingOtherServiceShareHolderInfo) {
      const warningMessage: string = this.checkDateRanges(this.state.otherServiceShareHolderInfos.list, {
        id: this.state.editingOtherServiceShareHolderInfo.id,
        fromDate: String(this.state.editingOtherServiceShareHolderInfo.from),
        toDate: String(this.state.editingOtherServiceShareHolderInfo.to)
      });

      if (warningMessage === '') {
        return this.executeShareholderInfoSubmitUpdate(
          id,
          this.state.editingOtherServiceShareHolderInfo.updateParams(this.getSupplierType())
        );
      }
      const userConfirmation = window.confirm(`${warningMessage}\n処理を続行しますか？`);
      if (userConfirmation) {
        return this.executeShareholderInfoSubmitUpdate(
          id,
          this.state.editingOtherServiceShareHolderInfo.updateParams(this.getSupplierType())
        );
      }
      this.setState({
        saving: false
      });
    }
    return false;
  }

  executeShareholderInfoSubmitUpdate(
    id: string,
    updateParams: {
      from: string | undefined;
      to: string | undefined;
      purchase_price_ana: number | null | undefined;
      purchase_price_jal: number | null | undefined;
      purchase_price_sfj: number | null | undefined;
      supplier_type: number;
    }
  ) {
    return Fetcher.put<OtherServiceShareholderInfoPutResponse>(
      `/arrangement/other_service_shareholder_infos/${id}`,
      updateParams
    ).then(
      () => {
        if (this.state.editingOtherServiceShareHolderInfo) {
          this.state.otherServiceShareHolderInfos.update(this.state.editingOtherServiceShareHolderInfo);
          this.fetchOtherServiceShareholderInfos(this.getSupplierType());
          this.setState({
            editingOtherServiceShareHolderInfo: new OtherServiceShareholderInfo(),
            saving: false
          });
        }
      },
      error => {
        const state = {
          saving: false,
          saveError: ''
        };
        if (error.response.status === 400) {
          state.saveError = error.response.data.errors;
        } else {
          state.saveError = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        }
        this.setState(state);
      }
    );
  }

  checkDateRanges(
    ranges: OtherServiceShareholderInfo[],
    newRange: {
      id: number | null | undefined;
      fromDate: string;
      toDate: string;
    }
  ): string {
    const overlapError = '期間が重複しています。';
    const nonContinuousError = '期間が連続していません。';

    const formattedRanges = ranges.map(item => {
      return {
        id: item.id,
        fromDate: new Date(String(item.from)).getTime(),
        toDate: new Date(String(item.to)).getTime()
      };
    });

    const editingIndex = formattedRanges.findIndex(range => {
      return range.id === newRange.id;
    });

    if (editingIndex !== -1) {
      formattedRanges.splice(editingIndex, 1);
    }

    const fromTime = new Date(newRange.fromDate).getTime();
    const toTime = new Date(newRange.toDate).getTime();

    const overlappingRange = formattedRanges.find(range => {
      return (
        (range.fromDate <= fromTime && fromTime <= range.toDate) ||
        (range.fromDate <= toTime && toTime <= range.toDate) ||
        (fromTime <= range.fromDate && range.fromDate <= toTime) ||
        (fromTime <= range.toDate && range.toDate <= toTime)
      );
    });

    if (overlappingRange) {
      return overlapError;
    }

    let isContinuous = false;
    for (let i = 0; i < formattedRanges.length; i += 1) {
      const timeDifference = fromTime - formattedRanges[i].toDate;
      if (timeDifference === 24 * 60 * 60 * 1000) {
        // 1日のミリ秒数
        isContinuous = true;
        break;
      }
    }

    if (!isContinuous) {
      return nonContinuousError;
    }

    return ''; // No errors
  }

  render() {
    const state = this.state;
    const classBase = 'shareholder-tickets';
    return (
      <div className={classBase}>
        <FlashMessage
          message={state.saveError}
          type="error"
          handleFlashMessageClose={this.handleFlashMessageClose}
        />
        <h2>株主優待券</h2>
        {utils.isAiTravel(this.props.serviceId) && (
          <SearchTabs>
            {this.tabLabels.map((tabLabel, i) => (
              <SearchTab
                key={i}
                onClick={() => this.handleSearchTabClick(tabLabel)}
                current={state.currentTabType === tabLabel}
              >
                {tabLabel}
              </SearchTab>
            ))}
          </SearchTabs>
        )}
        {state.currentTabType === '自社購入' && (
          <>
            <SelectModel>
              {AllTab.map(tab => (
                <Label key={tab}>
                  <input
                    type="radio"
                    checked={state.selectedOption === tab}
                    onChange={() => this.handleOptionChange(tab)}
                    style={{ marginRight: '0.375em' }}
                  />
                  {Tab[tab]}
                </Label>
              ))}
            </SelectModel>
            {state.selectedOption === 'LIST' && (
              <>
                <form
                  onSubmit={e => this.handleSubmit(e)}
                  style={{ display: 'flex', alignItems: 'center', padding: '10px', flexWrap: 'wrap' }}
                >
                  <div className={`${classBase}__search-form`}>
                    <span className={`${classBase}__search-label`}>航空会社:</span>
                    <span className={`${classBase}__search-select`}>
                      <select
                        value={state.companyType || ''}
                        onChange={e => {
                          if (e.target.value === '') {
                            this.setState({ companyType: null });
                          } else if (_.includes(['ANA', 'JAL', 'SFJ'], e.target.value)) {
                            this.setState({ companyType: e.target.value as CompanyType });
                          }
                        }}
                      >
                        <option value="">全て</option>
                        <option value="ANA">ANA</option>
                        <option value="JAL">JAL</option>
                        <option value="SFJ">SFJ</option>
                      </select>
                    </span>
                  </div>
                  <div className={`${classBase}__search-form`}>
                    <span className={`${classBase}__search-label`}>有効期限:</span>
                    <span className={`${classBase}__search-select`}>
                      <select
                        value={state.expireAt}
                        onChange={e => this.handleExpiredAtChange(parseInt(e.target.value, 10))}
                      >
                        <option value="0">期限内</option>
                        <option value="1">期限切れ</option>
                        <option value="2">両方</option>
                      </select>
                    </span>
                  </div>
                  <div className={`${classBase}__search-form`}>
                    <span className={`${classBase}__search-label`}>利用状況:</span>
                    <span className={`${classBase}__search-select`}>
                      <select
                        value={state.status}
                        onChange={e => this.handleStatusChange(parseInt(e.target.value, 10))}
                      >
                        <option value="0">未使用</option>
                        <option value="1">使用済み</option>
                        <option value="2">両方</option>
                      </select>
                    </span>
                  </div>
                  <div className={`${classBase}__search-form`}>
                    <span className={`${classBase}__search-label`}>削除状況:</span>
                    <span className={`${classBase}__search-select`}>
                      <select
                        value={state.isDeleted}
                        onChange={e => this.handleIsDeletedChange(parseInt(e.target.value, 10))}
                      >
                        <option value="0">未削除</option>
                        <option value="1">削除済み</option>
                        <option value="2">両方</option>
                      </select>
                    </span>
                  </div>
                  <div className={`${classBase}__search-form`} style={{ display: 'flex' }}>
                    <span className={`${classBase}__search-label`}>購入価格:</span>
                    <input
                      value={state.price || ''}
                      onChange={e =>
                        this.setState({ price: e.target.value === '' ? null : parseInt(e.target.value, 10) })
                      }
                      style={{ width: '70px' }}
                    />
                  </div>
                  <SearchForm>
                    <SearchLabel>購入日</SearchLabel>
                    <Flex alignItems="center" gap="4px">
                      <Datepicker
                        value={state.fromDate ?? undefined}
                        onChange={d => this.handleFromDateChange(d)}
                        placeholder="開始日"
                      />
                      ~
                      <Datepicker
                        value={state.toDate ?? undefined}
                        onChange={d => this.handleToDateChange(d)}
                        placeholder="終了日"
                      />
                    </Flex>
                  </SearchForm>
                  <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <Button type="submit" disabled={state.loading} style={{ marginRight: '10px' }}>
                      検索
                    </Button>
                    {state.editingTicket == null ? (
                      <Button type="submit" onClick={e => this.handleAddClick(e)}>
                        追加
                      </Button>
                    ) : null}
                  </div>
                </form>
                <Button onClick={() => this.handleCsvDownload()}>CSVダウンロード</Button>
                <Button onClick={() => this.handleShowUploadModal()} style={{ marginLeft: '10px' }}>
                  CSVアップロード
                </Button>
                <div style={{ marginTop: '20px', fontSize: '12px' }}>
                  <div>検索結果</div>
                  {['ANA', 'JAL', 'SFJ'].map(c => (
                    <div key={c}>
                      {c}: {state.tickets.countByCompany(c)}枚 {utils.formatPrice(state.tickets.totalByCompany(c))}
                    </div>
                  ))}
                </div>
                <form onSubmit={e => this.handleFormSubmit(e)}>
                  {state.loading || state.saving ? (
                    <div className="shareholder-tickets__loadling">
                      <SimpleLoading />
                    </div>
                  ) : (
                    <table className="shareholder-tickets__table">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Trip ID</th>
                          <th>使用状況</th>
                          <th>航空会社</th>
                          <th>購入価格</th>
                          <th>購入日</th>
                          <th>利用期限</th>
                          <th>優待券番号</th>
                          <th>パスワード</th>
                          <th>備考</th>
                          <th>失効日</th>
                          <th />
                          <th />
                        </tr>
                      </thead>
                      {state.tickets.list.map(ticket => (
                        <ShareholderBlock
                          key={ticket.id}
                          tickets={state.tickets}
                          ticket={ticket}
                          editingTicket={state.editingTicket}
                          priceError={
                            state.editingTicket && state.editingTicket.id === ticket.id ? state.priceError : null
                          }
                          handleFieldChange={(name, e) => this.handleFieldChange(name, e)}
                          handleCancelClick={e => this.handleCancelClick(e)}
                          handleEditClick={(ticket, e) => this.handleEditClick(ticket, e)}
                          handleDeleteClick={(ticket, e) => this.handleDeleteClick(ticket, e)}
                        />
                      ))}
                    </table>
                  )}
                </form>
                {state.deletingTicket.id ? (
                  <Modal show hideModal={this.handleDeleteAlertClose}>
                    <Confirm
                      onConfirmed={this.handleDeleteConfirm}
                      onAborted={this.handleDeleteAlertClose}
                      message={`${state.deletingTicket.number} を削除してよろしいですか？\n※この操作は取り消せません`}
                    />
                  </Modal>
                ) : null}
              </>
            )}
            {state.selectedOption === 'HISTORY' && <ShareholderTicketHistories />}
          </>
        )}
        {state.currentTabType !== '自社購入' && state.editingOtherServiceShareHolderInfo ? (
          <Button type="submit" onClick={e => this.handleAddOtherServiceShareholderInfoClick(e)}>
            追加
          </Button>
        ) : null}
        {state.currentTabType !== '自社購入' && (
          <form onSubmit={e => this.handleOtherServiceShareholderInfoFormSubmit(e)}>
            {state.loading || state.saving ? (
              <div className="message-templates__loadling">
                <SimpleLoading />
              </div>
            ) : (
              <table className="message-templates__table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>適用開始日</th>
                    <th>適用終了日</th>
                    <th>仕入金額(ANA)</th>
                    <th>仕入金額(JAL)</th>
                    {this.getSupplierType() === 1 && <th>仕入金額(SFJ)</th>}
                    <th />
                    <th />
                  </tr>
                </thead>
                {this.state.otherServiceShareHolderInfos.list.map((otherServiceShareHolderInfo, i) => (
                  <OtherServiceShareholderInfoBlock
                    key={i}
                    supplierType={this.getSupplierType()}
                    otherServiceShareholderInfos={state.otherServiceShareHolderInfos}
                    otherServiceShareholderInfo={otherServiceShareHolderInfo}
                    editingOtherServiceShareholderInfo={state.editingOtherServiceShareHolderInfo}
                    handleFieldChange={this.handleOtherServiceShareholderInfoFieldChange}
                    handleEditClick={this.handleOtherServiceShareholderInfoEditClick}
                    handleDeleteClick={this.handleOtherServiceShareholderInfoDeleteClick}
                    handleCancelClick={this.handleOtherServiceShareholderInfoCancelClick}
                  />
                ))}
              </table>
            )}
          </form>
        )}
        {this.state.deletingOtherServiceShareHolderInfo.id
          ? [
              <Modal key={1} show hideModal={this.handleOtherServiceShareholderInfoDeleteAlertClose}>
                <Confirm
                  onConfirmed={this.handleOtherServiceShareholderInfoDeleteConfirm}
                  onAborted={this.handleOtherServiceShareholderInfoDeleteAlertClose}
                  message={`${this.state.deletingOtherServiceShareHolderInfo.from} - ${this.state.deletingOtherServiceShareHolderInfo.to} を削除してよろしいですか？\n※この操作は取り消せません`}
                />
              </Modal>
            ]
          : null}
        {state.showUploadModal ? (
          <Modal show hideModal={this.handleCloseUploadModal}>
            <form onSubmit={this.handleUploadCsv}>
              <div style={{ padding: '20px' }}>
                <input type="file" name="file" accept="text/csv" onChange={this.handleChangeFile} />
                <Button type="submit" disabled={!this.state.file || this.state.uploading}>
                  アップロード
                </Button>
              </div>
            </form>
            <div style={{ padding: '20px' }}>
              <Paper style={{ color: 'gray', padding: '20px' }}>
                <ul>
                  <li style={{ marginBottom: '10px' }}>
                    <p style={{ fontWeight: 'bold' }}>CSVファイルの形式：</p>
                    <ul style={{ marginLeft: '10px' }}>
                      <li>1行目：ヘッダー</li>
                      <li>2行目以降：データ</li>
                    </ul>
                  </li>
                  <li style={{ marginBottom: '10px' }}>
                    <p style={{ fontWeight: 'bold' }}>ヘッダー：</p>
                    <p style={{ marginLeft: '10px' }}>
                      company_type,price,purchased_at,expire_at,number,password,remarks
                    </p>
                  </li>
                  <li>
                    <p style={{ fontWeight: 'bold' }}>データ：</p>
                    <ul style={{ marginLeft: '10px' }}>
                      <li>company_type: 航空会社種別(ANA or JAL or SFJ)</li>
                      <li>price: 金額</li>
                      <li>purchased_at: 購入日(YYYY-MM-DD)</li>
                      <li>expire_at: 有効期限(YYYY-MM-DD)</li>
                      <li>number: 券番号</li>
                      <li>password: パスワード</li>
                      <li>remarks: 備考(任意)</li>
                    </ul>
                  </li>
                </ul>
              </Paper>
            </div>
            {this.state.uploadErrors.length > 0 ? (
              <div style={{ padding: '20px' }}>
                {this.state.uploadErrors.map((error, i) => (
                  <p className="error" key={i}>
                    {error}
                  </p>
                ))}
              </div>
            ) : null}
          </Modal>
        ) : null}
      </div>
    );
  }
}

const SearchForm = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const SearchLabel = styled.span`
  margin-right: 10px;
  float: left;
`;

const SelectModel = styled.div`
  display: flex;
  margin-bottom: 10px;
  font-size: 12px;
`;

const Label = styled.label`
  margin-right: 20px;
`;

const SearchTabs = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const SearchTab = styled.div<{ current: boolean }>`
  padding: 10px 10px 3px;
  font-weight: bold;

  ${props =>
    props.current
      ? `
    border-bottom: solid 2px ${props.theme.linkColor};
  `
      : `
    cursor: pointer;
    &:hover {
      color: ${props.theme.linkColor};
    }
  `}
`;

export default ShareholderTickets;

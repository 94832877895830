/* eslint-disable max-lines */
import React from 'react';
import { withTheme } from 'styled-components';
import { styled } from '@this/constants/themes';
import A from '@this/shared/atoms/a';
import Link from '@this/shared/atoms/link';
import { ButtonBase, BorderButtonBase } from '@this/shared/atoms/button';
import Markdown from '@this/shared/markdown/markdown';
import _ from 'lodash';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { toDomesticTel, escapeHTML } from '@this/src/util';
import moment from '../../../../../lib/moment';

import type Traveler from '../../../../../domain/traveler/traveler';

import type { Props as ComponentProps, State as ComponentState } from './confirm_customer_information';

import OrderItemMappingListSection from './order_item_mapping_list_section/order_item_mapping_list_section';

const parse = (text: string) => {
  const safeText = escapeHTML(text);

  let result = safeText.replace(/\[(.+)\]\((.+)\)/gm, '<a href="$2" target="_blank">$1</a>');
  result = result.replace(/\*\*(.+)\*\*/, '<span class="bold">$1</span>');
  return result;
};

export type Props = {
  handleSaveDraft?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleConfirmed: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleAgreementTermsCheck: () => void;
  handleExicPasswordType: (i: number) => void;
  theme: { themeClass: string };
} & ComponentProps &
  ComponentState;

const ConfirmCustomerInformationTemplate: React.SFC<Props> = ({
  isCustomerInfoRequired,
  reserveInfo,
  reservingTrip,
  paymentLoading,
  paymentFailed,
  paymentErrors,
  // disconnected,
  handleBackToStep1Click,
  handleSaveDraft,
  handleConfirmed,
  handleAgreementTermsCheck,
  termsAgree,
  theme,
  prefecture,
  handleExicPasswordType,
  exicPasswordTypes
}) => (
  <>
    <Title>予約内容を確認してください</Title>
    <ChildWrapper>
      {isCustomerInfoRequired() && (
        <Section>
          <Subtitle>出張者</Subtitle>
          <ChildContent>
            {reserveInfo.travelers.list.map((traveler, i) => (
              <div key={i}>
                <TravelerPeopleNum>{i + 1}人目</TravelerPeopleNum>
                <TravelerBox>
                  <div className="flex">
                    <Label>氏名（ローマ字）</Label>
                    <Label data-wovn-ignore>
                      {traveler.lastNameRoman} {traveler.firstNameRoman}
                    </Label>
                  </div>
                  <div className="flex">
                    <Label>氏名（カナ）</Label>
                    <Label data-wovn-ignore>
                      {traveler.lastNameKana} {traveler.firstNameKana}
                    </Label>
                  </div>
                  {reservingTrip.isFlightCustomerInfoRequired() && (
                    <div className="flex">
                      <Label data-wovn-ignore>誕生日</Label>
                      <Label>
                        {traveler.type === 'companion' || traveler.id === reserveInfo.user.id
                          ? traveler.birthday
                          : '****-**-**(プライバシー保護のため非表示)'}
                      </Label>
                    </div>
                  )}
                  {(reservingTrip.isFlightCustomerInfoRequired() || reservingTrip.isHotelInfoRequired()) && (
                    <div className="flex">
                      <Label>性別</Label>
                      {traveler.flightGender === 'male' ? (
                        <Label>男性</Label>
                      ) : traveler.flightGender === 'female' ? (
                        <Label>女性</Label>
                      ) : null}
                    </div>
                  )}
                </TravelerBox>
              </div>
            ))}
          </ChildContent>
        </Section>
      )}
      {reservingTrip.isFlightCustomerInfoRequired() && (
        <Section>
          <Subtitle>航空券</Subtitle>
          <ChildContent>
            {reserveInfo.travelers.list.map((traveler, i) => (
              <div key={i}>
                <PeopleNum>{i + 1}人目</PeopleNum>
                <TravelerBox>
                  {reservingTrip.isForeignFlightCustomerInfoRequired() ? (
                    <>
                      <div className="flex">
                        <Label>姓（LastName）</Label>
                        <Label data-wovn-ignore>{traveler.flightLastNameRoman}</Label>
                      </div>
                      <div className="flex">
                        <Label>ミドルネーム（MiddleName）</Label>
                        <Label data-wovn-ignore>{traveler.flightMiddleNameRoman}</Label>
                      </div>
                      <div className="flex">
                        <Label>名（FirstName）</Label>
                        <Label data-wovn-ignore>{traveler.flightFirstNameRoman}</Label>
                      </div>
                      <SmallGray>
                        ※1文字でもパスポートの表記と異なるとご搭乗いただけません。
                        <br />
                        ※各航空会社の定めにより予約後の訂正はできないため、キャンセルと新たな予約が必要となります。その場合規定の手数料が発生いたします。
                        {theme.themeClass !== 'mynavi' && (
                          <>
                            手数料の詳細については
                            <A href="/charges" target="_blank">
                              旅行業務取扱い料金表
                            </A>
                            をご確認ください。
                          </>
                        )}
                      </SmallGray>
                    </>
                  ) : (
                    <>
                      <div className="flex">
                        <Label>氏名（カナ）</Label>
                        <Label data-wovn-ignore>
                          {traveler.flightLastNameKana} {traveler.flightFirstNameKana}
                        </Label>
                      </div>
                      <div className="flex">
                        <Label>氏名（アルファベット）</Label>
                        <Label data-wovn-ignore>
                          {traveler.flightLastNameRoman} {traveler.flightFirstNameRoman}
                        </Label>
                      </div>
                      <SmallGray>
                        ※各航空会社の定めにより予約後の訂正はできないため、キャンセルと新たな予約が必要となります。その場合規定の手数料が発生いたします。
                        {theme.themeClass !== 'mynavi' && (
                          <>
                            手数料の詳細については
                            <A href="/charges" target="_blank">
                              旅行業務取扱い料金表
                            </A>
                            をご確認ください。
                          </>
                        )}
                      </SmallGray>
                    </>
                  )}
                  <div className="flex">
                    <Label>性別</Label>
                    {traveler.flightGender === 'male' ? (
                      <Label>男性</Label>
                    ) : traveler.flightGender === 'female' ? (
                      <Label>女性</Label>
                    ) : null}
                  </div>
                  <div className="flex">
                    <Label>誕生日</Label>
                    <Label>
                      {traveler.type === 'companion' || traveler.id === reserveInfo.user.id
                        ? traveler.birthday
                        : '****-**-**(プライバシー保護のため非表示)'}
                    </Label>
                  </div>
                  {reservingTrip.isForeignFlightCustomerInfoRequired() && (
                    <div className="flex">
                      <Label>国籍</Label>
                      <Label>{reserveInfo.getNationalityName(traveler.nationalityId)}</Label>
                    </div>
                  )}
                  <div className="flex">
                    <Label>緊急連絡先電話番号</Label>
                    <Label data-wovn-ignore>{toDomesticTel(traveler.flightTel)}</Label>
                  </div>
                  {reservingTrip.isForeignFlightCustomerInfoRequired() && (
                    <>
                      <div className="flex">
                        <Label>パスポート番号</Label>
                        <Label data-wovn-ignore>{traveler.passportNumber}</Label>
                      </div>
                      <div className="flex">
                        <Label>パスポート有効期限</Label>
                        <Label data-wovn-ignore>{traveler.passportExpire}</Label>
                      </div>
                      <div className="flex">
                        <Label>パスポート発行国</Label>
                        <Label data-wovn-ignore>{traveler.passportIssuedCoutry}</Label>
                      </div>
                    </>
                  )}
                  <div className="flex">
                    <Label>座席の希望</Label>
                    <Label>{traveler.airSeat}</Label>
                  </div>
                  {!reserveInfo.user.organization?.setting?.disable_show_milage_number && (
                    <>
                      <div className="flex">
                        <Label>マイル番号</Label>
                        <Label data-wovn-ignore>{traveler.mileageNumber}</Label>
                      </div>
                      {traveler.mileageNumbers && traveler.mileageNumbers.length > 0 && (
                        <div className="flex">
                          <Label>登録済みマイル番号</Label>
                          <Label data-wovn-ignore>
                            <div>
                              {traveler.mileageNumbers.map((mileage, i) => (
                                <div key={i}>
                                  {mileage.carrier}:
                                  {traveler.type === 'companion' || traveler.id === reserveInfo.user.id
                                    ? mileage.number
                                    : '**********(プライバシー保護のため非表示)'}
                                </div>
                              ))}
                            </div>
                          </Label>
                        </div>
                      )}
                    </>
                  )}
                  {reserveInfo.user.organization.immigration_card_enabled && (
                    <div className="flex">
                      <Label>出入国カード作成代行</Label>
                      <Label>{traveler.immigrationCardCreation ? 'はい' : 'いいえ'}</Label>
                    </div>
                  )}
                  {reserveInfo.user.organization.visa_application_enabled && (
                    <div className="flex">
                      <Label>ビザ申請代行</Label>
                      <Label>{traveler.visaApplication ? 'はい' : 'いいえ'}</Label>
                    </div>
                  )}
                  <A href="https://aitravel.jp/knowledges/000001151" target="_blank">
                    ビザの必要な国
                  </A>
                </TravelerBox>
              </div>
            ))}
            <SmallRed>
              {reservingTrip.isForeignFlightCustomerInfoRequired()
                ? '※海外への渡航をご予定の場合、氏名等がパスポートの記載と異なると、当日ご利用いただけない場合がございます。'
                : '※氏名、誕生日が間違っていると搭乗できない場合がありますのでご注意ください。'}
            </SmallRed>
          </ChildContent>
        </Section>
      )}
      {reservingTrip.isShinkansenInfoRequired() && (
        <Section>
          <Subtitle>新幹線・特急</Subtitle>
          <ChildContent>
            <div className="flex">
              {reserveInfo.ticket !== 'ex' && !reserveInfo.user.organization.use_qr && (
                <>
                  <Label>切符送り先</Label>
                  {reserveInfo.shinkansenAddressType === 'text' && (
                    <div style={{ display: 'block' }}>
                      <Label data-wovn-ignore>{reserveInfo.postcode}</Label>
                      <Label data-wovn-ignore>{reserveInfo.shinkansenAddress}</Label>
                      <Label data-wovn-ignore>{reserveInfo.shinkansenAddressee}</Label>
                    </div>
                  )}
                  {reserveInfo.shinkansenAddressType === 'select' && (
                    <div style={{ display: 'block' }}>
                      <Label data-wovn-ignore>
                        {reserveInfo.organizationAddress && reserveInfo.organizationAddress.postalCode}
                      </Label>
                      <Label data-wovn-ignore>
                        {reserveInfo.organizationAddress && reserveInfo.organizationAddress.location}
                      </Label>
                      <Label data-wovn-ignore>
                        {reserveInfo.organizationAddress && reserveInfo.organizationAddress.addressee}
                      </Label>
                    </div>
                  )}
                  {reserveInfo.shinkansenAddressType === 'organization_base_select' && (
                    <div style={{ display: 'block' }}>
                      <Label data-wovn-ignore>
                        {reserveInfo.organizationBase && reserveInfo.organizationBase.postalCode}
                      </Label>
                      <Label data-wovn-ignore>
                        {reserveInfo.organizationBase && reserveInfo.organizationBase.address}
                      </Label>
                      <Label data-wovn-ignore>
                        {reserveInfo.organizationBase && reserveInfo.organizationBase.addressee}
                      </Label>
                    </div>
                  )}
                </>
              )}
            </div>
            {!reserveInfo.isSeparateShinkansenType && (
              <>
                {reserveInfo.ticket !== 'bulk_ticket' && (
                  <div className="flex">
                    <Label>座席指定</Label>
                    <div>
                      {reserveInfo.seatText()}
                      <Small>※指定席以外を選択された場合、変更後の料金を改めてご連絡いたします。</Small>
                    </div>
                  </div>
                )}
                <div className="flex">
                  <Label>チケットの券種</Label>
                  <div>{reserveInfo.ticketText()}</div>
                </div>
              </>
            )}
            {(reserveInfo.seat === 'reserve' || reserveInfo.seat === 'green' || reserveInfo.ticket === 'ex') &&
              reserveInfo.travelers.list.map((traveler, i) => (
                <div key={i}>
                  <PeopleNum>
                    {i + 1}人目：{traveler.lastNameRoman} {traveler.firstNameRoman}
                  </PeopleNum>
                  <TravelerBox>
                    {reserveInfo.isSeparateShinkansenType && (
                      <>
                        <TravelerInputArea>
                          <Label>チケットの券種</Label>
                          <p>{traveler.getShinkansenTicketTypeText()}</p>
                        </TravelerInputArea>
                        {traveler.shinkansenTicketType !== 'bulk_ticket' && (
                          <TravelerInputArea>
                            <Label>座席</Label>
                            <p>{traveler.getShinkansenSeatTypeText()}</p>
                          </TravelerInputArea>
                        )}
                      </>
                    )}
                    {traveler.shinkansenSeatType !== 'free' && (
                      <TravelerInputArea>
                        <Label>座席の希望</Label>
                        <p>{traveler.shinkansenSeat}</p>
                        {traveler.requestShinkansenSeat && <p>:{traveler.requestShinkansenSeat}</p>}
                      </TravelerInputArea>
                    )}
                    {(reserveInfo.isSeparateShinkansenType
                      ? traveler.shinkansenTicketType
                      : reserveInfo.ticket) === 'ex' && (
                      <>
                        <TravelerInputArea>
                          <Label>EX-IC番号</Label>
                          <p>{traveler.exicNumber}</p>
                        </TravelerInputArea>
                        <TravelerInputArea>
                          <Label>EX-ICパスワード</Label>
                          <p>
                            {exicPasswordTypes[i] === 'password'
                              ? traveler.exicPassword.replace(/./g, '*')
                              : traveler.exicPassword}
                          </p>
                          <span
                            onClick={async () => {
                              await handleExicPasswordType(i);
                            }}
                          >
                            {exicPasswordTypes[i] === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </span>
                        </TravelerInputArea>
                      </>
                    )}
                  </TravelerBox>
                </div>
              ))}
          </ChildContent>
        </Section>
      )}
      {reservingTrip.isHotelInfoRequired() && (
        <Section>
          <Subtitle>宿泊</Subtitle>
          {reserveInfo.hotels.map((hotel, i) => (
            <div key={`hotel_${String(i)}`}>
              <HotelName>{hotel.name}</HotelName>
              {hotel.rooms &&
                hotel.rooms.map((room, j) => (
                  <div key={`room_${String(j)}`}>
                    <RoomNum>{j + 1}室目</RoomNum>
                    <ChildContent>
                      <div className="flex">
                        <Label>宿泊代表者氏名（ローマ字）</Label>
                        <Label data-wovn-ignore>
                          {room.hotelLastName} {room.hotelFirstName}
                        </Label>
                      </div>
                      <div className="flex">
                        <Label>宿泊代表者氏名（カナ）</Label>
                        <Label data-wovn-ignore>
                          {room.hotelLastNameKana} {room.hotelFirstNameKana}
                        </Label>
                      </div>
                      <div className="flex">
                        <Label>宿泊代表者電話番号</Label>
                        <Label data-wovn-ignore>{toDomesticTel(room.hotelTel)}</Label>
                      </div>
                      {room.bedTypeDescription && (
                        <div className="flex">
                          <Label>ベッドの種類</Label>
                          <Label>{room.bedTypeDescription}</Label>
                        </div>
                      )}
                    </ChildContent>
                  </div>
                ))}
            </div>
          ))}
        </Section>
      )}
      {!reservingTrip.isForeignFlightCustomerInfoRequired() && (
        <>
          {reserveInfo.rentalCarAvailable && (
            <Section>
              <Subtitle>
                <SubtitleLeft>レンタカー</SubtitleLeft>
                <SubtitleRight>※オプション</SubtitleRight>
              </Subtitle>
              <ChildContent>
                <TravelerBox>
                  {reserveInfo.rentalCarRequired === 'true' && (
                    <>
                      {reserveInfo.rentalCars.map((rentalCar, i) => (
                        <div key={i}>
                          <RentalCarOrderTitle>ご依頼内容{i !== 0 && i + 1}</RentalCarOrderTitle>
                          <RentalCarOrderContentConfirm>
                            <div className="reserve-confirm__traveler__rentalcar_input-area-left">
                              <TravelerRentalCarLabel>出発日時</TravelerRentalCarLabel>
                              <TravelerInputAreaRentalCarText>
                                {moment(rentalCar.departureDate).format('YYYY月MM月DD日HH時mm分')}
                              </TravelerInputAreaRentalCarText>
                            </div>
                            <div className="reserve-confirm__traveler__rentalcar_input-area-left">
                              <TravelerRentalCarLabel>返却日時</TravelerRentalCarLabel>
                              <TravelerInputAreaRentalCarText>
                                {moment(rentalCar.returnDate).format('YYYY月MM月DD日HH時mm分')}
                              </TravelerInputAreaRentalCarText>
                            </div>
                            <div className="reserve-confirm__traveler__rentalcar_input-area-left">
                              <TravelerRentalCarLabel>出発場所</TravelerRentalCarLabel>
                              <TravelerInputAreaRentalCarText>
                                {rentalCar.departurePlace}
                              </TravelerInputAreaRentalCarText>
                            </div>
                            <TravelerInputArea>
                              <TravelerRentalCarLabel>返却場所</TravelerRentalCarLabel>
                              <TravelerInputAreaRentalCar>
                                {rentalCar.returnPlace === 'current' ? (
                                  <RentalCarRadio>
                                    <span>出発場所へ戻す</span>
                                  </RentalCarRadio>
                                ) : rentalCar.returnPlace === 'other' ? (
                                  <>
                                    <RentalCarRadio>
                                      <span>別の場所へ乗り捨てる</span>
                                    </RentalCarRadio>
                                    <RentalCarRadio>
                                      <span>{rentalCar.returnPlaceName}</span>
                                    </RentalCarRadio>
                                  </>
                                ) : null}
                              </TravelerInputAreaRentalCar>
                            </TravelerInputArea>
                            <TravelerInputArea>
                              <TravelerRentalCarLabel>
                                車種・クラス ※選択いただいたタイプから適切な車両をお選びします。
                              </TravelerRentalCarLabel>
                              <TravelerInputAreaRentalCarType>
                                <RentalCarRadio>
                                  {rentalCar.carTypeOptions.map(
                                    (c, i) =>
                                      rentalCar.carType === c[1] && (
                                        <p id="car_type_text" key={`car_type_confirm_${i}`}>
                                          {c[0]}
                                        </p>
                                      )
                                  )}
                                </RentalCarRadio>
                                {rentalCar.carType === 'other' && (
                                  <RentalCarRadio>
                                    <p id="car_type_text">{rentalCar.carTypeOther}</p>
                                  </RentalCarRadio>
                                )}
                              </TravelerInputAreaRentalCarType>
                            </TravelerInputArea>
                            <TravelerInputArea>
                              <TravelerRentalCarLabel>運転者氏名（カナ）</TravelerRentalCarLabel>
                              <TravelerInputAreaRentalCar>
                                <RentalCarRadio data-wovn-ignore>
                                  {rentalCar.lastNameKana} {rentalCar.firstNameKana}
                                </RentalCarRadio>
                              </TravelerInputAreaRentalCar>
                            </TravelerInputArea>
                            <TravelerInputArea>
                              <TravelerRentalCarLabel>運転者電話番号</TravelerRentalCarLabel>
                              <TravelerInputAreaRentalCar>
                                <RentalCarRadio data-wovn-ignore>{rentalCar.driverTel}</RentalCarRadio>
                              </TravelerInputAreaRentalCar>
                            </TravelerInputArea>
                            <TravelerInputArea>
                              <TravelerRentalCarLabel>備考</TravelerRentalCarLabel>
                              <TravelerInputAreaRentalRemarksConfirm>
                                <span data-wovn-ignore>{rentalCar.remarks}</span>
                              </TravelerInputAreaRentalRemarksConfirm>
                            </TravelerInputArea>
                          </RentalCarOrderContentConfirm>
                        </div>
                      ))}
                    </>
                  )}
                </TravelerBox>
              </ChildContent>
            </Section>
          )}
        </>
      )}
      <Section>
        {reserveInfo.user.organization.reserve_request_mail_notification_type === 'selectable_user' ? (
          <>
            <Subtitle>予約依頼に関するメール通知対象者の選択</Subtitle>
            <ChildContent>
              {reserveInfo.notifiedUsers.map((user: Traveler | undefined, i: number) => (
                <>
                  {user && <NotifiedUser data-wovn-ignore key={i}>{`${user.name}(${user.email})`}</NotifiedUser>}
                </>
              ))}
            </ChildContent>
          </>
        ) : (
          <>
            <Subtitle>予約依頼に関するメール通知の有無</Subtitle>
            <ChildContent>
              <div className="flex">
                {reserveInfo.allTravelersNotification ? (
                  <div>予約内容を関係者に通知する</div>
                ) : (
                  <div>予約内容を関係者に通知しない</div>
                )}
              </div>
            </ChildContent>
          </>
        )}
      </Section>
      <Section>
        <Subtitle>ワークフロー</Subtitle>
        <ChildContent>
          {!reserveInfo.user.organization.freee_workflow && reserveInfo.isApprovalRequired() && (
            <>
              {reserveInfo.workflowStyle === 'trip' ? (
                <>
                  {reserveInfo.approveStages.map((stage, i) => (
                    <div className="flex" key={`confirm_approver_${i}`}>
                      <Label>{stage.stage}次承認者</Label>
                      <Label data-wovn-ignore>{stage.approvers.map(a => a.name).join(', ')}</Label>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {reserveInfo.getApplicant().department_approvers!.list.map(
                    (a, i) =>
                      (i === 0 ||
                        a.approve_stage >
                          reserveInfo.getApplicant().department_approvers!.list[i - 1].approve_stage) && (
                        <div className="flex" key={`confirm_approver_${i}`}>
                          <Label>{a.approve_stage}次承認者</Label>
                          <Label>
                            {reserveInfo
                              .getApplicant()
                              .department_approvers!.joinApproverByApproveStage(a.approve_stage)
                              .join(', ')}
                          </Label>
                        </div>
                      )
                  )}
                </>
              )}
            </>
          )}
          <div className="flex">
            <Label>出張先</Label>
            <TravelPurposeLabel data-wovn-ignore>{reserveInfo.finalDestination}</TravelPurposeLabel>
          </div>
          <div className="flex">
            <Label>出張の目的</Label>
            <TravelPurposeLabel data-wovn-ignore>{reserveInfo.purpose}</TravelPurposeLabel>
          </div>
          <div className="flex">
            <Label>社内管理番号</Label>
            <Label data-wovn-ignore>{reserveInfo.internalNumber}</Label>
          </div>
          {reserveInfo.orderItemMappingsDepartmentPart.list.length >= 1 && (
            <div className="flex">
              <Label>費用負担部署</Label>
              {reserveInfo.chargingDepartment ? (
                <Label data-wovn-ignore>
                  {reserveInfo.chargingDepartment.code}：{reserveInfo.chargingDepartment.name}
                </Label>
              ) : (
                <OrderItemMappingListSection
                  data-wovn-ignore
                  reserveInfo={reserveInfo}
                  mappingList={reserveInfo.orderItemMappingsDepartmentPart}
                />
              )}
            </div>
          )}
          {/* - project = reserveInfo.getProject() */}
          {reserveInfo.orderItemMappingsProjectPart.list.length >= 1 && (
            <div className="flex">
              <Label>プロジェクト</Label>
              {reserveInfo.getProject() ? (
                <Label data-wovn-ignore>{reserveInfo.getProject()!.codeAndName()}</Label>
              ) : (
                <OrderItemMappingListSection
                  data-wovn-ignore
                  reserveInfo={reserveInfo}
                  mappingList={reserveInfo.orderItemMappingsProjectPart}
                />
              )}
            </div>
          )}
          {reserveInfo.expensesAccountTypeAvailable &&
            reserveInfo.isExpensesAcountTypeShown() &&
            reserveInfo.orderItemMappingsExpensesPart.list.length >= 1 && (
              <div className="flex">
                <Label>勘定科目</Label>
                {reserveInfo.orderItemMappingsExpensesPart.mappingMode() === 'default' ? (
                  <Label data-wovn-ignore>
                    {reserveInfo.orderItemMappingsExpensesPart.findDefaultMapping()!.selectedOptionLabel}
                  </Label>
                ) : (
                  <OrderItemMappingListSection
                    data-wovn-ignore
                    reserveInfo={reserveInfo}
                    mappingList={reserveInfo.orderItemMappingsExpensesPart}
                  />
                )}
              </div>
            )}
          {reserveInfo.approveItems.list.map((d: any, i: number) => (
            <div className="flex" key={d.id}>
              {d.dataType === 'label' ? (
                <ApproveItemLabelOnly style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
                  <Markdown markdownText={d.userDisplayName} openLinksNewTab />
                </ApproveItemLabelOnly>
              ) : (
                <>
                  <Label>{d.userDisplayName}</Label>
                  <Label data-wovn-ignore>
                    {reserveInfo.getApproveItemValueCode(d.id) && (
                      <>{reserveInfo.getApproveItemValueCode(d.id)}：</>
                    )}
                    {reserveInfo.getApproveItemValue(d.id)}
                  </Label>
                </>
              )}
            </div>
          ))}
        </ChildContent>
      </Section>
      {theme.themeClass === 'aitravel' && (
        <Section>
          <Subtitle>支払い方法</Subtitle>
          <ChildContent>
            {reserveInfo.paymentMethodType === 'billing' ? (
              <Label>{reserveInfo.user.organization.name}宛に請求</Label>
            ) : reserveInfo.paymentMethodType === 'card' && reserveInfo.paymentTransaction?.uid === 'new' ? (
              <Label>新しいクレジットカード *****{reserveInfo.cardNum04}</Label>
            ) : (
              <Label>クレジットカード {reserveInfo.selectedCardName()}</Label>
            )}
          </ChildContent>
        </Section>
      )}
      <Section>
        <Small>
          {reservingTrip.packageType === 'airPackage' && theme.themeClass === 'mynavi' ? (
            <>
              ※予約の完了後、旅行者名の変更や日程・宿泊施設および航空便の変更など、すべての変更は一切お受けできません。
              <br />
              ※当商品は時価販売の旅行商品のため、同一内容でも予約のタイミングにより旅行代金が異なる場合があります。
              <br />
              ※宿泊施設によってはフロントにて、 宿泊税・入湯税の現金払いが、必要な場合がございます。
              <br />
              ※取消を行う場合は、タイミングに応じて、下記の取消料が発生します。
              <br />
              【取消料】
              <br />
              <Table>
                <tr>
                  <LeftTd>
                    a)旅行契約の締結時から旅行開始日の前日から起算してさかのぼって 21 日目にあたる日まで
                  </LeftTd>
                  <RightTd>旅行契約解除時の航空券取消料の額</RightTd>
                </tr>
                <tr>
                  <LeftTd>
                    b)旅行開始日の前日から起算してさかのぼって
                    <br />
                    20日~8日前まで
                  </LeftTd>
                  <RightTd>
                    旅行代金の20％又は下記『航空券取消料』の
                    <br />
                    金額のどちらか高い方の金額
                  </RightTd>
                </tr>
                <tr>
                  <LeftTd>
                    c)旅行開始日の前日から起算してさかのぼって
                    <br />
                    7日~2日前まで
                  </LeftTd>
                  <RightTd>
                    旅行代金の30％又は下記『航空券取消料』の
                    <br />
                    金額のどちらか高い方の金額
                  </RightTd>
                </tr>
                <tr>
                  <LeftTd>d)旅行開始日の前日</LeftTd>
                  <RightTd>
                    旅行代金の40％又は下記『航空券取消料』の
                    <br />
                    金額のどちらか高い方の金額
                  </RightTd>
                </tr>
                <tr>
                  <LeftTd>e)旅行開始日の当日(fを除く)</LeftTd>
                  <RightTd>
                    旅行代金の40％又は下記『航空券取消料』の
                    <br />
                    金額のどちらか高い方の金額
                  </RightTd>
                </tr>
                <tr>
                  <LeftTd>f)旅行開始後の解除 無連絡不参加</LeftTd>
                  <RightTd>旅行代金の100％</RightTd>
                </tr>
              </Table>
              <br />
              【航空券取消料】(１区間あたり)
              <br />
              <Table>
                <tr>
                  <LeftTd>搭乗予定日の55日前まで</LeftTd>
                  <RightTd>500円</RightTd>
                </tr>
                <tr>
                  <LeftTd>搭乗予定日の54日前～21日前まで</LeftTd>
                  <RightTd>2,000円</RightTd>
                </tr>
                <tr>
                  <LeftTd>搭乗予定日の20日前～8日前まで</LeftTd>
                  <RightTd>3,000円</RightTd>
                </tr>
                <tr>
                  <LeftTd>搭乗予定日の7日前～1日前まで</LeftTd>
                  <RightTd>6,000円</RightTd>
                </tr>
                <tr>
                  <LeftTd>搭乗当日の予定便出発時刻まで</LeftTd>
                  <RightTd>9,000円</RightTd>
                </tr>
              </Table>
              <br />
              東京都知事登録旅行業 第2-6519号　(社)日本旅行業協会[JATA]正会員
              <br />
              株式会社マイナビ
              <br />
              〒100‐0003 東京都千代田区一ツ橋1-1-1 パレスサイドビル
              <br />
              総合旅行業務取扱管理者 : 木野田拓也
              <br />
              <br />
              総合旅行業務取扱管理者とは、お客様の旅行を取扱う旅行会社・営業所での取引に関する責任者です。
              <br />
              ご旅行の契約に関し担当者からの説明にご不明の点がありましたら、最終的には上記取扱管理者がご説明いたします。
            </>
          ) : reservingTrip.packageType === 'airPackage' && theme.themeClass !== 'mynavi' ? (
            <>
              <SmallIndented>※キャンセル料は、ご出発の前日から起算して24日前より発生致します。</SmallIndented>
              <SmallIndented>
                ※予約後のフライト変更はご出発の前日から起算して25日前迄であれば無料で承ります。24日前より手数料が発生致しますのでご注意下さいませ。
              </SmallIndented>
              <SmallIndented>
                ※ご予約後に変更(参加者変更、人数変更、日程変更等)が生じる際は、ご予約をお取消後に再度お申込み頂く必要がございます。空席・空室状況により同内容でご予約できない場合もございますのでご了承ください。
              </SmallIndented>
              {reserveInfo.packageProvider !== 'rakuten' && (
                <>
                  <br />
                  旅行企画実施
                  <br />
                  国土交通大臣登録旅行業第１３８３号
                  <br />
                  株式会社ホワイト・ベアーファミリー
                  <br />
                  〒531-0072 大阪市北区豊崎3-14-9 WBFビル
                  <br />
                  <br />
                  受託販売
                  <br />
                  {reserveInfo.consignmentSale.travel_agency_number}
                  <br />
                  {reserveInfo.consignmentSale.company_name}
                  <br />〒{reserveInfo.consignmentSale.postcode} {reserveInfo.consignmentSale.address}
                  <br />
                </>
              )}
            </>
          ) : reservingTrip.packageType === 'jrPackage' ? (
            <>
              旅行企画実施
              <br />
              観光庁長官登録　旅行業第1135号
              <br />
              株式会社びゅうトラベルサービス
              <br />
              〒130-0013 東京都墨田区錦糸3-2-1
              <br />
            </>
          ) : reserveInfo.isApprovalRequired() && theme.themeClass === 'aitravel' ? (
            <>
              出張事前申請機能をご利用の場合、申請時から承認時にかけて旅行商品の商品情報、価格、および状態が更新される場合があります。
              <br />
              申請が承認された際、申請時点と承認時点で内容に差分がある場合以下のメール本文で更新内容を記載します。
              <br />
              メール件名：「【AI Travel】出張が承認されました（出張者：XXXXX）」
              <br />
              <br />
              出張申請承認後からご依頼いただいた各商品のキャンセル規程が適用されます。
              <br />
              ご依頼いただいた各商品について、在庫情報のタイムラグ等によりご希望に添えなかった場合は代替案をご連絡いたしますのでご了承ください。
              <br />
              ユーザーは、キャンセル時に株主優待券を受領した場合、弊社に返却の必要があることをご了承ください。
            </>
          ) : theme.themeClass === 'aitravel' ? (
            <>
              予約（決済）完了後からご依頼いただいた各商品のキャンセル規程が適用されます。
              <br />
              ご依頼いただいた各商品について、在庫情報のタイムラグ等によりご希望に添えなかった場合は代替案をご連絡いたしますのでご了承ください。
              <br />
              ユーザーは、キャンセル時に株主優待券を受領した場合、弊社に返却の必要があることをご了承ください。
            </>
          ) : (
            <>
              予約（決済）完了後からご依頼いただいた各商品のキャンセル規程が適用されます。
              <br />
              ご依頼いただいた各商品について、在庫情報のタイムラグ等によりご希望に添えなかった場合は代替案をご連絡いたしますのでご了承ください。
            </>
          )}
          <br />
          <br />
          {theme.themeClass === 'mynavi' && (
            <>
              予約依頼時に、
              <A href="https://btm.mynavi.jp/terms/pdf/hojinkiyaku.pdf" target="_blank" rel="noopener">
                マイナビBTM会員向け利用規約
              </A>
              、
              <A href="https://btm.mynavi.jp/terms/pdf/yakkan_arrange.pdf" target="_blank" rel="noopener">
                標準旅行業約款
              </A>
              、
              <A href="https://btm.mynavi.jp/terms/pdf/privacypolicy.pdf" target="_blank" rel="noopener">
                個人情報の取り扱い
              </A>
              について同意するものとします。
            </>
          )}
          {theme.themeClass === 'aitravel' && (
            <>
              また、{reserveInfo.isApprovalRequired() ? '出張事前申請時' : '予約（決済）時'}に弊社の
              <A href="https://aitravel.cloud/tos/" target="_blank" rel="noopener">
                利用約款
              </A>
              、
              <A
                href="https://d14vxaqxdw6ji3.cloudfront.net/images/travel_terms.pdf"
                target="_blank"
                rel="noopener"
              >
                旅行業約款
              </A>
              ・
              <A
                href="https://d14vxaqxdw6ji3.cloudfront.net/images/requirements.pdf"
                target="_blank"
                rel="noopener"
              >
                手配旅行条件書
              </A>
              、
              <A href="https://transferdata.co.jp/privacy-policy#a" target="_blank" rel="noopener">
                プライバシーポリシー
              </A>
              に同意するものとします。
              <br />
              <label>
                <input
                  type="checkbox"
                  className="terms_agree"
                  checked={termsAgree}
                  onChange={handleAgreementTermsCheck}
                />
                上記に同意する
              </label>
            </>
          )}
          {theme.themeClass === 'tabikobo' && (
            <>
              また、予約（決済）時に弊社の
              <A href="https://www.tabikobo.com/policy/" target="_blank" rel="noopener">
                プライバシーポリシー
              </A>
              に同意するものとします。
              <br />
              <label>
                <input
                  type="checkbox"
                  className="terms_agree"
                  checked={termsAgree}
                  onChange={handleAgreementTermsCheck}
                />
                上記に同意する
              </label>
            </>
          )}
        </Small>
      </Section>
    </ChildWrapper>
    <div>
      {paymentLoading ? (
        <PaymentLoadingImg src="/images/loading.gif" width={50} height={50} />
      ) : (
        <>
          {paymentFailed && paymentErrors && (
            <PaymentError>
              {typeof paymentErrors === 'string' ? (
                // eslint-disable-next-line react/no-danger
                <pre
                  dangerouslySetInnerHTML={{ __html: parse(paymentErrors) }}
                  style={{ background: 'none', padding: 0, border: 'none' }}
                />
              ) : (
                paymentErrors.map((error, i) => <p key={i}>{error}</p>)
              )}
            </PaymentError>
          )}
          {/* {disconnected && (
            <div className="error">
              通信環境が不安定です。
              <br />
              時間をおいてもう一度お試しください。
            </div>
          )} */}
          <ButtonArea>
            <ConfirmBack to="" onClick={handleBackToStep1Click}>
              {'< 戻る'}
            </ConfirmBack>
            <ConfirmButtons>
              {handleSaveDraft && <DraftButton onClick={handleSaveDraft}>下書き保存</DraftButton>}
              <ConfirmButton type="button" role="confirm" onClick={handleConfirmed} disabled={!termsAgree}>
                この内容で手配を依頼
              </ConfirmButton>
            </ConfirmButtons>
          </ButtonArea>
        </>
      )}
    </div>
  </>
);

const Title = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const ChildWrapper = styled.div`
  font-size: 13px;
  margin-bottom: 20px;
`;

const Section = styled.div`
  padding-bottom: 20px;
`;

const Subtitle = styled.div`
  font-size: 15px;
  margin: 20px 0 10px;
  font-weight: bold;
`;

const SubtitleLeft = styled.div`
  float: left;
  padding-right: 5px;
`;

const SubtitleRight = styled.div`
  float: left;
  font-size: 13px;
  font-weight: normal;
`;

const ChildContent = styled.div`
  font-size: 13px;
  margin-left: 10px;
`;

const TravelerBox = styled.div`
  margin-bottom: 15px;
  margin-left: 10px;
`;

const TravelerPeopleNum = styled.p`
  font-size: 13px;
`;

const TravelerInputArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

const Label = styled.div`
  font-size: 13px;
  width: 200px;
  line-height: 34px;
`;

const ApproveItemLabelOnly = styled.div`
  font-size: 13px;
  margin-bottom: 5px;
  line-height: 34px;
`;

const TravelPurposeLabel = styled.div`
  font-size: 13px;
  width: 400px;
  line-height: 16px;
  white-space: pre-line;
  overflow-wrap: break-word;
`;

const PeopleNum = styled.p`
  font-size: 13px;
  margin-top: 10px;
`;

const Small = styled.div`
  font-size: 11px;
`;

const SmallIndented = styled.div`
  text-indent: -14px;
  margin-left: 14px;
`;

const SmallRed = styled.div`
  font-size: 11px;
  color: ${props => props.theme.redColor};
`;

const SmallGray = styled.div`
  font-size: 11px;
  color: $gray-color-text;
`;

const HotelName = styled.p`
  @media (max-width: 430px) {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }
`;

const RoomNum = styled.p`
  @media (max-width: 430px) {
    font-size: 13px;
    margin-top: 10px;
  }
`;

const RentalCarOrderTitle = styled.p`
  margin: 10px 0 5px;
`;

const RentalCarOrderContentConfirm = styled.div`
  margin-left: 0;
  padding-left: 20px;
  position: relative;
  border-left: 3px solid $gray-color-border;

  @media (max-width: 430px) {
    margin-left: 0;
  }
`;

const TravelerRentalCarLabel = styled.div`
  line-height: 34px;
  display: flex;
  float: left;
`;

const TravelerInputAreaRentalCar = styled.div`
  line-height: 34px;
`;

const TravelerInputAreaRentalCarText = styled.div`
  line-height: 34px;
  margin-left: 73px;
  margin-top: 5px;
`;

const TravelerInputAreaRentalCarType = styled.div`
  line-height: 34px;
  margin-left: 52px;

  @media (max-width: 430px) {
    margin-left: 0;
  }
`;

const TravelerInputAreaRentalRemarksConfirm = styled.div`
  line-height: 34px;
  margin-left: 45px;
`;

const RentalCarRadio = styled.div`
  margin-left: 20px;
`;

const NotifiedUser = styled.div`
  margin: 0 0 10px -10px;
`;

const PaymentLoadingImg = styled.img`
  display: block;
  margin: 0 auto;
`;

const PaymentError = styled.div`
  color: ${props => props.theme.redColor};
  margin-top: 10px;
ag`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ConfirmBack = styled(Link)`
  margin: auto 0;
`;

const ConfirmButtons = styled.div`
  display: flex;
`;

const DraftButton = styled.button`
  ${BorderButtonBase}
`;

const ConfirmButton = styled.button`
  ${ButtonBase}
  margin-left: 15px;
  padding: 0.75em 1em;
  line-height: 1;
`;

const Table = styled.table`
  margin-top: 10px;
  font-size: 11px;
  width: 90%;
`;

const LeftTd = styled.td`
  background: #d1d1d1;
  border: 1px solid #eee;
  padding-left: 10px;
`;

const RightTd = styled.td`
  padding-left: 10px;
  border: 1px solid #eee;
`;

export default withTheme(ConfirmCustomerInformationTemplate);

import { Fetcher } from '@this/src/util';
import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import type { RouteComponentProps } from 'react-router-dom';
import { useHistory, Route, Switch, matchPath } from 'react-router-dom';

import ReserveTrip, { ReserveTripPaths } from '@this/components/reserve_trip/reserve_trip';
import TripsManagement, { TripsManagementPaths } from '@this/components/trips_management/trips_management';
import Applications, { ApplicationsPaths } from '@this/components/applications/applications';
import Organization, { OrganizationPaths } from '@this/components/organization/organization';
import UserEdit from '@this/components/user_edit/user_edit';
import type UserJson from '@this/domain/user/user_json';
import type User from '@this/domain/user/user';
import type { AvailableRepository } from '@this/domain/available_repository';
import Chatbot from './chatbot/chatbot';

export const UserPagePaths = ReserveTripPaths.concat(TripsManagementPaths, ApplicationsPaths, OrganizationPaths, [
  '/users/edit'
]);
interface Props extends RouteComponentProps {
  serviceId: number;
  user: User | null;
  onUserSignedIn: () => void;
  availableRepos: AvailableRepository[];
  availableOptions: string[];
}

const UserPage = observer((props: Props) => {
  const history = useHistory();

  const checkTimeout = useCallback(async () => {
    try {
      await Fetcher.get<UserJson>('/users/check_timeout.json');
    } catch (e) {
      if (e.response.status === 404) {
        location.href = '/users/sign_in';
      } else {
        throw e;
      }
    }
  }, []);

  useEffect(() => {
    // ページ遷移時のコールバックでタイムアウトをチェックする
    const unlisten = history.listen(location => {
      // ログインが必要なページへ遷移する場合のみタイムアウトをチェックする
      if (UserPagePaths.some(path => matchPath(location.pathname, { path }))) {
        checkTimeout();
      }
    });
    checkTimeout();
    return () => {
      unlisten();
    };
  }, []);

  return (
    <>
      <Switch>
        <Route path={ReserveTripPaths} render={ps => <ReserveTrip {...ps} {...props} />} />
        <Route path={TripsManagementPaths} render={ps => <TripsManagement {...ps} {...props} />} />
        <Route path={ApplicationsPaths} render={ps => <Applications {...ps} {...props} />} />
        <Route path={OrganizationPaths} render={ps => <Organization {...ps} {...props} />} />
        <Route exact path="/users/edit" render={ps => <UserEdit {...ps} {...props} />} />
      </Switch>
      <Chatbot availableOptions={props.availableOptions} user={props.user} />
    </>
  );
});

export default UserPage;

import React, { useCallback, useState } from 'react';

import { AutocompleteInput } from '@this/shared/ui/inputs/autocomplete';
import { Fetcher } from '@this/src/util';
import type { KnowledgeResponseArgs } from '@this/domain/knowledge';
import Knowledge, { convertKnowledgeResponseToArgs } from '@this/domain/knowledge';
import { useHistory } from 'react-router-dom';
import type { TextColor } from '@this/shared/ui/data_displays/typography';
import { Text, TextTruncate } from '@this/shared/ui/data_displays/typography';
import { Box } from '@material-ui/core';
import { Button } from '@this/shared/ui/inputs/button';
import { SearchIcon } from '@this/shared/header/header.style';
import { styled } from '@this/constants/themes';

type KnowledgeSuggestsResponse = {
  suggests: KnowledgeResponseArgs[];
};

type HelpSearchBlockProps = {
  showTitle: boolean;
  searchKeyword?: string;
  onSearchSubmit?: (newKeyword: string) => void;
};

const HelpSearchBlock: React.FC<HelpSearchBlockProps> = props => {
  const [keyword, setKeyword] = useState<string>('');
  const fetchKnowledgeSuggests = useCallback(async () => {
    try {
      const res = await Fetcher.get<KnowledgeSuggestsResponse>('/knowledge_suggests.json', { keyword });
      return res.suggests.map(k => new Knowledge(convertKnowledgeResponseToArgs(k)));
    } catch (e) {
      return [];
    }
  }, [keyword]);

  const highlightMatch = (text: string, query: string, color: TextColor) => {
    if (text) {
      return text.split(new RegExp(`(${query})`, 'ig')).map((part, i) =>
        part.toLowerCase() === query.toLowerCase() ? (
          <Text component="span" bold color={color} key={i}>
            {part}
          </Text>
        ) : (
          part
        )
      );
    }
    return '';
  };

  const history = useHistory();

  const handleFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // 親コンポーネントから受け取った関数を実行
      if (props.onSearchSubmit) {
        props.onSearchSubmit(keyword);
      }

      history.push(`/knowledge_search_results?keyword=${keyword}&page=1`);
    },
    [history, keyword, props]
  );

  const onChange = (_event: React.ChangeEvent<unknown>, newValue: string | Knowledge) => {
    if (typeof newValue === 'object' && newValue !== null) {
      history.push(`/knowledges/${newValue.salesforceId}`);

      // 選択した項目の情報をキーワード状態として保存
      setKeyword(newValue.question);
    } else {
      // 選択せずに直接テキストを入力した場合 (newValue が string 入力された状態)
      // テキスト入力値をキーワード状態として保存
      setKeyword(newValue);
    }
  };

  // 入力欄が変更された時の動作
  const onInputChange = (_event: React.ChangeEvent<unknown>, newInputValue: string) => {
    setKeyword(newInputValue);
  };

  const renderOption = (k: Knowledge) => (
    <div style={{ padding: '10px 20px', width: '100%', display: 'flex', flexDirection: 'column', rowGap: '5px' }}>
      <Text color="description" level="caption">
        {k.breadcrumb}
      </Text>
      <TextTruncate lines={1}>
        <Text color="brand" level="body">
          {highlightMatch(k.question, keyword, 'brand')}
        </Text>
      </TextTruncate>
      <TextTruncate lines={1}>
        <Text color="primary" level="body">
          {highlightMatch(k.answer, keyword, 'primary')}
        </Text>
      </TextTruncate>
    </div>
  );

  // 必ずstringを返すように指定
  const getOptionLabel = (option: string | Knowledge) => (typeof option === 'object' ? option.question : option);
  return (
    <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
      {props.showTitle && <MainTitle>AI Travelに関するご不明点やご質問をご入力ください</MainTitle>}
      <Box display="flex" style={{ marginTop: '12px', marginBottom: '24px' }}>
        <AutocompleteInput
          size="medium"
          style={{ width: '100%', marginRight: '1%' }}
          async="FILTERING"
          placeholder="キーワードを入力"
          value={props.searchKeyword}
          getOptions={fetchKnowledgeSuggests}
          onChange={onChange}
          onInputChange={onInputChange}
          renderOption={renderOption}
          getOptionLabel={getOptionLabel}
        />
        <Button type="submit" size="small" disabled={false} style={{ marginLeft: 'auto' }}>
          <SearchIcon />
        </Button>
      </Box>
    </form>
  );
};

const MainTitle = styled.div`
  color: #3a3a3a;
  font-size: 23px;
  font-weight: 700;
  line-height: 28.75px;
`;

export default HelpSearchBlock;

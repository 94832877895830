import React from 'react';
import { styled } from '@this/constants/themes';
import type { TransitPriceJson } from '@this/domain/select_repository';
import { RightDomesticAirButton, Yen } from '@this/components/reserve_trip/select/list_area/list_area.template';

type Props = {
  loading: boolean;
  selected: boolean;
  price: TransitPriceJson;
};

const SelectTransportBoxRightInDomesticAirListTemplate: React.FC<Props> = ({ loading, selected, price }) => (
  <RightDomesticAirButton selected={selected}>
    {loading ? (
      <RightBody>
        <img src="/images/loading.gif" width={20} height={20} />
      </RightBody>
    ) : (
      <RightBody className="select-transport-box__right__body">
        <TicketLabelWrapper>
          <TicketLabelContainer>
            <div>
              <TicketLabelArea>
                <TicketLabel>{price.ticket_category === 'changeable' ? '変更可' : '変更不可'}</TicketLabel>
                {price.seat_type && <TicketLabel>{price.seat_type}</TicketLabel>}
              </TicketLabelArea>
              <Price>
                {price.price !== undefined && (
                  <>
                    <span data-wovn-ignore>{utils.digits(price.price)}</span>
                    <Yen>円</Yen>
                  </>
                )}
              </Price>
              <PeopleNum>/1名</PeopleNum>
            </div>
            {price.ticket_type === 'kabu' && (
              <KabuyuRecommendation selected={selected}>オススメ</KabuyuRecommendation>
            )}
          </TicketLabelContainer>
          <SeatBox selected={selected}>
            <div style={{ fontSize: '14px' }}>○</div>
            {price.seat_count !== 'unknown' && (
              <div>
                {price.seat_count === '○' || Number(price.seat_count) >= 10 ? '10席以上' : `${price.seat_count}席`}
              </div>
            )}
          </SeatBox>
        </TicketLabelWrapper>
      </RightBody>
    )}
  </RightDomesticAirButton>
);

const RightBody = styled.div`
  font-size: 11px;
`;

const SeatBox = styled.div<{ selected: boolean }>`
  width: 60px;
  background: #f9f7f3;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  ${props =>
    props.selected &&
    `
      background: ${props.theme.linkColor};
      color: white;
  `}
`;

const Price = styled.span`
  font-size: 14px;
`;

const PeopleNum = styled.span`
  font-size: 11px;
`;

const TicketLabelArea = styled.div`
  margin-bottom: 2px;
  @media screen and (max-width: 768px) {
    line-height: 200%;
  }
`;

const TicketLabel = styled.span`
  border: solid 1px #af985e;
  margin-right: 5px;
  padding: 1px 3px;
  white-space: nowrap;
`;

const TicketLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TicketLabelContainer = styled.div`
  padding: 10px;
`;

const KabuyuRecommendation = styled.span<{ selected: boolean }>`
  ${props =>
    !props.selected &&
    `
    font-weight: bold;
    color: red;
  `}
`;

export default SelectTransportBoxRightInDomesticAirListTemplate;

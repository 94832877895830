import React from 'react';
import { styled } from '@this/constants/themes';
import { Link } from '@this/shared/ui/navigations/link';
import Notification from '@this/src/notification';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import type TransportElementAttachFile from '@this/src/domain/transport_element_attach_file';
import type Traveler from '@this/src/domain/traveler/traveler';

type Props = {
  className?: string;
  attachFiles?: TransportElementAttachFile[];
  travelerInformations: any[];
  onChange: (file: File, travelerInformationId: string | null) => void;
  onDelete?: (travelerInformationId: string | null, index: number) => void;
};

export const TransportElementAttachFileForm = ({
  className,
  attachFiles = [],
  travelerInformations,
  onChange,
  onDelete
}: Props) => {
  const [localAttachFiles, setLocalAttachFiles] = React.useState(() => {
    return attachFiles.reduce((acc, file) => {
      const travelerId = file.travelerInformationId?.toString() ?? 'null';
      if (!acc[travelerId]) acc[travelerId] = [];
      acc[travelerId].push(file);
      return acc;
    }, {} as { [travelerId: string]: TransportElementAttachFile[] });
  });

  const [selectedTravelerId, setSelectedTravelerId] = React.useState<string | null>(null);

  const handleAddInputField = () => {
    const idKey = selectedTravelerId ?? 'null';
    const newPlaceholder = {
      travelerInformationId: selectedTravelerId,
      uploadFileName: '',
      isPlaceholder: true
    } as unknown as TransportElementAttachFile;

    setLocalAttachFiles(prev => ({
      ...prev,
      [idKey]: [...(prev[idKey] || []), newPlaceholder]
    }));
  };

  const handleRemoveInputField = (travelerId: string | null, index: number) => {
    const idKey = travelerId ?? 'null';
    setLocalAttachFiles(prev => {
      const updated = { ...prev };
      updated[idKey] = updated[idKey]?.filter((_, i) => i !== index);
      return updated;
    });

    if (onDelete) {
      onDelete(travelerId === 'null' ? null : travelerId, index);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, travelerId: string | null, index: number) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.size > 10 * 1024 * 1024) {
      const errorMessage = 'ファイルサイズが大きすぎます。10MB以下のファイルを選択してください。';
      e.target.value = '';
      Notification.error(errorMessage);
      return;
    }

    const idKey = travelerId ?? 'null';
    setLocalAttachFiles(prev => {
      const updated = { ...prev };
      updated[idKey][index] = {
        travelerInformationId: travelerId,
        uploadFileName: file.name,
        file
      } as unknown as TransportElementAttachFile;
      return updated;
    });

    onChange(file, travelerId === 'null' ? null : travelerId);
  };

  return (
    <Root className={className}>
      <div style={{ marginBottom: '5px' }}>
        <select value={selectedTravelerId ?? ''} onChange={e => setSelectedTravelerId(e.target.value || null)}>
          <option value="null">未割り当て</option>
          {travelerInformations.map((traveler: Traveler) => (
            <option key={traveler.id} value={traveler.id}>
              {traveler.lastNameKana} {traveler.firstNameKana}
            </option>
          ))}
        </select>
        <AddAttachmentButton
          onClick={(e: { preventDefault: () => void }) => {
            e.preventDefault();
            handleAddInputField();
          }}
        >
          ファイルを追加
        </AddAttachmentButton>
      </div>

      {travelerInformations.map((traveler: Traveler) => {
        const travelerId = traveler.id.toString();
        return (
          <div key={traveler.id} style={{ marginBottom: '20px' }}>
            <h4>
              {traveler.lastNameKana} {traveler.firstNameKana}
            </h4>
            {localAttachFiles[travelerId]?.map((file, index) => (
              <FileSection key={index}>
                {(file as any).isPlaceholder ? (
                  <input
                    type="file"
                    name="files[]"
                    onChange={e => handleChange(e, travelerId, index)}
                    accept="application/pdf"
                  />
                ) : (
                  <>
                    <PdfIcon style={{ marginRight: '5px' }} />
                    {file.uploadFileName}
                  </>
                )}
                <DeleteButton
                  onClick={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    handleRemoveInputField(travelerId, index);
                  }}
                >
                  削除
                </DeleteButton>
              </FileSection>
            ))}
          </div>
        );
      })}

      <div style={{ marginBottom: '20px' }}>
        <h4>出張者未割り当て</h4>
        {localAttachFiles.null?.map((file, index) => (
          <FileSection key={index}>
            {(file as any).isPlaceholder ? (
              <input
                type="file"
                name="files[]"
                onChange={e => handleChange(e, 'null', index)}
                accept="application/pdf"
              />
            ) : (
              <>
                <PdfIcon style={{ marginRight: '5px' }} />
                {file.uploadFileName}
              </>
            )}
            <DeleteButton
              onClick={(e: { preventDefault: () => void }) => {
                e.preventDefault();
                handleRemoveInputField('null', index);
              }}
            >
              削除
            </DeleteButton>
          </FileSection>
        ))}
      </div>
    </Root>
  );
};

const Root = styled.div`
  background-color: ${props => props.theme.grayColorLight};
  padding: 10px 5px 5px 5px;
  width: 100%;
`;

const AddAttachmentButton = styled(Link)`
  margin-bottom: 5px;
`;

const DeleteButton = styled(Link)`
  padding: 0 !important;
`;

const FileSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  padding: 2px 0 2px 10px;
  font-size: 12px;
`;

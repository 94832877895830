import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';

import type { DashboardProps, ApprovalStatus } from '../../share';
import { chartOptions, getBarDataApprovalStatusCounts } from '../../share/approval_rejection_count_graph';
import type { DashboardRequest } from '../../share/fetch';
import { fetchDashboard } from '../../share/fetch';
import { RefreshCacheButton } from '../../share/refresh_cache_button';

// Usecase::Organization::DashboardTrips::ApprovalStatusCountPatterns::CachePatternUsecaseを使用
const USECASE = { section: 'approval_status_count', pattern: 'cache' };

interface BarApprovalStatusCounts {
  label: string;
  approvalStatusCounts: Record<ApprovalStatus, number>;
}
interface State {
  loading: boolean;
  approvalStatusCounts: BarApprovalStatusCounts[];
  lastUpdatedAt: Moment | null;
  refreshAt: Moment | null;
}

interface Response {
  approvalStatusCounts: State['approvalStatusCounts'];
  last_updated_at: string;
}

const fetchSummary = async ({ setState, ...props }: DashboardRequest<State>) => {
  setState(state => ({ ...state, loading: true }));

  const result = await fetchDashboard<Response>({ ...USECASE, ...props });

  setState(state => ({
    ...state,
    loading: false,
    approvalStatusCounts: result.approvalStatusCounts,
    lastUpdatedAt: result.last_updated_at ? moment(result.last_updated_at) : null
  }));
};

const DashboardApprovalRejectionCountSection: React.FC<DashboardProps> = props => {
  const [state, setState] = useState<State>({
    loading: true,
    approvalStatusCounts: [],
    lastUpdatedAt: null,
    refreshAt: null
  });
  const { loading, approvalStatusCounts, lastUpdatedAt, refreshAt } = state;
  const { urlQuery, organizationId } = props;
  const { type, from, to } = urlQuery;

  const data = useMemo(() => getBarDataApprovalStatusCounts(approvalStatusCounts), [approvalStatusCounts]);

  const handleRefreshCache = useCallback(() => {
    setState(state => ({ ...state, refreshAt: moment() }));
  }, [setState]);

  useEffect(() => {
    if (props.wait) return;

    fetchSummary({ setState, ...props });
  }, [type, from, to, refreshAt, props.refreshAt, props.wait]);

  return (
    <>
      <h3 className="graph_title">承認・却下件数</h3>
      {loading ? <SimpleLoading /> : <Bar data={data} width={550} height={180} options={chartOptions} />}
      <RefreshCacheButton
        cacheType={USECASE.section}
        dateType={type}
        from={from}
        to={to}
        organizationId={organizationId}
        lastUpdatedAt={lastUpdatedAt}
        onRefreshCache={handleRefreshCache}
      />
    </>
  );
};

export default DashboardApprovalRejectionCountSection;

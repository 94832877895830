import { Fetcher } from '@this/src/util';
import { createContext, useContext } from 'react';

export type ActivitySelected = 'trip' | 'tripReport';

interface Activity {
  message: string;
  unread?: boolean;
}

interface TripActivityArgs {
  trip_activity: Activity[];
  trip_count: number;
  trip_report_activity: Activity[];
  trip_report_count: number;
}

export class TripActivityStore {
  tripActivity: Activity[] = [];

  tripCount = 0;

  tripReportActivity: Activity[] = [];

  tripReportCount = 0;

  // TODO: アクティビティ作成時に不要になるため、削除する
  mockActivity: boolean;

  constructor(search: string) {
    this.mockActivity = search.indexOf('mock_activity=true') !== -1;
  }

  activity(selected: ActivitySelected) {
    switch (selected) {
      case 'trip':
        return this.tripActivity;
      case 'tripReport':
        return this.tripReportActivity;
      default:
        return [];
    }
  }

  unreadCount(selected: ActivitySelected) {
    return this.activity(selected).filter(row => row.unread).length;
  }

  getActivity() {
    Fetcher.get<TripActivityArgs>('/trips/activity.json', { mock_activity: this.mockActivity })
      .then(result => {
        this.tripActivity = result.trip_activity;
        this.tripCount = result.trip_count;
        this.tripReportActivity = result.trip_report_activity;
        this.tripReportCount = result.trip_report_count;
      })
      .finally(() => {
        app.render();
      });
  }

  getApprovalActivity() {
    Fetcher.get<TripActivityArgs>('/trips/activity/approval.json', { mock_activity: this.mockActivity })
      .then(result => {
        this.tripActivity = result.trip_activity;
        this.tripCount = result.trip_count;
        this.tripReportActivity = result.trip_report_activity;
        this.tripReportCount = result.trip_report_count;
      })
      .finally(() => {
        app.render();
      });
  }
}

export const TripActivityContext = createContext<TripActivityStore>(null as unknown as TripActivityStore);

export const useTripActivityStore = () => useContext(TripActivityContext);

import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { OrganizationTable } from '@this/components/organization/organization.style';
import { withTheme } from 'styled-components';

import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { Flex } from '@this/shared/ui/layout/flex';
import { Button } from '@this/shared/ui/inputs/button';
import { reportError } from '@this/lib/bugsnag';
import { ApproveModalTemplate } from './modals/approve_modal.template';
import RejectModalTemplate from './modals/reject_modal.template';
import EditModalTemplate from './modals/edit_modal.template';

interface Props {
  reports: any[];
  projects: any[];
  expenseTypes: any;
  loading: boolean;
  onApprove: (report: any, onComplete: () => void) => void;
  onReject: (report: any, onComplete: () => void) => void;
  onEdit: (report: any, onComplete: () => void) => void;
  theme: { serviceName: string };
}

interface State {
  selectedReport: any;
  currentAction: any;
  confirming: boolean;
  requesting: boolean;
  errors: string[] | null;
}

class OrganizationTripReportsTable extends React.Component<Props, State> {
  private editSubmitRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedReport: null,
      currentAction: null,
      confirming: false,
      requesting: false,
      errors: null
    };

    this.handleReportChange = this.handleReportChange.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancelConfirm = this.handleCancelConfirm.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);

    this.editSubmitRef = React.createRef<HTMLFormElement>();
  }

  handleReportChange(name: string, e: React.ChangeEvent<HTMLTextAreaElement>) {
    e.preventDefault();
    if (this.state.selectedReport) {
      this.state.selectedReport[name](e.target.value);
    }
  }

  handleClickApprove(report: any) {
    this.setState({
      selectedReport: _.cloneDeep(report),
      currentAction: 'approve'
    });
  }

  handleClickReject(report: any) {
    this.setState({
      selectedReport: report,
      currentAction: 'reject'
    });
  }

  handleClickEdit(report: any) {
    this.setState({
      selectedReport: _.cloneDeep(report),
      currentAction: 'edit'
    });
  }

  handleApprove(payDate: Date) {
    this.setState({ requesting: true });
    const onComplete = () => {
      this.resetState();
    };
    const { selectedReport } = this.state;
    selectedReport.setPayDate(payDate);
    this.props.onApprove(selectedReport, onComplete);
  }

  handleReject() {
    if (this.state.selectedReport.isValidForReject()) {
      this.setState({ requesting: true });
      const onComplete = () => {
        this.resetState();
      };
      this.props.onReject(this.state.selectedReport, onComplete);
    } else {
      this.setState({ errors: this.state.selectedReport.errorsForReject() });
    }
  }

  handleEdit() {
    this.setState({ requesting: true });
    const onComplete = () => {
      this.resetState();
    };
    this.props.onEdit(this.state.selectedReport, onComplete);
  }

  handleConfirm() {
    this.setState({ confirming: true });
  }

  handleCancelConfirm() {
    this.setState({ confirming: false });
  }

  handleFormSubmit(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    this.editSubmitRef.current.handleConfirm(e);
  }

  handleCancelClick() {
    this.resetState();
  }

  resetState() {
    this.setState({
      selectedReport: null,
      currentAction: null,
      confirming: false,
      requesting: false,
      errors: null
    });
  }

  render() {
    try {
      const { reports, projects, expenseTypes, loading, theme } = this.props;
      const { selectedReport, currentAction, confirming, requesting, errors } = this.state;
      return (
        <>
          <OrganizationTable>
            <thead>
              <Tr isBgColor={false}>
                <Th>
                  <span>旅程番号</span>
                </Th>
                <Th>
                  <span>出張報告番号</span>
                </Th>
                <Th>
                  <span>出張者(代表者)</span>
                </Th>
                <Th>
                  <span>出張日程</span>
                </Th>
                <Th>
                  <span>出張先</span>
                </Th>
                <Th>
                  <span>目的</span>
                </Th>
                <Th>
                  <span>{`${theme.serviceName}手配金額`}</span>
                </Th>
                <Th>
                  <span>社員立替金額</span>
                </Th>
                <Th>
                  <span>合計金額</span>
                </Th>
                <Th>
                  <span>支払予定日</span>
                </Th>
                <Th>
                  <span>ステータス</span>
                </Th>
                <ThButton>
                  <span>操作</span>
                </ThButton>
              </Tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={10}>
                    <SimpleLoading />
                  </td>
                </tr>
              ) : (
                <>
                  {reports.map((report: any, i: number) => (
                    <Tr key={i} isBgColor={report.statusBeforeApplication()}>
                      <Td>
                        <Strong>{report.tripId}</Strong>
                      </Td>
                      <Td>{report.id}</Td>
                      <Td>{report.representativeName()}</Td>
                      <Td>
                        <div>
                          {`${report.departureDate.format('YYYY/MM/DD')} ~ ${report.returnDate.format(
                            'YYYY/MM/DD'
                          )}`}
                        </div>
                      </Td>
                      <Td>{report.finalDestination}</Td>
                      <Td>{report.purpose}</Td>
                      <Td>
                        <Cost>{`${report.totalPaidPrice().toLocaleString()}円`}</Cost>
                      </Td>
                      <Td>
                        <Cost>{`${report.totalUnpaidPrice().toLocaleString()}円`}</Cost>
                      </Td>
                      <Td>
                        <Cost>{`${report.totalPrice().toLocaleString()}円`}</Cost>
                      </Td>
                      <Td>{report.payDateDescription()}</Td>
                      <Td>
                        <div className={report.statusClass()}>
                          <span>{report.statusDescription()}</span>
                        </div>
                      </Td>
                      <Td>
                        <Flex gap="4px">
                          <Button
                            type="button"
                            size="small"
                            onClick={() => this.handleClickEdit(report)}
                            disabled={!report.adminAvailableFor('edit')}
                          >
                            編集
                          </Button>
                          <Button
                            type="button"
                            size="small"
                            onClick={() => this.handleClickApprove(report)}
                            disabled={!report.adminAvailableFor('approve')}
                          >
                            承認
                          </Button>
                          <Button
                            type="button"
                            size="small"
                            onClick={() => this.handleClickReject(report)}
                            disabled={!report.adminAvailableFor('reject')}
                          >
                            差戻
                          </Button>
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </>
              )}
            </tbody>
          </OrganizationTable>
          {currentAction === 'approve' ? (
            <ApproveModalTemplate
              submitting={requesting}
              report={selectedReport}
              onSubmit={this.handleApprove}
              onCancel={this.handleCancelClick}
            />
          ) : currentAction === 'reject' ? (
            <RejectModalTemplate
              selectedReport={selectedReport}
              requesting={requesting}
              errors={errors}
              handleReportChange={this.handleReportChange}
              handleReject={this.handleReject}
              handleCancelClick={this.handleCancelClick}
            />
          ) : currentAction === 'edit' ? (
            <EditModalTemplate
              projects={projects}
              expenseTypes={expenseTypes}
              selectedReport={selectedReport}
              trip={selectedReport.trip}
              confirming={confirming}
              requesting={requesting}
              handleEdit={this.handleEdit}
              handleConfirm={this.handleConfirm}
              handleFormSubmit={this.handleFormSubmit}
              handleCancelConfirm={this.handleCancelConfirm}
              handleCancelClick={this.handleCancelClick}
              editSubmitRef={this.editSubmitRef}
            />
          ) : (
            <></>
          )}
        </>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const Tr = styled.tr<{ isBgColor: boolean }>`
  ${props =>
    props.isBgColor &&
    `
    background: ${props.theme.grayColorLight};
  `};
`;

const Th = styled.th`
  padding: 4px 8px;
  background: ${props => props.theme.grayColorLight};
  font-size: 12px;
  font-weight: normal;
  text-align: right;
  border-bottom: none;
  border-right: solid 1px white;
`;

const ThButton = styled(Th)`
  width: 218px;
  text-align: center;
`;

const Td = styled.td`
  padding: 4px 8px;
  border-bottom: 1px solid ${props => props.theme.grayBorderColor};
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  word-break: keep-all;
`;

const Strong = styled.span`
  color: ${props => props.theme.linkColor};
`;

const Cost = styled.div``;

export default withTheme(OrganizationTripReportsTable);

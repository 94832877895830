import React, { useCallback, useEffect, useState } from 'react';
import { Bar, Radar } from 'react-chartjs-2';
import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import { Flex } from '@this/shared/ui/layout/flex';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';

import { styled, css } from '@this/src/components/constants/themes';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';
import type { DashboardCategory, DashboardProps } from '../../share';
import { BORDER_STYLES, SUMMARY_COLOR_THEMES } from '../../share/graph';
import type { DashboardRequest } from '../../share/fetch';
import { fetchDashboard } from '../../share/fetch';
import { RefreshCacheButton } from '../../share/refresh_cache_button';

// サマリーセクションV3：テーブルをカテゴリ別に集計したバージョン
// Usecase::Organization::DashboardTrips::SummaryPatterns::CategoryPatternUsecase を使用
const USECASE = { section: 'summary', pattern: 'category', version: 'v2' };

type SummaryTabLiProps = {
  left?: boolean;
  right?: boolean;
  active?: boolean;
};

type SummaryCategory = DashboardCategory & 'all';

interface SummaryCategoryProps {
  orderItemCategory: SummaryCategory;
  averagePrice: number;
  itemCount: number;
  totalPrice: number;
}

interface State {
  loading: boolean;
  totalPrice: number;
  totalCount: number;
  prices: SummaryCategoryProps[];
  raderData: [number, number, number, number, number, number] | null;
  barPricesDay: Record<string, number>;
  barPricesMonth: Record<string, number>;
  barPricesWeek: Record<string, number>;
  lastUpdatedAt: Moment | null;
  refreshAt: Moment | null;
}

interface SummaryPriceResponse {
  order_item_category: SummaryCategory;
  average_price: number;
  item_count: number;
  total_price: number;
}

interface Response {
  total_price: number;
  total_count: number;
  prices: SummaryPriceResponse[];
  rader_data: State['raderData'] | null;
  bar_prices_day: State['barPricesDay'];
  bar_prices_month: State['barPricesMonth'];
  bar_prices_week: State['barPricesWeek'];
  last_updated_at: string;
}

const CATEGORIES: Record<SummaryCategory, string> = {
  hotel: 'ホテル',
  domestic_air: '国内航空券',
  foreign_air: '海外航空券',
  railway: '新幹線・特急',
  railway_ticket: '鉄道',
  bus: 'バス',
  rental_car: 'レンタカー',
  air_package: '航空券パッケージ',
  railway_package: '新幹線パッケージ',
  cancel: 'キャンセル料',
  other: 'その他',
  all: '小計'
};

const fetchSummary = async ({ setState, ...props }: DashboardRequest<State>) => {
  setState(state => ({ ...state, loading: true }));
  const result = await fetchDashboard<Response>({ ...USECASE, ...props });
  setState(state => ({
    ...state,
    loading: false,
    totalPrice: result.total_price,
    totalCount: result.total_count,
    prices: result.prices.map(price => ({
      orderItemCategory: price.order_item_category,
      averagePrice: price.average_price,
      itemCount: price.item_count,
      totalPrice: price.total_price
    })),
    raderData: result.rader_data,
    barPricesDay: result.bar_prices_day,
    barPricesMonth: result.bar_prices_month,
    barPricesWeek: result.bar_prices_week,
    lastUpdatedAt: result.last_updated_at ? moment(result.last_updated_at) : null
  }));
};

const DashboardSummarySection: React.FC<DashboardProps> = props => {
  const [state, setState] = useState<State>({
    loading: true,
    totalPrice: 0,
    totalCount: 0,
    prices: [],
    raderData: null,
    barPricesDay: {},
    barPricesMonth: {},
    barPricesWeek: {},
    lastUpdatedAt: null,
    refreshAt: null
  });

  const [summaryType, setSummaryType] = useState<string>('month');

  const {
    loading,
    totalPrice,
    totalCount,
    prices,
    raderData,
    barPricesDay,
    barPricesMonth,
    barPricesWeek,
    lastUpdatedAt,
    refreshAt
  } = state;
  const { urlQuery, organizationId } = props;
  const { type, from, to } = urlQuery;

  useEffect(() => {
    fetchSummary({ setState, ...props });
  }, [type, from, to, refreshAt, props.refreshAt]);

  const handleRefreshCache = useCallback(() => {
    setState(state => ({ ...state, refreshAt: moment() }));
  }, [setState]);

  if (loading) return <SimpleLoading />;

  return (
    <>
      <h3 className="graph_title">サマリ</h3>
      <Flex justifyContent="space-between">
        <div style={{ flexBasis: '60%' }}>
          <p>合計金額: {totalPrice.toLocaleString()}円</p>
          <p style={{ marginBottom: '20px' }}>合計件数: {totalCount}件 </p>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>カテゴリ</TableCell>
                  <TableCell align="right" width="25%">
                    平均金額
                  </TableCell>
                  <TableCell align="right" width="25%">
                    件数
                  </TableCell>
                  <TableCell align="right" width="25%">
                    合計金額
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prices.map(({ orderItemCategory, averagePrice, itemCount, totalPrice }) => (
                  <TableRow key={orderItemCategory}>
                    <TableCell>{CATEGORIES[orderItemCategory]}</TableCell>
                    <TableCell align="right">{averagePrice.toLocaleString()}円</TableCell>
                    <TableCell align="right">{itemCount}件</TableCell>
                    <TableCell align="right">{totalPrice.toLocaleString()}円</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {raderData && (
          <div>
            <Radar data={getDataForMainRadar(raderData)} width={400} options={radarChartOptions} />
          </div>
        )}
      </Flex>
      <SummaryDiv>
        <SummaryTab>
          <SummaryTabLi left active={summaryType === 'day'}>
            <a onClick={() => setSummaryType('day')}>日</a>
          </SummaryTabLi>
          <SummaryTabLi active={summaryType === 'week'}>
            <a onClick={() => setSummaryType('week')}>週</a>
          </SummaryTabLi>
          <SummaryTabLi right active={summaryType === 'month'}>
            <a onClick={() => setSummaryType('month')}>月</a>
          </SummaryTabLi>
        </SummaryTab>
      </SummaryDiv>
      {summaryType === 'day' ? (
        <div style={{ marginTop: '20px' }}>
          <Bar data={getDataForTripCostByDate(barPricesDay)} height={50} options={tripChartOptions} />
        </div>
      ) : summaryType === 'week' ? (
        <div style={{ marginTop: '20px' }}>
          <Bar data={getDataForTripCostByDateWeek(barPricesWeek)} height={50} options={tripChartOptions} />
        </div>
      ) : (
        <div style={{ marginTop: '20px' }}>
          <Bar data={getDataForTripCostByDateMonth(barPricesMonth)} height={50} options={tripChartOptions} />
        </div>
      )}
      <RefreshCacheButton
        cacheType={`${USECASE.section}_${USECASE.version}`}
        dateType={type}
        from={from}
        to={to}
        organizationId={organizationId}
        lastUpdatedAt={lastUpdatedAt}
        onRefreshCache={handleRefreshCache}
      />
    </>
  );
};

const getDataForMainRadar = (data: State['raderData']) => {
  const labels = ['旅費規程', '手配依頼タイミング', '承認リードタイム', '市場価格比較', '季節要因', '券種適正'];
  const datasets = [
    {
      label: '過去の3ヶ月の評価値',
      data,
      borderWidth: 1,
      ...SUMMARY_COLOR_THEMES.default.transparentYellow
    }
  ];
  return { labels, datasets };
};

const radarChartOptions: React.ComponentProps<typeof Radar>['options'] = {
  scales: {
    r: {
      beginAtZero: true,
      ticks: {
        display: false,
        // 小数点の目盛は表示しないようにする
        callback: label => {
          const numLabel = Number(label);
          if (Math.floor(numLabel) === numLabel) {
            return label;
          }
          return null;
        }
      }
    }
  }
};

const getDataForTripCostByDate = (prices: State['barPricesDay']) => {
  const labels = Object.keys(prices);

  const datasets = [
    {
      label: `日毎の出張コスト集計 (${labels[0]} - ${labels.slice(-1)[0]})`,
      data: Object.values(prices),
      ...SUMMARY_COLOR_THEMES.default.darkYellow,
      ...BORDER_STYLES.default
    }
  ];
  return { labels, datasets };
};

const getDataForTripCostByDateMonth = (prices: State['barPricesMonth']) => {
  const labels = Object.keys(prices);

  const datasets = [
    {
      label: `月毎の出張コスト集計 (${labels[0]} - ${labels.slice(-1)[0]})`,
      data: Object.values(prices),
      ...SUMMARY_COLOR_THEMES.default.darkYellow,
      ...BORDER_STYLES.default
    }
  ];
  return { labels, datasets };
};

const getDataForTripCostByDateWeek = (prices: State['barPricesWeek']) => {
  const labels = Object.keys(prices);

  const datasets = [
    {
      label: `週毎の出張コスト集計 (${labels[0]} - ${labels.slice(-1)[0]})`,
      data: Object.values(prices),
      ...SUMMARY_COLOR_THEMES.default.darkYellow,
      ...BORDER_STYLES.default
    }
  ];
  return { labels, datasets };
};

const tripChartOptions: React.ComponentProps<typeof Bar>['options'] = {
  scales: {
    xAxes: {
      grid: { display: false }
    },
    yAxes: {
      grid: { display: false },
      ticks: {
        callback: (value: string | number) => `${parseInt(`${value}`, 10).toLocaleString()}円`
      }
    }
  }
};

export const SummaryDiv = styled.div`
  text-align: right;
`;

export const SummaryTab = styled.ul`
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background-color: #ffffff;
  padding: 0.6em 0.2em;
`;

export const SummaryTabLi = styled.li<SummaryTabLiProps>`
  position: relative;
  display: inline;
  padding: 0.4em 0.6em;
  ${({ left }) =>
    left
      ? css`
          border-right: 1px solid #c4c4c4;
        `
      : ''}
  ${({ right }) =>
    right
      ? css`
          border-left: 1px solid #c4c4c4;
        `
      : ''}
  ${({ active }) =>
    active
      ? css`
          border: 1px solid #af985e;
          background-color: #f7f5ef;
          border-radius: 4px;
        `
      : ''}
`;

export default DashboardSummarySection;

import _ from 'lodash';

export type HotelElementGuestJson = {
  bedTypeDescription?: string;
  guestFirstName?: string;
  guestLastName?: string;
  guestTel?: string;
};

class HotelElementGuest {
  bedTypeDescription: string | undefined;

  guestFirstName: string | undefined;

  guestLastName: string | undefined;

  guestTel: string | undefined;

  constructor(args: HotelElementGuestJson = {}) {
    this.bedTypeDescription = args.bedTypeDescription;
    this.guestFirstName = args.guestFirstName;
    this.guestLastName = args.guestLastName;
    this.guestTel = args.guestTel;
  }

  bedTypeDescriptionOverview() {
    if (_.isEmpty(this.bedTypeDescription)) {
      return 'ベッドタイプ：-';
    }
    return `ベッドタイプ：${this.bedTypeDescription}`;
  }
}

export default HotelElementGuest;

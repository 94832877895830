import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import moment from 'moment-timezone';

import type Trip from '@this/domain/trip/trip';
import type Order from '@this/domain/order';
import type OrderItem from '@this/domain/order_item';
import TransportElement from '@this/domain/transport_element';
import type HotelElement from '@this/domain/hotel_element';
import type ShareholderTicketList from '@this/domain/shareholder_ticket_list/index';
import type BulkTicket from '@this/domain/bulk_ticket2';
import type TaxType from '@this/domain/tax_type';
import type SuppliedItem from '@this/domain/supplied_item/supplied_item';
import type PaymentMethodList from '@this/src/domain/payment_method/payment_method_list';

import Text from '@this/shared/text/text';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import Modal from '@this/shared/modal/modal';
import Confirm from '@this/shared/confirm/confirm';
import { Button } from '@this/shared/ui/inputs/button';
import type { ShareholderInfo } from '@this/domain/other_service_shareholder_info';
import type { CabinOption } from '@this/domain/cabin_option';
import type { IndividualTargetSuppliedItem } from '@this/domain/individual_target_supplied_item';
import OrderItemPriceDetail from '@this/domain/order_item_price_detail';
import { Fetcher, HTTPError } from '@this/src/util';
import type { IndividualDomesticAirSuppliedItem } from '@this/domain/individual_domestic_air_supplied_item';
import PartnerErrorMessage from '../../partner_error_message';
import type {
  PnrRetrieveFlightResponseJson,
  PnrRetrievePriceResponseJson
} from './price_change_form_item/price_change_form_item';
import PriceChangeFormItem from './price_change_form_item/price_change_form_item';

interface Props {
  serviceId: number;
  selectedTrip: Trip;
  isEditing: boolean;
  order?: Order;
  shareholderTickets: ShareholderTicketList;
  bulkTickets: BulkTicket[];
  taxTypes: TaxType[];
  suppliedItems: SuppliedItem[];
  paymentMethods: PaymentMethodList;
  shareholderInfos: ShareholderInfo[];
  cabinOptions: CabinOption[];
  individualTargetSuppliedItems: IndividualTargetSuppliedItem[];
  individualDomesticAirSuppliedItems: IndividualDomesticAirSuppliedItem[];
  onToggle: () => void;
  onSubmitted: () => void;
  reloadSelectedTrip: () => void;
  hotelElementProviderOptions: { [key: string]: string };
}

interface State {
  isConfirmVisible: boolean;
  isSubmitting: boolean;
  isAirNumbersMissing: boolean;
  cancelHotels: HotelElement[];
  errors: string[][][];
  paymentError?: string;
  showMarginAmountNotification: boolean;
}

class PriceChangeForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isConfirmVisible: false,
      isSubmitting: false,
      isAirNumbersMissing: this.isAirNumbersMissing(),
      showMarginAmountNotification: false,
      cancelHotels: [],
      errors: []
    };
  }

  openConfirmWithMarginAmountNotification(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const needReCalcMarginAmount = this.props.order ? this.props.order.isNeedReCalcMarginAmount() : false;
    if (needReCalcMarginAmount) {
      this.setState({ showMarginAmountNotification: true });
    } else {
      this.openConfirm();
    }
  }

  handleConfirmMarginAmountNotification() {
    this.setState({ showMarginAmountNotification: false }, () => {
      this.openConfirm();
    });
  }

  openConfirm() {
    if (!this.props.order) {
      return;
    }
    this.setState({ errors: this.props.order.validationErrors() }, () => {
      this.setState({ isConfirmVisible: !this.hasValidationError() });
    });
  }

  closeConfirm() {
    this.setState({ isConfirmVisible: false, paymentError: '' });
  }

  hasValidationError(): boolean {
    for (let i = 0; i < this.state.errors.length; i += 1) {
      const err = this.state.errors[i];
      for (let j = 0; j < err.length; j += 1) {
        const e = err[j];
        if (e && e.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  requestParams(): object {
    if (!this.props.order) {
      return {};
    }

    return _.merge(this.props.order.updateParams(), { trip_id: this.props.selectedTrip.id });
  }

  handleRemoveItem = (orderItem: OrderItem) => {
    let errors: string[][][] = [];
    orderItem.elements.some((element: any) => {
      errors = this.addToCancelList(element, orderItem.orderItemCategory);
      return errors.length > 0;
    });

    if (this.props.order && errors.length === 0) {
      this.props.order.handleRemoveItem(orderItem);
    } else {
      this.setState({ errors });
    }
  };

  handleRemoveElement = (orderItem: OrderItem, i: number) => {
    let errors: string[][][] = [];
    const element = orderItem.elements[i];
    errors = this.addToCancelList(element, orderItem.orderItemCategory);

    if (errors.length === 0) {
      orderItem.handleRemoveElement(i);
    }
    this.setState({ errors });
  };

  updatePriceDetailsFromPnr = (
    orderItem: OrderItem,
    pnrPriceResponseJsonArray: PnrRetrievePriceResponseJson[]
  ): void => {
    orderItem.isEditing = true; // 元に戻すボタン対応
    const priceDetails = orderItem.price.priceDetails;

    if (!priceDetails) return;

    const updatedPriceDetails: OrderItemPriceDetail[] = [];

    pnrPriceResponseJsonArray.forEach((priceDetail: PnrRetrievePriceResponseJson) => {
      const existingDetail = priceDetails.find(detail => detail.name === priceDetail.name);

      if (existingDetail) {
        // 名前が同じもの → 金額・課税区分を更新
        existingDetail.setPriceAndTaxType(priceDetail.price || 0, priceDetail.tax_type_id || 0);
        updatedPriceDetails.push(existingDetail);
      } else {
        // PNRから取り込んだものには名前があるが、元々ないもの → 追加
        const newPriceDetail = new OrderItemPriceDetail({
          name: priceDetail.name || '',
          price: priceDetail.price || 0,
          orderItemPriceId: orderItem.price.id,
          taxTypeId: priceDetail.tax_type_id || 0
        });
        updatedPriceDetails.push(newPriceDetail);
      }
    });

    orderItem.price.priceDetails = updatedPriceDetails;

    const total = orderItem.price.priceDetails.reduce((value, detail) => value + detail.price, 0);
    orderItem.price.setPrice(total);
  };

  updateForeignAirTransportElements = (
    orderItem: OrderItem,
    responseSegments: PnrRetrieveFlightResponseJson[]
  ) => {
    orderItem.isEditing = true; // 元に戻すボタン対応
    // isForeignAirがfalseの要素を残す
    orderItem.elements = orderItem.elements.filter((element: any) => {
      const isForeignAir = element.type === 'transport' && element.transportType === 'foreign_air';

      return !isForeignAir;
    });

    // responseSegmentsをループして新しいTransportElementを作成し、orderItem.elementsに追加
    const newElements = responseSegments.map(
      segment =>
        new TransportElement({
          transportType: 'foreign_air',
          from: {
            name: segment.airport_from_name,
            time: segment.d_datetime,
            timeZone: segment.from_time_zone
          },
          to: {
            name: segment.airport_to_name,
            time: segment.a_datetime,
            timeZone: segment.to_time_zone
          },
          name: segment.carrier + segment.flight_number,
          cabin: segment.cabin
        })
    );

    orderItem.elements.push(...newElements);
  };

  addToCancelList(element: any, itemCategory: string) {
    if (element.type === 'hotel' && element.initialData.provider === 'mynavi' && itemCategory !== 'cancel') {
      if (element.checkinDate >= moment.tz('Asia/Tokyo').add(1, 'day').startOf('day')) {
        this.state.cancelHotels.push(element);
      } else {
        return [[['変更受付期間を過ぎているため、予約のキャンセルは行えません。']]];
      }
    }
    return [];
  }

  async handleSubmit(order: Order) {
    this.setState({ isSubmitting: true });
    let errors: string[][][] = [];

    if (this.state.cancelHotels.length > 0) {
      this.state.cancelHotels.forEach((element: any, i: number) => {
        Fetcher.put(`/hotels/${element.id}/cancel`, {}).then(
          () => {
            if (element.provider === 'mynavi') {
              element.handleHotelProviderChange('other');
            }
            if (this.state.cancelHotels.length === i + 1) {
              this.setState({ cancelHotels: [], errors });
              this.executeSubmit();
            }
          },
          error => {
            element.restore();
            errors = [
              [
                [
                  error instanceof HTTPError && error.response?.data.error
                    ? error.response.data.error
                    : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
                ]
              ]
            ];
            if (this.state.cancelHotels.length === i + 1) {
              this.setState({ cancelHotels: [], errors });
              this.executeSubmit();
            }
          }
        );
      });
    } else {
      this.executeSubmit();
    }
  }

  executeSubmit() {
    Fetcher.post('/orders', this.requestParams()).then(
      () => {
        this.props.onSubmitted();
        this.setState({ isSubmitting: false, isConfirmVisible: false });
      },
      error => {
        this.setState({
          isSubmitting: false,
          paymentError:
            error instanceof HTTPError &&
            [400, 500].includes(error.response?.status ?? 0) &&
            error.response?.data.errors
              ? error.response.data.errors
              : ''
        });
      }
    );
  }

  updateIsAirNumbersMissing = (orderItem: OrderItem) => {
    if (this.props.order) {
      this.props.order.orderItems = this.props.order.orderItems.map((item: OrderItem) => {
        if (item.id === orderItem.id) {
          return orderItem;
        }
        return item;
      });
      this.setState({
        isAirNumbersMissing: this.isAirNumbersMissing()
      });
    }
  };

  isAirNumbersMissing() {
    let missing = false;
    if (utils.isAiTravel(this.props.serviceId) && this.props.selectedTrip.isFixed() && this.props.order) {
      _.each(this.props.order.orderItems, item => {
        _.each(item.elements, el => {
          if (
            el.type === 'transport' &&
            el instanceof TransportElement &&
            el.isDomesticAir() &&
            el.isAirNumbersMissing()
          ) {
            missing = true;
          }
        });
      });
    }
    return missing;
  }

  render() {
    try {
      const {
        selectedTrip,
        isEditing,
        order,
        shareholderTickets,
        bulkTickets,
        taxTypes,
        suppliedItems,
        paymentMethods,
        shareholderInfos,
        cabinOptions,
        individualTargetSuppliedItems,
        individualDomesticAirSuppliedItems,
        onToggle,
        reloadSelectedTrip,
        hotelElementProviderOptions
      } = this.props;
      const {
        isConfirmVisible,
        errors,
        isSubmitting,
        isAirNumbersMissing,
        paymentError,
        showMarginAmountNotification
      } = this.state;
      return (
        <>
          {isEditing && order ? (
            <form
              className="virtual-counter-partner__pay-request-form"
              onSubmit={e => this.openConfirmWithMarginAmountNotification(e)}
            >
              {order.orderItems
                .filter(item => !item.deletedAt)
                .map((item, i) => (
                  <PriceChangeFormItem
                    key={i}
                    orderItem={item}
                    index={i}
                    order={order}
                    selectedTrip={selectedTrip}
                    shareholderTickets={shareholderTickets}
                    bulkTickets={bulkTickets}
                    taxTypes={taxTypes}
                    serviceId={this.props.serviceId}
                    suppliedItems={suppliedItems}
                    paymentMethods={paymentMethods}
                    shareholderInfos={shareholderInfos}
                    cabinOptions={cabinOptions}
                    individualTargetSuppliedItems={individualTargetSuppliedItems}
                    individualDomesticAirSuppliedItems={individualDomesticAirSuppliedItems}
                    handleRemoveItem={this.handleRemoveItem}
                    handleRemoveElement={this.handleRemoveElement}
                    updateIsAirNumbersMissing={this.updateIsAirNumbersMissing}
                    reloadSelectedTrip={reloadSelectedTrip}
                    hotelElementProviderOptions={hotelElementProviderOptions}
                    updateForeignAirTransportElements={this.updateForeignAirTransportElements}
                    updatePriceDetailsFromPnr={this.updatePriceDetailsFromPnr}
                  />
                ))}
              <div className="virtual-counte-price-change-form__add-order-item-button">
                <a onClick={() => order.handleAddItem()}>+商品を追加</a>
              </div>
              {this.hasValidationError() && (
                <div className="virtual-counter-partner__order-error-list">
                  {errors.map((itemErrors, index) => (
                    <div key={index}>
                      {itemErrors.map((elementErrors, i) => (
                        <div className="error virtual-counter-partner__order-error(" key={i}>
                          {elementErrors.map((error, j) => (
                            <Text key={j} text={error} />
                          ))}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
              <input type="submit" value="変更内容を保存" disabled={isSubmitting || isAirNumbersMissing} />
              {isAirNumbersMissing && <Error>※国内航空券の予約番号、または確認番号が未入力です。</Error>}
              <a className="virtual-counter__show-change-price-form-button" onClick={onToggle} />
              <Modal hideModal={() => this.closeConfirm()} show={isConfirmVisible} title="値段変更の確認">
                <p>この内容で決済金額を変更します。</p>
                <div className="virtual-counter__pay-request-modal">
                  <Text text={order.description()} />
                  <p>---</p>
                  <br />
                  <p className="bold">{`合計：${utils.formatPrice(order.totalPrice())}`}</p>
                </div>
                {paymentError && <PartnerErrorMessage message={paymentError} />}
                {isSubmitting ? (
                  <SimpleLoading />
                ) : (
                  <>
                    {paymentError ? (
                      <ButtonSection>
                        <Button onClick={() => this.closeConfirm()}>閉じる</Button>
                      </ButtonSection>
                    ) : (
                      <Confirm
                        onConfirmed={() => this.handleSubmit(order)}
                        onAborted={() => this.closeConfirm()}
                      />
                    )}
                  </>
                )}
              </Modal>
            </form>
          ) : (
            <a className="virtual-counter__show-change-price-form-button" onClick={onToggle}>
              内容を変更する
            </a>
          )}
          <Modal
            hideModal={() => this.setState({ showMarginAmountNotification: false })}
            show={showMarginAmountNotification}
          >
            <p>自動計算された手数料と入力された手数料が異なります。このまま保存しますか？</p>
            <Confirm
              onConfirmed={() => this.handleConfirmMarginAmountNotification()}
              onAborted={() => this.setState({ showMarginAmountNotification: false })}
            />
          </Modal>
        </>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;

export default PriceChangeForm;

import { Fetcher, objectToFormData } from '@this/src/util';
import _ from 'lodash';
import { observable, action } from 'mobx';
import type { PaymentMethodArgs } from './payment_method';
import PaymentMethod, { initializePaymentMethod } from './payment_method';

class PaymentMethodList {
  @observable
  public list: PaymentMethod[];

  @observable
  public editingObj: PaymentMethod | null = null;

  @observable
  public saving = false;

  constructor(raw: PaymentMethodArgs[]) {
    this.list = raw.map(paymentMethod => new PaymentMethod(paymentMethod));
  }

  find(id?: number) {
    if (!id) return undefined;
    return _.find(this.list, paymentMethod => paymentMethod.id === id);
  }

  default(id?: number) {
    const paymentMethod = this.find(id);
    if (!paymentMethod) return null;

    return paymentMethod?.codeWithName();
  }

  @action
  add() {
    const newObject = new PaymentMethod(initializePaymentMethod);
    newObject.addClosingDate();
    this.list.push(newObject);
    this.editingObj = newObject;
  }

  @action
  edit(paymentMethod: PaymentMethod) {
    this.editingObj = paymentMethod;
  }

  @action
  remove(paymentMethod: PaymentMethod) {
    const i = this.list.indexOf(paymentMethod);
    if (i > -1) this.list.splice(i, 1);
  }

  @action
  close() {
    if (!this.editingObj) return;

    if (!this.editingObj.id) {
      this.remove(this.editingObj);
    }

    this.editingObj = null;
  }

  @action
  async fetchUpdate() {
    if (!this.editingObj) return false;

    const method = this.editingObj.id ? 'PUT' : 'POST';
    const url = this.editingObj.id
      ? `/admin/payment_methods/${this.editingObj.id}.json`
      : '/admin/payment_methods.json';

    const submitParams = this.editingObj.id
      ? objectToFormData(this.editingObj.submitParams()) // FIXME: 編集のときだけFormDataに変換しないといけないのを修正したい
      : this.editingObj.submitParamsForCreate();
    this.saving = true;
    let successFlg;
    await Fetcher.request<{ id: number }>({ url, data: submitParams, method })
      .then(result => {
        this.editingObj?.setField('id', result.id);
        this.editingObj = null;
        this.saving = false;
        successFlg = true;
      })
      .catch(e => {
        this.saving = false;
        alert(e.response.data.message);
        successFlg = false;
      });
    return successFlg;
  }

  @action
  async fetchRemove(paymentMethod: PaymentMethod) {
    this.saving = true;
    let successFlg;
    await Fetcher.delete(`/admin/payment_methods/${paymentMethod.id}.json`)
      .then(() => {
        this.saving = false;
        successFlg = true;
      })
      .catch(e => {
        this.saving = false;
        alert(e.response.data.message);
        successFlg = false;
      });
    return successFlg;
  }

  @action
  async fetchRivive(paymentMethod: PaymentMethod) {
    this.saving = true;
    let successFlg;
    await Fetcher.put(`/admin/payment_methods/${paymentMethod.id}/rivive.json`, {})
      .then(() => {
        this.saving = false;
        successFlg = true;
      })
      .catch(e => {
        this.saving = false;
        alert(e.response.data.message);
        successFlg = false;
      });
    return successFlg;
  }
}

export default PaymentMethodList;

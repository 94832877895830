import _ from 'lodash';
import type MarginTypeList from '@this/domain/organization/margin_type_list';
import type { Args as TransitArgs } from '@this/domain/transit/transit';
import Transit from '@this/domain/transit/transit';
import type { Args as FlightArgs } from '@this/domain/flight/flight';
import Flight from '@this/domain/flight/flight';
import type { Args as HotelArgs } from '@this/domain/hotel/hotel';
import Hotel from '@this/domain/hotel/hotel';

interface Args {
  index?: number | string; // reserve_confirm/show.json のレスポンスが文字列 ("0")
  element_id: string | number | null | undefined;
  element_type: 'transport' | 'hotel' | 'flight' | undefined;
  element_raw: TransitArgs | FlightArgs | HotelArgs | null | undefined;
  domestic_air_price_index: number | null | undefined;
  showFee: boolean;
  marginTypes: MarginTypeList;
  foreign_exist: string; // "true" or "false"
}

class ReservingTripItem {
  index: number | string | undefined;

  element_id: string | number | null | undefined;

  element_type: 'transport' | 'hotel' | 'flight' | undefined;

  // element_rawの型がelement_typeに応じて決まっていることを表現できない
  element_raw: TransitArgs | FlightArgs | HotelArgs | null | undefined;

  domesticAirPriceIndex: number | null | undefined;

  showFee: boolean;

  marginTypes: MarginTypeList;

  element: Transit | Hotel | Flight;

  foreignExist: boolean;

  constructor(args: Args) {
    this.index = args.index;
    this.element_id = args.element_id;
    this.element_type = args.element_type;
    this.element_raw = args.element_raw;
    this.domesticAirPriceIndex = args.domestic_air_price_index;
    this.showFee = args.showFee;
    this.marginTypes = args.marginTypes;
    this.foreignExist = args.foreign_exist === 'true';

    const raw = _.merge(this.element_raw, { showFee: this.showFee });
    this.element = (() => {
      switch (this.element_type) {
        case 'transport': {
          const marginType = (raw as TransitArgs).shinkansen
            ? this.marginTypes.domesticShinkansenMarginType()
            : this.marginTypes.domesticFlightMarginType();
          const args = _.merge(raw, {
            domestic_air_price_index: this.domesticAirPriceIndex,
            marginType
          }) as TransitArgs;
          return new Transit(args);
        }
        case 'flight': {
          const args = _.merge(raw, { marginType: this.marginTypes.foreignMarginType() }) as FlightArgs;
          return new Flight(args);
        }
        case 'hotel': {
          const marginType = this.foreignExist
            ? this.marginTypes.foreignHotelMarginType()
            : this.marginTypes.domesticHotelMarginType();
          const args = _.merge(raw, { showFee: this.showFee, marginType }) as HotelArgs;
          return new Hotel(args);
        }
        default:
          throw new Error(`invalid element_type: "${this.element_type}"`);
      }
    })();
  }

  totalPrice() {
    if (this.isTransit() || this.isFlight() || this.isHotel()) {
      return this.element.totalPrice();
    }

    return undefined;
  }

  priceText() {
    if (this.isTransit() && this.element instanceof Transit) {
      return this.element.transitPriceText();
    }
    if (this.isFlight()) {
      return this.element.priceText();
    }
    if (this.isHotel()) {
      return this.element.priceDetailText();
    }

    return undefined;
  }

  airExists() {
    if (this.isTransit() && this.element instanceof Transit) {
      return this.element.airExists();
    }
    if (this.isFlight()) {
      return true;
    }
    if (this.isHotel()) {
      return false;
    }

    return undefined;
  }

  shinkansenExists() {
    if (this.isTransit() && this.element instanceof Transit) {
      return this.element.shinkansenExists();
    }

    return false;
  }

  expressExists() {
    if (this.isTransit() && this.element instanceof Transit) {
      return this.element.expressExists();
    }

    return false;
  }

  startDate() {
    if (
      (this.isTransit() && this.element instanceof Transit) ||
      (this.isFlight() && this.element instanceof Flight)
    ) {
      return this.element.startDate();
    }
    if (this.isHotel() && this.element instanceof Hotel) {
      return this.element.checkinDateStr();
    }

    return undefined;
  }

  endDate() {
    if (
      (this.isTransit() && this.element instanceof Transit) ||
      (this.isFlight() && this.element instanceof Flight)
    ) {
      return this.element.endDate();
    }
    if (this.isHotel() && this.element instanceof Hotel) {
      return this.element.checkoutDateStr();
    }

    return undefined;
  }

  flightDescription() {
    if (this.isFlight() && this.element instanceof Flight) {
      return this.element.description;
    }

    return undefined;
  }

  minirule() {
    if (this.isFlight() && this.element instanceof Flight && this.element.minirule) {
      const { peoplenum } = this.element;
      if (this.element.minirule.split(/：|円|　/)[3] !== '不可') {
        const cancelPrice = utils.formatPriceText(this.element.minirule.split(/：|円|　/)[3]);
        const cancelPricePerPerson = cancelPrice / peoplenum;
        return this.element.minirule.replace(
          `${this.element.minirule.split(/：|円|　/)[3]}円`,
          `${utils.formatPrice(cancelPricePerPerson)} * ${peoplenum}人`
        );
      }
    }

    return undefined;
  }

  getDeadlineHour() {
    if (this.isFlight() && this.element instanceof Flight && this.element.itineraries[0].timeToLive) {
      return this.element.itineraries[0].timeToLive;
    }

    return undefined;
  }

  isTransit() {
    return this.element_type === 'transport';
  }

  isFlight() {
    return this.element_type === 'flight';
  }

  isHotel() {
    return this.element_type === 'hotel';
  }

  isDomesticExist() {
    return !this.foreignExist;
  }

  submitParams() {
    let railway_distance = null;
    let distance = null;
    let route_time = null;
    if (this.element instanceof Transit) {
      railway_distance = this.element.railway_distance;
      distance = this.element.segments[0].distance;
      route_time = this.element.segments[0].routeTime;
    }
    return {
      index: this.index,
      element_type: this.element_type,
      element_id: this.element_id,
      railway_distance,
      distance,
      route_time
    };
  }

  bedType() {
    return (this.element instanceof Hotel && this.element.bed_types) || [];
  }

  get isUnreservedSeatAvailable() {
    if (this.element_type !== 'transport') return false;

    const transit = this.element as Transit;
    return transit.isUnreservedSeatAvailable;
  }
}

export default ReservingTripItem;

import React, { useCallback, useMemo, useState } from 'react';

import { Button } from '@this/components/shared/ui/inputs/button';
import { Text } from '@this/components/shared/ui/data_displays/typography';
import { Flex } from '@this/src/components/shared/ui/layout/flex';
import Notification from '@this/src/notification';

import type Trip from '@this/src/domain/trip/trip';
import { Fetcher, HTTPError } from '@this/src/util';

type Props = {
  serviceId: number;
  trip: Trip;
  onReloadTrip: () => void;
};

export const ReservationCompleteButton = ({ serviceId, trip, onReloadTrip }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const isReservable = useMemo(() => !trip.isReserveRequied(), [trip]);
  const isAirNumbersMissing = useMemo(
    () => utils.isAiTravel(serviceId) && trip.isAirNumbersMissing(),
    [serviceId, trip]
  );
  const disabled = useMemo(() => isReservable || isAirNumbersMissing, [isReservable, isAirNumbersMissing]);

  const handleClick = useCallback(async () => {
    if (disabled || submitting) {
      return;
    }

    setSubmitting(true);
    try {
      await Fetcher.post(`/payments/fix`, { trip_id: trip.id });
      onReloadTrip();
    } catch (e) {
      const msg =
        e instanceof HTTPError && e.response?.data?.errors
          ? e.response.data.errors
          : '通信環境が不安定です。画面をリロードしてからもう一度お試しください。';

      Notification.error(msg);
    } finally {
      setSubmitting(false);
    }
  }, [disabled, submitting, trip, onReloadTrip]);

  return (
    <Flex flexDirection="column" gap="4px">
      <Button
        loading={submitting}
        disabled={disabled}
        onClick={handleClick}
        data-testid="reservation-complete-button"
      >
        予約確定
      </Button>
      {isReservable && <Text color="danger">※予約確定できるのはステータスが「要予約」の時のみです。</Text>}
      {isAirNumbersMissing && <Text color="danger">※国内航空券の予約番号、または確認番号が未入力です。</Text>}
    </Flex>
  );
};

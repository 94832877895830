import { Fetcher } from '@this/src/util';
import { createContext, useEffect, useState } from 'react';

export type ServiceType = 'admin' | 'organization';

const ENDPOINTS: Record<ServiceType, string> = {
  admin: '/admin/organization_dashboards/:id/departments',
  organization: '/organization/dashboard_trips/departments'
};

export interface Department {
  id: number;
  code: string;
  name: string;
}

interface Props {
  serviceType: ServiceType;
  organizationId?: number;
}

interface State {
  departments: Department[];
}

export const useDepartments = ({ serviceType, organizationId }: Props) => {
  const [state, setState] = useState<State>({ departments: [] });
  const endpoint = ENDPOINTS[serviceType].replace(':id', organizationId?.toString() ?? '');

  useEffect(() => {
    Fetcher.get<State>(endpoint, { organization_id: organizationId }).then(({ departments }) =>
      setState(state => ({ ...state, departments }))
    );
  }, []);

  return state.departments;
};

export const DepartmentSelectContext = createContext<Department[]>([]);

import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import User from '@this/domain/user/user';
import type UserJson from '@this/domain/user/user_json';

import Header from '@this/shared/header/header';
import Footer from '@this/shared/footer/footer';

import FaqBlockForAIT from '@this/components/reserve_trip/dashboard/help_area_for_ait/faq_block_for_ait.template';
import HowToBlockForAIT from '@this/components/reserve_trip/dashboard/help_area_for_ait/how_to_block_for_ait.template';
import type { HeaderWithCodeProps } from '@this/components/reserve_trip/dashboard/help_area_for_ait/help_area_for_ait';
import {
  getHeaders,
  getHelpItems
} from '@this/components/reserve_trip/dashboard/help_area_for_ait/help_area_for_ait';
import type { HowToUse } from '@this/components/reserve_trip/dashboard/dashboard';
import HelpSearchBlock from '@this/components/reserve_trip/dashboard/help_area_for_ait/help_search_block';

interface HelpResponse {
  traveler_approver_guides: HowToUse[];
  traveler_approver_manuals: HowToUse[];
  admin_guides: HowToUse[];
  admin_manuals: HowToUse[];
}

interface Props {
  serviceId: number;
}

interface Item {
  href: string;
  text: string;
}

interface State {
  user: User | null;
  items: Item[];
  faqHeader: HeaderWithCodeProps;
  howToUseHeader: HeaderWithCodeProps;
  travelerApproverGuides: HowToUse[];
  travelerApproverManuals: HowToUse[];
  adminGuides: HowToUse[];
  adminManuals: HowToUse[];
}

class Help extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      travelerApproverGuides: [],
      travelerApproverManuals: [],
      adminGuides: [],
      adminManuals: [],
      faqHeader: {
        mainTitle: '何かお探しですか？',
        subTitles: [{ text: 'よくあるご質問' }]
      },
      howToUseHeader: {
        mainTitle: '何かお探しですか？',
        subTitles: [{ text: 'よくあるご質問' }]
      },
      items: [],
      user: null
    };
  }

  componentDidMount() {
    utils
      .jsonPromise<UserJson>('/users.json')
      .then(
        response => {
          this.setState({ user: new User(response) });
        },
        () => {
          this.setState({ user: null });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });

    this.fetchHelp();
    this.setState({
      faqHeader: getHeaders('HELP_FAQ'),
      howToUseHeader: getHeaders('HELP_HOW_TO_USE'),
      items: getHelpItems()
    });
  }

  async fetchHelp() {
    try {
      const res = await utils.jsonPromise<HelpResponse>('/help.json');
      this.setState({
        travelerApproverGuides: res.traveler_approver_guides,
        travelerApproverManuals: res.traveler_approver_manuals,
        adminGuides: res.admin_guides,
        adminManuals: res.admin_manuals
      });
    } catch (e) {
      utils.sendErrorObject(e);
    }
  }

  signedIn(): boolean {
    return !_.isNil(this.state.user);
  }

  memberSignedIn(): boolean {
    return this.signedIn() && this.state.user!.isMember();
  }

  render() {
    try {
      const {
        user,
        faqHeader,
        howToUseHeader,
        items,
        travelerApproverGuides,
        travelerApproverManuals,
        adminGuides,
        adminManuals
      } = this.state;
      const { serviceId } = this.props;

      return (
        <div className="help">
          <Wrap>
            <Header user={user} />
            <div
              style={{
                flexGrow: 9999
              }}
            >
              <MainVisual>
                <h1
                  style={{
                    color: 'white',
                    fontSize: '23px',
                    fontWeight: 700,
                    lineHeight: '28.75px',
                    wordWrap: 'break-word',
                    zIndex: 1
                  }}
                >
                  AI Travelサポートページ
                </h1>
              </MainVisual>
              <Contents data-wovn-ignore>
                <>
                  <HelpSearchBlock showTitle />
                  <FaqBlockForAIT header={faqHeader} items={items} showSubCategory />
                  {this.memberSignedIn() && (
                    <HowToBlockForAIT
                      header={howToUseHeader}
                      travelerApproverGuides={travelerApproverGuides}
                      travelerApproverManuals={travelerApproverManuals}
                      adminGuides={adminGuides}
                      adminManuals={adminManuals}
                    />
                  )}
                </>
              </Contents>
            </div>
            <Footer signedIn={this.signedIn()} serviceId={serviceId} />
          </Wrap>
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
`;

const MainVisual = styled.div`
  padding: 96px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;

  &::after {
    background: url('/images/biz_lp/top_bg.png') no-repeat center center;
    background-size: cover;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
`;

const Contents = styled.div`
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 14.5px;
`;

export default Help;

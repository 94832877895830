import { Fetcher } from '@this/src/util';
import _ from 'lodash';
import { observable, computed } from 'mobx';

export interface ExpensesAccountTypeJson {
  id?: number;
  name: string;
  code: string;
  organization?: number;
  selected?: boolean;
  is_deleted?: boolean;
  display_order?: number;
  freee_account_item_id?: string;
  receipt_file_required?: boolean;
}

interface DepartmentstResponse {
  departments: string;
}
export class ExpensesAccountType {
  id?: number;

  @observable
  name: string;

  @observable
  code: string;

  @observable
  departments: string;

  organization?: number;

  selected: boolean;

  isDeleted?: boolean;

  displayOrder?: number | null;

  @observable
  freeeAccountItemId?: string | null;

  receiptFileRequired?: boolean;

  private departmentsPath: string;

  constructor(args: ExpensesAccountTypeJson) {
    this.id = args.id;
    this.name = args.name;
    this.organization = args.organization;
    this.code = args.code;
    this.selected = false;
    this.isDeleted = args.is_deleted;
    this.displayOrder = args.display_order;
    this.freeeAccountItemId = args.freee_account_item_id;
    this.departments = '';
    this.departmentsPath = `/organization/expenses_account_types/${this.id}/departments`;
    this.receiptFileRequired = args.receipt_file_required;
  }

  @computed
  get codeText() {
    if (!_.isEmpty(this.code) && this.code.match(/\S/g)) {
      return `(${this.code})`;
    }
    return '';
  }

  @computed
  get isDeletedName() {
    if (this.isDeleted) {
      return `${this.name}(削除済)`;
    }
    return this.name;
  }

  codeAndNameOneLine = (): string => {
    if (this.code) {
      return `${this.code}:${this.name}`;
    }
    return this.name;
  };

  getIdStr = (): string => {
    return this.id ? this.id.toString() : '';
  };

  submitParams() {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      departments: this.departments,
      display_order: this.displayOrder,
      freee_account_item_id: this.freeeAccountItemId,
      receipt_file_required: this.receiptFileRequired
    };
  }

  static new() {
    return new ExpensesAccountType({ receipt_file_required: false, name: '', code: '' });
  }

  async fetchDepartments() {
    await Fetcher.get<DepartmentstResponse>(`${this.departmentsPath}.json`).then(
      result => {
        this.departments = result.departments;
      },
      _error => {
        throw _error;
      }
    );
  }

  setReceiptFileRequired(value: boolean) {
    this.receiptFileRequired = value;
    app.render();
  }
}

export default ExpensesAccountType;

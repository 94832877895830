import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import HowToBlockForAIT from '@this/components/reserve_trip/dashboard/help_area_for_ait/how_to_block_for_ait.template';
import HelpSearchBlock from '@this/components/reserve_trip/dashboard/help_area_for_ait/help_search_block';
import type User from '@this/domain/user/user';
import type { HowToUse } from '../dashboard';
import FaqBlockForAIT from './faq_block_for_ait.template';

interface Props {
  user: User | null;
  travelerApproverGuides: HowToUse[];
  travelerApproverManuals: HowToUse[];
  adminGuides: HowToUse[];
  adminManuals: HowToUse[];
}

interface Item {
  href: string;
  text: string;
}

type Code = 'FAQ' | 'HOW_TO_USE' | 'HELP_FAQ' | 'HELP_HOW_TO_USE' | '';

export type SubCode = 'T_AND_A' | 'ADMIN' | '';

export interface HeaderWithCodeProps {
  mainTitle: string;
  subTitles: { subCode?: SubCode; text: string }[];
}

export const getHeaders = (code: Code): HeaderWithCodeProps => {
  switch (code) {
    case 'FAQ':
      return {
        mainTitle: '何かお探しですか？',
        subTitles: [{ text: 'よくあるご質問' }]
      };
    case 'HOW_TO_USE':
      return {
        mainTitle: '使い方ガイド',
        subTitles: [
          {
            subCode: 'T_AND_A',
            text: '出張者・承認者向け情報'
          },
          {
            subCode: 'ADMIN',
            text: '管理者向け情報'
          }
        ]
      };
    case 'HELP_FAQ':
      return {
        mainTitle: '',
        subTitles: [{ text: 'よくあるご質問' }]
      };
    case 'HELP_HOW_TO_USE':
      return {
        mainTitle: '',
        subTitles: [
          {
            subCode: 'T_AND_A',
            text: '出張者・承認者向け情報'
          },
          {
            subCode: 'ADMIN',
            text: '管理者向け情報'
          }
        ]
      };
    default:
      return {
        mainTitle: '何かお探しですか？',
        subTitles: [{ text: 'よくあるご質問' }]
      };
  }
};

export const getHelpItems = (): Item[] => {
  return [
    { href: `/knowledges/000001043`, text: '宿泊なしの手配は可能ですか？' },
    { href: `/knowledges/000001040`, text: '「目的地周辺のホテルが見つかりませんでした。」と表示されました' },
    {
      href: `/knowledges/000001106`,
      text: 'エラーメッセージが表示されて、交通機関・ホテルの予約ができない場合はどうすればいいですか？'
    },
    {
      href: `/knowledges/000001121`,
      text: 'パッケージで手配の場合、宿泊日数や宿泊地を変更することは可能ですか？'
    },
    {
      href: `/knowledges/000001048`,
      text: '交通機関・天候などのやむを得ない状況により宿までたどり着けないため、キャンセルしたいです。キャンセル料はどのようになりますか？'
    },
    {
      href: `/knowledges/000001006`,
      text: '出張対象者が今の時点でパスポートをもっておらず、出張日までに取得する予定なのですがその場合でも事前に旅券と宿泊の手配の依頼はできますか？'
    },
    {
      href: `/knowledges/000001030`,
      text: '航空券を選択する画面に表示されている「変更可・変更不可」とは何ですか？'
    },
    {
      href: `/knowledges/000001047`,
      text: '新幹線の実券のチケットを受取り後にキャンセルした場合、チケット類はどうしたらよいですか？'
    }
  ];
};

const HelpAreaForAIT = ({
  user,
  travelerApproverGuides,
  travelerApproverManuals,
  adminGuides,
  adminManuals
}: Props) => {
  const [items] = useState<Item[]>(getHelpItems);
  const [faqHeader] = useState<HeaderWithCodeProps>(getHeaders('FAQ'));
  const [howToUseHeader] = useState<HeaderWithCodeProps>(getHeaders('HOW_TO_USE'));

  return (
    <Wrapper data-wovn-ignore>
      <>
        <HelpSearchBlock showTitle />
        <FaqBlockForAIT header={faqHeader} items={items} showSubCategory={false} />
        {user && user.organizationId && (
          <HowToBlockForAIT
            header={howToUseHeader}
            travelerApproverGuides={travelerApproverGuides}
            travelerApproverManuals={travelerApproverManuals}
            adminGuides={adminGuides}
            adminManuals={adminManuals}
          />
        )}
      </>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 10px 14.5px;
`;

export default HelpAreaForAIT;

import _ from 'lodash';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import ExpensesType from '../expenses/expenses_type';
import TaxType from '../tax_type';
import type { TripType } from '../expenses/item';
import type { OrderItemCategoryOptions } from '../order_item';
import Project from '../project/project';
import type { ExpenseTool } from '../setting';
import { Participant } from './participant';
import type { NonOrderItemForReportArgs } from './trip_args';

interface OptionalProps {
  defaultTime?: Moment | null;
  adding?: boolean;
  options?: OrderItemCategoryOptions;
}

export type CalcPriceOrder = 'price' | 'time' | 'transfer';

export type TripTypeOptions = [string, TripType][];

export type CalcPriceOrderOptions = [string, CalcPriceOrder][];

interface EditingRequest {
  cancel?: boolean;
  expenseTool?: ExpenseTool;
}

type EditableFields =
  | 'time'
  | 'payee'
  | 'price'
  | 'taxTypeId'
  | 'tripType'
  | 'railwayName'
  | 'peopleNum'
  | 'fromName'
  | 'toName'
  | 'memo';

type ValidationFields = EditableFields | 'projectId' | 'expensesTypeId' | 'participants';

export const NonOrderItemSort = (a: NonOrderItemForReport, b: NonOrderItemForReport) => {
  const aTime = a.getSortingTime();
  const bTime = b.getSortingTime();

  if (aTime === bTime) {
    return (a.id || 0) < (b.id || 0) ? -1 : 1;
  }

  return aTime.isBefore(bTime) ? -1 : 1;
};

export class NonOrderItemForReport {
  serial: string;

  id?: number;

  tripId?: number;

  tripReportId?: number;

  projectId?: number;

  expensesTypeId?: number;

  expensesReportItemId?: number;

  taxTypeId?: number;

  tripType: TripType;

  time: Moment | null = null;

  payee: string;

  railwayName: string;

  peopleNum: number;

  memo: string;

  price: number | null;

  project: Project | null;

  expensesType: ExpensesType | null;

  taxType: TaxType | null;

  startDate: Moment | null = null;

  endDate: Moment | null = null;

  fromName: string | null = null;

  toName: string | null = null;

  elementName?: string;

  participants: Participant[] = [];

  adding = false;

  editing = false;

  beforeEdit: Pick<
    NonOrderItemForReport,
    | EditableFields
    | 'projectId'
    | 'expensesTypeId'
    | 'expensesType'
    | 'taxTypeId'
    | 'taxType'
    | 'project'
    | 'participants'
  > | null = null;

  validationErrors: Partial<Record<ValidationFields, string>> = {};

  constructor(args: Partial<NonOrderItemForReportArgs>, { defaultTime, adding, options }: OptionalProps = {}) {
    this.serial = Math.random().toString(32).substring(2); // IDが未生成でも使える一意のキーを作成
    this.id = args.id;
    this.tripId = args.trip_id;
    this.tripReportId = args.trip_report_id;
    this.projectId = args.project_id;
    this.expensesTypeId = args.expenses_type_id;
    this.expensesReportItemId = args.expenses_report_item_id;
    this.taxTypeId = args.tax_type_id;
    this.tripType = args.trip_type || 'one_way';
    this.time = args.time ? moment(args.time) : null;
    this.payee = args.payee || '';
    this.railwayName = args.railway_name || '';
    this.peopleNum = args.participant_people_num || 1;
    this.memo = args.memo || '';
    this.price = args.price?.price || null;
    this.project = args.project ? new Project(args.project) : null;
    this.expensesType = args.expensesType || (args.expenses_type ? new ExpensesType(args.expenses_type) : null);
    this.taxType = args.taxType || (args.tax_type ? new TaxType(args.tax_type) : null);
    this.editing = adding || false;
    this.adding = adding || false;

    if (args.hotels && args.hotels.length > 0) {
      const hotel = args.hotels[0];
      this.startDate = moment(hotel.checkin_date);
      this.endDate = moment(hotel.checkout_date);
      this.elementName = `[宿泊] ${hotel.name}`;
    }

    if (args.transports && args.transports.length > 0) {
      const firstTransport = args.transports[0];
      const lastTransport = args.transports.slice(-1)[0];
      const element = options?.find(([_, key]) => key === firstTransport.transport_type)?.[0];
      this.startDate = moment(firstTransport.from.time);
      this.endDate = moment(lastTransport.to.time);
      this.fromName = firstTransport.from.name;
      this.toName = lastTransport.to.name;
      const fromToName = `${this.fromName} - ${this.toName}`;
      this.elementName = element ? `[${element}] ${fromToName}` : fromToName;
    }

    if (!this.time && !this.expensesTypeId) {
      this.time = this.startDate || this.endDate;
    }

    if (!this.time && !this.startDate && !this.endDate) {
      this.time = defaultTime || null;
    }

    if (args.participants) {
      this.participants = args.participants.map(args => new Participant(args));
    }
  }

  setEditing(editing: boolean, { cancel, expenseTool }: EditingRequest = {}) {
    if (!editing && !cancel) {
      const errors = this.validation({ expenseTool });

      if (errors.length > 0) {
        app.render();
        return;
      }
    }

    this.editing = editing;
    this.adding = false;

    // 編集時に`beforeEdit`に変更前のデータを格納し、キャンセル時にデータを戻すことでバリデーションされている状態を保つ
    if (editing) {
      this.beforeEdit = {
        time: this.time,
        price: this.price,
        payee: this.payee,
        tripType: this.tripType,
        railwayName: this.railwayName,
        peopleNum: this.peopleNum,
        fromName: this.fromName,
        toName: this.toName,
        memo: this.memo,
        projectId: this.projectId,
        expensesTypeId: this.expensesTypeId,
        taxTypeId: this.taxTypeId,
        taxType: this.taxType,
        expensesType: this.expensesType,
        project: this.project,
        participants: [...this.participants]
      };
      this.validationErrors = {};
    } else {
      if (cancel && this.beforeEdit) {
        this.time = this.beforeEdit.time;
        this.price = this.beforeEdit.price;
        this.payee = this.beforeEdit.payee;
        this.tripType = this.beforeEdit.tripType;
        this.railwayName = this.beforeEdit.railwayName;
        this.peopleNum = this.beforeEdit.peopleNum;
        this.fromName = this.beforeEdit.fromName;
        this.toName = this.beforeEdit.toName;
        this.memo = this.beforeEdit.memo;
        this.projectId = this.beforeEdit.projectId;
        this.expensesTypeId = this.beforeEdit.expensesTypeId;
        this.taxTypeId = this.beforeEdit.taxTypeId;
        this.expensesType = this.beforeEdit.expensesType;
        this.project = this.beforeEdit.project;
        this.participants = this.beforeEdit.participants;
      }
      this.beforeEdit = null;
    }

    app.render();
  }

  setField<T extends EditableFields>(name: T, value: this[T]) {
    this[name] = value;
    app.render();
  }

  setProject(project?: Project) {
    this.project = project || null;
    this.projectId = project?.id;
    app.render();
  }

  setExpensesType(expensesType?: ExpensesType) {
    this.expensesType = expensesType || null;
    this.expensesTypeId = expensesType?.id;
    app.render();
  }

  setTaxType(taxType?: TaxType) {
    this.taxType = taxType || null;
    this.taxTypeId = taxType?.id;
    app.render();
  }

  addParticipant() {
    this.participants.push(
      new Participant({
        non_order_item_id: this.id || 0,
        name: '',
        people_num: 1
      })
    );
    app.render();
  }

  removeParticipant(index: number) {
    this.participants.splice(index, 1);
    app.render();
  }

  getPeriod() {
    if (this.time) {
      return this.time.format('YYYY/MM/DD');
    }

    if (this.startDate && this.endDate) {
      return this.startDate.month === this.endDate.month
        ? `${this.startDate.format('M月D日')}-${this.endDate.format('D日')}`
        : `${this.startDate.format('M月D日')}-${this.endDate.format('M月D日')}`;
    }

    return `${(this.startDate || this.endDate)?.format('M月D日') || '─'}`;
  }

  getPrice() {
    return this.price ? `${this.price.toLocaleString()}円` : '─';
  }

  getProjectName() {
    return this.project?.name || '─';
  }

  getMemoOrElement() {
    return this.memo || this.elementName;
  }

  getSortingTime() {
    return this.time || this.startDate || moment().startOf('day');
  }

  travelExpenses() {
    return this.expensesType?.category === 'travel_expenses';
  }

  generalExpenses() {
    return this.expensesType?.category === 'general_expenses';
  }

  needParticipants() {
    return this.expensesType?.isNeedParticipants();
  }

  totalPeopleNum() {
    return this.participants.reduce((p, c) => p + c.peopleNum, this.peopleNum) || 0;
  }

  avaragePeopleUnit() {
    const total = this.totalPeopleNum();
    return this.price && total > 0 ? Math.floor(this.price / total) : null;
  }

  availableTime() {
    return Boolean(this.expensesTypeId);
  }

  invalid(field: ValidationFields) {
    return _.isEmpty(this.validationErrors[field]);
  }

  validation({ expenseTool }: Pick<EditingRequest, 'expenseTool'>) {
    const errors = [];

    if (!this.expensesTypeId && !this.elementName && expenseTool !== 'none') {
      this.validationErrors.expensesTypeId = 'ジャンルを選択してください';
      errors.push(this.validationErrors.expensesTypeId);
    }

    if (this.expensesTypeId) {
      if (this.time === null) {
        this.validationErrors.time = '日付を設定してください';
        errors.push(this.validationErrors.time);
      }

      if (this.payee === '') {
        this.validationErrors.payee = `${this.travelExpenses() ? '訪問先' : '支払先'}を入力してください`;
        errors.push(this.validationErrors.payee);
      }
    }

    if (this.price === null) {
      this.validationErrors.price = '金額を入力してください';
      errors.push(this.validationErrors.price);
    }

    if (this.needParticipants()) {
      if (isNaN(this.peopleNum)) {
        this.validationErrors.peopleNum = '参加人数を入力してください';
        errors.push(this.validationErrors.peopleNum);
      }

      this.validationErrors.participants = undefined;
      this.participants.forEach(participant => {
        if (this.validationErrors.participants) return;

        if (participant.name === '') {
          this.validationErrors.participants = '参加企業名を入力してください';
          errors.push(this.validationErrors.participants);
        } else if (isNaN(participant.peopleNum)) {
          this.validationErrors.participants = '参加人数を入力してください';
          errors.push(this.validationErrors.participants);
        }
      });
    }

    if (this.travelExpenses()) {
      if (this.fromName === '' || this.fromName === null) {
        this.validationErrors.fromName = '出発地を入力してください';
        errors.push(this.validationErrors.fromName);
      }

      if (this.toName === '' || this.toName === null) {
        this.validationErrors.toName = '到着地を入力してください';
        errors.push(this.validationErrors.toName);
      }

      if (this.expensesType?.isTrain() && this.railwayName === '') {
        this.validationErrors.railwayName = '利用鉄道名を入力してください';
        errors.push(this.validationErrors.railwayName);
      }
    }

    return errors;
  }

  submitParams() {
    return {
      id: this.id,
      trip_id: this.tripId,
      project_id: this.projectId || null,
      expenses_type_id: this.expensesTypeId || null,
      tax_type_id: this.taxTypeId || null,
      trip_type: this.tripType,
      time: this.time,
      payee: this.payee,
      railway_name: this.railwayName,
      participant_people_num: this.peopleNum,
      memo: this.memo,
      price: {
        price: this.price || 0
      },
      transports: this.transportParams(),
      participants: this.participants.map(participant => participant.submitParams())
    };
  }

  transportParams() {
    if (!this.expensesType || this.expensesType.category !== 'travel_expenses') return [];

    return [
      {
        transport_type: this.expensesType.mapTransportType(),
        from: {
          name: this.fromName || '',
          time: this.startDate?.format('YYYY-MM-DD') || this.time?.format('YYYY-MM-DD') || ''
        },
        to: {
          name: this.toName || '',
          time: this.endDate?.format('YYYY-MM-DD') || this.time?.format('YYYY-MM-DD') || ''
        }
      }
    ];
  }
}

export const convertNonOrderItemProps = (
  props: Partial<NonOrderItemForReport>
): Partial<NonOrderItemForReportArgs> => ({
  id: props.id,
  trip_id: props.tripId,
  trip_report_id: props.tripReportId,
  trip_type: props.tripType,
  payee: props.payee,
  railway_name: props.railwayName,
  participant_people_num: props.peopleNum,
  memo: props.memo,
  project: undefined,
  project_id: props.project?.id || props.projectId,
  expensesType: props.expensesType || undefined,
  expenses_type_id: props.expensesType?.id || props.expensesTypeId,
  tax_type_id: props.taxType?.id || props.taxTypeId,
  price: props.price ? { price: props.price } : undefined,
  transports: props.transportParams ? props.transportParams() : [],
  participants: props.participants?.map(participant => participant.submitParams()) || []
});

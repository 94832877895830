import { Fetcher } from '@this/src/util';
/* eslint-disable max-lines */
import { observable, computed, action } from 'mobx';
import _ from 'lodash';
import type { ExpensesAccountTypeJson } from '@this/domain/expenses/expenses_account_type';
import { ExpensesAccountType } from '@this/domain/expenses/expenses_account_type';
import type OrderItem from '@this/domain/order_item';
import type Approver from '@this/domain/approver/approver';
import type { ApproveItemArgs } from '@this/domain/approve_item/approve_item';
import type { ImageFile } from 'react-dropzone';
import type ProjectList from '../project/project_list';
import type DepartmentList from '../department/department_list';
import type OrganizationAddress from '../department/organization_address/organization_address';
import TravelerList from '../traveler/traveler_list';
import Traveler from '../traveler/traveler';
import type User from '../user/user';
import type Trip from '../trip/trip';
import Setting from '../setting';
import type { SettingArgs } from '../setting';
import type { OrderItemMappingArgs } from '../order_item_mapping';
import ApproveItemList from '../approve_item/approve_item_list';
import type { WorkflowStyle } from '../workflow_style';
import type ChargingDepartmentShareList from '../department/charging_department_share_list';
import type ProjectShareList from '../project/project_share_list';

interface ExpensesAccountTypesResponse {
  expenses_account_types: ExpensesAccountTypeJson[];
}

interface Stage {
  stage: number;
  approvers: (User | null)[];
}

interface Args {
  trip: Trip;
  travelers: TravelerList;
  departments: DepartmentList;
  departmentShares: ChargingDepartmentShareList;
  projects: ProjectList;
  projectShares: ProjectShareList;
  approvers: Approver[];
  projectShareAvailability: boolean;
  expensesAccountTypes: ExpensesAccountType[];
  orderItemMappingArgsList: OrderItemMappingArgs[];
  expensesAccountTypeAvailable: boolean;
  setting: SettingArgs | null;
  workflowStyle?: WorkflowStyle;
}

interface ApproveItemsResponse {
  approve_items: ApproveItemArgs[];
  total_page: number;
}

class ArrangementRequestWfInfo {
  @observable
  reservingTrip: Trip;

  @observable
  orderItems: OrderItem[];

  @observable
  travelers: TravelerList;

  @observable
  user: User;

  @observable
  approvers: Approver[];

  @observable
  foreignExist: boolean;

  @observable
  domesticExist: boolean;

  // FIXME: 使われていない
  @observable
  organizationAddress: OrganizationAddress | null;

  @observable
  internalNumber: string;

  @observable
  finalDestination: string;

  @observable
  purpose: string;

  @observable
  departments: DepartmentList;

  @observable
  departmentShares: ChargingDepartmentShareList;

  @observable
  chargingDepartmentId: string;

  @observable
  projects: ProjectList;

  @observable
  projectShares: ProjectShareList;

  @observable
  projectId: string;

  @observable
  expensesAccountTypes: ExpensesAccountType[];

  @observable
  setting: Setting | null;

  @observable
  workflowStyle?: WorkflowStyle;

  @observable
  showProjectDetail: boolean;

  @observable
  showDepartmentDetail: boolean;

  @observable
  showExpensesAccountTypeDetail: boolean;

  @observable
  approveItems: ApproveItemList;

  @observable
  projectShareAvailability: boolean;

  @observable
  expensesAccountTypeAvailable: boolean;

  @observable
  expensesAccountTypeRequired: boolean;

  @observable
  approveItemValues: Map<number, string>;

  @observable
  approveItemValueCodes: Map<number, string>;

  @observable
  approveItemFiles: Map<number, ImageFile>;

  @observable
  approveItemFilesBase64: Map<number, string>;

  @observable
  approveStages: Stage[];

  constructor(args: Args) {
    this.reservingTrip = args.trip;
    this.orderItems = args.trip.order.orderItems;
    this.travelers = args.travelers || new TravelerList();
    this.user = args.trip.user;
    this.approvers = args.approvers;
    this.approveItems = new ApproveItemList([]);
    this.foreignExist = args.trip.foreign_air;
    this.domesticExist = !args.trip.foreign_air;
    this.organizationAddress = null;
    this.internalNumber = args.trip.internalNumber || '';
    this.finalDestination = args.trip.final_destination || '';
    this.purpose = args.trip.purpose || '';
    this.departments = args.departments || '';
    this.departmentShares = args.departmentShares;
    this.projects = args.projects || '';
    this.projectShares = args.projectShares;
    this.chargingDepartmentId = args.trip.getChargingDepartmentIDs()[0]?.toString() || '';
    this.projectId = args.trip.projects.list[0]?.id?.toString() || '';
    this.expensesAccountTypes = args.expensesAccountTypes;
    this.setting = args.setting ? new Setting(args.setting) : null;
    this.workflowStyle = args.workflowStyle || this.user.organization.workflow_style;
    this.showProjectDetail = this.isForProjectDetail();
    this.showDepartmentDetail = this.isForDepartmentDetail();
    this.showExpensesAccountTypeDetail = false;
    this.projectShareAvailability = args.projectShareAvailability;
    this.expensesAccountTypeAvailable = args.expensesAccountTypeAvailable;
    this.expensesAccountTypeRequired = args.trip.user.organization.setting
      ? args.trip.user.organization.setting.is_expenses_account_type_required
      : false;
    this.approveItemValues = new Map<number, string>();
    this.approveItemValueCodes = new Map<number, string>();
    this.approveItemFiles = new Map<number, ImageFile>();
    this.approveItemFilesBase64 = new Map<number, string>();
    this.approveStages = [];
  }

  isApprovalRequired() {
    const applicant = this.applicant;
    if (applicant) {
      return applicant.approvalRequired(this.foreignExist);
    }
    return undefined;
  }

  isInternalNumberShown() {
    const setting = this.setting;
    if (!setting) return true;
    return setting.internalNumber !== 'hidden';
  }

  isProjectNameShown() {
    if (!(this.projects && this.projects.list.length > 0)) return false;

    const setting = this.setting;
    if (!setting) return true;

    return setting.projectName !== 'hidden';
  }

  isChargingDepartmentShown() {
    if (!(this.departments && this.departments.list.length > 0)) return false;
    const setting = this.setting;
    if (!setting) return true;
    return setting.chargingDepartment !== 'hidden';
  }

  isExpensesAccountTypeShown() {
    const setting = this.setting;
    if (!setting) return true;

    return setting.expensesAccountType !== 'hidden';
  }

  isPurposeBusinessTripRequired() {
    return !_.isNil(this.setting) && this.setting.purposeBusinessTrip === 'mandatory';
  }

  isDestinationBusinessTripRequired() {
    return !_.isNil(this.setting) && this.setting.destinationBusinessTrip === 'mandatory';
  }

  isInternalNumberRequired() {
    return !_.isNil(this.setting) && this.setting.internalNumber === 'mandatory';
  }

  isProjectNameRequired() {
    return (
      this.projects &&
      this.projects.list.length > 0 &&
      !_.isNil(this.setting) &&
      this.setting.projectName === 'mandatory'
    );
  }

  isChargingDepartmentRequired() {
    return (
      this.departments &&
      this.departments.list.length > 0 &&
      !_.isNil(this.setting) &&
      this.setting.chargingDepartment === 'mandatory'
    );
  }

  isExpensesAccountTypeRequired() {
    return !_.isNil(this.setting) && this.setting.expensesAccountType === 'mandatory';
  }

  isApproveItemRequiredWithWorkflow(requiredType: string) {
    if (requiredType !== 'required_with_workflow') {
      return false;
    }
    if (this.isApprovalRequired()) {
      return true;
    }
    if (this.user.departmentId() === null || this.user.departmentId() === '') {
      return true;
    }
    return false;
  }

  isForDepartmentDetail() {
    return this.orderItems
      .map(item => item.orderItemMappings)
      .some(mappingList => mappingList.isForDepartmentDetail());
  }

  isForProjectDetail() {
    return this.orderItems
      .map(item => item.orderItemMappings)
      .some(mappingList => mappingList.isForProjectDetail());
  }

  isForExpensesAccountTypeDetail() {
    return this.orderItems
      .map(item => item.orderItemMappings)
      .some(mappingList => mappingList.isForExpensesAccountTypeDetail());
  }

  @computed
  get chargingDepartmentIDs() {
    const ids: number[] = [];
    this.orderItems.forEach(item =>
      item.orderItemMappings.list.forEach(mapping => {
        if (mapping.chargingDepartmentId !== null) {
          ids.push(mapping.chargingDepartmentId);
        }
      })
    );
    return _.uniq(ids);
  }

  @computed
  get chargingDepartment() {
    return this.departments.find(this.chargingDepartmentId);
  }

  @computed
  get project() {
    return this.projects.find(this.projectId);
  }

  @computed
  get expensesAccountType() {
    const orderItemMapping = this.orderItems[0]?.orderItemMappings?.list?.[0];
    if (!orderItemMapping) return null;

    return this.expensesAccountTypes.find(
      accountType => accountType.id === orderItemMapping.expensesAccountTypeId
    );
  }

  @action
  setApproveItemValue(id: number, value: string) {
    this.approveItemValues.set(id, value);
  }

  getApproveItemValue(id: number) {
    return this.approveItemValues.get(id);
  }

  @action
  setApproveItemValueCode(id: number, value: string) {
    this.approveItemValueCodes.set(id, value);
  }

  getApproveItemValueCode(id: number) {
    return this.approveItemValueCodes.get(id);
  }

  @action
  setApproveStages(stages: Stage[]) {
    this.approveStages = stages;
  }

  @action
  async setApproveItemFile(id: number, file: ImageFile) {
    this.approveItemFiles.set(id, file);
    this.approveItemValues.set(id, file.name);
    this.approveItemFilesBase64.set(id, await this.convertFileToBase64(file));
    app.render();
  }

  getApproveItemFile(id: number) {
    return this.approveItemFiles.get(id);
  }

  async convertFileToBase64(file: ImageFile) {
    if (file) {
      const result = await new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result as string);
        };
        fileReader.onerror = error => {
          reject(error);
        };
      });
      return result as string;
    }
    return '';
  }

  @action
  removeApproveItemFile(id: number) {
    this.approveItemFiles.delete(id);
    this.approveItemValues.delete(id);
    this.approveItemFilesBase64.delete(id);
    app.render();
  }

  getApproveItemFileBase64(id: number) {
    return this.approveItemFilesBase64.get(id);
  }

  getApproveItemFileType(id: number) {
    const file = this.getApproveItemFile(id);
    if (file) {
      return file.type;
    }
    return '';
  }

  @computed
  private get applicant() {
    const applicants = this.travelers.list;
    let applicant = _.first(applicants);
    if (!applicant || applicant.id == null) {
      applicant = new Traveler(this.user);
    }
    return applicant;
  }

  private generateApproveItemParams() {
    const data: any[] = [];
    this.approveItems.list.forEach(r => {
      data.push({
        id: r.id,
        userDisplayName: r.userDisplayName,
        dataType: r.dataType,
        display: r.display,
        requiredType: r.requiredType,
        placeholder: r.placeholder,
        value: this.approveItemValues.get(r.id),
        valueCode: this.approveItemValueCodes.get(r.id),
        file: this.getApproveItemFileBase64(r.id),
        fileType: this.getApproveItemFileType(r.id)
      });
    });
    return JSON.stringify(data);
  }

  private static requiredError(name: string, value: string | undefined | null, message = 'を入力してください。') {
    if (!value || value.length === 0) {
      return name + message;
    }

    return undefined;
  }

  private requireProjectMappingError() {
    const mappingLists = this.orderItems.map(item => item.orderItemMappings).flat();
    const projectExist = mappingLists.every(mapping => mapping.isProjectExist());
    if (!projectExist) return 'プロジェクト名を選択して下さい';
    return undefined;
  }

  private rquireDepartmentMappingError() {
    const mappingLists = _.flatten(this.orderItems.map(item => item.orderItemMappings));
    const departmentExist = mappingLists.every(list => list.isChargingDepartmentExist());
    if (!departmentExist) return '費用負担部署を選択して下さい';
    return undefined;
  }

  private requireExpensesMappingError() {
    const mappingLists = this.orderItems.map(item => item.orderItemMappings).flat();
    const expensesExist = mappingLists.every(mapping => mapping.isExpensesAccountTypeExist());
    if (!expensesExist) return '勘定科目を選択して下さい';
    return undefined;
  }

  validationErrors() {
    const errors: { [key: string]: string | undefined } = {};

    if (this.isInternalNumberRequired())
      errors.internalNumber = ArrangementRequestWfInfo.requiredError('社内管理番号', this.internalNumber);
    if (this.isProjectNameRequired()) {
      if (this.showProjectDetail) {
        errors.projectId = this.requireProjectMappingError();
      } else {
        errors.projectId = ArrangementRequestWfInfo.requiredError('プロジェクト名', this.projectId);
      }
    }
    if (this.isChargingDepartmentRequired()) {
      if (this.showDepartmentDetail) {
        errors.chargingDepartment = this.rquireDepartmentMappingError();
      } else {
        errors.chargingDepartment = ArrangementRequestWfInfo.requiredError(
          '費用負担部署',
          this.chargingDepartmentId
        );
      }
    }
    if (this.expensesAccountTypeAvailable && this.isExpensesAccountTypeRequired()) {
      errors.expensesAccountType = this.requireExpensesMappingError();
    }

    if (this.isApprovalRequired() || this.isDestinationBusinessTripRequired()) {
      errors.area = ArrangementRequestWfInfo.requiredError('出張先', this.finalDestination);
    }
    if (this.isApprovalRequired() || this.isPurposeBusinessTripRequired()) {
      errors.purpose = ArrangementRequestWfInfo.requiredError('出張の目的', this.purpose);
    }

    if (this.isApprovalRequired() && this.workflowStyle === 'trip') {
      if (this.approveStages.length === 0) {
        errors.approveStages = 'ワークフロールートに空欄があります。承認者を登録してください。';
      }
      _.each(this.approveStages, stage => {
        _.each(stage.approvers, approver => {
          if (_.isNil(approver)) {
            errors.approveStages = 'ワークフロールートに空欄があります。承認者を登録してください。';
          }
        });
      });
    }

    this.approveItems.list.forEach(r => {
      if (
        (r.requiredType !== 'optional' && r.requiredType !== 'required_with_workflow' && r.requiredType !== '') ||
        this.isApproveItemRequiredWithWorkflow(r.requiredType) === true
      ) {
        errors[`appleveItem_${r.id}`] = ArrangementRequestWfInfo.requiredError(
          r.userDisplayName,
          this.approveItemValues.get(r.id)
        );
      }
    });

    errors.organization = this.organizationErrors();

    return utils.compactObject(errors);
  }

  private organizationErrors() {
    if (this.isApprovalRequired() && this.organizationError() && this.workflowStyle !== 'trip') {
      const errors: string[] = [];
      errors.push('出張者の所属部署またはその承認者が設定されていません。管理者に連絡してください。');
      return errors.join('\n');
    }
    return undefined;
  }

  private organizationError() {
    const applicant = this.applicant;
    if (applicant) {
      return this.approvers === null;
    }
    return undefined;
  }

  submitParams() {
    const params: { [key: string]: any } = {
      internal_number: this.internalNumber,
      final_destination: this.finalDestination,
      purpose: this.purpose,

      applicant_id: this.applicant.id,
      charging_department_id: this.chargingDepartmentId,
      project_id: this.projectId,
      order_item_mappings: this.orderItems.map(item => item.orderItemMappings.submitParams()).flat(),
      is_approval_required: this.isApprovalRequired(),
      approve_stages: JSON.stringify(this.approveStages)
    };
    params.approve_items = this.generateApproveItemParams();
    return params;
  }

  @action
  resetMappingsDepartmentId() {
    this.orderItems.forEach(item => {
      item.orderItemMappings.list.forEach(mapping => {
        mapping.setChargingDepartmentId(parseInt(this.chargingDepartmentId, 10));
      });
    });
  }

  @action
  resetMappingsProjectId() {
    this.orderItems.forEach(item => {
      item.orderItemMappings.list.forEach(mapping => {
        mapping.setProjectId(parseInt(this.projectId, 10));
      });
    });
  }

  @action
  resetMappingsExpensesAccountTypeId() {
    const expensesAccountTypeId = this.expensesAccountType?.id || null;
    this.orderItems.forEach(item => {
      item.orderItemMappings.list.forEach(mapping => {
        mapping.setExpensesAccountTypeId(expensesAccountTypeId);
      });
    });
  }

  @action
  async fetchApproveItems() {
    await Fetcher.get<ApproveItemsResponse>(`/organization/approve_items/display.json`, {
      foreign_exist: this.foreignExist,
      target: 'before_business_trip'
    }).then(
      result => {
        this.approveItems = new ApproveItemList(result.approve_items);
      },
      _error => {
        throw _error;
      }
    );
  }

  @action
  async resetExpensesAccountTypes() {
    const chargingDepartmentIds = this.chargingDepartmentIDs;
    await Fetcher.get<ExpensesAccountTypesResponse>(`/reserve_confirm/expenses_account_types.json`, {
      department_ids: chargingDepartmentIds,
      request_date: Date.now()
    }).then(
      result => {
        this.expensesAccountTypes = result.expenses_account_types.map(e => new ExpensesAccountType(e));
      },
      _error => {
        throw _error;
      }
    );
  }
}

export default ArrangementRequestWfInfo;

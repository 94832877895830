import React from 'react';
import { observer } from 'mobx-react';

import { Datepicker } from '@this/shared/ui/inputs/datepicker';
import type ShareholderTicketList from '../../../../domain/shareholder_ticket_list/index';
import type ShareholderTicket from '../../../../domain/shareholder_ticket';

interface Props {
  key: number;
  tickets: ShareholderTicketList;
  ticket: ShareholderTicket;
  editingTicket: ShareholderTicket | null;
  priceError: string | null;
  handleCancelClick: (e: React.MouseEvent<HTMLElement>) => void;
  handleEditClick: (ticket: ShareholderTicket, e: React.MouseEvent<HTMLElement>) => void;
  handleDeleteClick: (ticket: ShareholderTicket, e: React.MouseEvent<HTMLElement>) => void;
  handleFieldChange: (
    name: keyof ShareholderTicketObj,
    e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>
  ) => void;
}

interface ShareholderTicketObj {
  setCompanyType: string;
  setPrice: number;
  setNumber: number;
  setPassword: string;
  setRemarks: string;
}

const ShareholderBlock = observer(
  ({
    ticket,
    editingTicket,
    handleFieldChange,
    handleCancelClick,
    handleEditClick,
    handleDeleteClick,
    priceError
  }: Props) => {
    return (
      <tbody>
        {editingTicket && ticket.id === editingTicket.id ? (
          <tr>
            <td />
            <td />
            <td />
            <td>
              <select value={editingTicket.companyType} onChange={e => handleFieldChange('setCompanyType', e)}>
                <option value={0}>ANA</option>
                <option value={1}>JAL</option>
                <option value={2}>SFJ</option>
              </select>
            </td>
            <td>
              <input
                type="text"
                value={editingTicket.price}
                onChange={e => handleFieldChange('setPrice', e)}
                onBlur={e => editingTicket.sanitizePrice()}
              />
              {priceError && <div style={{ color: 'red' }}>{priceError}</div>}
            </td>
            <td>
              <Datepicker
                value={editingTicket.purchasedAt.toDate()}
                onChange={value => editingTicket.setPurchasedAt(value ? value.toISOString() : '')}
                disabledClear
              />
            </td>
            <td>
              <Datepicker
                value={editingTicket.expireAt.toDate()}
                onChange={value => editingTicket.setExpireAt(value ? value.toISOString() : '')}
                disabledClear
              />
            </td>
            <td>
              <input type="text" value={editingTicket.number} onChange={e => handleFieldChange('setNumber', e)} />
            </td>
            <td>
              <input
                type="text"
                value={editingTicket.password}
                onChange={e => handleFieldChange('setPassword', e)}
              />
            </td>
            <td>
              <input
                type="text"
                value={editingTicket.remarks}
                onChange={e => handleFieldChange('setRemarks', e)}
              />
            </td>
            <td>{editingTicket.inactivatedAt?.format('YYYY-MM-DD')}</td>
            <td className="organization__td">
              <input type="submit" value="保存" />
            </td>
            <td className="organization__td">
              <input type="submit" value="キャンセル" onClick={e => handleCancelClick(e)} />
            </td>
          </tr>
        ) : ticket.isDeleted || editingTicket ? (
          <tr>
            <td>{ticket.id}</td>
            <td>{ticket.tripId}</td>
            <td>{ticket.status}</td>
            <td>{ticket.companyStr()}</td>
            <td>{ticket.price}</td>
            <td>{ticket.purchasedAt.format('YYYY-MM-DD')}</td>
            <td>{ticket.expireAt.format('YYYY-MM-DD')}</td>
            <td>{ticket.number}</td>
            <td>{ticket.password}</td>
            <td>{ticket.remarks}</td>
            <td>{ticket.inactivatedAt?.format('YYYY-MM-DD')}</td>
            <td className="organization__td" />
            <td className="organization__td" />
          </tr>
        ) : (
          <tr>
            <td>{ticket.id}</td>
            <td>{ticket.tripId}</td>
            <td>{ticket.status}</td>
            <td>{ticket.companyStr()}</td>
            <td>{ticket.price}</td>
            <td>{ticket.purchasedAt.format('YYYY-MM-DD')}</td>
            <td>{ticket.expireAt.format('YYYY-MM-DD')}</td>
            <td>{ticket.number}</td>
            <td>{ticket.password}</td>
            <td>{ticket.remarks}</td>
            <td>{ticket.inactivatedAt?.format('YYYY-MM-DD')}</td>
            <td className="organization__td">
              <input type="submit" value="編集" onClick={e => handleEditClick(ticket, e)} />
            </td>
            <td className="organization__td">
              <input type="submit" value="削除" onClick={e => handleDeleteClick(ticket, e)} />
            </td>
          </tr>
        )}
      </tbody>
    );
  }
);

export default ShareholderBlock;

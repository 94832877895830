import React from 'react';
import { styled } from '@this/constants/themes';
import Header from '@this/shared/header/header';
import Footer from '@this/shared/footer/footer';
import A from '@this/shared/atoms/a';
import SubInfo from '@this/shared/sub_info/sub_info';
import type UsersJson from '../../../../domain/user/user_json';
import User from '../../../../domain/user/user';

interface Props {}

interface State {
  user: User | null;
  cdnDomain?: string;
}

interface Response {
  cdn_domain: string;
}

class TravelTerms extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: null
    };
  }

  componentDidMount() {
    utils
      .jsonPromise<UsersJson>('/users.json')
      .then(
        response => {
          const user = new User(response);
          this.setState({ user });
        },
        () => {
          this.setState({
            user: null
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
    this.fetchCdnDomain();
  }

  async fetchCdnDomain() {
    const res = await utils.jsonPromise<Response>('/travel_terms.json');
    this.setState({ cdnDomain: res.cdn_domain });
  }

  render() {
    try {
      const { user, cdnDomain } = this.state;
      return (
        <Wrap>
          <div className="travel_terms__pc">
            <div>
              <Header user={user} />
              <div className="content-body">
                <div className="info-content">
                  <h1 className="info-content__title">標識、各種約款、その他</h1>
                  <h2 className="info-content__subtitle">標識</h2>
                  <SubInfo
                    data={{
                      業務範囲: '国内旅行・海外旅行',
                      登録番号: '東京都知事登録旅行業第2-7247号',
                      登録年月日: '平成28年10月13日から平成33年10月12日まで',
                      名称: '株式会社トランスファーデータ',
                      営業所の名称: 'AI Travel カスタマーサクセス',
                      所在地: '〒105-0014\n 東京都港区芝3丁目5-7',
                      '総合旅行業/取扱管理者':
                        '澁谷 誠（総合旅行業務取扱管理者とは、お客様の旅行を取り扱う営業所での取引の責任者です。この旅行の契約に関し担当者からの説明にご不明な点がありましたら、ご遠慮なく表記の旅行業取扱管理者にご質問ください）',
                      営業時間: '平日9時-18時'
                    }}
                    customWidth="150px"
                    customFontSize="15px"
                  />
                  <table className="key-value-table">
                    <tbody>
                      <tr>
                        <td className="key-value-table__label">受託取扱企画旅行</td>
                        <td className="key-value-table__separator">：</td>
                        <td className="key-value-table__value">
                          <A href="https://travel.rakuten.co.jp/corporate/rato/" target="_blank">
                            楽天ANAトラベルオンライン株式会社
                          </A>
                        </td>
                      </tr>
                      <tr>
                        <td className="key-value-table__label" />
                        <td className="key-value-table__separator">　</td>
                        <td className="key-value-table__value">
                          <A href="https://www.jal.co.jp/jp/ja/tours/info/operate/" target="_blank">
                            株式会社ジャルパック
                          </A>
                        </td>
                      </tr>
                      <tr>
                        <td className="key-value-table__label" />
                        <td className="key-value-table__separator">　</td>
                        <td className="key-value-table__value">
                          <A href="https://www.jre-travel.com/guide/operate/" target="_blank">
                            株式会社びゅうトラベルサービス
                          </A>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="travel_terms__small">
                    ※営業時間外のご依頼は、翌営業日に対応いたします。 ご了承ください。
                  </div>

                  <h2 className="info-content__subtitle">各種約款等</h2>
                  <table className="key-value-table">
                    <tbody>
                      <tr>
                        <td className="key-value-table__label"> 旅行業約款</td>
                        <td className="key-value-table__separator">：</td>
                        <td className="key-value-table__value">
                          <A href={`//${cdnDomain}/images/travel_terms.pdf`} download="travel_terms.pdf">
                            手配契約の部
                          </A>
                        </td>
                      </tr>
                      <tr>
                        <td className="key-value-table__label"> 旅行条件書</td>
                        <td className="key-value-table__separator">：</td>
                        <td className="key-value-table__value">
                          <A href={`//${cdnDomain}/images/requirements.pdf`} download="requirements.pdf">
                            手配旅行条件書
                          </A>
                        </td>
                      </tr>
                      <tr>
                        <td className="key-value-table__label">旅行業務取扱料金表</td>
                        <td className="key-value-table__separator">：</td>
                        <td className="key-value-table__value">
                          <A href="/charges">取扱い料金表</A>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <h2 className="info-content__subtitle">その他</h2>
                  <table className="key-value-table">
                    <tbody>
                      <tr>
                        <td className="key-value-table__label">個人情報保護について</td>
                        <td className="key-value-table__separator">：</td>
                        <td className="key-value-table__value">
                          <A href="https://transferdata.co.jp/privacy-policy#a">個人情報保護方針</A>
                        </td>
                      </tr>
                      <tr>
                        <td className="key-value-table__label">所属旅行業協会名</td>
                        <td className="key-value-table__separator">：</td>
                        <td className="key-value-table__value">一般社団法人日本旅行業協会正会員</td>
                      </tr>
                      <tr>
                        <td className="key-value-table__label">問い合わせ先</td>
                        <td className="key-value-table__separator">：</td>
                        <td className="key-value-table__value">contact@aitravel.jp</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <Footer signedIn={!!user} />
            </div>
          </div>
        </Wrap>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Wrap = styled.div`
  &__small {
    font-size: 10px;
    margin-bottom: 5px;
    margin-left: calc(150px + 10px);
  }

  .key-value-table__label {
    width: 150px;
  }
`;

export default TravelTerms;

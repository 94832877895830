import { Fetcher } from '@this/src/util';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';

import { styled } from '@this/constants/themes';
import {
  Link as MuiLink,
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';

import { CheckCircleOutline, HighlightOff } from '@material-ui/icons';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';

const StyledLink = styled(MuiLink)`
  text-decoration: none;
  color: #3f51b5;
  &:hover {
    text-decoration: underline;
  }
`;

interface Reason {
  rakuten_url: string;
  reason: string;
  plan_exists: boolean;
  rakuten_heya_narrowing_exist: boolean;
  rakuten_plan_prices_exist: boolean;
  rakuten_plans_exist: boolean;
  plans: any[];
  hotel_filter_list: string[];
  request_params: {
    rakuten_id: number;
    smoke?: string;
    breakfast?: 'no';
    roomnum: number;
    peoplenum: number;
    checkin: string;
    checkout: string;
  };
  rakuten_yado: {
    hotel_name: string;
  };
}

interface ReasonResponse {
  data: Reason;
}

const highlightText = (text: string, keywords?: string[]): JSX.Element => {
  if (!keywords) return <>{text}</>;

  const parts = text.split(new RegExp(`(${keywords.join('|')})`, 'gi'));
  return (
    <>
      {parts.map((part, index) =>
        keywords.includes(part) ? (
          <span key={index} style={{ color: 'red' }}>
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </>
  );
};

const RakutenStaticInvestigate: React.FC = observer(() => {
  const [reasons, setReasons] = useState<Reason>();
  const [loading, setLoading] = useState(true);
  const searchParams = new URLSearchParams(window.location.search);
  const queryString = searchParams.toString();
  const roomNameFilter = ['おまかせ'];

  const getDisplayValue = (value: any) => (value == null ? '未指定' : value.toString());

  const handleFetchReason = useCallback(() => {
    setLoading(true);
    Fetcher.get<ReasonResponse>(`/arrangement/rakuten_static_investigate/reason_by_rakuten_id?${queryString}`)
      .then(response => {
        setReasons(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  useEffect(() => {
    handleFetchReason();
  }, []);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        楽天StaticFileホテル調査ページ
      </Typography>
      {loading && !reasons ? (
        <SimpleLoading />
      ) : (
        <Box>
          <Card style={{ marginTop: '16px' }}>
            <CardHeader title="指定条件" />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2">楽天ID:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{getDisplayValue(reasons?.request_params.rakuten_id)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">ホテル名:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{reasons?.rakuten_yado.hotel_name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">喫煙:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{getDisplayValue(reasons?.request_params.smoke)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">朝食:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{getDisplayValue(reasons?.request_params.breakfast)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">部屋数:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{getDisplayValue(reasons?.request_params.roomnum)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">人数:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{getDisplayValue(reasons?.request_params.peoplenum)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">チェックイン:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{getDisplayValue(reasons?.request_params.checkin)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">チェックアウト:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{getDisplayValue(reasons?.request_params.checkout)}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card style={{ marginTop: '16px', marginBottom: '16px' }}>
            <CardHeader title="Status" />
            <CardContent>
              <List>
                <ListItem>
                  <ListItemText primary="楽天トラベルURL" />
                  <ListItemIcon>
                    <StyledLink href={reasons?.rakuten_url} target="_blank">
                      リンク
                    </StyledLink>
                  </ListItemIcon>
                </ListItem>
                <ListItem>
                  <ListItemText primary="楽天トラベル公式サイトに有効なプランが存在する" />
                  <ListItemIcon>
                    {reasons?.plan_exists ? (
                      <CheckCircleOutline color="primary" />
                    ) : (
                      <HighlightOff color="error" />
                    )}
                  </ListItemIcon>
                </ListItem>

                <ListItem>
                  <ListItemText primary="rakuten_heya_narrowingテーブルの有無" />
                  <ListItemIcon>
                    {reasons?.rakuten_heya_narrowing_exist ? (
                      <CheckCircleOutline color="primary" />
                    ) : (
                      <HighlightOff color="error" />
                    )}
                  </ListItemIcon>
                </ListItem>

                <ListItem>
                  <ListItemText primary="rakuten_plan_pricesの有無" />
                  <ListItemIcon>
                    {reasons?.rakuten_plan_prices_exist ? (
                      <CheckCircleOutline color="primary" />
                    ) : (
                      <HighlightOff color="error" />
                    )}
                  </ListItemIcon>
                </ListItem>

                <ListItem>
                  <ListItemText primary="rakuten_plansの有無" />
                  <ListItemIcon>
                    {reasons?.rakuten_plans_exist ? (
                      <CheckCircleOutline color="primary" />
                    ) : (
                      <HighlightOff color="error" />
                    )}
                  </ListItemIcon>
                </ListItem>
              </List>
            </CardContent>
          </Card>

          <Card style={{ marginTop: '16px' }}>
            <CardHeader title="Plan List" />
            <CardContent>
              {reasons?.plans &&
                reasons?.plans.map(plan => {
                  const isPlanTitleMatch = reasons?.hotel_filter_list
                    ? reasons?.hotel_filter_list.some((keyword: string) => plan.title.includes(keyword))
                    : false;
                  const isRoomNameMatch = roomNameFilter.some((keyword: string) => plan.na.includes(keyword));
                  const enableKessasi = plan.f_kessai !== 0;
                  const isGrayedOut = isRoomNameMatch || isPlanTitleMatch || !enableKessasi;

                  return (
                    <Box key={plan.id} mb={2}>
                      <Paper
                        key={plan.id}
                        style={{
                          margin: '16px 0',
                          padding: '16px',
                          backgroundColor: isGrayedOut ? 'lightgray' : 'white'
                        }}
                      >
                        <Typography variant="h6">部屋名: {highlightText(plan.na, ['おまかせ'])}</Typography>
                        <Typography variant="body1">
                          プラン名: {highlightText(plan.title, reasons.hotel_filter_list)}
                        </Typography>
                        <Typography variant="body2">
                          決済方法:{' '}
                          {enableKessasi ? '現地決済なし' : <span style={{ color: 'red' }}>現地決済あり</span>}
                        </Typography>
                      </Paper>
                    </Box>
                  );
                })}
            </CardContent>
          </Card>
        </Box>
      )}
    </Container>
  );
});

export default RakutenStaticInvestigate;

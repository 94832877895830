import _ from 'lodash';
import type OrganizationMemberJson from '@this/domain/user/organization_member_json';
import type { BudgetParams } from '@this/domain/organization/budget';
import User from '../user/user';

export type ProjectArgs = {
  id: number;
  code: string;
  name: string;
  disabled: boolean;
  approval_authorities?: ProjectApprovalAuthorityArgs[];
  approvers?: OrganizationMemberJson[];
  approveStages?: ApproveStages; //  ProjectFormModalで使用
  budgets?: BudgetParams[]; //  ProjectFormModalで使用
};

interface ProjectApprovalAuthorityArgs {
  approver_id: number | '';
  approve_stage: number;
  workflow_type: WorkflowType;
}

export type WorkflowType = 'default';

export type ApproveStages = Record<WorkflowType, Record<number, (number | '')[]>>;

export default class Project {
  id: number;

  code: string;

  name: string;

  disabled: boolean;

  approvers: User[] = [];

  public readonly members: User[] | null;

  public readonly approveStages: ApproveStages;

  budgets: BudgetParams[] = [];

  constructor(args: ProjectArgs, members?: User[]) {
    this.id = args.id;
    this.code = args.code;
    this.name = args.name;
    this.disabled = args.disabled;
    this.approvers = args.approvers?.map(a => new User(a)) || [];
    this.members = members || null;
    this.approveStages =
      args.approveStages ||
      (args.approval_authorities || []).reduce(
        (approveStages: ApproveStages, authority: ProjectApprovalAuthorityArgs) => {
          const workflowType = authority.workflow_type;
          const stage = authority.approve_stage;
          const id = authority.approver_id;

          if (approveStages[workflowType][stage] != null && approveStages[workflowType][stage][0] !== '') {
            approveStages[workflowType][stage].push(id);
          } else {
            approveStages[workflowType][stage] = [id];
          }
          return approveStages;
        },
        { default: { 1: [''] } }
      );
    this.budgets = args.budgets ? args.budgets : [];
  }

  setCode(value: string) {
    this.code = value;
    return app.render();
  }

  setName(value: string) {
    this.name = value;
    return app.render();
  }

  setDisabled(value: boolean) {
    this.disabled = value;
    return app.render();
  }

  setApproveStageParam(multipleApproveStages: ApproveStages) {
    return _.mapValues(multipleApproveStages, approveStages =>
      _.mapValues(approveStages, as => _.filter(as, a => a !== ''))
    );
  }

  updateParams(): {
    code: string;
    name: string;
    approve_stages: ApproveStages;
    budgets_attributes: BudgetParams[];
    disabled: boolean;
  } {
    return {
      code: this.code,
      name: this.name,
      approve_stages: this.setApproveStageParam(this.approveStages),
      budgets_attributes: this.budgets,
      disabled: this.disabled
    };
  }

  codeAndName(): string {
    return `${this.code}:${this.name}`;
  }

  membersNotApprover(workflowType: WorkflowType, stage: number): User[] {
    return _.filter(this.members, m => {
      return !_.includes(this.approveStages[workflowType][stage], m.id);
    });
  }

  setApproverIds(ids: number[], s: number, workflowType: WorkflowType): void {
    this.approveStages[workflowType][s] = ids || [];
    return app.render();
  }
}

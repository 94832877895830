import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import type Project from '@this/domain/project/project';
import type TransportElementTerminal from '@this/domain/transport_element_terminal';
import type OrderItem from '@this/domain/order_item';
import SCButton from '@this/components/organization/button';
import Detail from '@this/components/organization/trips/detail/detail';
import type { TripSummary } from '@this/components/organization/trips/trips';
import type Trip from '@this/domain/trip/trip';
import type Department from '@this/domain/department/department';
import type { ExpensesAccountType } from '@this/domain/expenses/expenses_account_type';
import type { UpdationTripParams, UpdationTripApproveItemParams } from '@this/components/organization/trips/types';
import type ProjectShareList from '@this/domain/project/project_share_list';
import type ChargingDepartmentShareList from '@this/domain/department/charging_department_share_list';
import Tooltip from '@this/shared/tooltip/tooltip';
import moment from 'moment-timezone';
import { reportError } from '@this/lib/bugsnag';
import ItemArrangeIcons from './item_arrange_icons';
import SCText from '../../../text';
import SimpleLoading from '../../../../shared/simple_loading/simple_loading';

interface Props {
  serviceId: number;
  trip: TripSummary;
  projects: Project[];
  departments: Department[];
  expensesAccountTypes: ExpensesAccountType[];
  expensesAccountTypeAvailable: boolean;
  fetchTrip: (id: Trip['id']) => Promise<boolean>;
  onUpdateTrip: (params: UpdationTripParams) => Promise<void>;
  onUpdateTripApproveItems: (params: UpdationTripApproveItemParams) => Promise<void>;
  projectShares: ProjectShareList;
  projectShareAvailability: boolean;
  tripReportAvailable: boolean;
  chargingDepartmentShares: ChargingDepartmentShareList;
}

interface State {
  showDetail: boolean;
  loading: boolean;
}

interface TripRoute {
  place: string;
  isPath: boolean;
}

class Item extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { showDetail: false, loading: true };
    this.toggleShowDetail = this.toggleShowDetail.bind(this);
  }

  toggleShowDetail(e: React.MouseEvent) {
    e.preventDefault();
    if (!this.state.showDetail) {
      this.fetchTrip();
    }

    this.setState({
      showDetail: !this.state.showDetail
    });
  }

  fetchTrip = async () => {
    this.setState({ loading: true });
    if (await this.props.fetchTrip(this.props.trip.id)) {
      this.setState({ loading: false });
    }
  };

  getTravelersRemainCountLabel() {
    const travelers = this.getTravelers();
    if (travelers.length > 1) {
      return `+${travelers.length - 1}`;
    }
    return '';
  }

  getTravelersTooltipText() {
    const travelers = this.getTravelers();
    return travelers
      .slice(1, travelers.length)
      .map((t: any) => t.name)
      .join(',');
  }

  getTravelers() {
    const trip = this.props.trip;
    return trip.travelers.list;
  }

  getTripRoutes() {
    const trip = this.props.trip;
    const currentOrder = trip.order;
    if (!currentOrder) {
      return [];
    }

    return currentOrder.orderItems
      .reduce((array: TripRoute[], order: OrderItem) => {
        order.transports.forEach(({ from, to }) => {
          const lastRoute = _.last(array);
          if (!(lastRoute && lastRoute.place === from.name)) {
            array.push(Item.createRoute(from));
          }
          array.push(Item.createRoute(to));
        });
        return array;
      }, [])
      .filter((route: any) => route.place);
  }

  getTripCategory() {
    const currentOrder = this.props.trip.order;

    const foreignItems = currentOrder.orderItems.filter((item: OrderItem) => item.foreign);
    const nonOrderforeignAirs = this.props.trip.nonOrderItems.filter((item: any) =>
      item.transports.some((element: any) => element.transportType === 'foreign_air')
    );

    if (foreignItems.length > 0 || nonOrderforeignAirs.length > 0) {
      return '海外';
    }
    return '国内';
  }

  static createRoute(routeDetail: TransportElementTerminal): TripRoute {
    return {
      place: routeDetail.name,
      isPath: moment() > moment(routeDetail.time)
    };
  }

  render() {
    try {
      const {
        serviceId,
        trip,
        projects,
        departments,
        onUpdateTrip,
        onUpdateTripApproveItems,
        expensesAccountTypes,
        expensesAccountTypeAvailable,
        tripReportAvailable
      } = this.props;
      const { showDetail, loading } = this.state;
      const travelers = this.getTravelers();
      return (
        <Tr>
          <TrContent>
            <Td tripReport={tripReportAvailable}>
              <div className="text--primary">
                <SCText
                  serviceId={serviceId}
                  type="link"
                  value={String(trip.id)}
                  to={`/trips/${trip.id}/messages`}
                />
              </div>
            </Td>
            <Td>{utils.isTabikobo(serviceId) && <span>{this.getTripCategory()}</span>}</Td>
            <Td className="travelers">
              <span>{utils.dig(travelers[0], 'displayName')}</span>
              {!trip.privateUse && this.getTravelersRemainCountLabel() && (
                <span data-tooltip={this.getTravelersTooltipText()}>{this.getTravelersRemainCountLabel()}</span>
              )}
            </Td>
            <Td>
              {trip.cacheStartTime && trip.cacheEndTime && (
                <div>{`${trip.cacheStartTime.format('YYYY/MM/DD')} ~ ${trip.cacheEndTime.format(
                  'YYYY/MM/DD'
                )}`}</div>
              )}
              <small>
                <TdPath>
                  {this.getTripRoutes().map((route: any, i: any) => (
                    <li key={i}>
                      {route.isPath ? <TdPathText>{route.place}</TdPathText> : <span>{route.place}</span>}
                    </li>
                  ))}
                </TdPath>
              </small>
            </Td>
            <Td tripReport={tripReportAvailable}>
              <TdArranges>
                <ItemArrangeIcons trip={trip} isOrderItem />
              </TdArranges>
              <TdArranges>
                <ItemArrangeIcons trip={trip} isOrderItem={false} />
              </TdArranges>
            </Td>
            <Td tripReport={tripReportAvailable}>
              {trip.approvalChannelId() && (
                <SCText
                  serviceId={serviceId}
                  type="link"
                  value={String(trip.approvalChannelId())}
                  to={`/application/approved/${trip.approvalChannelId()}`}
                />
              )}
            </Td>
            <Td tripReport={tripReportAvailable}>
              {tripReportAvailable && trip.tripReportId() && (
                <SCText
                  serviceId={serviceId}
                  type="link"
                  value={String(trip.tripReportId())}
                  to={`/trip_report/${trip.tripReportId()}`}
                />
              )}
            </Td>
            <Td>
              <TdCost>{trip.totalPrice().toLocaleString()}</TdCost>
              {trip.isFixed() || trip.isComplete() ? (
                <small className="text--success">手配済み</small>
              ) : (
                <small className="text--muted">{trip.statusSt()}</small>
              )}
            </Td>
            <Td>
              {trip.ruleErrors.length === 0 && (
                <>
                  <TdCostCheckMarkSuccess>
                    <img src="/images/organization/trips/check.png" alt="ok" />
                  </TdCostCheckMarkSuccess>
                  <TdCostCheckTextSuccess>問題なし</TdCostCheckTextSuccess>
                </>
              )}
              {trip.ruleErrors.length > 0 && (
                <RuleErrorTdContent>
                  <TdCostCheckMarkDanger>
                    <img src="/images/organization/trips/question.png" alt="question" />
                  </TdCostCheckMarkDanger>
                  <TdCostCheckTextDanger>
                    <span>{trip.ruleErrors.length}件の規程違反</span>
                    <Tooltip type="info" place="left">
                      <ul>
                        {trip.ruleErrors.map((e, i: number) => (
                          <li key={i.toString()}>{e.ruleErrorText()}</li>
                        ))}
                      </ul>
                    </Tooltip>
                  </TdCostCheckTextDanger>
                </RuleErrorTdContent>
              )}
            </Td>
            <Td className="center">
              <SCButton
                serviceId={serviceId}
                tag="a__link_border_color"
                value={showDetail ? '閉じる' : '開く'}
                width="60px"
                padding="6px"
                onClick={this.toggleShowDetail}
              />
            </Td>
          </TrContent>
          {showDetail &&
            (loading ? (
              <Loading className="loading">
                <SimpleLoading />
              </Loading>
            ) : (
              <Detail
                trip={trip}
                projects={projects}
                departments={departments}
                expensesAccountTypes={expensesAccountTypes}
                expensesAccountTypeAvailable={expensesAccountTypeAvailable}
                onUpdateTrip={onUpdateTrip}
                onUpdateTripApproveItems={onUpdateTripApproveItems}
                projectShares={this.props.projectShares}
                projectShareAvailability={this.props.projectShareAvailability}
                chargingDepartmentShares={this.props.chargingDepartmentShares}
                serviceId={serviceId}
                tripReportAvailable={tripReportAvailable}
              />
            ))}
        </Tr>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const Loading = styled.div`
  &.loading .vertical-centered-box {
    height: 120px;
  }
`;

const Tr = styled.div`
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;

const TrContent = styled.div`
  display: flex;
  align-items: center;
`;

const Td = styled.div<{ tripReport?: boolean }>`
  padding: 14px 0;
  font-weight: bold;

  small {
    display: block;
    font-weight: normal;
    font-size: 10px;
    margin-top: 4px;
  }

  &.travelers span + span {
    margin-left: 4px;
  }

  &:nth-child(1) {
    width: ${props => (props.tripReport ? '7.5%' : '8%')};
  }
  &:nth-child(2) {
    width: ${props => (props.theme.themeClass === 'tabikobo' ? '7%' : '0%')};
  }
  &:nth-child(3) {
    width: ${props => (props.theme.themeClass === 'tabikobo' ? '13%' : '15%')};
  }
  &:nth-child(4) {
    width: ${props => (props.theme.themeClass === 'tabikobo' ? '18%' : '23%')};
  }
  &:nth-child(5) {
    width: ${props => (props.tripReport ? '9%' : '12%')};
  }
  &:nth-child(6) {
    width: ${props => (props.tripReport ? '7.5%' : '12%')};
  }
  &:nth-child(7) {
    width: ${props => (props.tripReport ? '7.5%' : '0%')};
  }
  &:nth-child(8) {
    width: 8%;
  }
  &:nth-child(9) {
    width: 16%;
  }
  &:nth-child(10) {
    width: 6%;
  }
`;

const TdPath = styled.ul`
  list-style-type: none;

  li {
    display: inline-block;

    &:not(:last-child) {
      &::after {
        display: inline-block;
        content: '→';
        margin: 0 2px;
      }
    }
  }
`;

const TdPathText = styled.span`
  color: ${props => props.theme.linkColor};
`;

const TdArranges = styled.ul`
  list-style-type: none;
  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 6px;
    }
  }
`;

const TdCost = styled.div`
  &::after {
    display: inline-block;
    content: '円';
  }
`;

const TdCostCheckMark = styled.div`
  display: inline-block;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 6px;
  margin-right: 10px;
  vertical-align: middle;

  img {
    width: 15px;
    height: 15px;
  }
`;

const TdCostCheckMarkSuccess = styled(TdCostCheckMark)`
  background-color: #28a745;
`;

const TdCostCheckMarkDanger = styled(TdCostCheckMark)`
  background-color: ${props => props.theme.redColor};
`;

const TdCostCheckTextSuccess = styled.span`
  color: #28a745;
`;

const RuleErrorTdContent = styled.div`
  display: flex;
  align-items: center;
`;

const TdCostCheckTextDanger = styled.span`
  display: inline-flex;
  align-items: center;
  color: ${props => props.theme.redColor};

  .tooltip__content {
    width: 250px;
    top: auto;
    bottom: 110%;
  }
`;

export default Item;

import React, { useCallback, useState } from 'react';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@this/shared/ui/feedbacks/modal';
import { Text } from '@this/shared/ui/data_displays/typography';
import { Radio, RadioGroup } from '@this/shared/ui/inputs/radio';
import { Textarea } from '@this/shared/ui/inputs/textarea';
import { styled } from '@this/constants/themes';

export const FEEDBACK_OPTIONS = [
  '価格が期待と異なる',
  '立地条件が合わない',
  '施設・設備が不十分',
  'レビュー・評価への不安',
  'おすすめ理由が不適切',
  'その他'
] as const;

type FeedbackOption = typeof FEEDBACK_OPTIONS[number];

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (feedback: string) => void;
}

export const FeedbackModal: React.FC<Props> = ({ open, onClose, onSubmit }) => {
  const [feedbackOption, setFeedbackOption] = useState<FeedbackOption | null>(null);
  const [feedbackMessage, setFeedbackMessage] = useState<string>('');

  const handleSubmit = useCallback(() => {
    if (!feedbackOption) {
      return;
    }

    onSubmit(feedbackOption === 'その他' ? feedbackMessage : feedbackOption);
  }, [feedbackOption, feedbackMessage, onSubmit]);

  return (
    <Modal size="small" open={open} onClose={onClose}>
      <ModalHeader>フィードバック</ModalHeader>
      <ModalBody>
        <Text>検索結果に対するフィードバックに関するご要望などがあればお知らせください。</Text>
        <StyledRadioGroup onChange={(_, value) => setFeedbackOption(value as FeedbackOption)}>
          {FEEDBACK_OPTIONS.map(fb => (
            <Radio key={fb} value={fb}>
              {fb}
            </Radio>
          ))}
        </StyledRadioGroup>
        {feedbackOption === 'その他' && (
          <StyledTextarea rowsMin="3" rowsMax="5" fullWidth onChange={e => setFeedbackMessage(e.target.value)} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} color="sub">
          キャンセル
        </Button>
        <Button onClick={handleSubmit} color="primary">
          送信
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const StyledRadioGroup = styled(RadioGroup)`
  margin-top: 16px;
`;

const StyledTextarea = styled(Textarea)`
  margin-top: 8px;
  width: calc(100% - 16px);
`;

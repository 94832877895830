import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import { Button } from '@this/shared/ui/inputs/button';
import { Fetcher } from '@this/src/util';

interface AirRouteTableProps {}

interface DomesticAirRouteResponse {
  data: DomesticAirRoute[];
}

interface DomesticAirRoute {
  id: number;
  from_airport: string;
  to_airport: string;
  class_name: string;
}

const AirRouteTable: React.FC<AirRouteTableProps> = () => {
  const [priority, setAirRoute] = useState<DomesticAirRoute[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAirRoute = async () => {
      setLoading(true);
      try {
        const response = await Fetcher.get<DomesticAirRouteResponse>('/god/domestic_air_route');
        setAirRoute(response.data);
      } catch (error) {
        setError('データの取得に失敗しました');
      } finally {
        setLoading(false);
      }
    };

    fetchAirRoute();
  }, []);

  const handleSave = async (id: number, updatedItem: DomesticAirRoute) => {
    setLoading(true);
    try {
      await Fetcher.put(`/god/domestic_air_route/${id}`, updatedItem);
      setAirRoute(prevAirRoute => prevAirRoute.map(item => (item.id === id ? updatedItem : item)));
    } catch (error) {
      setError('データの更新に失敗しました');
    }
    setLoading(false);
  };

  const handleInputChange = (id: number, field: keyof DomesticAirRoute, value: string | number) => {
    setAirRoute(prevAirRoute => prevAirRoute.map(item => (item.id === id ? { ...item, [field]: value } : item)));
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Container style={{ fontSize: '10px' }}>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>出発空港</th>
            <th>到着空港</th>
            <th>クラス名</th>
            <th>アクション</th>
          </tr>
        </thead>
        <tbody>
          {priority.map(p => (
            <tr key={p.id}>
              <td>{p.id}</td>
              <td>{p.from_airport}</td>
              <td>{p.to_airport}</td>
              <td>
                <input
                  type="text"
                  value={p.class_name}
                  onChange={e => handleInputChange(p.id, 'class_name', e.target.value)}
                />
              </td>
              <td style={{ paddingLeft: '10px' }}>
                <Button onClick={() => handleSave(p.id, p)}>保存</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export default observer(AirRouteTable);

import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import type { LinkProps } from '@this/src/components/shared/ui/navigations/link';

export type EditableFields = 'name' | 'price' | 'createdAt' | 'editing' | 'removed';

export class BudgetItem {
  id: number | null;

  budgetId: number | null;

  name: string;

  price: number;

  targetTable: string | null;

  targetId: number | null;

  createdAt: Moment;

  editing = false;

  removed = false;

  constructor(args: BudgetItemArgs) {
    this.id = args.id || null;
    this.budgetId = args.budget_id || null;
    this.name = args.name;
    this.price = args.price;
    this.targetTable = args.target_table || null;
    this.targetId = args.target_id || null;
    this.createdAt = args.created_at ? moment(args.created_at) : moment();
    this.editing = args.editing ?? false;
  }

  setField<T extends EditableFields>(name: T, value: this[T]) {
    this[name] = value;
    app.render();
  }

  getTarget(): Partial<LinkProps> | null {
    if (this.targetTable === 'trip_report_applications') {
      return { href: `/trip_report/approvals/${this.targetId}`, children: '出張報告' };
    }

    return null;
  }

  getPrice() {
    return `${this.price.toLocaleString()} 円`;
  }

  getCreatedAt() {
    return this.createdAt.format('Y/MM/DD');
  }

  validName() {
    return this.name === '' ? '項目を設定してください' : null;
  }

  submitParams(): BudgetItemParams {
    return {
      id: this.id,
      budget_id: this.budgetId,
      name: this.name,
      price: this.price,
      created_at: this.createdAt.format('YYYY-MM-DD HH:mm:SS ZZ'),
      _destroy: this.removed
    };
  }
}

export interface BudgetItemArgs {
  id?: number;

  budget_id?: number;

  name: string;

  price: number;

  target_table?: string;

  target_id?: number;

  created_at?: string;

  editing?: boolean;
}

export interface BudgetItemParams {
  id: number | null;
  budget_id: number | null;
  name: string;
  price: number;
  created_at: string;
  _destroy: boolean;
}

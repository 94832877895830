import React, { useCallback, useMemo, useState } from 'react';

import { styled } from '@this/constants/themes';
import type Trip from '@this/src/domain/trip/trip';
import { Text } from '@this/shared/ui/data_displays/typography';
import { getSpacing } from '@this/shared/ui/theme';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import Notification from '@this/src/notification';
import { Fetcher, HTTPError } from '@this/src/util';

type Props = {
  className?: string;
  trip: Trip;
  onRestart: () => void;
};

export const RestartArrangement = ({ className, trip, onRestart }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const disabled = useMemo(() => trip.status === 3, [trip.status]);

  const handleClick = useCallback(async () => {
    if (disabled || submitting) return;

    setSubmitting(true);
    try {
      await Fetcher.put(`/arrangement/trips/${trip.id}`, { status: 3 });
      onRestart();
    } catch (e) {
      const msg =
        e instanceof HTTPError && e.response?.data?.errors
          ? e.response.data.errors
          : '通信環境が不安定です。画面をリロードしてからもう一度お試しください。';

      Notification.error(msg);
    } finally {
      setSubmitting(false);
    }
  }, [disabled, submitting, trip.id, onRestart]);

  return (
    <Root className={className}>
      <h3>手配の変更</h3>
      <p>
        予約確定後や完了後に変更の依頼があったら
        <br />
        下記のボタンからステータスを「要予約」に変更
      </p>
      <ButtonContainer>
        <Button disabled={disabled} loading={submitting} onClick={handleClick}>
          「要予約」に変更
        </Button>
      </ButtonContainer>
      {disabled && <Text color="danger">※既に要予約です</Text>}
    </Root>
  );
};

const Root = styled.div``;

const ButtonContainer = styled.div`
  margin-top: ${getSpacing(2)}px;
`;

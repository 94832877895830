import React from 'react';
import { Flex } from '@this/shared/ui/layout/flex';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import type { SvgIconComponent } from '@material-ui/icons';

type Props = {
  className?: string;
  value: 'good' | 'bad' | 'not_rated';
  onClick: (value: 'good' | 'bad') => void;
};

export const FeedbackButtons = ({ className, value, onClick }: Props) => (
  <Flex className={className} gap="4">
    <IconButton aria-label="good" size="small" onClick={() => onClick('good')}>
      {value === 'good' ? (
        <XSmallIcon Icon={ThumbUpIcon} color="primary" />
      ) : (
        <XSmallIcon Icon={ThumbUpOutlinedIcon} />
      )}
    </IconButton>
    <IconButton aria-label="bad" size="small" onClick={() => onClick('bad')}>
      {value === 'bad' ? (
        <XSmallIcon Icon={ThumbDownIcon} color="primary" />
      ) : (
        <XSmallIcon Icon={ThumbDownOutlinedIcon} />
      )}
    </IconButton>
  </Flex>
);

const XSmallIcon = ({ Icon, color = 'inherit' }: { Icon: SvgIconComponent; color?: 'inherit' | 'primary' }) => (
  <Icon style={{ fontSize: '16px' }} color={color} />
);

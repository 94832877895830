import _ from 'lodash';
import type { Moment } from 'moment';
import type MarginType from '@this/domain/organization/margin_type2';
import type SearchResult from './search_result';
import type SearchQueryItem from './search_query_item';
import Transit from './transit/transit';
import TransitList from './transit/transit_list';
import Flight from './flight/flight';
import FlightList from './flight/flight_list';
import Hotel from './hotel/hotel';
import HotelList from './hotel/hotel_list';
import MarginTypeList from './organization/margin_type_list';
import ReservingTripItem from './trip/reserving_trip_item';

import type { MarginTypeJson, TransitJson, HotelJson, FlightJson, HotelScore } from './select_repository';

interface LatLng {
  lat: number;
  lng: number;
}

class SearchResultItem {
  private searchResult: SearchResult;

  public queryItem: SearchQueryItem;

  private itemType: 'transport' | 'hotel' | 'rentalCar' | undefined;

  public pair: 'outward' | 'homeward' | undefined;

  public readonly index: number;

  public readonly type: 'separate' | 'airPackage';

  public elementList: TransitList | HotelList | FlightList | null;

  public isDomestic: boolean;

  public isNeed: boolean;

  public isNonOrderItem: boolean;

  public loading: boolean;

  public selectedLoading: boolean;

  public error: string | null;

  public destLocation: { lat: number; lng: number } | null;

  private showFee: boolean;

  private marginTypes: MarginTypeList;

  private includeTax: boolean;

  public hotelPriceLimit: number | null = null;

  public hotelPriceLimitOverCount: number | null = null;

  public filteredByOrganizationBreakfastFlag: boolean | undefined;

  private searchQueryId: number | null;

  public flightIndex: number | undefined;

  public rakutenStaticFileUpdatedAt: Moment | null = null;

  public handleChange: ((prevId: string | undefined) => void) | null = null;

  public scores: HotelScore | null = null;

  public reasonStatus: 'none' | 'loading' | 'completed' = 'none';

  public selectedReason: string | null = null;

  public scoreReason: string | null = null;

  constructor(args: {
    searchResult: SearchResult;
    queryItem: SearchQueryItem;
    index: number;
    type: 'separate' | 'airPackage';
    showFee: boolean;
    marginTypes: MarginTypeJson[];
    includeTax: boolean;
    elementList?: TransitList | HotelList | FlightList;
  }) {
    this.searchResult = args.searchResult;
    this.queryItem = args.queryItem;
    if (args.type === 'separate') this.queryItem.setResultItem(this);
    this.itemType = this.queryItem.itemType;
    this.index = args.index;
    this.type = args.type;
    this.elementList = args.elementList || null;
    this.isDomestic = true;
    this.isNeed = true;
    this.isNonOrderItem = false;
    this.loading = !args.elementList;
    this.selectedLoading = false;
    this.error = null;
    this.destLocation = null;
    this.showFee = args.showFee;
    this.marginTypes = new MarginTypeList(args.marginTypes);
    this.includeTax = args.includeTax;
    this.searchQueryId = null;
    this.scores = null;

    if (this.searchResult.handleChange) {
      this.handleChange = (prevId: string | undefined) => {
        if (this.searchResult.handleChange) this.searchResult.handleChange(this.index, prevId);
      };
    }
  }

  setQueryItem(item: SearchQueryItem) {
    this.queryItem = item;
    this.queryItem.setResultItem(this);
    this.itemType = this.queryItem.itemType;
    app.render();
  }

  setNeed(need: boolean) {
    this.isNeed = need;
    app.render();
  }

  toggleNeed = () => {
    if (this.isDomestic || this.itemType === 'hotel') {
      this.isNeed = !this.isNeed;
      app.render();
    } else {
      this.searchResult.toggleAllFlight();
    }
  };

  toggleNonOrderItem = () => {
    this.isNonOrderItem = !this.isNonOrderItem;
    app.render();
  };

  setIsDomestic(isDomestic: boolean) {
    this.isDomestic = isDomestic;
    app.render();
  }

  setDestLocation(location: LatLng) {
    this.destLocation = { lat: location.lat, lng: location.lng };
    app.render();
  }

  setFlightIndex(index: number) {
    this.flightIndex = index;
    app.render();
  }

  elementType(): 'transport' | 'hotel' | 'flight' | undefined {
    switch (this.itemType) {
      case 'transport':
        return this.isDomestic ? 'transport' : 'flight';
      case 'hotel':
        return 'hotel';
      default:
        return undefined;
    }
  }

  // エクスペディアホテルの場合はHotelTooLateのバリデーションが不要の為、ホテルのタイプを取得する
  currentElementType(): Hotel['type'] | undefined {
    switch (this.itemType) {
      case 'hotel':
        return this.elementList instanceof HotelList ? this.elementList.current().type : undefined;
      default:
        return undefined;
    }
  }

  setElementList(
    result: TransitJson[] | HotelJson[] | FlightJson[],
    current: string | undefined,
    changeableAir?: boolean | undefined,
    isShowMap?: boolean
  ) {
    let marginType: MarginType | undefined;
    switch (this.elementType()) {
      case 'transport':
        marginType = this.isShinkansen()
          ? this.marginTypes.domesticShinkansenMarginType()
          : this.marginTypes.domesticFlightMarginType();
        this.elementList = new TransitList(result as TransitJson[], {
          showFee: this.showFee,
          marginType,
          current,
          changeableAir,
          handleChange: this.handleChange || undefined
        });
        this.elementList.firstHandleChange();
        break;
      case 'flight':
        this.elementList = new FlightList(result as FlightJson[], {
          showFee: this.showFee,
          marginType: this.marginTypes.foreignMarginType(),
          current,
          cabins: this.searchResult.query.cabin,
          handleChange: this.handleChange || undefined
        });
        this.elementList.firstHandleChange();
        break;
      case 'hotel':
        marginType = this.isDomestic
          ? this.marginTypes.domesticHotelMarginType()
          : this.marginTypes.foreignHotelMarginType();
        this.elementList = new HotelList(result as HotelJson[], {
          includeTax: this.includeTax,
          marginType,
          current,
          isShowMap
        });
        break;
      default:
    }
    app.render();
  }

  setFlightList(flightList: FlightList) {
    this.elementList = flightList;
    app.render();
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    app.render();
  }

  loadingWithPair() {
    if (this.elementType() !== 'transport') return this.loading;
    if (!this.queryItem.pair) return this.loading;

    return this.searchResult.items
      .filter(item => item.queryItem.pair === this.queryItem.pair)
      .some(item => item.loading);
  }

  setLoadingWithSelectedLoading(loading: boolean) {
    this.loading = loading;
    this.selectedLoading = loading;
    app.render();
  }

  setError(error: string | null) {
    this.error = error;
    app.render();
  }

  setSearchQueryId(id: number) {
    this.searchQueryId = id;
  }

  setShowFee(value: boolean) {
    this.showFee = value;
  }

  setMarginTypes(value: MarginTypeJson[]) {
    this.marginTypes = new MarginTypeList(value);
  }

  setIncludeTax(value: boolean) {
    if (this.isHotel()) {
      this.includeTax = value;
      if (this.elementList instanceof HotelList) this.elementList.setIncludeTax(value);
    }
  }

  isAirChangeable() {
    if (this.isDomestic && this.isTransport() && this.elementList instanceof TransitList)
      return this.elementList.isAirChangeable;
    return undefined;
  }

  setAirChangeable(c: boolean) {
    if (this.isDomestic && this.isTransport() && this.elementList instanceof TransitList)
      this.elementList.setAirChangeable(c);
  }

  setHotelScores(scores: HotelScore) {
    this.scores = scores;
  }

  setReasonStatus(status: 'none' | 'loading' | 'completed') {
    this.reasonStatus = status;
    app.render();
  }

  setReason(selectedReason: string, scoreReason: string) {
    this.selectedReason = selectedReason;
    this.scoreReason = scoreReason;
    this.reasonStatus = 'completed';
    app.render();
  }

  currentElementId() {
    if (this.isNeed && this.elementList) return this.elementList.currentId;
    return undefined;
  }

  currentElement(): Transit | Hotel | Flight | null | undefined {
    return this.elementList && this.elementList.current();
  }

  cheapestElement(): Transit | Hotel | Flight | null | undefined {
    if (this.elementList && this.elementList instanceof HotelList) {
      const lists = _.clone(this.elementList);
      lists.setSortBy('average_price_with_tax');
      return lists && lists.first();
    }
    return this.currentElement();
  }

  // /trips/:id/edit での日時順並び替えのため
  startDateTime() {
    const current = this.currentElement();
    if (current) {
      return current.startDateTime();
    }
    return null;
  }

  private getQueryParams() {
    const currentElement = this.currentElement();
    if (!currentElement) return {};

    let text = (currentElement as Transit).text || (currentElement as Hotel).name;

    // ブラウザでリロードした際のパッケージのパラメータ対策
    let flightNum = {};
    let hotelName = {};
    if (currentElement instanceof Transit) {
      flightNum = { flightNum: currentElement.segments[0].legs.map(l => l.name).join(',') }; // [経由便対応]全ての便名をカンマでつなぐ
    } else if (currentElement instanceof Hotel) {
      // ホテル名の一意性をある程度担保できそう、かつ、
      // パラメーターとしては長過ぎない文字数。
      const maxHotelNameLength = 20;
      if (currentElement.name) {
        hotelName = { hotelName: _.truncate(currentElement.name, { length: maxHotelNameLength, omission: '' }) };
      }
    }

    if (!text) {
      if (typeof (currentElement as Flight).uniqString === 'function') {
        text = (currentElement as Flight).uniqString();
      } else {
        text = '';
      }
    }

    const current = this.currentElement();

    return {
      selected: currentElement instanceof Flight ? text : text + currentElement.price,
      changeable_air: current instanceof Transit ? current.isAirChangeable || '' : '',
      ...flightNum,
      ...hotelName
    };
  }

  getQueryString(key: string) {
    let res = '';
    _.entries(this.getQueryParams()).forEach(([k, v]) => {
      res += `${key}[${k}]=${v}&`;
    });
    return res;
  }

  confirmParams(index?: number) {
    const current = this.currentElement();
    const elementList = this.elementList;
    let air_price = null;
    if (current instanceof Transit && current.air && this.isNonOrderItem) {
      if (current.isAirChangeable) {
        if (current.changeable_price) {
          air_price = current.changeable_price;
        }
      } else if (current.unchangeable_price) {
        air_price = current.unchangeable_price;
      }
    }

    return {
      index: index ?? this.index,
      element_type: this.elementType(),
      current_element_type: this.currentElementType(),
      element_id: this.currentElementId(),
      domestic_air_price_index: elementList instanceof TransitList ? elementList.domesticAirPriceIndex : null,
      changeable_air: current instanceof Transit ? `${current.isAirChangeable}` : null,
      foreign_exist: !this.isDomestic,
      search_query_id: this.searchQueryId,
      price: air_price || utils.dig(current, 'price') || 0,
      is_non_order_item: this.isNonOrderItem,
      hotel_price_limit: this.hotelPriceLimit,
      selected_stay_plan_id: current instanceof Hotel ? current.selected_stay_plan_id : null
    };
  }

  updateTransitSegmentsParams() {
    const current = this.currentElement();
    if (current instanceof Transit) {
      return {
        element_type: this.elementType(),
        element_id: this.currentElementId(),
        segments: current.segments,
        price: current.ticketPrice()
      };
    }
    return null;
  }

  getReservingTripItem() {
    if (!(this.elementList && this.elementList.list.length && this.isNeed)) return undefined;

    const current = this.currentElement();

    return new ReservingTripItem({
      index: this.index,
      element_type: this.elementType(),
      element_id: this.currentElementId(),
      element_raw: current && current.toRaw(),
      showFee: this.showFee,
      marginTypes: this.marginTypes,
      foreign_exist: this.isDomestic ? 'false' : 'true',
      domestic_air_price_index:
        this.elementList instanceof TransitList ? this.elementList.domesticAirPriceIndex : null
    });
  }

  getReservingTripItemCheapest() {
    if (!(this.elementList && this.elementList.list.length && this.isNeed)) return undefined;

    const current = this.cheapestElement();

    return new ReservingTripItem({
      index: this.index,
      element_type: this.elementType(),
      element_id: current?.id,
      element_raw: current && current.toRaw(),
      showFee: this.showFee,
      marginTypes: this.marginTypes,
      foreign_exist: this.isDomestic ? 'false' : 'true',
      domestic_air_price_index:
        this.elementList instanceof TransitList ? this.elementList.domesticAirPriceIndex : null
    });
  }

  isDomesticEmpty(): boolean {
    return this.isDomestic && !this.isNeed;
  }

  isForeignEmpty(): boolean {
    return !this.isDomestic && !this.isNeed;
  }

  isShinkansenTooLate(): boolean {
    return (
      this.isDomestic &&
      this.isTransport() &&
      this.isNeed &&
      this.elementList instanceof TransitList &&
      this.elementList.isCurrentShinkansenTooLate()
    );
  }

  isAirTooLate(): boolean {
    return (
      this.type === 'airPackage' &&
      this.isTransport() &&
      this.isNeed &&
      this.elementList instanceof TransitList &&
      this.elementList.isCurrentAirTooLate()
    );
  }

  isHotelTooLate(): boolean {
    return this.elementList instanceof HotelList && this.elementList.isCurrentHotelTooLate();
  }

  canReserveJustBefore(): boolean {
    return this.elementList instanceof HotelList && this.elementList.canReserveJustBefore();
  }

  shinkansenDeadline() {
    if (this.isShinkansenTooLate() && this.elementList instanceof TransitList)
      return this.elementList.currentShinkansenDeadline();
    return undefined;
  }

  isDomesticAir(): boolean {
    return (
      this.isDomestic &&
      this.isTransport() &&
      this.elementList instanceof TransitList &&
      this.elementList.includeAir()
    );
  }

  isShinkansen(): boolean {
    return (
      this.isDomestic &&
      this.isTransport() &&
      this.elementList instanceof TransitList &&
      this.elementList.includeShinkansen()
    );
  }

  private isTransport(): boolean {
    return this.itemType === 'transport';
  }

  private isHotel(): boolean {
    return this.itemType === 'hotel';
  }
}
export default SearchResultItem;

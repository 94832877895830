import { Fetcher } from '@this/src/util';
/* eslint-disable max-lines */
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Pagination from '@material-ui/lab/Pagination';
import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';

import ContentBody from '@this/components/shared/atoms/content_body';
import { media } from '@this/components/shared/atoms/media';
import { Loading } from '@this/components/shared/ui/feedbacks/loading/loading';
import DraftSearch from '@this/components/trips_management/trips/draft_search/draft_search';
import { styled } from '@this/constants/themes';
import type { AvailableRepository } from '@this/domain/available_repository';
import Trip from '@this/domain/trip/trip';
import type User from '@this/domain/user/user';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import { Modal, ModalHeader, ModalBody } from '@this/shared/ui/feedbacks/modal';
import { Button } from '@this/shared/ui/inputs/button';
import { Link } from '@this/shared/ui/navigations/link';

import TripHeader from './trip_header';
import TripRow from './trip_row';

type SortKey = 'created_at' | 'started_at';
type SortDirection = 'asc' | 'desc';
type Type = 'user';

interface Props extends RouteComponentProps {
  user: User | null;
  serviceId: number;
  availableRepos: AvailableRepository[];
  availableOptions: string[];
}

interface FetchTripsResult {
  user: any;
  trips: any[];
  show_fee: boolean;
  current_page: number;
  total_pages: number;
}

interface TripParams {
  type: Type;
  page: number;
  trip_id: string | null | undefined;
  trip_from_date: string | undefined;
  trip_to_date: string | undefined;
  sort_key: SortKey;
  sort_direction: SortDirection;
  simple?: string;
}

const getTripIdParam = () => {
  return utils.getParam('trip_id') ?? null;
};

const getFromDateParam = () => {
  const fromDate = utils.getParam('trip_from_date');
  return fromDate ? moment(fromDate) : undefined;
};

const getToDateParam = () => {
  const toDate = utils.getParam('trip_to_date');
  return toDate ? moment(toDate) : undefined;
};

const getSortKeyParam = () => {
  return utils.getParam('sort_key') as SortKey | undefined;
};

const getSortDirectionParam = () => {
  return utils.getParam('sort_direction') as SortDirection | undefined;
};

const getPageParam = () => {
  return parseInt(utils.getParam('page') || '1', 10);
};

const Trips = observer(({ user, availableRepos, availableOptions }: Props) => {
  const tripReportAvailable = availableOptions.includes('trip_report');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedDraft, setSelectedDraft] = useState<any | null>(null);
  const [ongoingTrips, setOngoingTrips] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(getPageParam());
  const [totalPages, setTotalPages] = useState(1);

  const [tripId, setTripId] = useState<string | null>(getTripIdParam());
  const [tripFromDate, setTripFromDate] = useState<Moment | undefined>(getFromDateParam());
  const [tripToDate, setTripToDate] = useState<Moment | undefined>(getToDateParam());
  const [sortKey, setSortKey] = useState<SortKey>(getSortKeyParam() ?? 'created_at');
  const [sortDirection, setSortDirection] = useState<SortDirection>(getSortDirectionParam() ?? 'desc');

  const [tripParams, setTripPrams] = useState<TripParams>({
    type: 'user',
    page: currentPage,
    trip_id: tripId || undefined,
    trip_from_date: tripFromDate?.format('YYYY-MM-DD'),
    trip_to_date: tripToDate?.format('YYYY-MM-DD'),
    sort_key: sortKey,
    sort_direction: sortDirection,
    simple: 'true'
  });
  const [showWorkflowUnappliedModal, setShowWorkflowUnappliedModal] = useState(false);
  const [workflowUnappliedTrips, setWorkflowUnappliedTrips] = useState<any[]>([]);

  const fetchTrips = useCallback(() => {
    setLoading(true);
    setError(null);
    Fetcher.get<FetchTripsResult>('/trips.json', tripParams)
      .then(result => {
        setOngoingTrips(_.map(result.trips, t => new Trip(_.merge(t, { showFee: result.show_fee }))));
        setTotalPages(result.total_pages);

        // 検索クエリをURLクエリに反映
        const queries = Object.entries(tripParams)
          .filter(([k, v]) => v !== undefined && !['type', 'simple'].includes(k))
          .map(([k, v]) => `${k}=${v}`)
          .join('&');
        window.history.replaceState(null, '', `${window.location.pathname}?${queries}`);
      })
      .finally(() => setLoading(false))
      .catch(e => {
        setError('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
      });
  }, [tripParams]);

  useEffect(() => {
    fetchTrips();
  }, [fetchTrips]);

  useEffect(() => {
    const unappliedTrips = ongoingTrips.filter(trip => trip.status === 8 && trip.freee_approval_request_id);
    setWorkflowUnappliedTrips(unappliedTrips);
    setShowWorkflowUnappliedModal(unappliedTrips.length > 0);
  }, [ongoingTrips]);

  const handleSearchTrips = useCallback(() => {
    setCurrentPage(1);
    setTripPrams({
      type: 'user',
      page: 1,
      trip_id: tripId || undefined,
      trip_from_date: tripFromDate?.format('YYYY-MM-DD'),
      trip_to_date: tripToDate?.format('YYYY-MM-DD'),
      sort_key: sortKey,
      sort_direction: sortDirection,
      simple: 'true'
    });
  }, [tripId, tripFromDate, tripToDate, sortKey, sortDirection]);

  const handlePageChange = useCallback(
    (_: React.ChangeEvent<unknown>, page: number) => {
      setCurrentPage(page);
      setTripPrams({
        type: 'user',
        page,
        trip_id: tripId || undefined,
        trip_from_date: tripFromDate?.format('YYYY-MM-DD'),
        trip_to_date: tripToDate?.format('YYYY-MM-DD'),
        sort_key: sortKey,
        sort_direction: sortDirection,
        simple: 'true'
      });
    },
    [tripId, tripFromDate, tripToDate, sortKey, sortDirection]
  );

  return (
    <Wrap>
      <BodyWrap>
        <div>
          <TripHeader selected="trip" tripReportAvailable={tripReportAvailable} />
          <Body>
            <Box display="flex">
              <PageTitle style={{ marginRight: 'auto' }}>予約した旅程一覧</PageTitle>
              <Box display="flex" alignItems="center" style={{ gap: '0 10px' }}>
                {!tripReportAvailable && <Link href="/trip_reports">出張報告一覧(β)</Link>}
              </Box>
            </Box>
            <SearchFields className="searchFields">
              <SearchField>
                <SearchTitle>旅程番号</SearchTitle>
                <SearchInput>
                  <input
                    type="text"
                    placeholder="旅程番号"
                    value={tripId || ''}
                    onChange={e => setTripId(e.target.value)}
                  />
                </SearchInput>
              </SearchField>
              <SearchField>
                <SearchTitle>出張日</SearchTitle>
                <SearchInput>
                  <DatetimePicker
                    dateFieldId="filterParams-fromDate"
                    value={tripFromDate}
                    onChange={date => setTripFromDate(date)}
                    onClear={() => setTripFromDate(undefined)}
                    placeholder="開始日"
                    border
                    showToday
                    showPast
                    width="140px"
                    deletable
                  />
                  <span>〜</span>
                  <DatetimePicker
                    dateFieldId="filterParams-toDate"
                    value={tripToDate}
                    onChange={date => setTripToDate(date)}
                    onClear={() => setTripToDate(undefined)}
                    placeholder="終了日"
                    border
                    showToday
                    showPast
                    width="140px"
                    deletable
                  />
                </SearchInput>
              </SearchField>
              <SearchField>
                <SearchTitle>並び順</SearchTitle>
                <Box display="flex">
                  <SearchInput>
                    <select value={sortKey} onChange={e => setSortKey(e.target.value as SortKey)}>
                      <option value="created_at">旅程作成日順</option>
                      <option value="started_at">出発日順</option>
                    </select>
                  </SearchInput>
                  <SearchInput>
                    <select
                      value={sortDirection}
                      onChange={e => setSortDirection(e.target.value as SortDirection)}
                    >
                      <option value="desc">降順</option>
                      <option value="asc">昇順</option>
                    </select>
                  </SearchInput>
                </Box>
              </SearchField>
              <SearchField>
                <Button className={loading ? 'disabled' : undefined} onClick={handleSearchTrips}>
                  検索
                </Button>
              </SearchField>
            </SearchFields>
            <TripsWrap>
              {loading ? (
                <Loading />
              ) : error ? (
                <Error>{error}</Error>
              ) : selectedDraft ? (
                <DraftSearch
                  trip={selectedDraft}
                  onClose={() => setSelectedDraft(null)}
                  availableRepos={availableRepos}
                />
              ) : ongoingTrips.length > 0 ? (
                <ListWrap>
                  {ongoingTrips.map(trip => (
                    <CardWrap key={trip.id}>
                      <TripRow trip={trip} />
                    </CardWrap>
                  ))}
                  <Pagination
                    style={{ margin: '10px 0' }}
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </ListWrap>
              ) : (
                <NoItem>条件に合致する旅程はありません</NoItem>
              )}
            </TripsWrap>
          </Body>
        </div>
        <Modal open={showWorkflowUnappliedModal} onClose={() => setShowWorkflowUnappliedModal(false)}>
          <ModalHeader>ワークフローを申請してください</ModalHeader>
          <ModalBody>
            <Box marginBottom="20px">
              以下の旅程のワークフローが申請待ちになっています。
              <br />
              詳細ページに遷移してワークフローを申請してください。
            </Box>
            <Box>
              {workflowUnappliedTrips.map(trip =>
                trip.freee_approval_request_id ? (
                  <Box>
                    <a
                      href={`https://secure.freee.co.jp/approval_requests/${trip.freee_approval_request_id}`}
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      rel="noreferrer"
                    >
                      freeeワークフロー申請ページ（旅程番号：{trip.id}）
                    </a>
                  </Box>
                ) : null
              )}
            </Box>
          </ModalBody>
        </Modal>
      </BodyWrap>
    </Wrap>
  );
});

export const Wrap = styled.div`
  min-height: 100vh;
`;

export const BodyWrap = styled.div`
  min-height: 100vh;
  display: block;
`;

export const Body = styled(ContentBody)`
  max-width: 1150px;
  padding: 20px;
  background: #fff;
`;

const PageTitle = styled.h2`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const SearchFields = styled.div`
  display: flex;
  column-gap: 16px;
  row-gap: 8px;
  align-items: flex-end;

  ${media.sp`
    flex-wrap: wrap;
  `}
`;

const SearchField = styled.div`
  display: flex;
  flex-flow: column;
`;

const SearchTitle = styled.label`
  font-size: 12px;
  color: ${props => props.theme.grayTextColor};
`;

const SearchInput = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  column-gap: 4px;

  input[type='text'] {
    margin-bottom: 0;
  }

  label {
    font-weight: normal;
    cursor: pointer;
    margin-right: 10px;
  }

  input[type='checkbox'] {
    cursor: pointer;
  }

  & .single-calendar-input {
    width: 120px !important;
  }

  select,
  #filterParams-fromDate,
  #filterParams-toDate {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 6%);
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif;
    font-size: 1em;
    padding: 0.5em;
    margin-bottom: 0;
    transition: border-color;
    width: 100%;
    height: auto;
  }

  select {
    font-size: 14px;
  }
`;

export const LoadingWrap = styled.div`
  padding-top: 20px;
`;

const Error = styled.div`
  padding: 20px;
  color: ${props => props.theme.redColor};
`;

const TripsWrap = styled.div`
  padding: 20px 0;
`;

const ListWrap = styled.div``;

const NoItem = styled.p`
  margin: 0 auto;
  text-align: center;
`;

const CardWrap = styled.div`
  box-shadow: 0 0 3px 1px ${props => props.theme.grayBorderColor};
  border-radius: 5px;
`;

export default Trips;

import { Fetcher } from '@this/src/util';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { setupMixpanel } from '@this/lib/mixpanel_utils';
import { reportError, setupBugsnag } from '@this/lib/bugsnag';

import { styled } from '@this/constants/themes';
import Header from '@this/shared/header/header';
import Footer from '@this/shared/footer/footer';
import OrganizationMemberInvitationEdit from '@this/components/organization_member_invitations_edit';
import UserPasswordReset from '@this/components/user/password_reset';
import UserPage, { UserPagePaths } from '@this/components/user_page/user_page';
import type UserJson from '@this/domain/user/user_json';
import User from '@this/domain/user/user';
import type { AvailableRepository } from '@this/domain/available_repository';
import OrganizationMemberInsufficientEdit from '@this/components/organization_member_insufficient_edit';
import Context from './core_app_context';

export const CoreAppPaths = UserPagePaths.concat([
  '/biz/inquiry',
  '/users/invitation/accept',
  '/users/password/edit',
  '/admins/password/edit',
  '/arrangers/password/edit',
  '/users/insufficient'
]);

interface Props {
  serviceId?: number | null;
  user?: User;
  env?: string;
}

interface State {
  user: User | null;
  onUserSignedIn: () => void;
  serviceId: number;
  availableRepos: AvailableRepository[];
  availableOptions: string[];
  isValidAITravelPlan: boolean;
  loading: boolean;
  env: string;
  ignoreHeaderShowFlg: boolean;
}

class CoreApp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let ignoreMenu = false;
    if (location.pathname === '/users/insufficient') {
      ignoreMenu = true;
    }
    this.state = {
      user: props.user || null,
      onUserSignedIn: () => {
        this.fetch();
      },
      serviceId: props.serviceId || 1,
      availableRepos: [],
      availableOptions: [],
      isValidAITravelPlan: true,
      loading: true,
      env: props.env || '',
      ignoreHeaderShowFlg: ignoreMenu
    };
  }

  componentDidMount() {
    this.fetch();
  }

  private async fetch() {
    try {
      this.setState({ loading: true });
      await Promise.all([this.fetchUser(), this.fetchAvailableRepos(), this.checkValidAITravelPlan()]);
    } finally {
      this.setState({ loading: false });
    }
  }

  async fetchUser() {
    try {
      const res = await Fetcher.get<UserJson>('/users.json');
      const user = new User(res);
      this.setState({ user, availableOptions: res.available_options ?? [] });
      setupMixpanel(user);
      setupBugsnag(user);
    } catch (e) {
      this.setState({ user: null });
      if (e.response.status !== 404) {
        throw e;
      }
    }
  }

  private async fetchAvailableRepos() {
    try {
      const res = await Fetcher.get<AvailableRepository[]>('/available_repositories');
      this.setState({ availableRepos: res });
    } catch (e) {
      this.setState({ availableRepos: [] });
      if (e.response.status !== 404) {
        throw e;
      }
    }
  }

  private async checkValidAITravelPlan() {
    try {
      await Fetcher.get('/valid_aitravel_plan');
    } catch (e) {
      if (e.response.status === 404 && e.response.data?.message === 'AITravel::ResourceNotFound') {
        this.setState({ isValidAITravelPlan: false });
      } else {
        throw e;
      }
    }
  }

  render() {
    try {
      const { user, serviceId, loading, ignoreHeaderShowFlg, isValidAITravelPlan } = this.state;
      return (
        !loading &&
        (isValidAITravelPlan ? (
          <Context.Provider value={this.state}>
            <Wrapper>
              <Header user={user} ignoreMenu={ignoreHeaderShowFlg} />
              <Switch>
                <Route
                  exact
                  path="/users/invitation/accept"
                  render={props => <OrganizationMemberInvitationEdit {...props} {...this.state} />}
                />
                <Route
                  exact
                  path="/users/insufficient"
                  render={props => <OrganizationMemberInsufficientEdit {...props} {...this.state} />}
                />
                <Route
                  exact
                  path={['/users/password/edit', '/admins/password/edit', '/arrangers/password/edit']}
                  render={props => <UserPasswordReset {...props} {...this.state} />}
                />
                <Route path={UserPagePaths} render={props => <UserPage {...props} {...this.state} />} />
              </Switch>
              <Footer signedIn={!!user} serviceId={serviceId} ignoreMenu={ignoreHeaderShowFlg} />
            </Wrapper>
          </Context.Provider>
        ) : (
          <p style={{ width: '100%', textAlign: 'center', padding: '20px' }}>ご利用のプランでは利用できません</p>
        ))
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export default CoreApp;

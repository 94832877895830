import { Fetcher } from '@this/src/util';
import React from 'react';
import _ from 'lodash';
import type { RouteComponentProps } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { styled } from '@this/constants/themes';

import SendList from '@this/components/arrangement/send_list/send_list';
import SendList2 from '@this/components/arrangement/send_list2/send_list2';
import ShareholderTickets from '@this/components/arrangement/shareholder_tickets/shareholder_tickets';
import type { ArrangerArgs } from '@this/src/domain/arranger/arranger';
import Arranger from '@this/src/domain/arranger/arranger';
import ArrangementHeader from '@this/components/arrangement/arrangement_header/arrangement_header';
import { reportError } from '@this/lib/bugsnag';
import TodoList from './todo_list/todo_list';
import OrderHistories from './order_histories/order_histories';
import SendList2Edit from './send_list2/edit/edit';
import SendList2New from './send_list2/new/new';
import MessageTemplates from './message_templates/message_templates';
import HotelReservations from './hotel_reservations/hotel_reservations';
import SearchPnrFlights from './search_pnr_flights/search_pnr_flights';
import YamatoCsvImportActions from './yamato_csv_import_actions/yamato_csv_import_actions';
import YamatoCsvImportActionDetail from './yamato_csv_import_actions/yamato_csv_import_action_detail';
import SearchFlights from './search_flights/search_flights';
import SearchTrackings from './search_trackings/search_trackings';
import SearchTrackingDetails from './search_tracking_details/search_tracking_details';
import RakutenStaticInvestigate from './rakuten_static_investigate/rakuten_static_investigate';
import VirtualCounter from './virtual_counter/virtual_counter';
import MailSendLogs from './mail_send_logs/mail_send_logs';

interface Props extends RouteComponentProps {
  serviceId: number;
}

interface State {
  arranger: Arranger | null;
}

interface Response {
  arranger: ArrangerArgs;
}

class Arrangement extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      arranger: null
    };
  }

  componentDidMount() {
    this.fetchArranger();
  }

  fetchArranger() {
    Fetcher.get<Response>('/arrangers.json').then(res => {
      this.setState({ arranger: new Arranger(res.arranger) });
    });
  }

  render() {
    try {
      const { arranger } = this.state;
      const {
        match: { path }
      } = this.props;
      return (
        <Wrapper>
          <ArrangementHeader arranger={arranger} serviceId={this.props.serviceId} />
          <Content>
            <Switch>
              <Route
                exact
                path={`${path}/todo_list`}
                render={props => <TodoList serviceId={this.props.serviceId} {...props} />}
              />
              <Route exact path={`${path}/virtual_counter`} component={VirtualCounter} />
              <Route exact path={`${path}/send_list`} component={SendList} />
              <Route exact path={`${path}/send_list2`} component={SendList2} />
              <Route exact path={`${path}/send_list2/new`} component={SendList2New} />
              <Route path={`${path}/send_list2/:id/edit`} component={SendList2Edit} />
              <Route
                exact
                path={`${path}/shareholder_tickets`}
                render={props => <ShareholderTickets serviceId={this.props.serviceId} {...props} />}
              />
              <Route exact path={`${path}/order_histories`} component={OrderHistories} />
              <Route exact path={`${path}/message_templates`} component={MessageTemplates} />
              <Route exact path={`${path}/hotel_reservations`} component={HotelReservations} />
              <Route exact path={`${path}/search_pnr_flights`} component={SearchPnrFlights} />
              <Route exact path={`${path}/yamato_csv_import_actions`} component={YamatoCsvImportActions} />
              <Route exact path={`${path}/search_flights`} component={SearchFlights} />
              <Route exact path={`${path}/rakuten_static_investigate`} component={RakutenStaticInvestigate} />
              <Route exact path={`${path}/search_trackings`} component={SearchTrackings} />
              <Route path={`${path}/search_trackings/:id`} component={SearchTrackingDetails} />
              <Route exact path={`${path}/mail_send_logs`} component={MailSendLogs} />
              <Route
                exact
                path={`${path}/yamato_csv_import_actions/:id`}
                component={YamatoCsvImportActionDetail}
              />
            </Switch>
          </Content>
        </Wrapper>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Content = styled.div`
  flex-grow: 99999;
  display: flex;
  flex-direction: column;
  background: white;
`;

export default Arrangement;

/* eslint-disable max-lines */
import React from 'react';
import { Prompt } from 'react-router';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import type NonOrderItem from '@this/domain/non_order_item';
import type TransportElement from '@this/domain/transport_element';
import HotelElement from '@this/domain/hotel_element';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import StepBar from '@this/components/step_bar';

import Transit from '@this/domain/transit/transit';
import Flight from '@this/domain/flight/flight';
import Hotel from '@this/domain/hotel/hotel';
import TravelerList from '@this/src/domain/traveler/traveler_list';

import MarginTypeList from '@this/domain/organization/margin_type_list';

import Department from '@this/domain/department/department';
import OrganizationBase from '@this/domain/organization_base/organization_base';
import ChargingDepartmentShareList from '@this/domain/department/charging_department_share_list';
import ProjectList from '@this/domain/project/project_list';
import ProjectShareList from '@this/domain/project/project_share_list';
import type UserJson from '@this/domain/user/user_json';
import User from '@this/domain/user/user';
import Traveler from '@this/domain/traveler/traveler';
import type { CardJson, RentalCarJson, ConsignmentSale, PaymentGatewayMember } from '@this/domain/reserve_info';
import ReserveInfo from '@this/domain/reserve_info';
import ReservingTrip from '@this/domain/trip/reserving_trip';
import ReservingTripItem from '@this/domain/trip/reserving_trip_item';
import type { PackageProvider } from '@this/domain/select_store';
import type { RentalCarLimitTypeJson } from '@this/domain/organization/rental_car_limit_type';
import RentalCarLimitType from '@this/domain/organization/rental_car_limit_type';
import Trip from '@this/domain/trip/trip';
import type { OrderItemMappingArgs } from '@this/domain/order_item_mapping';
import Prefecture from '@this/domain/prefecture';

import type { RouteComponentProps } from 'react-router-dom';
import type { WorkflowStyle } from '@this/domain/workflow_style';
import type { SearchType } from '@this/domain/search_query';
import type { TripDuplicationInterface } from '@this/components/reserve_trip/reserve_confirm/reserve_confirm';
import type { ExpensesAccountTypeJson } from '@this/domain/expenses/expenses_account_type';
import ExpensesAccountType from '@this/domain/expenses/expenses_account_type';
import { Fetcher, HTTPError, objectToFormData } from '@this/src/util';
import { reportError } from '@this/lib/bugsnag';
import type TripApproveItemJson from '@this/src/domain/trip_approve_item/trip_approve_item_json';
import ReserveConfirmOutlineItemTemplate from '../../../reserve_trip/reserve_confirm/outline_item/outline_item.template';
import ReserveConfirmRoutingsTemplate from '../../../reserve_trip/reserve_confirm/routings/routings.template';
import { ConfirmWrapHeader } from '../../../reserve_trip/reserve_confirm/reserve_confirm_pc.template';
import { Wrap, BodyWrap, Body, LoadingWrap } from '../trips';

interface Props extends RouteComponentProps<Record<string, string | undefined>, Record<string, any>, any> {
  serviceId: number;
}

interface State {
  reserveInfo: ReserveInfo | null;
  trip: any;
  user: any;
  travelers: any;
  queries: { peoplenum: number; roomnum: number; stay_days: number; search_type: SearchType } | null;
  searchQueryId: number | null;
  organizationBases: OrganizationBase[] | undefined;
  departments: Department[] | undefined;
  useBulkTicket: boolean | undefined;
  useKyuusyuuTicket: boolean | undefined;
  showEx: boolean | undefined;
  loading: boolean;
  paymentLoading: boolean;
  steps: string[];
  stepIndex: number;
  shouldBlockNavigation: boolean;
  submitErrors: string[];
  error: string | null;
  termsAgree: boolean;
  projectShareAvailability: boolean;
  prefecture: Prefecture[];
  useSmartSearch: boolean;
  tripDuplications: TripDuplicationInterface[];
}

interface TripsConfirmResponse {
  trip: any;
  user: UserJson;
  items: {
    element_id: string;
    element_raw: never;
    element_type: 'transport' | 'hotel' | 'flight';
    foreign_exist: string; // "false" で返ってくる;
    changeable_air: string; // "true" で返ってくる;
    index: string; // "0" で返ってくる
    domestic_air_price_index: number;
  }[];
  approvers: never;
  type: never;
  package_type: never;
  package_provider?: PackageProvider;
  package_search_condition: never;
  foreign_exist: never;
  domestic_exist: never;
  shinkansen_address: never;
  shinkansen_addressee: never;
  shinkansen_address_type: never;
  shinkansen_fee?: never;
  domestic_air_fee: never;
  foreign_air_fee: never;
  use_bulk_ticket: boolean;
  use_kyuusyuu_ticket: boolean;
  show_ex: boolean;
  has_members: boolean;
  organization_bases: never[];
  departments: never[];
  charging_department_shares: never;
  postcode: never;
  cards: CardJson[];
  car_type: [string, string][];
  rentalcar_available: boolean;
  rental_cars: RentalCarJson[];
  rental_car_limit_type: RentalCarLimitTypeJson;
  is_smoke: [string, string][];
  projects: never;
  project_shares: never;
  order_item_mapping_args: OrderItemMappingArgs[];
  outword: never;
  homeword: never;
  hotel?: { bed_types: { id: number; description: string }[] };
  flight: never;
  margin_types: never;
  show_fee: never;
  draft_trip: { purpose: never } | null;
  all_travelers_notification: never;
  notified_users: UserJson[];
  token_host: string;
  shop_id: string;
  consignment_sale: ConsignmentSale;
  login_user_id: number;
  project_share_availability: boolean;
  prefecture: Prefecture[];
  internal_number: string;
  nationalities: { id: number; name: string }[];
  use_default_workflow: boolean;
  workflow_style: WorkflowStyle;
  use_smart_search: boolean;
  trip_duplications: TripDuplicationInterface[];
  trip_approve_item: { json: string };
  expenses_account_type_available: boolean;
  expenses_account_types: ExpensesAccountTypeJson[];
  payment_gateway_members: PaymentGatewayMember[] | undefined;
  traveler_candidates: UserJson[];
}

interface SearchQueryResponse {
  queries: { peoplenum: number; roomnum: number; stay_days: number; search_type: SearchType } | null;
  search_query_id: never;
}

interface NonOrderItemWithIndex extends NonOrderItem {
  index: number;
}

class TripsConfirm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      reserveInfo: null,
      trip: null,
      user: null,
      travelers: [],
      queries: null,
      searchQueryId: null,
      organizationBases: undefined,
      departments: undefined,
      useBulkTicket: undefined,
      useKyuusyuuTicket: undefined,
      showEx: undefined,
      loading: true,
      paymentLoading: false,
      steps: ['Step 1. 入力', 'Step 2. 確認', 'Step 3. 完了'],
      stepIndex: 0,
      shouldBlockNavigation: true,
      submitErrors: [],
      error: null,
      termsAgree: false,
      projectShareAvailability: false,
      prefecture: [],
      useSmartSearch: false,
      tripDuplications: []
    };
  }

  componentDidMount() {
    const storedSearchQueryId = sessionStorage.getItem('searchQueryId');
    Fetcher.post<SearchQueryResponse>('/search_queries', {
      query: this.props.location.state.query,
      search_result: this.props.location.state?.params?.search_result,
      search_query_id: storedSearchQueryId
    }).then(
      queryResult => {
        if (queryResult.search_query_id) {
          sessionStorage.setItem('searchQueryId', queryResult.search_query_id);
          this.setState({ searchQueryId: queryResult.search_query_id });
        }

        Fetcher.post<TripsConfirmResponse>(
          `${location.href}.json`,
          objectToFormData(this.props.location.state.params)
        ).then(
          async result => {
            const trip = new Trip(result.trip);
            if (!trip.draft) {
              alert('この旅程は既に申請済みです。');
              this.props.history.push('/trips');
            }
            const approvers = _.sortBy(result.approvers, 'approve_stage');
            if (!_.isUndefined(result.user.department)) {
              result.user.department.approvers = approvers;
            }
            const user = new User(result.user);
            const members = _.map(result.traveler_candidates, u => new User(u));
            const travelers = TravelerList.fromTravelerInformations({
              informations: trip.travelerInformations,
              user,
              members,
              peoplenum: trip.travelerInformations.list.length
            });
            const marginTypes = new MarginTypeList(result.margin_types);
            const rentalCarLimitType = new RentalCarLimitType(result.rental_car_limit_type);
            // eslint-disable-next-line
            // @ts-ignore
            const nonOrderItemsWithIndex: NonOrderItemWithIndex[] = (trip?.nonOrderItems || []).map(
              (item, index) => ({
                ...item,
                index
              })
            );
            const reserveInfo = new ReserveInfo({
              reservingTrip: new ReservingTrip({
                packageType: result.type,
                peopleNum: travelers.list.length,
                rentalCars: result.rental_cars,
                showFee: result.show_fee,
                marginTypes,
                rentalCarLimitType,
                startTime: trip.startTime,
                endTime: trip.endTime,
                nonOrderItems: nonOrderItemsWithIndex,
                items: _.map(
                  result.items,
                  item => new ReservingTripItem(_.merge(item, { showFee: result.show_fee, marginTypes }))
                )
              }),
              roomNum: utils.dig(queryResult.queries, 'roomnum') || 0,
              type: result.type,
              packageType: result.package_type,
              packageProvider: result.package_provider || null,
              packageSearchCondition: result.package_search_condition,
              travelers,
              user,
              foreignExist: result.foreign_exist,
              domesticExist: result.domestic_exist,
              shinkansenAddress: result.shinkansen_address,
              shinkansenAddressee: result.shinkansen_addressee,
              shinkansenAddressType: result.shinkansen_address_type,
              postcode: result.postcode,
              carType: result.car_type,
              rentalCarAvailable: result.rentalcar_available,
              rentalCars: result.rental_cars,
              paymentMethodOptions: result.user.organization.payment_method_type,
              paymentMethodType: result.user.organization.payment_method_type !== 'card' ? 'billing' : 'card',
              paymentGatewayMembers: result.payment_gateway_members,
              paymentGatewayType: result.user.organization.payment_gateway_type,
              paymentTransaction: {
                uid:
                  result.user.organization.payment_method_type === 'card' &&
                  result.payment_gateway_members &&
                  result.payment_gateway_members.length > 0
                    ? result.payment_gateway_members[0].uid
                    : 'new'
              },
              departments: result.departments.map(d => new Department(d)),
              chargingDepartmentShares: new ChargingDepartmentShareList(result.charging_department_shares),
              chargingDepartment:
                result.trip.charging_departments.length > 0
                  ? new Department(result.trip.charging_departments[0])
                  : undefined,
              projects: new ProjectList(result.projects),
              projectId: result.trip.projects.length > 0 ? result.trip.projects[0].id : undefined,
              projectShares: new ProjectShareList(result.project_shares),
              expensesAccountTypes: result.expenses_account_types.map(e => new ExpensesAccountType(e)),
              orderItemMappingArgsList: result.order_item_mapping_args,
              searchQueryId: queryResult.search_query_id,
              finalDestination: result.trip.final_destination,
              purpose: result.trip.purpose,
              allTravelersNotification: result.all_travelers_notification,
              notifiedUsers: result.notified_users.map(user => new Traveler(user)),
              hasMembers: result.has_members,
              consignmentSale: result.consignment_sale,
              loginUserId: result.login_user_id,
              rentalCarLimitType,
              internalNumber: result.internal_number,
              nationalities: result.nationalities,
              expensesAccountTypeAvailable: result.expenses_account_type_available,
              useDefaultWorkflow: result.use_default_workflow,
              workflowStyle: result.workflow_style,
              approveItemValues: result.trip.trip_approve_item
                ? new Map<number, string>(
                    JSON.parse(result.trip.trip_approve_item.json).map((item: TripApproveItemJson) => [
                      item.id,
                      item.value
                    ])
                  )
                : undefined,
              approveItemValueCodes: result.trip.trip_approve_item
                ? new Map<number, string>(
                    JSON.parse(result.trip.trip_approve_item.json).map((item: TripApproveItemJson) => [
                      item.id,
                      item.valueCode
                    ])
                  )
                : undefined
            });

            if (result.trip.trip_approve_item) {
              reserveInfo.fetchAttachedApproveItemFiles(trip.id, JSON.parse(result.trip.trip_approve_item.json));
            }
            await reserveInfo.fetchApproveItems('before_business_trip');
            this.setState({
              reserveInfo,
              trip,
              user,
              travelers,
              queries: queryResult.queries,
              searchQueryId: queryResult.search_query_id,
              departments: result.departments.map(d => new Department(d)),
              organizationBases: result.organization_bases.map(o => new OrganizationBase(o)),
              useBulkTicket: result.use_bulk_ticket,
              useKyuusyuuTicket: result.use_kyuusyuu_ticket,
              showEx: result.show_ex,
              projectShareAvailability: result.project_share_availability,
              prefecture: result.prefecture.map(r => new Prefecture(r)),
              loading: false,
              useSmartSearch: result.use_smart_search,
              tripDuplications: result.trip_duplications
            });
          },
          () => {
            this.setState({
              error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
            });
          }
        );
      },
      () => {
        this.setState({
          error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        });
      }
    );
  }

  componentDidUpdate() {
    // 未保存離脱の警告をPromptで実装しているが、再読み込みとタブの終了に対応していないため
    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }

  componentWillUnmount() {
    sessionStorage.removeItem('searchQueryId');
  }

  getApplicant() {
    let applicant = _.first(this.state.travelers);
    if (_.isNil(applicant) || _.isNil(utils.dig(applicant, 'id'))) {
      applicant = new Traveler(this.state.user);
    }
    return applicant;
  }

  itemsTotalPrice() {
    if (!this.state.reserveInfo) return 0;

    const prices = _.map(
      utils.dig(this.state.reserveInfo.reservingTrip, 'items') || [],
      item => (item && item.totalPrice()) || 0
    );
    return _.sum(prices);
  }

  itemsPricePerType(type: 'shinkansen' | 'air' | 'rental_car' | 'hotel') {
    if (!this.state.reserveInfo) return 0;

    const elements = _.map(
      utils.dig(this.state.reserveInfo.reservingTrip, 'items') || [],
      item => item && item.element
    );
    const filteredElements = (() => {
      switch (type) {
        case 'shinkansen':
          return _.filter(elements, e => e instanceof Transit && e.shinkansen);
        case 'air':
          return _.filter(elements, e => (e instanceof Transit && e.air) || e instanceof Flight);
        case 'hotel':
          return _.filter(elements, e => e instanceof Hotel);
        default:
          return [];
      }
    })();
    if (filteredElements.length === 0) {
      return 0;
    }
    const prices = _.map(filteredElements, e => (e && e.totalPrice()) || 0);
    return _.sum(prices);
  }

  isCustomerInfoRequired = () => !!this.state.user && !!this.state.user.organization;

  handleBackToStep1Click = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    this.setState(
      {
        shouldBlockNavigation: false
      },
      () => {
        this.props.history.push(`${location.pathname}#`);
        this.setState({
          shouldBlockNavigation: true,
          stepIndex: 0
        });
      }
    );
  };

  handleValidationSuccess = () => {
    this.setState(
      {
        shouldBlockNavigation: false
      },
      () => {
        this.props.history.push(`${location.pathname}#step2`);
        this.setState({
          shouldBlockNavigation: true,
          stepIndex: 1
        });
      }
    );
    return utils.scrollToTop();
  };

  handleSaveDraft = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    this.handleSubmit(true);
  };

  handleConfirm = () => {
    this.handleSubmit(false);
  };

  handleSubmit(asDraft?: boolean) {
    this.setState({
      paymentLoading: true
    });
    const params = {
      as_draft: asDraft,
      payment_type: utils.dig(this.state.reserveInfo, 'paymentType') || 'billing',
      reserve_info: this.state.reserveInfo ? this.state.reserveInfo.submitParams() : null,
      user_id: this.state.trip.user_id,
      travelers: this.state.travelers.getTravelersParam(),
      title: this.state.trip.title,
      departure_time: this.state.trip.startTime.format('YYYY-MM-DD HH:mm ZZ'),
      return_time: this.state.trip.endTime.format('YYYY-MM-DD HH:mm ZZ'),
      applicant_id: utils.dig(this.getApplicant(), 'id'),
      search_query_id: this.state.searchQueryId,
      non_order_items: _.compact(_.map(this.state.trip.nonOrderItems, item => item.updateParams()))
    };

    let path = '/trips';
    let method: 'POST' | 'PUT' = 'POST';
    if (this.state.trip.id) {
      path += `/${this.state.trip.id}`;
      method = 'PUT';
    }

    Fetcher.request({ url: `${path}.json`, method, data: objectToFormData(params) })
      .then(
        () => {
          this.setState(
            {
              shouldBlockNavigation: false
            },
            () => {
              location.href = '/trips';
            }
          );
        },
        e => {
          let errors;
          if (e instanceof HTTPError && e.response?.data.errors) {
            errors = e.response?.data.errors;
          } else {
            errors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
          }
          this.setState({
            submitErrors: errors
          });
        }
      )
      .finally(() => {
        this.setState({
          paymentLoading: false
        });
      });
  }

  clickBackLink = () => {
    this.setState(
      {
        shouldBlockNavigation: false
      },
      () => {
        this.props.history.push({
          pathname: this.state.trip.id ? `/trips/${this.state.trip.id}/edit` : '/trips/new',
          state: this.backState()
        });
      }
    );
  };

  backState() {
    return {
      title: this.state.trip.title,
      travelers: this.state.travelers.getTravelersParam(),
      outdate: this.state.trip.startTime.format('YYYY-MM-DD HH:mm'),
      homedate: this.state.trip.endTime.format('YYYY-MM-DD HH:mm'),
      purpose: utils.dig(this.state.reserveInfo, 'purpose'),
      final_destination: utils.dig(this.state.reserveInfo, 'finalDestination'),
      non_order_items: _.compact(_.map(this.state.trip.nonOrderItems, item => item.updateParams())),
      search_query_id: this.state.searchQueryId
    };
  }

  handleAgreementTermsCheck = () => {
    this.setState({
      termsAgree: !this.state.termsAgree
    });
  };

  render() {
    const { serviceId } = this.props;
    const {
      reserveInfo,
      trip,
      queries,
      organizationBases,
      departments,
      useBulkTicket,
      useKyuusyuuTicket,
      showEx,
      loading,
      paymentLoading,
      steps,
      stepIndex,
      error,
      submitErrors,
      shouldBlockNavigation,
      termsAgree,
      projectShareAvailability,
      prefecture,
      useSmartSearch,
      tripDuplications
    } = this.state;
    const paymentFailed = false;
    const paymentErrors = '';

    try {
      return (
        <Wrap>
          <Prompt
            when={shouldBlockNavigation}
            message="保存されていないデータが失われます。本当によろしいですか？"
          />
          <BodyWrap>
            <div>
              <StepBarWrap>
                <StepBar steps={steps} index={stepIndex} />
              </StepBarWrap>
              <ConfirmBody>
                <BodyIn>
                  {loading ? (
                    <LoadingWrap>
                      <SimpleLoading />
                    </LoadingWrap>
                  ) : error ? (
                    <LoadingWrap>
                      <Error>{error}</Error>
                    </LoadingWrap>
                  ) : (
                    <>
                      <BodyHeader>
                        <Title>
                          旅程番号：<span data-wovn-ignore>{trip.id || '未発行'}</span>
                          <br />
                          旅程名：<span data-wovn-ignore>{trip.title}</span>
                          <br />
                          出張日時：<span data-wovn-ignore>{trip.dateRange()}</span>
                        </Title>
                      </BodyHeader>
                      <ItemsBlock>
                        <ItemsBlockHeader>
                          <BlockTitle>【予約内容内訳】</BlockTitle>
                          <ItemsTotalPrice>
                            合計金額：
                            <Price data-wovn-ignore>
                              {utils.formatPrice(this.itemsTotalPrice() + trip.nonOrderItemsTotalPrice())}
                            </Price>
                          </ItemsTotalPrice>
                        </ItemsBlockHeader>
                        {useSmartSearch && (
                          <ItemsSection>
                            <ItemsTitle>□■AI トラベル手配分■□</ItemsTitle>
                            <ItemsContent>
                              <ItemsCost>
                                <ItemsCostDetail>
                                  <ItemsCostTitle>【交通費】</ItemsCostTitle>
                                  <ItemsCostTable>
                                    <tbody>
                                      <ItemsCostTableTr>
                                        <ItemsCostTableTh>新幹線</ItemsCostTableTh>
                                        <ItemsCostTableSeparator>：</ItemsCostTableSeparator>
                                        <ItemsCostTableTd data-wovn-ignore>
                                          {utils.formatPrice(this.itemsPricePerType('shinkansen'))}
                                        </ItemsCostTableTd>
                                      </ItemsCostTableTr>
                                      <ItemsCostTableTr>
                                        <ItemsCostTableTh>航空機</ItemsCostTableTh>
                                        <ItemsCostTableSeparator>：</ItemsCostTableSeparator>
                                        <ItemsCostTableTd data-wovn-ignore>
                                          {utils.formatPrice(this.itemsPricePerType('air'))}
                                        </ItemsCostTableTd>
                                      </ItemsCostTableTr>
                                      <ItemsCostTableTr>
                                        <ItemsCostTableTh>レンタカー</ItemsCostTableTh>
                                        <ItemsCostTableSeparator>：</ItemsCostTableSeparator>
                                        <ItemsCostTableTd data-wovn-ignore>
                                          {utils.formatPrice(this.itemsPricePerType('rental_car'))}
                                        </ItemsCostTableTd>
                                      </ItemsCostTableTr>
                                    </tbody>
                                  </ItemsCostTable>
                                  <ItemsCostTitle>【宿泊費】</ItemsCostTitle>
                                  <ItemsCostTable>
                                    <tbody>
                                      <ItemsCostTableTr>
                                        <ItemsCostTableTh>宿泊費</ItemsCostTableTh>
                                        <ItemsCostTableSeparator>：</ItemsCostTableSeparator>
                                        <ItemsCostTableTd data-wovn-ignore>
                                          {utils.formatPrice(this.itemsPricePerType('hotel'))}
                                        </ItemsCostTableTd>
                                      </ItemsCostTableTr>
                                    </tbody>
                                  </ItemsCostTable>
                                </ItemsCostDetail>
                                <ItemsCostSum>
                                  ①合計：
                                  <span data-wovn-ignore>{utils.formatPrice(this.itemsTotalPrice())}</span>
                                </ItemsCostSum>
                              </ItemsCost>
                              <ItemsDetail>
                                <ItemsDetailTitle>旅程明細①</ItemsDetailTitle>
                                {(utils.dig(reserveInfo, 'reservingTrip', 'items') || []).map(
                                  (item: ReservingTripItem | undefined, i: number) =>
                                    item && (
                                      <ReserveConfirmOutlineItemTemplate
                                        isPackage={false}
                                        key={i}
                                        element={item.element}
                                        elementType={item.element_type}
                                        elementRaw={item.element_raw}
                                        foreignExist={item.foreignExist}
                                        // shinkansenTooLate={shinkansenTooLate}
                                        flightDescription={item.flightDescription()}
                                        minirule={item.minirule()}
                                        deadline={item.getDeadlineHour()}
                                        flightPrice={item.totalPrice() || 0}
                                        hotelPrice={item.totalPrice() || 0}
                                        peoplenum={queries ? queries.peoplenum : 0}
                                        roomnum={queries ? queries.roomnum : 0}
                                        travelers={reserveInfo!.travelers}
                                        index={item.index}
                                        domesticAirPriceIndex={item.domesticAirPriceIndex}
                                      />
                                    )
                                )}
                                <ItemsDetailSubTitle>
                                  【合計】
                                  <Price>{utils.formatPrice(this.itemsTotalPrice())}</Price>
                                </ItemsDetailSubTitle>
                              </ItemsDetail>
                            </ItemsContent>
                          </ItemsSection>
                        )}
                        <ItemsSection>
                          <ItemsTitle>□■個人手配分■□</ItemsTitle>
                          <ItemsContent>
                            <ItemsCost>
                              <ItemsCostDetail>
                                <ItemsCostTitle>【交通費】</ItemsCostTitle>
                                <ItemsCostTable>
                                  <tbody>
                                    <ItemsCostTableTr>
                                      <ItemsCostTableTh>新幹線</ItemsCostTableTh>
                                      <ItemsCostTableSeparator>：</ItemsCostTableSeparator>
                                      <ItemsCostTableTd data-wovn-ignore>
                                        {utils.formatPrice(trip.nonOrderItemsTotalPrice(['shinkansen']))}
                                      </ItemsCostTableTd>
                                    </ItemsCostTableTr>
                                    <ItemsCostTableTr>
                                      <ItemsCostTableTh>航空機</ItemsCostTableTh>
                                      <ItemsCostTableSeparator>：</ItemsCostTableSeparator>
                                      <ItemsCostTableTd data-wovn-ignore>
                                        {utils.formatPrice(
                                          trip.nonOrderItemsTotalPrice(['domestic_air', 'foreign_air'])
                                        )}
                                      </ItemsCostTableTd>
                                    </ItemsCostTableTr>
                                    <ItemsCostTableTr>
                                      <ItemsCostTableTh>レンタカー</ItemsCostTableTh>
                                      <ItemsCostTableSeparator>：</ItemsCostTableSeparator>
                                      <ItemsCostTableTd data-wovn-ignore>
                                        {utils.formatPrice(trip.nonOrderItemsTotalPrice(['rental_car']))}
                                      </ItemsCostTableTd>
                                    </ItemsCostTableTr>
                                    <ItemsCostTableTr>
                                      <ItemsCostTableTh>その他</ItemsCostTableTh>
                                      <ItemsCostTableSeparator>：</ItemsCostTableSeparator>
                                      <ItemsCostTableTd data-wovn-ignore>
                                        {utils.formatPrice(
                                          trip.nonOrderItemsTotalPrice(['railway_ticket', 'bus', 'taxi'])
                                        )}
                                      </ItemsCostTableTd>
                                    </ItemsCostTableTr>
                                  </tbody>
                                </ItemsCostTable>
                                <ItemsCostTitle>【宿泊費】</ItemsCostTitle>
                                <ItemsCostTable>
                                  <tbody>
                                    <ItemsCostTableTr>
                                      <ItemsCostTableTh>宿泊費</ItemsCostTableTh>
                                      <ItemsCostTableSeparator>：</ItemsCostTableSeparator>
                                      <ItemsCostTableTd data-wovn-ignore>
                                        {utils.formatPrice(trip.nonOrderItemsTotalPrice(['hotel']))}
                                      </ItemsCostTableTd>
                                    </ItemsCostTableTr>
                                  </tbody>
                                </ItemsCostTable>
                                <ItemsCostTitle>【その他】</ItemsCostTitle>
                                <ItemsCostTable>
                                  <tbody>
                                    <ItemsCostTableTr>
                                      <ItemsCostTableTh>Wifi</ItemsCostTableTh>
                                      <ItemsCostTableSeparator>：</ItemsCostTableSeparator>
                                      <ItemsCostTableTd data-wovn-ignore>
                                        {utils.formatPrice(trip.nonOrderItemsTotalPrice(['wifi']))}
                                      </ItemsCostTableTd>
                                    </ItemsCostTableTr>
                                    <ItemsCostTableTr>
                                      <ItemsCostTableTh>会議室</ItemsCostTableTh>
                                      <ItemsCostTableSeparator>：</ItemsCostTableSeparator>
                                      <ItemsCostTableTd data-wovn-ignore>
                                        {utils.formatPrice(trip.nonOrderItemsTotalPrice(['room']))}
                                      </ItemsCostTableTd>
                                    </ItemsCostTableTr>
                                  </tbody>
                                </ItemsCostTable>
                              </ItemsCostDetail>
                              <ItemsCostSum>
                                ②合計：
                                <span data-wovn-ignore>{utils.formatPrice(trip.nonOrderItemsTotalPrice())}</span>
                              </ItemsCostSum>
                            </ItemsCost>
                            <ItemsDetail>
                              <ItemsDetailTitle>旅程明細②</ItemsDetailTitle>
                              {_.sortBy(trip.nonOrderItems, item => item.startDateTime()).map(
                                (item: NonOrderItem, i: number) => (
                                  <ItemsDetailItem key={i}>
                                    <ItemsDetailSubTitle>
                                      {item.elements[0].type === 'hotel' ? '【宿泊】' : '【経路】'}
                                    </ItemsDetailSubTitle>
                                    {item.elements.map((element: TransportElement | HotelElement, j: number) => (
                                      <ItemsDetailElement key={j}>
                                        {element instanceof HotelElement ? (
                                          <>
                                            <ItemsDetailText data-wovn-ignore>{element.name}</ItemsDetailText>
                                            <ItemsDetailText data-wovn-ignore>
                                              <p>{element.summary()}</p>
                                              {element.address.length !== 0 && <p>{element.address}</p>}
                                              <p>{`${item.paymentTypeStr()}：${utils.formatPrice(
                                                item.totalPrice()
                                              )}`}</p>
                                            </ItemsDetailText>
                                          </>
                                        ) : (
                                          <>
                                            <div data-wovn-ignore>
                                              {`${element.boardingStation} ${element.from.dateTimeStr()} 発 -> ${
                                                element.arrivalStation
                                              } ${element.to.dateTimeStr()} 着`}
                                            </div>
                                            <ItemsDetailText data-wovn-ignore>{element.title()}</ItemsDetailText>
                                            {element.airlineName && (
                                              <ItemsDetailText data-wovn-ignore>
                                                {element.airlineName}
                                              </ItemsDetailText>
                                            )}
                                            <ItemsDetailText data-wovn-ignore>
                                              {`
                                              出発${
                                                ['railsway_ticket', 'shinkansen'].indexOf(element.transportType) >
                                                -1
                                                  ? '駅'
                                                  : '地'
                                              }:
                                              ${element.boardingStation}
                                              到着${
                                                ['railsway_ticket', 'shinkansen'].indexOf(element.transportType) >
                                                -1
                                                  ? '駅'
                                                  : '地'
                                              }:
                                              ${element.arrivalStation}
                                            `}
                                            </ItemsDetailText>
                                          </>
                                        )}
                                      </ItemsDetailElement>
                                    ))}
                                  </ItemsDetailItem>
                                )
                              )}
                              <ItemsDetailSubTitle>
                                【合計】
                                <Price>{utils.formatPrice(trip.nonOrderItemsTotalPrice())}</Price>
                              </ItemsDetailSubTitle>
                            </ItemsDetail>
                          </ItemsContent>
                        </ItemsSection>
                      </ItemsBlock>
                      {reserveInfo && shouldBlockNavigation && (
                        // 上のshouldBlockNavigationはステップを行き来するときに一時非表示にすることで中身の更新を促すため
                        <ReserveConfirmRoutingsTemplate
                          serviceId={serviceId}
                          isCustomerInfoRequired={this.isCustomerInfoRequired}
                          reserveInfo={reserveInfo}
                          termsAgree={termsAgree}
                          // projects={projects}
                          organizationBases={organizationBases}
                          departments={departments}
                          useBulkTicket={useBulkTicket}
                          useKyuusyuuTicket={useKyuusyuuTicket}
                          showEx={showEx}
                          handleValidationSuccess={this.handleValidationSuccess}
                          // approvers={approvers}
                          // shinkansenAddress={shinkansenAddress}
                          paymentLoading={paymentLoading}
                          paymentFailed={paymentFailed}
                          paymentErrors={paymentErrors}
                          // disconnected={disconnected}
                          handleBackToSelectClick={this.clickBackLink}
                          handleBackToStep1Click={this.handleBackToStep1Click}
                          handleSaveDraft={this.handleSaveDraft}
                          handlePaymentSubmit={this.handleConfirm}
                          handleAgreementTermsCheck={this.handleAgreementTermsCheck}
                          projectShareAvailability={projectShareAvailability}
                          prefecture={prefecture}
                          isOnTripsConfirm
                          tripRuleErrors={[]}
                          tripDuplications={tripDuplications}
                          tripDistanceAndTimeInfo={[]}
                          queries={queries!}
                          fromTripForm
                        />
                      )}
                    </>
                  )}
                  {submitErrors && (
                    <>
                      {submitErrors.map((error, i) => (
                        <Error key={i}>{error}</Error>
                      ))}
                    </>
                  )}
                </BodyIn>
              </ConfirmBody>
            </div>
          </BodyWrap>
        </Wrap>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const StepBarWrap = styled(ConfirmWrapHeader)`
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 20px 0;
`;

const ConfirmBody = styled(Body)``;

const BodyIn = styled.div`
  padding: 40px 20px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const BodyHeader = styled.div``;

const Title = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
`;

const BlockTitle = styled.div`
  font-weight: bold;
`;

const ItemsBlock = styled.div`
  margin-bottom: 50px;
  padding: 20px;
  border: solid 1px ${props => props.theme.grayBorderColor};
`;

const ItemsBlockHeader = styled.div`
  display: flex;
`;

const ItemsTotalPrice = styled.div`
  margin-left: auto;
  font-size: 16px;
  font-weight: bold;
`;

const ItemsSection = styled.div`
  padding-top: 20px;
`;

const ItemsTitle = styled.div`
  margin-bottom: 10px;
`;

const ItemsContent = styled.div`
  display: flex;
`;

const ItemsCost = styled.div`
  width: 60%;
  margin-right: 40px;
  font-size: 13px;
`;

const ItemsCostDetail = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  border: solid 1px ${props => props.theme.grayBorderColor};
`;

const ItemsCostSum = styled.div`
  padding: 10px;
  border: solid 1px ${props => props.theme.grayBorderColor};
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
`;

const ItemsCostTitle = styled.div`
  font-weight: bold;
`;

const ItemsCostTable = styled.table`
  width: auto;
  margin: 0 0 10px;
`;

const ItemsCostTableTr = styled.tr``;

const ItemsCostTableTh = styled.th`
  min-width: 100px;
  padding: 0 15px;
  border: 0;
  font-weight: normal;
`;

const ItemsCostTableSeparator = styled.td`
  padding: 0;
  border: 0;
`;

const ItemsCostTableTd = styled.td`
  width: 100px;
  padding: 0 15px;
  border: 0;
  text-align: right;
`;

const ItemsDetail = styled.div`
  width: 40%;
  padding: 20px;
  background: ${props => props.theme.grayBgColor};
  font-size: 13px;
`;

const ItemsDetailTitle = styled.div`
  margin-bottom: 15px;
  font-weight: bold;
`;

const ItemsDetailSubTitle = styled.div`
  font-weight: bold;
`;

const ItemsDetailItem = styled.div`
  margin-bottom: 15px;
  padding-left: 10px;
`;

const ItemsDetailElement = styled.div``;

const ItemsDetailText = styled.div``;

const Price = styled.span`
  color: ${props => props.theme.redColor};
  font-weight: bold;
`;

export default TripsConfirm;

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import { Button } from '@this/shared/ui/inputs/button';
import { Fetcher } from '@this/src/util';

interface ResultTableProps {
  sourceFilter: string;
  onSourceFilterChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  fromAirportFilter: string;
  onFromAirportChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  toAirportFilter: string;
  onToAirportChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  targetDateFilter: string;
  onTargetDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface DomesticAirFlightCacheResponse {
  data: DomesticAirFlightCache[];
}

interface DomesticAirFlightCache {
  id: number;
  from_airport: string;
  to_airport: string;
  target_date: string;
  departure_time: string;
  arrival_time: string;
  source: string;
  carrer: string;
  flight_number: string;
  changeable: boolean;
  ticket_type: string;
  seat_type: string;
  price: number;
  seat_count: string;
  created_at: string;
  updated_at: string;
  expire_at: string;
}

interface AirportData {
  from_airports: string[];
  to_airports: string[];
}

const ResultTable: React.FC<ResultTableProps> = ({
  sourceFilter,
  onSourceFilterChange,
  fromAirportFilter,
  onFromAirportChange,
  toAirportFilter,
  onToAirportChange,
  targetDateFilter,
  onTargetDateChange
}) => {
  const [flightCaches, setFlightCaches] = useState<DomesticAirFlightCache[]>([]);
  const [fromAirports, setFromAirports] = useState<string[]>([]);
  const [toAirports, setToAirports] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const handleDeleteAllCaches = async () => {
    if (confirm('本当にすべてのキャッシュを削除しますか？')) {
      try {
        await Fetcher.delete('/god/domestic_air_flight_caches');
        setFlightCaches([]);
      } catch (error) {
        setError('キャッシュデータの削除に失敗しました');
      }
    }
  };

  useEffect(() => {
    const fetchFlightCaches = async () => {
      try {
        setLoading(true);
        const params = new URLSearchParams();
        if (sourceFilter) params.append('source', sourceFilter);
        if (fromAirportFilter) params.append('from_airport', fromAirportFilter);
        if (toAirportFilter) params.append('to_airport', toAirportFilter);
        if (targetDateFilter) params.append('target_date', targetDateFilter);
        const response = await Fetcher.get<DomesticAirFlightCacheResponse>(
          `/god/domestic_air_flight_caches?${params.toString()}`
        );
        setFlightCaches(response.data);
      } catch (error) {
        setError('データの取得に失敗しました');
      } finally {
        setLoading(false);
      }
    };

    fetchFlightCaches();
  }, [sourceFilter, fromAirportFilter, toAirportFilter, targetDateFilter]);

  useEffect(() => {
    const fetchAirports = async () => {
      try {
        setLoading(true);
        const response = await Fetcher.get<AirportData>('/god/domestic_air/index_airports');
        setFromAirports(response.from_airports);
        setToAirports(response.to_airports);
      } catch (error) {
        setError('データの取得に失敗しました');
      } finally {
        setLoading(false);
      }
    };

    fetchAirports();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Container style={{ fontSize: '10px' }}>
      <Filters>
        <div>
          <label>ソース:</label>
          <select value={sourceFilter} onChange={onSourceFilterChange}>
            <option value="">すべて</option>
            <option value="jal_official">JAL</option>
            <option value="ana_official">ANA</option>
            <option value="sna_official">SNA</option>
            <option value="ado_official">ADO</option>
            <option value="sfj_official">SFJ</option>
            <option value="orc_official">ORC</option>
            <option value="amx_official">AMX</option>
            <option value="ibx_official">IBX</option>
            <option value="peach_official">PEACH</option>
            <option value="jetstar_official">JETSTAR</option>
          </select>
        </div>
        <div>
          <label htmlFor="from-airport">出発空港</label>
          <select id="from-airport" value={fromAirportFilter} onChange={onFromAirportChange}>
            <option value="">すべて</option>
            {fromAirports.map((airport, index) => (
              <option key={index} value={airport}>
                {airport}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="to-airport">到着空港</label>
          <select id="to-airport" value={toAirportFilter} onChange={onToAirportChange}>
            <option value="">すべて</option>
            {toAirports.map((airport, index) => (
              <option key={index} value={airport}>
                {airport}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="target-date">対象日</label>
          <input type="date" id="target-date" value={targetDateFilter} onChange={onTargetDateChange} />
        </div>
      </Filters>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleDeleteAllCaches}>キャッシュデータをすべて削除</Button>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>出発空港</th>
            <th>到着空港</th>
            <th>対象日(JTC)</th>
            <th>出発時刻(JST)</th>
            <th>到着時刻(JST)</th>
            <th>ソース</th>
            <th>キャリア</th>
            <th>便名</th>
            <th>変更可否</th>
            <th>券種</th>
            <th>席種</th>
            <th>価格</th>
            <th>残席数</th>
            <th>作成日時(UTC)</th>
            <th>更新日時(UTC)</th>
            <th>有効期限(UTC)</th>
          </tr>
        </thead>
        <tbody>
          {flightCaches.map(cache => (
            <tr key={cache.id}>
              <td>{cache.id}</td>
              <td>{cache.from_airport}</td>
              <td>{cache.to_airport}</td>
              <td>{cache.target_date}</td>
              <td>{cache.departure_time}</td>
              <td>{cache.arrival_time}</td>
              <td>{cache.source}</td>
              <td>{cache.carrer}</td>
              <td>{cache.flight_number}</td>
              <td>{cache.changeable ? '可' : '不可'}</td>
              <td>{cache.ticket_type}</td>
              <td>{cache.seat_type}</td>
              <td>{cache.price}</td>
              <td>{cache.seat_count}</td>
              <td>{cache.created_at}</td>
              <td>{cache.updated_at}</td>
              <td>{cache.expire_at}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

const Filters = styled.div`
  display: flex;
`;

export default observer(ResultTable);

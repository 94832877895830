import { Fetcher } from '@this/src/util';
import type { PlaceEntity } from '@this/domain/place/place.type';
import Place from '@this/domain/place/place';

export type PlaceItemType = 'hotel' | 'transport';

export type PlaceOptions = Record<string, string>;

export default class PlaceRepository {
  static async fetch(
    address?: string,
    placeName?: string,
    itemType?: PlaceItemType,
    options?: PlaceOptions
  ): Promise<Place | null> {
    if (!(address || placeName)) {
      return null;
    }

    const result = await Fetcher.get<PlaceEntity>('/place.json', {
      address,
      place_name: placeName,
      item_type: itemType,
      ...(options || {})
    });
    return new Place(result);
  }
}

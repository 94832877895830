import moment from 'moment';
import type ElementBaseInterface from '@this/domain/element_base_interface';

export type RoomElementJson = {
  id?: number;
  name?: string;
  address?: string;
  remark?: string;
  startedAt?: string;
  endedAt?: string;
  reservationNumber?: string;
};

class RoomElement implements ElementBaseInterface {
  id: number | undefined;

  name: string;

  type: string;

  startedAt: moment.Moment;

  endedAt: moment.Moment;

  address: string;

  remark: string;

  reservationNumber: string;

  constructor(args: RoomElementJson = {}) {
    this.id = args.id;
    this.type = 'room';
    this.startedAt = moment(args.startedAt);
    this.endedAt = args.endedAt ? moment(args.endedAt) : moment(this.startedAt).add(1, 'hour');
    this.name = args.name || '';
    this.address = args.address || '';
    this.remark = args.remark || '';
    this.reservationNumber = args.reservationNumber || '';
  }

  handleStartedAtChange(value: moment.Moment) {
    this.startedAt = value;
    return app.render();
  }

  handleEndedAtChange(value: moment.Moment) {
    this.endedAt = value;
    return app.render();
  }

  handleChangeName(value: string) {
    this.name = value;
    return app.render();
  }

  handleChangeAddress(value: string) {
    this.address = value;
    return app.render();
  }

  handleChangeRemark(value: string) {
    this.remark = value;
    return app.render();
  }

  handleReservationNumberChange(value: string) {
    this.reservationNumber = value;
    return app.render();
  }

  startDate() {
    return this.startedAt;
  }

  endDate() {
    return this.endedAt;
  }

  description() {
    return `\
<会議室>
■施設名：${this.name}
■住所：${this.address}
■ご利用日時：${this.startedAt.format('MM/DD HH:mm')}〜${this.endedAt.format('MM/DD HH:mm')}
■備考
${this.remark}\
`;
  }

  title() {
    return '会議室';
  }

  summary() {
    return `[会議室]${this.name}`;
  }

  detail() {
    return '';
  }

  structuredDetail() {
    return [];
  }

  mappingDescription() {
    return this.title();
  }

  validationErrors(orderItemStatus = 0) {
    const errors = [];
    const needStatusGuard = orderItemStatus === 7 || orderItemStatus === 13;
    if (this.name === '') {
      errors.push(this.roomValidationError());
    }
    if (this.endedAt < this.startedAt) {
      errors.push(this.timeValidationError());
    }
    if (this.reservationNumber === '' && needStatusGuard) {
      errors.push(this.reservationNumberValidationError());
    }
    return errors;
  }

  roomValidationError() {
    return '会議室の施設名を入力してください。';
  }

  timeValidationError() {
    return '利用開始以降を指定してください。';
  }

  reservationNumberValidationError() {
    return '予約番号を入力してください。';
  }

  updateParams() {
    return {
      type: this.type,
      started_at: this.startedAt.format('YYYY-MM-DD HH:mm:ss'),
      ended_at: this.endedAt.format('YYYY-MM-DD HH:mm:ss'),
      name: this.name,
      address: this.address,
      remark: this.remark,
      reservation_number: this.reservationNumber
    };
  }
}

export default RoomElement;

import _ from 'lodash';
import type ShareholderTicketArgs from '@this/domain/shareholder_ticket';
import ShareholderTicket from '@this/domain/shareholder_ticket';

export type ShareholderTicketListJson = Array<ShareholderTicketArgs>;

class ShareholderTicketList {
  allList: ShareholderTicket[];

  list: ShareholderTicket[];

  unavailableTicketsList: ShareholderTicket[];

  constructor(rawTickets: ShareholderTicketListJson = []) {
    this.allList = _.map(rawTickets, t => new ShareholderTicket(t)) || [];
    this.list = this.allList.reverse();
    this.unavailableTicketsList = _.filter(this.allList, t => t.isDeleted || t.isExpired);
  }

  find(id: number | string) {
    return _.find(this.list, t => t.id === parseInt(id.toString(), 10));
  }

  last() {
    return _.last(this.list);
  }

  push(t: ShareholderTicket) {
    return this.list.push(t);
  }

  unshift(t: ShareholderTicket) {
    return this.list.unshift(t);
  }

  remove(t: ShareholderTicket) {
    const i = this.list.indexOf(t);
    if (i > -1) {
      this.list.splice(i, 1);
    }
    if (typeof t.id !== 'undefined') {
      this.unavailableTicketsList.unshift(t);
    }
  }

  update(newTicket: ShareholderTicket) {
    const t = this.find(newTicket.id);
    if (t) {
      _.assign(t, newTicket);
    }
  }

  removeExpired(t: ShareholderTicket) {
    const i = this.list.indexOf(t);
    if (i > -1) {
      this.list.splice(i, 1);
    }
    return this.unavailableTicketsList.unshift(t);
  }

  totalByCompany(companyType: string) {
    const tickets = _.filter(this.list, st => st.companyStr() === companyType);
    return _.sumBy(tickets, st => st.price);
  }

  countByCompany(companyType: string) {
    const tickets = _.filter(this.list, st => st.companyStr() === companyType);
    return tickets.length;
  }
}

export default ShareholderTicketList;

import React from 'react';
import styled from 'styled-components';
import type { HotelJson } from '@this/domain/select_repository';
import type HotelStayPlan from '@this/domain/hotel/hotel_stay_plan';
import type MarginType from '@this/domain/organization/margin_type2';
import Tooltip from '@this/shared/tooltip/tooltip';
import { useSwitchText } from '@this/lib/hooks/useSwitchingText';
import {
  Area,
  BoxWrap,
  BoxPriceArea,
  BoxPrice,
  BoxSmall,
  BoxButton,
  LoadingArea,
  AreaText,
  NoVacancyText,
  NoVacancyWithinLimitArea,
  SpanBlock
} from '../transport_box/right/right_in_outline.template';
import { BoxButtonText, Yen } from '../shared/box_parts';

import HotelDetail, {
  HotelBox,
  HotelImageArea,
  HotelContentArea,
  Left,
  HotelName,
  HotelBody,
  Note,
  Rating,
  AnnounceOverLimit,
  FetchNewPriceText
} from './hotel_box_parts';

import type { SelectHotelBoxProps, SelectHotelBoxState } from './types';

type Props = SelectHotelBoxProps &
  SelectHotelBoxState & {
    handleChange: () => void;
    handleChangeHotelStayPlan: (
      hotel: HotelJson,
      selectedPlan: HotelStayPlan,
      showFee: boolean,
      marginType: MarginType | undefined
    ) => void;
    showCloseButton?: never;
    onClose?: never;
  };

const PriceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 26px 10px 26px 10px;
  height: 100%;
`;

const NoVacancyAreaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 12px 0 12px;
  height: 100%;
`;

export const BoxButtonArea = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: auto 13px auto 13px;
`;

export const BoxButtonRPArea = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  margin: auto 21px auto 13px;
  width: 100%;
`;

export const NoVacancy = styled(Area)`
  max-width: 100px;
  margin-right: 12px;
`;

const PriceAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledBoxPriceArea = styled(BoxPriceArea)`
  flex: 1;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceRow = styled.div`
  display: flex;
  align-items: center;
`;

const StayInfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

const InfoIcon = styled.span`
  margin-left: 4px;
`;

const StyledBoxButton = styled(BoxButton)`
  margin-left: 16px;
`;

const FetchNewPriceWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 16px 0 0 10px;
`;

const StyledFetchNewPriceText = styled(FetchNewPriceText)`
  font-size: 0.9em;
  color: #4a4a4a;
`;

export const OtherCandidateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const OtherCandidateText = styled.div`
  margin: 0 auto 0 auto;
  white-space: nowrap;
`;

const SelectHotelBoxTemplate: React.FC<Props> = ({
  me,
  destLocation,
  key,
  selected,
  inList,
  hotel,
  hotels,
  searchQueryId,
  serviceId,
  user,
  isDomestic,
  handleChange,
  handleChangeHotelStayPlan,
  hotelPriceLimit,
  isRequestForm,
  disabled
}) => {
  const loadingText = useSwitchText('hotel', hotel.loading);
  const showOverLimitHotel =
    (isDomestic && user.show_hotel_of_over_limit) || (!isDomestic && user.show_foreign_hotel_of_over_limit);
  const limit = hotelPriceLimit || 0;
  const isOverLimit = limit > 0 && hotel.getAveragePrice()! > limit;

  return (
    <HotelBox className="select-hotel-box" selected={selected} key={key}>
      <HotelImageArea hotel={hotel} />
      <HotelContentArea>
        <Left>
          <HotelName hotel={hotel} inList={inList} />
          <HotelBody>
            <HotelDetail
              me={me}
              destLocation={destLocation}
              inList={inList}
              user={user}
              hotel={hotel}
              hotels={hotels}
              hotelPriceLimit={hotelPriceLimit}
              searchQueryId={searchQueryId}
              serviceId={serviceId}
              handleChangeHotelStayPlan={handleChangeHotelStayPlan}
            />
          </HotelBody>
        </Left>
        <BoxWrap isRakutenPackage={hotel.package_type === 'RP'}>
          {!hotel.loading ? (
            !hotel.sold_out ? (
              !showOverLimitHotel && isOverLimit && hotel.operator_class_name === 'RakutenStaticfileOperator' ? (
                <NoVacancyWithinLimitArea>
                  <NoVacancyText>
                    <SpanBlock>規定金額以内の</SpanBlock>
                    <SpanBlock>お部屋がなくなり</SpanBlock>
                    ました
                  </NoVacancyText>
                  <AreaText>他の候補を選択してください</AreaText>
                </NoVacancyWithinLimitArea>
              ) : (
                hotel.package_type !== 'RP' && (
                  <PriceInfoContainer>
                    <Note hotel={hotel} hotelPriceLimit={hotelPriceLimit} />
                    <PriceAndButtonWrapper>
                      <StyledBoxPriceArea>
                        <PriceWrapper>
                          <PriceRow>
                            {hotel.package_type ? (
                              <>
                                <BoxPrice>
                                  {utils.digits(hotel.package_base_price || 0)}
                                  <Yen>円</Yen>
                                </BoxPrice>
                                <BoxSmall>/1名</BoxSmall>
                              </>
                            ) : (
                              <BoxPrice>
                                {utils.digits(hotel.getAveragePrice() || 0)}
                                <Yen>円</Yen>
                              </BoxPrice>
                            )}
                          </PriceRow>
                          <StayInfoRow>
                            <BoxSmall>/1泊1室(税別)</BoxSmall>
                            <InfoIcon>
                              <Tooltip type="info">
                                <div className="select__tooltip-format">
                                  {hotel
                                    .averagePriceTDetailText()
                                    .split('\n')
                                    .map((l: string, i: number) => (
                                      <p key={i}>{l}</p>
                                    ))}
                                </div>
                              </Tooltip>
                            </InfoIcon>
                          </StayInfoRow>
                        </PriceWrapper>
                        {hotel.isForeign() &&
                          (hotel.rated() ? <Rating rating={hotel.rating!} /> : <span>評価なし</span>)}
                        <AnnounceOverLimit isOverLimit={isOverLimit} loading={hotel.loading} />
                      </StyledBoxPriceArea>
                      {!isRequestForm && (
                        <StyledBoxButton
                          className="select-hotel-box__right-gray__button"
                          selected={selected}
                          disabled={disabled}
                          onClick={handleChange}
                        >
                          <BoxButtonText>
                            {selected ? ['変更を', <br key={0} />, 'キャンセル'] : '変更 >'}
                          </BoxButtonText>
                        </StyledBoxButton>
                      )}
                    </PriceAndButtonWrapper>
                    <FetchNewPriceWrapper>
                      <StyledFetchNewPriceText isOverLimit={isOverLimit} loading={hotel.loading} />
                    </FetchNewPriceWrapper>
                  </PriceInfoContainer>
                )
              )
            ) : (
              <PriceInfoContainer>
                <NoVacancyAreaContainer>
                  <NoVacancy>
                    <NoVacancyText>空室なし</NoVacancyText>
                  </NoVacancy>
                  <BoxButtonArea>
                    <BoxButton
                      className="select-hotel-box__right-gray__button"
                      selected={selected}
                      disabled={disabled}
                      onClick={handleChange}
                    >
                      <BoxButtonText>
                        {selected ? ['変更を', <br key={0} />, 'キャンセル'] : '変更 >'}
                      </BoxButtonText>
                    </BoxButton>
                  </BoxButtonArea>
                </NoVacancyAreaContainer>
                <OtherCandidateContainer>
                  <OtherCandidateText>他の候補を選択してください</OtherCandidateText>
                </OtherCandidateContainer>
              </PriceInfoContainer>
            )
          ) : (
            <LoadingArea>
              <img src="/images/loading.gif" width={35} height={35} alt="loading" />
              <AreaText>{loadingText}</AreaText>
            </LoadingArea>
          )}
          {hotel.package_type === 'RP' && !isRequestForm && (
            <BoxButtonRPArea>
              <BoxButton
                className="select-hotel-box__right-gray__button"
                selected={selected}
                disabled={disabled}
                onClick={handleChange}
              >
                <BoxButtonText>{selected ? ['変更を', <br key={0} />, 'キャンセル'] : '変更 >'}</BoxButtonText>
              </BoxButton>
            </BoxButtonRPArea>
          )}
        </BoxWrap>
      </HotelContentArea>
    </HotelBox>
  );
};

export default SelectHotelBoxTemplate;

import { Fetcher } from '@this/src/util';
import React from 'react';
import { styled } from '@this/constants/themes';
import { MidButton } from '@this/shared/atoms/button';
import { Input } from '@this/shared/ui/inputs/input';
import { FormControl, FormHelperText, InputLabel } from '@this/shared/ui/inputs/form_control';
import { reportError } from '@this/lib/bugsnag';
import { UserSignInArea } from '../user_signin/user_signin';
import { UserSignInBody, LogoContainer, BodyLogo } from '../user_signin/user_signin_body';
import { FormContainer, Form } from '../user_signin/user_signin_form';

interface Props {}

interface State {
  newPassword: string;
  newPasswordConfirmation: string;
  resetSuccess: string;
  resetErrors: string[];
  resetting: boolean;
  resetPasswordToken: string;
}

class UserPasswordReset extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      newPassword: '',
      newPasswordConfirmation: '',
      resetSuccess: '',
      resetErrors: [],
      resetting: false,
      resetPasswordToken: utils.getParam('reset_password_token')
    };
  }

  handlePasswordChange(v: string) {
    this.setState({ newPassword: v });
  }

  handleConfirmationChange(v: string) {
    this.setState({ newPasswordConfirmation: v });
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({ resetting: true });
    const params =
      location.pathname === '/admins/password/edit'
        ? {
            admin: this.resetPasswordParams()
          }
        : location.pathname === '/arrangers/password/edit'
        ? {
            arranger: this.resetPasswordParams()
          }
        : {
            user: this.resetPasswordParams()
          };

    Fetcher.put(location.pathname.replace('/edit', ''), params).then(
      result => {
        this.setState({
          resetting: false,
          resetErrors: [],
          resetSuccess: 'リセットに成功しました。ログインページにジャンプします...'
        });
        setTimeout(() => {
          location.href = utils.dig(result, 'redirect');
        }, 1000);
      },
      error => {
        if (error.response.data && error.response.data.errors) {
          this.setState({
            resetErrors: error.response.data.errors,
            resetting: false
          });
        } else {
          this.setState({
            resetErrors: ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'],
            resetting: false
          });
        }
      }
    );
  }

  resetPasswordParams() {
    return {
      new_password: this.state.newPassword,
      new_password_confirmation: this.state.newPasswordConfirmation,
      reset_password_token: this.state.resetPasswordToken
    };
  }

  render() {
    try {
      const { newPassword, newPasswordConfirmation, resetErrors, resetSuccess, resetting } = this.state;
      return (
        <UserSignInArea>
          <UserSignInBody>
            <div>
              <LogoContainer>
                <BodyLogo />
              </LogoContainer>
              <FormContainer>
                <Form onSubmit={e => this.handleSubmit(e)}>
                  <FormControl fullWidth error={resetErrors.length > 0}>
                    <InputLabel htmlFor="newPassword">新しいパスワード</InputLabel>
                    <Input
                      type="password"
                      id="newPassword"
                      placeholder="英(大小)数記号を含む8文字以上"
                      value={newPassword}
                      onChange={e => this.handlePasswordChange(e.target.value)}
                    />
                  </FormControl>

                  <FormControl margin="dense" error={resetErrors.length > 0} fullWidth>
                    <InputLabel htmlFor="newPasswordConfirmation">確認</InputLabel>
                    <Input
                      type="password"
                      id="newPasswordConfirmation"
                      placeholder="確認のためもう一度入力してください"
                      value={newPasswordConfirmation}
                      onChange={e => this.handleConfirmationChange(e.target.value)}
                    />
                    <FormHelperText>
                      {resetErrors.map(error => (
                        <p key={error}>{error}</p>
                      ))}
                    </FormHelperText>
                  </FormControl>

                  <Messages>
                    <Success>{resetSuccess}</Success>
                  </Messages>
                  <Button type="submit" value="リセット" disabled={resetting} />
                </Form>
              </FormContainer>
            </div>
          </UserSignInBody>
        </UserSignInArea>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const Messages = styled.div`
  margin-bottom: 8px;
  font-size: 12px;
  text-align: left;
`;

const Success = styled.div`
  color: ${props => props.theme.successColor};
`;

const Button = styled.input`
  &&& {
    ${MidButton}
    width: 100%;
  }
`;

export default UserPasswordReset;

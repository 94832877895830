import React, { useCallback, useEffect, useReducer } from 'react';

import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';

import { styled } from '@this/constants/themes';
import InfiniteScroll from '@this/src/components/shared/infinite_scroll/InfiniteScroll';
import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import ChatbotHistory, { convertChatbotHistoryArgs } from '@this/src/domain/chatbot/chatbot_history';
import type { ChatbotHistoryResponseArgs } from '@this/src/domain/chatbot/chatbot_history';

import { CHAT_MESSAGES_ID } from '../idList';
import fetchHistoriesApi from '../api/fetch_histories_api';
import ChatIcon from '../icons/ChatIcon';

interface Props {
  onClick: (sessionId: number) => void;
}

interface State {
  loading: boolean;
  histories: ChatbotHistory[];
  nextId: number | null;
  hasMore: boolean;
}

interface HistoriesResponse {
  chatbot_sessions: ChatbotHistoryResponseArgs[];
  next_id: number | null;
  has_more: boolean;
}

type Action = { type: 'SET_LOADING'; payload: boolean } | { type: 'SET_RESPONSE'; payload: HistoriesResponse };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_RESPONSE':
      return {
        ...state,
        loading: false,
        histories: [
          ...state.histories,
          ...action.payload.chatbot_sessions.map(args => new ChatbotHistory(convertChatbotHistoryArgs(args)))
        ],
        nextId: action.payload.next_id,
        hasMore: action.payload.has_more
      };
    default:
      return state;
  }
};

const initialState: State = {
  loading: false,
  histories: [],
  nextId: null,
  hasMore: true
};

export const ChatHistories: React.FC<Props> = ({ onClick }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading, histories, nextId, hasMore } = state;

  const fetchHistories = useCallback(
    (nextId: number | null) => {
      if (!nextId) dispatch({ type: 'SET_LOADING', payload: true });
      fetchHistoriesApi({
        nextId,
        onSuccess: response => {
          dispatch({ type: 'SET_RESPONSE', payload: response });
        }
      });
    },
    [dispatch]
  );

  const fetchMoreData = useCallback(() => {
    if (hasMore && nextId) fetchHistories(nextId);
  }, [nextId, hasMore]);

  useEffect(() => {
    fetchHistories(null);
  }, [fetchHistories]);

  if (loading) return <SimpleLoading />;

  return (
    <List>
      <InfiniteScroll
        dataLength={histories.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<SimpleLoading />}
        scrollableTarget={CHAT_MESSAGES_ID}
      >
        {histories.map(history => (
          <ListItem key={history.id} button onClick={() => onClick(history.id)}>
            <ListItemIcon>
              <IconContainer>
                <ChatIcon />
              </IconContainer>
            </ListItemIcon>
            <ListItemText primary={history.userMessage} secondary={history.createdAtString()} />
          </ListItem>
        ))}
      </InfiniteScroll>
    </List>
  );
};

const List = styled(MuiList)`
  margin: -16px;
`;

const ListItem = styled(MuiListItem)`
  gap: 12px;
  padding: 12px 16px;
  border-bottom: 1px solid ${props => props.theme.grayBorderColor};

  &:last-of-type {
    border-bottom: none;
  }
`;

const ListItemIcon = styled(MuiListItemIcon)`
  min-width: inherit;
`;

const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 100px;
  background: ${props => props.theme.grayBgColorLight};

  & > svg {
    width: 24px;
    height: 24px;
  }
`;

const ListItemText = styled(MuiListItemText)`
  display: flex;
  flex-direction: column-reverse;

  & > .MuiTypography-body1 {
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;

    line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  & > .MuiTypography-body2 {
    font-size: 11px;
    font-weight: 400;
    line-height: 16.5px;
  }
`;

/* eslint max-lines: ["error", {"max": 1200, "skipBlankLines": true, "skipComments": true}] */

import { createContext, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import ApproveItemList from '@this/domain/approve_item/approve_item_list';
import type ItemList from '@this/domain/approve_item/item_list';
import type { ImageFile } from 'react-dropzone';
import { Fetcher, HTTPError } from '@this/src/util';
import type { TripReportStatus, TripReportArgs, EditableFields, TripReportNewArgs } from './trip_report';
import { tripReportStatuses, TripReport, STATUS_NAMES } from './trip_report';
import { approvalStatuses, STATUS_NAMES as APPROVAL_STATUS, Approval } from './approval';
import type { ApprovalArgs, WorkflowStyle, ApprovalStatus, Stage } from './approval';
import { TripForReport } from './trip';
import type { OrderItemPriceDetailForReport } from './order_item_price_detail';
import type { TripForReportArgs, TripSelectResponse } from './trip_args';
import type { ProjectArgs } from '../project/project';
import Project from '../project/project';
import type { OrderItemCategoryOptions } from '../order_item';
import { convertNonOrderItemProps, NonOrderItemForReport } from './non_order_item';
import { convertAllowanceItemProps, AllowanceItemForReport } from './allowance_item';
import type { TripTypeOptions, CalcPriceOrderOptions } from './non_order_item';
import type Department from '../department/department';
import { ExpensesType } from '../expenses/expenses_type';
import type { ExpensesTypeJson } from '../expenses/expenses_type';
import type { BudgetArgs } from '../organization/budget';
import { Budget } from '../organization/budget';
import Setting from '../setting';
import type { SettingArgs } from '../setting';
import type { TaxTypeArgs } from '../tax_type';
import TaxType from '../tax_type';
import type TripApproveItemJson from '../trip_approve_item/trip_approve_item_json';
import type ApproveItem from '../approve_item/approve_item';
import { convertTripApproveItemToApproveItem } from '../approve_item/approve_item_from_trip_approve_item';

interface Props {
  serviceId: number;
  loading?: boolean;
  allowanceAvailable: boolean;
}

export type UpdateAction = 'draft' | 'apply';
export type ApproveAction = 'accept' | 'reject' | 'comment';
export type SearchExpensesStatus = 'uncreated' | 'created';

type UpdateCallback = {
  action: UpdateAction;
  onSuccess?: (id: number) => void;
};

type CancelCallback = {
  tripReportId: number;
  message: string;
  onSuccess?: (id: number) => void;
  onError?: (errors: string[]) => void;
};

type ApproveCallback = {
  actionType: ApproveAction;
  message?: string;
  onSuccess?: (id: number) => void;
  onError?: (errors: string[]) => void;
};

type CreateExpensesReportCallback = {
  tripReport: TripReport;
  onSuccess?: (id: number) => void;
  onError?: (errors: string[]) => void;
};

type DefaultAllowanceItemsResponse = {
  default_allowance_items: any[];
};

type IndexResponse = {
  trip_reports: TripReportArgs[];
  setting?: SettingArgs;
};

type CreateExpensesReportResponse = {
  success: boolean;
  id: number;
  expenses_report_id: number;
};

type UpdateResponse = {
  id: number;
};

type Step = 'tripReport' | 'tripSelect' | 'tripSelected' | 'confirm';

type ValidationFields = EditableFields | 'trips' | 'priceDetails' | 'approveItem';

type WorkflowOption = {
  name: string;
  workflow: Stage[];
};

export type TripSearchType = 'history' | 'ongoing';

export class TripReportStore {
  serviceId: number;

  currentTripReport: TripReport;

  approveItems: ApproveItemList;

  approveItemJson: string;

  approveItemValues: Map<number, string>;

  approveItemValueCodes: Map<number, string>;

  approveItemFiles: Map<number, ImageFile>;

  approveItemFilesBase64: Map<number, string>;

  tripReports: TripReport[] = [];

  trips: TripForReport[] = [];

  priceDetails: OrderItemPriceDetailForReport[] = [];

  nonOrderItems: NonOrderItemForReport[] = [];

  allowanceItems: AllowanceItemForReport[] = [];

  defaultAllowanceItems: AllowanceItemForReport[] = [];

  allowanceAvailable = false;

  approvals: Approval[] = [];

  approvalStages: Stage[] = []; // 承認ルート編集用

  budgets: Record<string, Budget> = {};

  removingPriceDetails: number[] = [];

  removingNonOrderItems: number[] = [];

  removingAllowanceItems: number[] = [];

  workflowStyle: WorkflowStyle | null = null;

  tripOptions: TripForReport[] = []; // 選択用

  projectOptions: Project[] = []; // 選択用

  expensesTypeOptions: ExpensesType[] = []; // 選択用

  taxTypeOptions: TaxType[] = []; // 選択用

  workflowOptions: WorkflowOption[] = []; // 選択用

  categoryOptions: OrderItemCategoryOptions = [];

  tripTypeOptions: TripTypeOptions = [];

  calcPriceOrderOptions: CalcPriceOrderOptions = [];

  statusEq: TripReportStatus | ApprovalStatus | '' = '';

  step: Step = 'tripReport';

  setting: Setting | null = null;

  selected: number | null = null; // tripId

  editing: number | null = null; // tripId

  itemEditing = false;

  loading = false;

  submitting = false;

  page = 1;

  totalPages = 0;

  tripSearchType: TripSearchType = 'history';

  messages: Record<number | never, string> = {};

  errors: string[] = [];

  validationErrors: Partial<Record<ValidationFields, string>> = {};

  validationMessages: string[] = [];

  approvalRequired = true;

  lastApprovalParams = '{}';

  constructor({ serviceId, loading, allowanceAvailable }: Props) {
    this.serviceId = serviceId;
    this.loading = loading !== undefined ? loading : false;
    this.allowanceAvailable = allowanceAvailable;
    this.currentTripReport = TripReport.initialize();
    this.approveItems = new ApproveItemList([]);
    this.approveItemJson = '';
    this.approveItemValues = new Map<number, string>();
    this.approveItemValueCodes = new Map<number, string>();
    this.approveItemFiles = new Map<number, ImageFile>();
    this.approveItemFilesBase64 = new Map<number, string>();
  }

  // ステップの変更
  setStep(step: Step) {
    this.step = step;
    this.errors = [];
    this.scrollToTop();

    if (step === 'tripReport') {
      this.selected = null;
      this.editing = null;
    }

    if (step === 'tripSelect') {
      this.selected = null;
      this.getSelect(1, 'history');
    }

    if (step === 'tripSelected') {
      this.getMenus();
      this.selectedPriceDetails().forEach(detail => {
        if (detail.projects.length < 1) detail.addProject();
      });
    }

    app.render();
  }

  // 選択中の編集モードへ移行
  setSelected(selected: number | null) {
    this.selected = selected;
    this.itemEditing = false;
    app.render();
  }

  // 旅程に紐づかない立替経費の編集へ移行
  setItemEditing(bool: boolean, { changeStep }: { changeStep?: boolean } = {}) {
    this.selected = null;
    this.itemEditing = bool;
    if (changeStep) this.setStep('tripSelected');
    app.render();
  }

  // 編集モードへ移行、編集に必要なデータの読み込みを同時に行う
  setEditing(editing: number | null) {
    this.step = 'tripSelected';
    this.errors = [];
    this.scrollToTop();

    this.editing = editing;
    this.itemEditing = false;
    this.getMenus();
    this.editingPriceDetails().forEach(detail => {
      if (detail.projects.length < 1) detail.addProject();
    });

    app.render();
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    app.render();
  }

  setSubmitting(submitting: boolean) {
    this.submitting = submitting;
    app.render();
  }

  setStatusEq(status: string, onChange?: () => void) {
    if (
      status === '' ||
      tripReportStatuses.includes(status as TripReportStatus) ||
      approvalStatuses.includes(status as ApprovalStatus)
    ) {
      this.statusEq = status as TripReportStatus | ApprovalStatus | '';
    }

    if (onChange) onChange();
  }

  setMessage(id: number, message: string) {
    this.messages[id] = message;
    app.render();
  }

  setApproveStages(stages: Stage[]) {
    this.approvalStages = stages;
    app.render();
  }

  resetValidation() {
    this.validationErrors = {};
    this.validationMessages = [];
    app.render();
  }

  // フォームに利用する旅程、商品、立替経費を一括で取得
  getFormTargets() {
    if (this.itemEditing) {
      return {
        trip: null,
        priceDetails: [],
        nonOrderItems: this.optionalNonOrderItems(),
        allowanceItems: this.optionalAllowanceItems()
      };
    }

    if (this.editing) {
      return {
        trip: this.editingTrip(),
        priceDetails: this.editingPriceDetails(),
        nonOrderItems: this.editingNonOrderItems(),
        allowanceItems: this.editingAllowanceItems()
      };
    }

    return {
      trip: this.selectedTrip(),
      priceDetails: this.selectedPriceDetails(),
      nonOrderItems: this.selectedNonOrderItems(),
      allowanceItems: this.selectedAllowanceItems()
    };
  }

  // 旅程に紐づかない立替経費を取得
  optionalNonOrderItems() {
    return this.nonOrderItems.filter(item => !item.tripId);
  }

  // 旅程に紐づかない立替経費の合計額を取得
  optionalNonOrderItemPrice() {
    return this.optionalNonOrderItems().reduce((prev, current) => prev + (current.price || 0), 0);
  }

  // 日当を取得
  optionalAllowanceItems() {
    return this.allowanceItems.filter(item => !item.tripId);
  }

  // 日当の合計額を取得
  optionalAllowanceItemPrice() {
    return this.optionalAllowanceItems().reduce((prev, current) => prev + (current.price || 0), 0);
  }

  optionalTotalPrice() {
    const totalAdvance = this.optionalNonOrderItems().reduce((prev, current) => prev + (current.price || 0), 0);
    const totalAllowance = this.optionalAllowanceItems().reduce((prev, current) => prev + (current.price || 0), 0);
    return {
      totalAdvance,
      totalAllowance,
      totalAmount: totalAdvance + totalAllowance
    };
  }

  // 旅程に紐づかない立替経費のプロジェクトを取得
  optionalNonOrderItemProjects() {
    return this.optionalNonOrderItems()
      .flatMap(item => item.project || [])
      .reduce(
        (projects, project) =>
          projects.findIndex(p => p.id === project.id) !== -1 ? projects : projects.concat(project),
        [] as Project[]
      );
  }

  // 選択中の旅程の金額の集計を取得
  tripPrices(tripId: number) {
    const totalPrice = this.priceDetails
      .filter(priceDetail => priceDetail.tripId === tripId)
      .reduce((prev, current) => prev + current.price, 0);
    const totalAdvance = this.nonOrderItems
      .filter(item => item.tripId === tripId)
      .reduce((prev, current) => prev + (current.price || 0), 0);
    const totalAllowance = this.allowanceItems
      .filter(item => item.tripId === tripId)
      .reduce((prev, current) => prev + (current.price || 0), 0);
    return {
      totalPrice,
      totalAdvance,
      totalAllowance,
      totalAmount: totalPrice + totalAdvance + totalAllowance
    };
  }

  workflowPrices(groupId: number) {
    if (this.workflowStyle === 'project') {
      return this.projectPrices(groupId);
    }

    return { totalPrice: 0, totalAdvance: 0, totalAllowance: 0, totalAmount: 0 };
  }

  // プロジェクトの金額の集計を取得
  projectPrices(projectId: number) {
    const totalPrice = this.priceDetails
      .filter(priceDetail => priceDetail.projects.find(project => project.projectId === projectId))
      .reduce((prev, current) => prev + current.price, 0);
    const totalAdvance = this.nonOrderItems
      .filter(item => item.projectId === projectId)
      .reduce((prev, current) => prev + (current.price || 0), 0);
    return {
      totalPrice,
      totalAdvance,
      totalAmount: totalPrice + totalAdvance
    };
  }

  // 全体の金額の合計を取得
  totalAmount() {
    const nonOrderItems = this.optionalNonOrderItems();
    return (
      this.trips.reduce((prev, current) => prev + this.tripPrices(current.id).totalAmount, 0) +
      nonOrderItems.reduce((prev, current) => prev + (current.price || 0), 0) +
      this.optionalAllowanceItems().reduce((prev, current) => prev + (current.price || 0), 0)
    );
  }

  // 編集中の旅程を取得
  editingTrip() {
    if (!this.editing) return null;
    return this.trips.find(trip => trip.id === this.editing);
  }

  // 編集中の旅程の商品を取得
  editingPriceDetails() {
    if (!this.editing) return [];
    return this.priceDetails.filter(detail => detail.tripId === this.editing);
  }

  // 編集中の旅程の立替経費を取得
  editingNonOrderItems() {
    if (!this.editing) return [];
    return this.nonOrderItems.filter(item => item.tripId === this.editing);
  }

  // 編集中の旅程の日当を取得
  editingAllowanceItems() {
    if (!this.editing) return [];

    const editingAllowance = this.allowanceItems.filter(item => item.tripId === this.editing);
    if (editingAllowance.length > 0) return editingAllowance;

    this.allowanceItems = this.allowanceItems.concat(this.defaultAllowanceItems);
    const concatDefaultAllowance = this.allowanceItems.filter(item => item.tripId === this.editing);
    if (concatDefaultAllowance.length > 0) return concatDefaultAllowance;

    return this.defaultAllowanceItems;
  }

  // 選択中の旅程を取得
  selectedTrip() {
    if (!this.selected) return null;
    return this.tripOptions.find(trip => trip.id === this.selected);
  }

  // 選択中の旅程の商品を取得
  selectedPriceDetails() {
    const newTrip = this.selectedTrip();
    if (!newTrip) return [];

    return newTrip.orderItemPriceDetails;
  }

  // 選択中の旅程の立替経費を取得
  selectedNonOrderItems() {
    const newTrip = this.selectedTrip();
    if (!newTrip) return [];

    return newTrip.nonOrderItems;
  }

  // 選択中の旅程の日当を取得
  selectedAllowanceItems() {
    const newTrip = this.selectedTrip();
    if (!newTrip) return [];

    newTrip.allowanceItems = this.defaultAllowanceItems;
    return newTrip.allowanceItems;
  }

  // 選択中の旅程を出張報告に取り込む
  insertSelectedTrip() {
    const newTrip = this.selectedTrip();
    if (!newTrip) return;

    this.trips = [...this.trips, newTrip];
    this.priceDetails = [
      ...this.priceDetails,
      ...newTrip.orderItemPriceDetails.map(detail => {
        if (detail.projects.length < 1) detail.addProject();
        return detail;
      })
    ];
    this.nonOrderItems = [...this.nonOrderItems, ...newTrip.nonOrderItems];
    this.allowanceItems = [...this.allowanceItems, ...newTrip.allowanceItems];
    app.render();
  }

  // 旅程を出張報告から削除、紐づく商品・立替経費を削除
  removeTrip(tripId: number) {
    _.remove(this.trips, trip => trip.id === tripId);
    this.removingPriceDetails = this.removingPriceDetails.concat(
      _.remove(this.priceDetails, priceDetail => priceDetail.tripId === tripId)
        .filter(priceDetail => priceDetail.id)
        .map(priceDetail => priceDetail.id)
    );
    this.removingNonOrderItems = this.removingNonOrderItems.concat(
      _.remove(this.nonOrderItems, item => item.tripId === tripId)
        .filter(item => item.id)
        .map(item => item.id || 0)
    );
    this.removingAllowanceItems = this.removingAllowanceItems.concat(
      _.remove(this.allowanceItems, item => item.tripId === tripId)
        .filter(item => item.id)
        .map(item => item.id || 0)
    );
    app.render();
  }

  // 立替経費を出張報告に追加、選択中旅程の変更中の場合は旅程に追加
  addNonOrderItem() {
    const tripId = this.editing || this.selected;
    const trip = this.editing ? this.editingTrip() : this.selectedTrip();

    const nonOrderItem = new NonOrderItemForReport(
      convertNonOrderItemProps({
        tripId: tripId || undefined,
        expensesType: this.expensesTypeOptions[0]
      }),
      {
        defaultTime: trip?.startTime || trip?.endTime || this.nonOrderItems.slice(-1)[0]?.time || moment(),
        adding: true
      }
    );
    // NonOrderItemForReportインスタンス化後、projectを設定する
    // 既にtrip.projectsがインスタンス化してあるため
    if (trip?.projects) {
      nonOrderItem.setProject(trip?.projects?.[0]);
    }

    if (this.selected && trip) {
      trip.nonOrderItems.push(nonOrderItem);
    } else {
      this.nonOrderItems.push(nonOrderItem);
    }

    app.render();
  }

  addAllowanceItem() {
    const tripId = this.editing || this.selected;
    const trip = this.editing ? this.editingTrip() : this.selectedTrip();

    const allowanceItem = new AllowanceItemForReport(
      convertAllowanceItemProps({
        tripId: tripId || undefined
      }),
      {
        defaultTime: trip?.startTime || trip?.endTime || this.allowanceItems.slice(-1)[0]?.time || moment(),
        adding: true
      }
    );

    if (this.selected && trip) {
      trip.allowanceItems.push(allowanceItem);
    } else {
      this.allowanceItems.push(allowanceItem);
    }

    app.render();
  }

  // 立替経費を出張報告から削除、選択中旅程の変更中の場合は旅程から削除
  removeNonOrderItem(nonOrderItem: NonOrderItemForReport) {
    if (!nonOrderItem.tripId || this.editing) {
      if (nonOrderItem.id) this.removingNonOrderItems.push(nonOrderItem.id);
      nonOrderItem.id = -1;
      const index = this.nonOrderItems.findIndex(item => item.id === -1);
      if (index !== -1) this.nonOrderItems.splice(index, 1);
    } else {
      this.selectedTrip()?.removeNonOrderItem(nonOrderItem);
    }
    app.render();
  }

  removeAllowanceItem(allowanceItem: AllowanceItemForReport) {
    if (!allowanceItem.tripId || this.editing) {
      if (allowanceItem.id) this.removingAllowanceItems.push(allowanceItem.id);
      allowanceItem.id = -1;
      const index = this.allowanceItems.findIndex(item => item.id === -1);
      if (index !== -1) this.allowanceItems.splice(index, 1);
    } else {
      this.selectedTrip()?.removeAllowanceItem(allowanceItem);
    }
    app.render();
  }

  selectStatusMenu(): { status: TripReportStatus | ''; label: string }[] {
    return [
      { status: 'applying', label: STATUS_NAMES.applying },
      { status: 'send_back', label: STATUS_NAMES.send_back },
      { status: 'draft', label: STATUS_NAMES.draft },
      { status: '', label: 'すべて' }
    ];
  }

  selectApprovalStatusMenu(): { status: TripReportStatus | ApprovalStatus | ''; label: string }[] {
    return [
      { status: 'applied', label: APPROVAL_STATUS.applied },
      { status: 'send_back', label: APPROVAL_STATUS.rejected },
      { status: '', label: 'すべて' }
    ];
  }

  selectExpensesStatusMenu(): { status: SearchExpensesStatus | ''; label: string }[] {
    return [
      { status: 'uncreated', label: '未登録' },
      { status: 'created', label: '登録済み' },
      { status: '', label: 'すべて' }
    ];
  }

  unNullableApprovalStages() {
    return this.approvalStages
      .map(({ stage, approvers }) => ({
        stage,
        approvers: approvers.filter(approver => approver).map(approver => ({ id: approver?.id }))
      }))
      .filter(({ approvers }) => approvers.length > 0);
  }

  approvalGroups() {
    return Approval.approvalGroups(this.approvals);
  }

  approvalGroupBudgets(approvalGroups: Approval[][]) {
    if (!this.workflowStyle || this.workflowStyle === 'trip_report') return [];

    const approved = this.currentTripReport.status === 'approved';

    return approvalGroups.map(approvals => {
      const total = this.workflowPrices(approvals[0].groupId || 0);
      const budget = this.budgets[approvals[0].groupId || 0];
      const usedBudgets = budget?.usedBudgets() || 0;

      // TODO: OrderItemの消化予算への加算タイミング変更した際に、OrderItem分を計算に含めないように変更する
      // https://aitravel.atlassian.net/browse/AITRAVEL-3382?focusedCommentId=24524
      const currentTotal = approved ? usedBudgets : total.totalAmount + usedBudgets;

      return {
        total: total.totalAmount,
        budget,
        remainingBudget: budget ? budget.totalBudget - usedBudgets : null,
        percent: budget ? ((total.totalAmount / (budget.totalBudget || 1)) * 100).toFixed() : null,
        isOver: budget ? currentTotal > budget.totalBudget : false
      };
    });
  }

  currentProjects() {
    const orderProjects = this.priceDetails.reduce((prev, detail) => {
      detail.projects.forEach(project => {
        if (project.project?.id && !prev[project.project.id]) prev[project.project.id] = project.project;
      });
      return prev;
    }, {} as Record<number, Project>);

    const nonOrderProjects = this.nonOrderItems.reduce((prev, item) => {
      if (item.project?.id && !prev[item.project.id]) prev[item.project.id] = item.project;
      return prev;
    }, orderProjects);

    return Object.values(nonOrderProjects);
  }

  // TODO: 部署による承認に対応
  currentDepartments() {
    return [] as Department[];
  }

  setApproveItemValue(id: number, value: string) {
    this.approveItemValues.set(id, value);
    app.render();
  }

  getApproveItemValue(id: number) {
    return this.approveItemValues.get(id);
  }

  setApproveItemValueCode(id: number, value: string) {
    this.approveItemValueCodes.set(id, value);
    app.render();
  }

  getApproveItemValueCode(id: number) {
    return this.approveItemValueCodes.get(id);
  }

  getApproveItemFile(id: number) {
    return this.approveItemFiles.get(id);
  }

  async setApproveItemFile(id: number, file: ImageFile) {
    this.approveItemFiles.set(id, file);
    this.approveItemValues.set(id, file.name);
    this.approveItemFilesBase64.set(id, await this.convertFileToBase64(file));
    app.render();
  }

  async convertFileToBase64(file: ImageFile) {
    if (file) {
      const result = await new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result as string);
        };
        fileReader.onerror = error => {
          reject(error);
        };
      });
      return result as string;
    }
    return '';
  }

  removeApproveItemFile(id: number) {
    this.approveItemFiles.delete(id);
    this.approveItemValues.delete(id);
    this.approveItemFilesBase64.delete(id);
    app.render();
  }

  getApproveItemFileBase64(id: number) {
    return this.approveItemFilesBase64.get(id);
  }

  getApproveItemFileType(id: number) {
    const file = this.getApproveItemFile(id);
    if (file) {
      return file.type;
    }
    return '';
  }

  isApprovalRequired(requiredType: string) {
    if (requiredType === 'required') {
      return true;
    }
    return false;
  }

  handleApproveCalendarSelect = (id: number) => (date: moment.Moment) => {
    if (date !== null) {
      this.setApproveItemValue(id, date.format('YYYY-MM-DD'));
    } else {
      (this as any).setApproveItemValue(id, null);
    }
  };

  handleApproveListChange = (id: number) => (itemList: ItemList | null) => {
    (this as any).setApproveItemValueCode(id, itemList?.code);
    (this as any).setApproveItemValue(id, itemList?.name);
  };

  handleApproveFileChange = (id: number, file?: ImageFile) => {
    (this as any).setApproveItemFile(id, file);
  };

  handleApproveFileRemove = (id: number) => {
    this.removeApproveItemFile(id);
  };

  getChargingDepartmentIDs() {
    return [];
  }

  // 出張報告の一覧データをAPIから取得
  getIndex() {
    this.setLoading(true);
    const params = this.statusEq === '' ? '' : `?status=${this.statusEq}`;
    Fetcher.get<IndexResponse>(`/trip_report.json${params}`)
      .then(result => {
        this.tripReports = result.trip_reports.map(trip => new TripReport(trip));
        if (result.setting) this.setting = new Setting(result.setting);
      })
      .catch(() => {
        this.errors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        this.loading = false;
        app.render();
      });
  }

  // ビズトラの経費登録可能な出張報告の一覧データをAPIから取得
  getExpensesIndex(status?: SearchExpensesStatus | '') {
    this.setLoading(true);
    const params = status === '' ? '' : `?status=${status || 'uncreated'}`;
    Fetcher.get<IndexResponse>(`/trip_report/expenses.json${params}`)
      .then(result => {
        this.tripReports = result.trip_reports.map(trip => new TripReport(trip));
        if (result.setting) this.setting = new Setting(result.setting);
      })
      .catch(() => {
        this.errors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        this.loading = false;
        app.render();
      });
  }

  // 出張報告の詳細データをAPIから取得、編集のため立替経費をセット
  getShow(tripReportId: string) {
    this.setLoading(true);
    Fetcher.get<TripReportArgs>(`/trip_report/${tripReportId}.json`)
      .then(result => {
        this.currentTripReport = new TripReport(result);
        this.nonOrderItems = this.currentTripReport.nonOrderItems;
        if (result.approve_item) {
          this.approveItemJson = result.approve_item.json;
          this.parseTripApproveItemJson(); // NOTE: TripReportApproveItem -> ApproveItem への変換
        }
        this.allowanceItems = this.currentTripReport.allowanceItems;
        if (result.user?.organization.setting) {
          this.setting = new Setting(result.user.organization.setting);
        }
      })
      .catch(() => {
        this.errors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        this.loading = false;
        app.render();
      });
  }

  parseApproveItemJson = (item: string) => {
    return JSON.parse(item);
  };

  /**
   * TripReportApproveItem -> ApproveItem への変換
   * カスタム申請項目を入力した後、そのデータは TripReportApproveItem の json カラムにまとめて格納されている
   * これを parse して、入力フォームのデータ型に合わせるための変換とデータのセット作業を実施
   */
  parseTripApproveItemJson = () => {
    const approveItems = this.parseApproveItemJson(this.approveItemJson);
    const approveItemArr: ApproveItem[] = approveItems.map((item: TripApproveItemJson) =>
      convertTripApproveItemToApproveItem(item, 'report_after_business_trip')
    );
    const approveItemList = new ApproveItemList([]);
    approveItemList.replaceList(approveItemArr);
    this.approveItems = approveItemList;
    if (approveItemList.list.length) {
      approveItems.forEach((item: TripApproveItemJson) => {
        this.approveItemValues.set(item.id, item.value);
        if (item.dataType === 'list') this.approveItemValueCodes.set(item.id, item.valueCode);
        if (item.dataType === 'file' && item.value) {
          this.fetchFileObject(item);
        }
      });
    }
  };

  /**
   * ファイルを非同期で取得
   * バイナリデータがそのまま返ってくるのでファイルに変換してリストに格納する
   * Response.blob を利用したいが、Fetcher にオプションが渡せないため fetchAPI を利用している
   *
   * TODO: fetch APIはIEでサポートされてない。対応ブラウザを確認。
   *
   * @returns
   */
  private fetchFileObject(item: TripApproveItemJson) {
    fetch(`/trip_reports/${this.currentTripReport.id}/approve_item_files/${item.id}`, { method: 'GET' })
      .then(result => {
        return result.blob();
      })
      .then(blob => {
        const file = new File([blob], item.value, { type: item.fileType });
        this.setApproveItemFile(item.id, file);
      });
  }

  getDefaultAllowanceItems(tripId: number | undefined) {
    // 既に取得済みの場合は接続しない
    if (this.defaultAllowanceItems.length > 0) return;

    const params = { trip_id: tripId };
    Fetcher.get<DefaultAllowanceItemsResponse>(`/trip_report/default_allowance_items.json`, params)
      .then(result => {
        this.defaultAllowanceItems = result.default_allowance_items.map(item => new AllowanceItemForReport(item));
      })
      .catch(() => {
        this.errors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        this.loading = false;
        app.render();
      });
  }

  // 出張報告作成時に必要なデータをAPIから取得、出張報告を初期化データを作成
  getNew() {
    this.setLoading(true);
    Fetcher.get<TripReportNewArgs>(`/trip_report/new.json`)
      .then(result => {
        this.currentTripReport.convertNewArgs(result);

        if (result.user?.organization.setting) {
          this.setting = new Setting(result.user.organization.setting);
        }

        this.approveItems = new ApproveItemList(result.approve_items);
        if (this.approveItems.list && this.approveItems.list.length > 0) {
          this.approveItems.list.forEach(approveItem => {
            if (
              approveItem.defaultItem != null &&
              approveItem.defaultItemCode != null &&
              approveItem.defaultItemName != null
            ) {
              this.setApproveItemValueCode(approveItem.id, approveItem.defaultItemCode);
              this.setApproveItemValue(approveItem.id, approveItem.defaultItemName);
            }
          });
        }
      })
      .catch(() => {
        this.errors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        this.loading = false;
        app.render();
      });
  }

  // 出張報告の編集に必要なデータをAPIから取得
  getMenus() {
    // 既に取得済みの場合は接続しない
    if (this.projectOptions.length > 0) return;

    Fetcher.get<{
      projects: ProjectArgs[];
      expenses_types: ExpensesTypeJson[];
      tax_types: TaxTypeArgs[];
      order_item_categories: OrderItemCategoryOptions;
      trip_types: TripTypeOptions;
      calc_price_orders: CalcPriceOrderOptions;
    }>(`/trip_report/menu.json`)
      .then(result => {
        this.projectOptions = result.projects.map(args => new Project(args));
        this.expensesTypeOptions = result.expenses_types.map(args => new ExpensesType(args));
        this.taxTypeOptions = result.tax_types.map(args => new TaxType(args));
        this.categoryOptions = result.order_item_categories;
        this.tripTypeOptions = result.trip_types.reverse();
        this.calcPriceOrderOptions = result.calc_price_orders;
      })
      .finally(() => {
        this.loading = false;
        this.scrollToTop();
        app.render();
      });
  }

  // 出張報告内の旅程・商品の一覧をAPIから取得、編集のため商品をセット
  getTrips(tripReportId: string) {
    this.setLoading(true);
    Fetcher.get<TripForReportArgs[]>(`/trip_report/trips/${tripReportId}.json`)
      .then(result => {
        this.trips = result.map(trip => new TripForReport(trip));
        this.trips.forEach(trip => {
          this.priceDetails = this.priceDetails.concat(trip.orderItemPriceDetails);
        });
      })
      .catch(() => {
        this.errors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        this.loading = false;
        this.scrollToTop();
        app.render();
      });
  }

  // 出張報告の旅程選択時の旅程一覧をAPIから取得
  getSelect(page?: number, searchType?: TripSearchType) {
    // 既に取得済みの場合は接続しない
    if (page === this.page && searchType === this.tripSearchType && this.tripOptions.length > 0) return;

    this.setLoading(true);
    const newPage = page || this.page;
    const newSearchType = searchType || this.tripSearchType;

    Fetcher.get<TripSelectResponse>(`/trip_report/trips.json?page=${newPage}&search_type=${newSearchType}`)
      .then(result => {
        this.tripOptions = result.trips.map(trip => new TripForReport(trip, result.order_item_categories));
        this.page = newPage;
        this.totalPages = result.total_pages;
        this.tripSearchType = newSearchType;
      })
      .catch(() => {
        this.errors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        this.loading = false;
        this.scrollToTop();
        app.render();
      });
  }

  getApprovals(tripReportId?: number | string, onLoaded?: () => void) {
    const params = {
      projects: this.currentProjects().map(project => project.id.toString()),
      departments: this.currentDepartments().map(department => department.id.toString()),
      trip_ids: this.trips.map(t => t.id.toString())
    };
    const json = JSON.stringify(params);

    if (this.lastApprovalParams === json) {
      onLoaded?.();
      return;
    }
    this.lastApprovalParams = json;

    const id = tripReportId || this.currentTripReport?.id;
    const url = id ? `/trip_report/${id}/approval.json` : '/trip_report/approval.json';
    // GETだとクエリパラメータが長くなる可能性があるため、POSTにしていると思われる
    Fetcher.post<{
      workflow_style: WorkflowStyle;
      approvals: ApprovalArgs[];
      budgets: { id: number; budgets: BudgetArgs[] }[];
      workflow_options: WorkflowOption[];
      approval_required: boolean;
    }>(url, params)
      .then(result => {
        this.approvals = result.approvals.map(args => new Approval(args));
        this.approvalStages = Approval.stages(this.approvals);
        this.budgets = Object.fromEntries(
          result.budgets.map(({ id, budgets }) => [id.toString(), new Budget(budgets[0])])
        );
        this.workflowStyle = result.workflow_style;
        this.workflowOptions = result.workflow_options;
        this.approvalRequired = result.approval_required;
      })
      .catch(() => {
        this.errors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        app.render();
        onLoaded?.();
      });
  }

  // 承認ルート内の承認アイテムを取得
  getApprovalInGroups(tripReport: TripReport, approval: Approval) {
    const url = `/trip_report/approvals/${tripReport.id}/in_groups.json?approval_id=${approval.id}`;
    Fetcher.get<ApprovalArgs[]>(url)
      .then(result => {
        approval.setInGroups(result);
      })
      .catch(() => {
        approval.setInGroups([]);
      });
  }

  // 承認者側の出張報告の一覧データをAPIから取得
  getIndexForApproval() {
    this.setLoading(true);
    const params = this.statusEq === '' ? '' : `?status=${this.statusEq}`;
    Fetcher.get<IndexResponse>(`/trip_report/approvals.json${params}`)
      .then(result => {
        this.tripReports = result.trip_reports.map(trip => new TripReport(trip));
        if (result.setting) this.setting = new Setting(result.setting);
      })
      .catch(() => {
        this.errors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        this.loading = false;
        app.render();
      });
  }

  // 承認者側の出張報告の詳細データをAPIから取得、編集のため立替経費をセット
  getShowForApproval(tripReportId: string) {
    this.setLoading(true);
    Fetcher.get<TripReportArgs>(`/trip_report/approvals/${tripReportId}.json`)
      .then(result => {
        this.currentTripReport = new TripReport(result);
        this.nonOrderItems = this.currentTripReport.nonOrderItems;
        if (result.approve_item) {
          this.approveItemJson = result.approve_item.json;
          this.parseTripApproveItemJson(); // NOTE: TripReportApproveItem -> ApproveItem への変換
        }
        this.allowanceItems = this.currentTripReport.allowanceItems;
        if (result.user?.organization.setting) {
          this.setting = new Setting(result.user.organization.setting);
        }
      })
      .catch(() => {
        this.errors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        this.loading = false;
        app.render();
      });
  }

  // 出張報告を作成
  create({ action, onSuccess }: UpdateCallback) {
    if (this.currentTripReport.id > 0) {
      this.update({ action, onSuccess });
      return;
    }

    const submitParams = this.submitParams(action);
    if (!this.requestSizeValidation(submitParams)) {
      return;
    }

    this.setSubmitting(true);
    Fetcher.post<UpdateResponse>('/trip_report', submitParams)
      .then(result => {
        if (onSuccess) onSuccess(result.id);
      })
      .catch(error => {
        this.validationMessages =
          error instanceof HTTPError && error.response?.data?.errors
            ? error.response.data.errors
            : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        this.submitting = false;
        app.render();
      });
  }

  // 出張報告を更新
  update({ action, onSuccess }: UpdateCallback) {
    if (this.currentTripReport.id === 0) {
      this.create({ action, onSuccess });
      return;
    }

    const submitParams = this.submitParams(action);
    if (!this.requestSizeValidation(submitParams)) {
      return;
    }

    const tripReport = this.currentTripReport;
    this.setSubmitting(true);
    Fetcher.put<UpdateResponse>(`/trip_report/${tripReport.id}`, submitParams)
      .then(result => {
        if (onSuccess) onSuccess(result.id);
      })
      .catch(error => {
        this.validationMessages =
          error instanceof HTTPError && error.response?.data?.errors
            ? error.response.data.errors
            : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        this.submitting = false;
        app.render();
      });
  }

  requestSizeValidation(params: any) {
    this.validationMessages = [];

    const requestSize = JSON.stringify(params).length;
    if (requestSize > 20 * 1024 * 1024) {
      const requestSizeMB = (requestSize / 1024 / 1024).toFixed(2);
      this.validationMessages = [
        `ファイルサイズが大きすぎます。合計で20MB以下にしてください。(現在: ${requestSizeMB}MB)`
      ];
    }

    if (this.validationMessages.length > 0) {
      app.render();
      return false;
    }

    return true;
  }

  // 申請の取り消し
  cancel({ tripReportId, message, onSuccess, onError }: CancelCallback) {
    const tripReport = this.tripReports.find(tripReport => tripReport.id === tripReportId);

    if (!tripReport) {
      this.validationMessages = ['ご指定の出張報告が見つかりませんでした'];
      app.render();
      return;
    }

    Fetcher.put<UpdateResponse>(`/trip_report/${tripReport.id}/cancel`, { message })
      .then(result => {
        tripReport.status = 'draft';
        this.messages[result.id] = '申請を取り消しました';
        if (onSuccess) onSuccess(result.id);
      })
      .catch(error => {
        this.validationMessages =
          error instanceof HTTPError && error.response?.data?.errors
            ? error.response.data.errors
            : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
        if (onError) onError(this.validationMessages);
      })
      .finally(() => {
        app.render();
      });
  }

  // 承認・差し戻し
  approve({ actionType, message, onSuccess, onError }: ApproveCallback) {
    const tripReport = this.currentTripReport;
    this.setSubmitting(true);
    this.resetValidation();

    Fetcher.post<{ success: true }>(`/trip_report/approvals/${tripReport.id}/${actionType}`, { message })
      .then(() => {
        if (onSuccess) onSuccess(tripReport.id);
      })
      .catch(error => {
        this.validationMessages =
          error instanceof HTTPError && error.response?.data?.errors
            ? error.response.data.errors
            : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
        if (onError) onError(this.validationMessages);
      })
      .finally(() => {
        this.submitting = false;
        app.render();
      });
  }

  // ビズトラの経費申請を作成
  async createExpensesReport({ tripReport, onSuccess, onError }: CreateExpensesReportCallback) {
    this.setSubmitting(true);
    this.resetValidation();

    await Fetcher.post<CreateExpensesReportResponse>(`/trip_report/${tripReport.id}/expenses_report`, {})
      .then(() => {
        tripReport.setField('expensesConverted', true);
        if (onSuccess) onSuccess(tripReport.id);
      })
      .catch(error => {
        this.validationMessages =
          error instanceof HTTPError && error.response?.data?.errors
            ? error.response.data.errors
            : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
        if (onError) onError(this.validationMessages);
      })
      .finally(() => {
        this.submitting = false;
        app.render();
      });
  }

  // 項目単位でバリデーション済みメッセージを取得する
  invalid(field: ValidationFields) {
    return _.isEmpty(this.validationErrors[field]);
  }

  // バリデーションを行う
  validate(action: UpdateAction, confirm = false) {
    const tripReport = this.currentTripReport;
    const errors = [];

    if (tripReport.name === '') {
      this.validationErrors.name = '申請タイトルを入力してください';
      errors.push(this.validationErrors.name);
    }

    if (action === 'apply') {
      if (this.setting?.tripReportActivityContent === 'mandatory' && tripReport.activityContent === '') {
        this.validationErrors.activityContent = '活動内容を入力してください';
        errors.push(this.validationErrors.activityContent);
      }
      if (this.setting?.tripReportResultsContent === 'mandatory' && tripReport.resultsContent === '') {
        this.validationErrors.resultsContent = '成果内容を入力してください';
        errors.push(this.validationErrors.resultsContent);
      }
      if (this.setting?.tripReportOtherText === 'mandatory' && tripReport.otherText === '') {
        this.validationErrors.otherText = 'その他を入力してください';
        errors.push(this.validationErrors.otherText);
      }

      if (this.priceDetails.length < 1 && this.nonOrderItems.length < 1 && this.allowanceItems.length < 1) {
        this.validationErrors.priceDetails = '旅程を選択してください';
        errors.push(this.validationErrors.priceDetails);
      } else if (
        confirm &&
        this.approvalGroups().length < 1 &&
        this.unNullableApprovalStages().length < 1 &&
        this.approvalRequired
      ) {
        if (this.workflowStyle === 'project') {
          // TODO: ユーザー毎に事後承認必要・不要を設定できるようにする（プロジェクトの場合は一旦ワークフローをスキップできるようにしている）
          // https://aitravel.atlassian.net/browse/AITRAVEL-3435
          // this.validationErrors.priceDetails = '承認者が設定されているプロジェクトを指定してください';
          // errors.push(this.validationErrors.priceDetails);
        } else {
          this.validationErrors.priceDetails = '承認者を設定してください';
          errors.push(this.validationErrors.priceDetails);
        }
      }
    }

    this.approveItems.list.forEach(r => {
      if (r.requiredType !== 'optional' && r.requiredType !== '' && !this.approveItemValues.get(r.id)) {
        this.validationErrors.approveItem = `${r.userDisplayName}を入力して下さい。`;
        errors.push(this.validationErrors.approveItem);
      }
    });

    return errors;
  }

  // 作成・更新時のAPIに送るデータを整形
  submitParams(action: UpdateAction) {
    return {
      trip_report: this.currentTripReport.submitParams(action),
      approve_items: this.generateApproveItemParams(),
      approval_stages: this.unNullableApprovalStages(),
      ...this.submitPriceDetailsParams(),
      ...this.submitNonOrderItemsParams(),
      ...this.submitAllowanceItemsParams()
    };
  }

  private generateApproveItemParams() {
    const data: any[] = [];
    this.approveItems.list.forEach(r => {
      data.push({
        id: r.id,
        userDisplayName: r.userDisplayName,
        dataType: r.dataType,
        display: r.display,
        requiredType: r.requiredType,
        placeholder: r.placeholder,
        value: this.approveItemValues.get(r.id),
        valueCode: this.approveItemValueCodes.get(r.id),
        file: this.getApproveItemFileBase64(r.id),
        fileType: this.getApproveItemFileType(r.id)
      });
    });
    return JSON.stringify(data);
  }

  submitPriceDetailsParams() {
    const insertIds = this.priceDetails.map(priceDetail => priceDetail.id);
    const removeIds = this.removingPriceDetails.filter(i => insertIds.indexOf(i) === -1);

    return {
      order_item_price_details: this.priceDetails.map(priceDetail => priceDetail.submitParams()),
      order_item_price_detail_ids: insertIds,
      order_item_price_detail_removes: removeIds
    };
  }

  submitNonOrderItemsParams() {
    const insertIds = this.nonOrderItems.map(item => item.id).filter(v => v);
    const removeIds = this.removingNonOrderItems.filter(i => insertIds.indexOf(i) === -1);

    return {
      non_order_items: this.nonOrderItems.map(item => item.submitParams()),
      non_order_item_removes: removeIds
    };
  }

  submitAllowanceItemsParams() {
    const insertIds = this.allowanceItems.map(item => item.id).filter(v => v);
    const removeIds = this.removingAllowanceItems.filter(i => insertIds.indexOf(i) === -1);

    return {
      allowance_items: this.allowanceItems.map(item => item.submitParams()),
      allowance_item_removes: removeIds
    };
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }
}

export const TripReportContext = createContext<TripReportStore>(null as unknown as TripReportStore);

export const useTripReportStore = () => useContext(TripReportContext);

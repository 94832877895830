/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import type { RouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { Modal as FeedbackModal } from '@this/shared/ui/feedbacks/modal';
import type { ExpensesAccountTypeJson } from '@this/domain/expenses/expenses_account_type';
import { ExpensesAccountType } from '@this/domain/expenses/expenses_account_type';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MainTitle from '@this/shared/organization/main_title/main_title';
import {
  OrganizationBiztraTh,
  OrganizationBiztraTable,
  OrganizationBiztraTd,
  OrganizationAccountTypeButtonSection,
  OrganizationEditModalWrap,
  OrganizationEditModalErr
} from '@this/components/expenses/organization/organization.style';
import { BiztraModal, BiztraModalHeader, BiztraModalBody } from '@this/components/expenses/ui/feedbacks/modal';
import { Flex } from '@this/shared/ui/layout/flex';
import { Fetcher, HTTPError } from '@this/src/util';
import { TheAddButton } from './departments';
import { ContentBody } from './organization';
import { ModalInputArea, ModalInputLabel, ModalInput, MemberEditRadioValue } from './members';
import { Wrapper, Header } from '../report_items/search_form';
import { ActionWrapper, BaseButton, CancelButton } from '../approvals/reject';
import { ConfirmTxt, ConfirmBody } from '../approvals/zengin_download';
import { FileUploadOutlinedIcon } from '../icons';
import Notification from '../../../notification';

interface ExpensesAccountTypesResponse {
  expenses_account_types: ExpensesAccountTypeJson[];
  receipt_available: boolean;
}

interface ExpensesAccountTypeResponse {
  expenses_account_type: ExpensesAccountTypeJson;
}

interface ExpensesAccountTypesPutResponse {
  expenses_account_type: ExpensesAccountTypeJson;
}

type Props = RouteComponentProps<{ id?: string }>;

interface State {
  expensesAccountTypes: ExpensesAccountType[];
  error?: string;
  putErrors?: string | string[];
  loading: boolean;
  saving: boolean;
  editingExpensesAccountType: ExpensesAccountType | null;
  deletingExpensesAccountType: ExpensesAccountType | null;
  selectedRowIds: string[];
  title: string;
  showEditModal: boolean;
  receiptAvailable: boolean;
}

class ExpensesAccountTypes extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      expensesAccountTypes: [],
      loading: true,
      putErrors: '',
      editingExpensesAccountType: null,
      deletingExpensesAccountType: null,
      title: '',
      saving: false,
      selectedRowIds: [],
      showEditModal: false,
      receiptAvailable: false
    };
  }

  componentDidMount() {
    this.fetchExpensesAccountTypes();
    mixpanel.track('[Biztra][view] /organization/expenses_account_types');
  }

  onDragStart = (start: any) => {
    const id = start.draggableId;
    const selected = this.state.selectedRowIds.find(selectedId => selectedId === id);

    // If dragging an item that is not selected, unselect all items
    if (!selected) {
      this.unselectAll();
    }
  };

  onDragEnd = (result: any) => {
    const { destination, source } = result;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const expensesAccountTypes = Object.assign([], this.state.expensesAccountTypes);
    if (this.state.expensesAccountTypes) {
      const quote = this.state.expensesAccountTypes[source.index];
      expensesAccountTypes.splice(source.index, 1);
      expensesAccountTypes.splice(destination.index, 0, quote);
    }
    this.setState({
      expensesAccountTypes
    });
  };

  unselect = () => {
    this.unselectAll();
  };

  unselectAll = () => {
    this.setState({
      selectedRowIds: []
    });
  };

  private async fetchExpensesAccountTypes() {
    try {
      this.setState({ loading: true });
      const response = await Fetcher.get<ExpensesAccountTypesResponse>(
        '/biztra/organization/expenses_account_types.json'
      );
      const expensesAccountTypes = response.expenses_account_types.map(
        account => new ExpensesAccountType(account)
      );
      this.setState({
        expensesAccountTypes,
        loading: false,
        receiptAvailable: response.receipt_available
      });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  private async handleAddClick() {
    this.setState({ putErrors: [], title: '追加' });
    try {
      const response = await Fetcher.get<ExpensesAccountTypeResponse>(
        `/biztra/organization/expenses_account_types/new`
      );
      const new_type = new ExpensesAccountType(response.expenses_account_type);
      this.state.expensesAccountTypes.push(new_type);
      this.setState({
        editingExpensesAccountType: new_type,
        showEditModal: true
      });
    } catch (e) {
      if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.error) {
        this.setState({
          putErrors: e.response.data.error,
          saving: false
        });
      } else {
        this.setState({
          putErrors: '通信エラーが発生しました。時間をおいて再度お試しください。',
          saving: false
        });
      }
    }
  }

  allSubmitParams() {
    const expensesAccountTypes = this.state.expensesAccountTypes;
    if (expensesAccountTypes) {
      const expensesAccountTypesParams = expensesAccountTypes.map(ex => ex.submitParams());
      return { expenses_account_types: expensesAccountTypesParams };
    }
    return {};
  }

  async handleDisplayOrderSubmit() {
    this.setState({
      saving: true
    });
    return Fetcher.put<ExpensesAccountTypesResponse>(
      '/biztra/organization/expenses_account_types/display_order',
      this.allSubmitParams()
    )
      .then(() => {
        Notification.success('保存が完了しました');
        this.setState({
          saving: false
        });
      })
      .catch(e => {
        if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.error) {
          this.setState({
            putErrors: e.response.data.error,
            saving: false
          });
        } else {
          this.setState({
            putErrors: '通信エラーが発生しました。時間をおいて再度お試しください。',
            saving: false
          });
        }
      });
  }

  handleEditClick(expensesAccountType: ExpensesAccountType) {
    this.setState({
      editingExpensesAccountType: _.cloneDeep(expensesAccountType),
      putErrors: [],
      title: '変更',
      showEditModal: true
    });
  }

  handleDeleteClick(expensesAccountType: ExpensesAccountType) {
    this.setState({ deletingExpensesAccountType: expensesAccountType });
  }

  handleDeleteAlertClose() {
    this.setState({ deletingExpensesAccountType: null });
  }

  handleDeleteConfirm() {
    Fetcher.delete(
      `/biztra/organization/expenses_account_types/${this.state.deletingExpensesAccountType!.id}`
    ).then(
      _result => {
        this.setState({
          deletingExpensesAccountType: null
        });
        this.fetchExpensesAccountTypes();
      },
      e => {
        this.setState({
          error:
            e instanceof HTTPError && e.response?.status === 400 && e.response.data.errors
              ? e.response.data.errors
              : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        });
      }
    );
  }

  handleCancelClick() {
    if (this.state.editingExpensesAccountType) {
      if (!this.state.editingExpensesAccountType.id) {
        if (this.state.expensesAccountTypes) {
          this.state.expensesAccountTypes.pop();
        }
      }
    }
    this.setState({ editingExpensesAccountType: null, showEditModal: false });
  }

  private handleEditFormSubmit() {
    this.setState({
      saving: true
    });
    if (this.state.editingExpensesAccountType) {
      if (this.state.editingExpensesAccountType.id) {
        this.submitUpdate();
      } else {
        this.submitCreate();
      }
    }
  }

  private submitCreate() {
    Fetcher.post<ExpensesAccountTypesPutResponse>(
      `/biztra/organization/expenses_account_types`,
      this.state.editingExpensesAccountType!.submitParams()
    )
      .then(res => {
        _.assign(this.state.editingExpensesAccountType, { id: res.expenses_account_type.id });
        this.setState({
          saving: false,
          editingExpensesAccountType: null,
          showEditModal: false
        });
        this.fetchExpensesAccountTypes();
      })
      .catch(e => {
        if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.error) {
          this.setState({
            putErrors: e.response.data.error,
            saving: false
          });
        } else {
          this.setState({
            putErrors: '通信エラーが発生しました。時間をおいて再度お試しください。',
            saving: false
          });
        }
      });
  }

  private submitUpdate() {
    if (!this.state.editingExpensesAccountType) return;
    if (!this.state.expensesAccountTypes) return;
    Fetcher.put<ExpensesAccountTypesPutResponse>(
      `/biztra/organization/expenses_account_types/${this.state.editingExpensesAccountType.id}`,
      this.state.editingExpensesAccountType!.submitParams()
    )
      .then(() => {
        if (!this.state.editingExpensesAccountType) return;
        const account_id = this.state.editingExpensesAccountType.id;
        const account = _.find(this.state.expensesAccountTypes, type => {
          return type.id === account_id;
        });
        if (account) {
          _.assign(account, this.state.editingExpensesAccountType);
        }
        this.setState({
          saving: false,
          editingExpensesAccountType: null,
          showEditModal: false
        });
        this.fetchExpensesAccountTypes();
      })
      .catch(e => {
        if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.error) {
          this.setState({
            putErrors: e.response.data.error,
            saving: false
          });
        } else {
          this.setState({
            putErrors: '通信エラーが発生しました。時間をおいて再度お試しください。',
            saving: false
          });
        }
      });
  }

  private expensesAccountTypeCards(expensesAccountTypes: ExpensesAccountType[], receiptAvailable: boolean) {
    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
        <OrganizationBiztraTable showBorder>
          <thead>
            <tr>
              <OrganizationBiztraTh width="18%">勘定科目コード</OrganizationBiztraTh>
              <OrganizationBiztraTh>勘定科目名</OrganizationBiztraTh>
              {receiptAvailable && <OrganizationBiztraTh>領収書アップロード</OrganizationBiztraTh>}
              <OrganizationBiztraTh width="12%">編集</OrganizationBiztraTh>
              <OrganizationBiztraTh width="12%">削除</OrganizationBiztraTh>
            </tr>
          </thead>
          <Droppable droppableId="tableBody">
            {(provided: any, _snapshot: any) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {expensesAccountTypes.map((expensesAccountType, index) =>
                  this.expensesAccountTypeCard(expensesAccountType, index, receiptAvailable)
                )}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </OrganizationBiztraTable>
      </DragDropContext>
    );
  }

  private expensesAccountTypeCard(
    expensesAccountType: ExpensesAccountType,
    index: number,
    receiptAvailable: boolean
  ) {
    return (
      <Draggable draggableId={String(expensesAccountType.id)} index={index} key={expensesAccountType.id}>
        {(provided: any, snapshot: any) => (
          <Tr
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            key={expensesAccountType.id}
            ref={provided.innerRef}
            style={this.getTrStyle(snapshot.isDragging, provided.draggableProps.style)}
            data-e2e-id={expensesAccountType.id}
          >
            <OrganizationBiztraTd showBorder className="e2e-code">
              {expensesAccountType.code}
            </OrganizationBiztraTd>
            <OrganizationBiztraTd showBorder className="e2e-name">
              {expensesAccountType.name}
            </OrganizationBiztraTd>
            {receiptAvailable && (
              <OrganizationBiztraTd showBorder>
                {expensesAccountType.receiptFileRequired ? '必須' : '任意'}
              </OrganizationBiztraTd>
            )}
            <OrganizationBiztraTd showBorder>
              <EditButton onClick={() => this.handleEditClick(expensesAccountType)}>編集</EditButton>
            </OrganizationBiztraTd>
            <OrganizationBiztraTd showBorder>
              <TheDeleteButton onClick={() => this.handleDeleteClick(expensesAccountType)}>削除</TheDeleteButton>
            </OrganizationBiztraTd>
          </Tr>
        )}
      </Draggable>
    );
  }

  private deleteConfirm(deletingExpensesAccountType: ExpensesAccountType) {
    return (
      <Wrapper className="e2e-confirm-delete">
        <Header>
          {`${deletingExpensesAccountType.name}${deletingExpensesAccountType.codeText}を削除してよろしいですか？`}
        </Header>
        <ConfirmBody>
          <ConfirmTxt style={{ textAlign: 'center' }}>※この操作は取り消せません</ConfirmTxt>
        </ConfirmBody>
        <ActionWrapper>
          <CancelButton onClick={() => this.handleDeleteAlertClose()}>キャンセル</CancelButton>
          <ModalDeleteButton onClick={() => this.handleDeleteConfirm()}>削除</ModalDeleteButton>
        </ActionWrapper>
      </Wrapper>
    );
  }

  getTrStyle = (isDragging: any, draggableStyle: any) => ({
    background: isDragging && '#fff9ed',
    ...draggableStyle
  });

  getTdStyle = (isDragging: any) => ({
    width: isDragging && '25%',
    textAlign: 'left' as const
  });

  getMiniTdStyle = (isDragging: any) => ({
    width: isDragging && '10%',
    textAlign: 'left' as const
  });

  buttons() {
    return (
      <TheButtonSection>
        {!this.state.editingExpensesAccountType && (
          <div>
            <TheAddButton onClick={() => this.handleAddClick()}>
              <AddIcon fontSize="small" style={{ marginRight: 8, verticalAlign: 'text-top' }} />
              直接入力で追加
            </TheAddButton>
          </div>
        )}
        <Link to="/biztra/organization/expenses_account_types/csv/bulk_upsert">
          <TheOutlinedButton>
            <span style={{ marginRight: 8, verticalAlign: 'text-top', lineHeight: 1 }}>
              <FileUploadOutlinedIcon color="#927230" />
            </span>
            CSVで追加
          </TheOutlinedButton>
        </Link>
        <div>
          <TheOutlinedButton onClick={() => this.handleDisplayOrderSubmit()}>
            <ArrowForwardIosOutlinedIcon style={{ marginRight: 8, verticalAlign: 'text-top', fontSize: 15 }} />
            順番を保存
          </TheOutlinedButton>
        </div>
      </TheButtonSection>
    );
  }

  render() {
    const {
      expensesAccountTypes,
      loading,
      error,
      putErrors,
      editingExpensesAccountType,
      title,
      saving,
      deletingExpensesAccountType,
      showEditModal,
      receiptAvailable
    } = this.state;
    // eslint-disable-next-line no-unused-expressions
    expensesAccountTypes?.forEach((ex, i) => {
      ex.displayOrder = i;
    });
    return (
      <>
        <MainTitle value="勘定科目マスタ" buttons={this.buttons()} flexWrap />
        <ContentBody>
          {editingExpensesAccountType && (
            <BiztraModal
              size="large"
              onClose={() => this.handleCancelClick()}
              open={showEditModal}
              className="e2e-modal-edit"
            >
              <BiztraModalHeader>勘定科目を{title}する</BiztraModalHeader>
              <BiztraModalBody>
                <form onSubmit={() => this.handleEditFormSubmit()}>
                  <OrganizationEditModalWrap>
                    <ModalInputArea>
                      <ModalInputLabel htmlFor="code">勘定科目コード</ModalInputLabel>
                      <ModalInput
                        id="code"
                        type="text"
                        value={editingExpensesAccountType.code || ''}
                        onChange={e => {
                          editingExpensesAccountType.code = e.target.value;
                        }}
                        style={{ margin: 0 }}
                      />
                    </ModalInputArea>
                    <ModalInputArea>
                      <ModalInputLabel htmlFor="name">勘定科目</ModalInputLabel>
                      <ModalInput
                        id="name"
                        type="text"
                        value={editingExpensesAccountType.name || ''}
                        onChange={e => {
                          editingExpensesAccountType.name = e.target.value;
                        }}
                        style={{ margin: 0 }}
                      />
                    </ModalInputArea>
                    {receiptAvailable && (
                      <ModalInputArea>
                        <ModalInputLabel>領収書アップロード</ModalInputLabel>
                        <Flex>
                          <ExpensesAccountTypesEditRadioValue>
                            <ModalInput
                              type="radio"
                              value="mandatory"
                              checked={editingExpensesAccountType.receiptFileRequired === true}
                              onChange={() => editingExpensesAccountType.setReceiptFileRequired(true)}
                            />
                            <span>必須</span>
                          </ExpensesAccountTypesEditRadioValue>
                          <ExpensesAccountTypesEditRadioValue>
                            <ModalInput
                              type="radio"
                              value="optional"
                              checked={editingExpensesAccountType.receiptFileRequired === false}
                              onChange={() => editingExpensesAccountType.setReceiptFileRequired(false)}
                            />
                            <span>任意</span>
                          </ExpensesAccountTypesEditRadioValue>
                        </Flex>
                      </ModalInputArea>
                    )}
                  </OrganizationEditModalWrap>

                  <ActionWrapper>
                    <CancelButton onClick={() => this.handleCancelClick()}>キャンセル</CancelButton>
                    <BaseButton onClick={() => this.handleEditFormSubmit()}>保存</BaseButton>
                  </ActionWrapper>
                  {typeof putErrors === 'string' ? (
                    <p> {putErrors} </p>
                  ) : (
                    putErrors &&
                    putErrors.map((error, i) => (
                      <OrganizationEditModalErr key={i.toString()}>{error}</OrganizationEditModalErr>
                    ))
                  )}
                </form>
                {saving && <SimpleLoading />}
              </BiztraModalBody>
            </BiztraModal>
          )}
          {loading ? (
            <SimpleLoading />
          ) : error ? (
            <OrganizationEditModalErr>{error}</OrganizationEditModalErr>
          ) : (
            this.expensesAccountTypeCards(expensesAccountTypes, receiptAvailable)
          )}
        </ContentBody>
        {deletingExpensesAccountType && (
          <FeedbackModal
            open
            onClose={() => this.handleDeleteAlertClose()}
            PaperProps={{ style: { background: 'white' } }}
          >
            {this.deleteConfirm(deletingExpensesAccountType)}
          </FeedbackModal>
        )}
      </>
    );
  }
}

const baseColor = '#927230';

const deleteBtnColor = '#faf9f8';

const deleteTxtColor = '#c72f62';

const Tr = styled.tr`
  &:hover {
    background: #fff9ed;
  }
  &:last-child td {
    border: none;
  }
`;

export const EditButton = styled.button.attrs({ type: 'button' })`
  width: unset !important;
  padding: 9px 20px 8px;
  background: ${baseColor};
  border-radius: 6px;
  color: white;
  font-size: 10px;
  line-height: 1;
  &&:focus,
  &&:hover {
    background: ${baseColor};
  }
`;

export const TheDeleteButton = styled(EditButton)`
  background: ${deleteBtnColor};
  color: ${deleteTxtColor};
  border: 1px solid ${deleteTxtColor};
  padding: 8px 19px 7px;
  &&:focus,
  &&:hover {
    background: ${deleteBtnColor};
    color: ${deleteTxtColor};
  }
`;

export const ModalDeleteButton = styled(CancelButton)`
  background: ${deleteBtnColor};
  color: ${deleteTxtColor};
  border: 1px solid ${deleteTxtColor};
  &&:focus,
  &&:hover {
    background: ${deleteBtnColor};
    color: ${deleteTxtColor};
  }
`;

export const TheButtonSection = styled(OrganizationAccountTypeButtonSection)`
  gap: 0 12px;
`;

export const TheOutlinedButton = styled(TheAddButton)`
  border: 1px solid ${baseColor};
  padding: 5px 15px;

  &&& {
    background: white;
    color: ${baseColor};
    &&:focus,
    &&:hover {
      background: white;
      color: ${baseColor};
    }
  }
`;

const ExpensesAccountTypesEditRadioValue = styled(MemberEditRadioValue)``;

export default observer(ExpensesAccountTypes);

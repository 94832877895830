/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import A from '@this/shared/atoms/a';
import { media } from '@this/components/shared/atoms/media';
import { ButtonBase } from '@this/components/shared/atoms/button';

import NotifiedUserSelectorTemplate from '@this/shared/user_selector/notified_user_selector.template';

import type SearchQuery from '@this/domain/search_query';
import type SearchQueryItem from '@this/domain/search_query_item';
import type SelectStore from '@this/domain/select_store';
import ArrangementRequestSetting from '@this/domain/organization/arrangement_request_setting';
import ArrangementRequestInfo from '@this/domain/arrangement_request_info/arrangement_request_info';
import type { ReserveRequestMailNotificationType } from '@this/domain/organization/organization2';
import type { AvailableRequestForm } from '@this/src/domain/available_request_form';
import SubInfo from '@this/shared/sub_info/sub_info';
import { Fetcher, HTTPError } from '@this/src/util';
import User from '../../../../domain/user/user';
import type UserJson from '../../../../domain/user/user_json';
import Department from '../../../../domain/department/department';
import TravelerList from '../../../../domain/traveler/traveler_list';
import Traveler from '../../../../domain/traveler/traveler';
import ProjectList from '../../../../domain/project/project_list';
import SimpleLoading from '../../../shared/simple_loading/simple_loading';
import moment from '../../../../lib/moment';
import ArrangementRequestInfoForm from './arrangement_request_info_form/arrangement_request_info_form';
import SelectOutlineWrapperTemplate from '../outline_wrapper/outline_wrapper.template';
import SelectOutlineWrapperShortdistanceTemplate from '../outline_wrapper/outline_wrapper_shortdistance.template';
import SelectTravelers from '../../../reserve_trip/reserve_confirm/select_travelers/select_travelers';

interface ArrangementRequestResponse {
  redirect_path: string;
}

interface ArrangementRequestNewResponse {
  members: UserJson[];
  departments: never[];
  use_bulk_ticket: boolean;
  show_ex: boolean;
  projects: never;
  notified_users: UserJson[];
  car_type: [string, string][];
  item_type: [string, string][];
  available_request_forms: AvailableRequestForm[];
}

interface Props {
  serviceId: number;
  query: SearchQuery;
  store: SelectStore;
  selectErrors: string[];
  reserveRequestMailNotificationType: ReserveRequestMailNotificationType;
  handleNextButtonClick: (e: React.MouseEvent<HTMLElement>) => void;
  handleSelectItem: () => void;
  handleAdditionalSearch: (queryItem: SearchQueryItem, e: React.FormEvent<HTMLFormElement>) => void;
  additionalSearchErrors?: { [k in string]: string };
  handleOpenShowSearchBox: () => void;
  shortdistance: boolean;
}

interface State {
  setting: ArrangementRequestSetting;
  email: string;
  origin: string;
  destination: string;
  outdate?: string;
  outhour?: string;
  outmin?: string;
  outtype: 'arrival' | 'departure';
  homedate?: string;
  homehour?: string;
  homemin?: string;
  hometype: 'arrival' | 'departure';
  peoplenum: number;
  firstName: string;
  lastName: string;
  passportNumber: string;
  passportExpire: string;
  passportIssuedCoutry: string;
  hotelLocation: string;
  showSearchResult: boolean;
  memo: string;
  description: string;
  user: User;
  travelers: TravelerList;
  members: User[];
  domesticAirExist: boolean;
  foreignExist: boolean;
  shinkansenExist: boolean;
  hotelExist: boolean;
  submitting: boolean;
  errors: string[];
  loading: boolean;
  loadingError?: string | null;
  requestInfo?: ArrangementRequestInfo;
  termsAgree: boolean;
  tripForeign: boolean;
}

export default class ArrangementRequestForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const settingJson = utils.dig(props.store, 'user', 'organization', 'arrangement_request_setting');
    const user = new User(props.store.user as any);
    const members: User[] = [];
    const travelers =
      props.query.travelers || ArrangementRequestForm.getInitialTravelers(props.query.peoplenum, user, members);
    const hotelExist = props.store.result.isHotelResult();
    const shinkansenExist = props.store.result.isShinkansenResult();
    this.state = {
      setting: new ArrangementRequestSetting(settingJson),
      email: user.email || '',
      origin: '',
      destination: props.query.finalDestination() || '',
      outdate: utils.dig(props.query.startTime(), 'date'),
      outhour: utils.dig(props.query.startTime(), 'hour'),
      outmin: utils.dig(props.query.startTime(), 'min'),
      outtype: 'arrival',
      homedate: utils.dig(props.query.endTime(), 'date'),
      homehour: utils.dig(props.query.endTime(), 'hour'),
      homemin: utils.dig(props.query.endTime(), 'min'),
      hometype: 'arrival',
      peoplenum: props.query.peoplenum,
      firstName: '',
      lastName: '',
      passportNumber: '',
      passportExpire: '',
      passportIssuedCoutry: '',
      hotelLocation: '',
      showSearchResult: true,
      memo: '',
      description: ArrangementRequestForm.itineraryDeescription(props.query.getSummaryData()),
      user,
      members,
      domesticAirExist: props.store.result.isDomesticAirResult(),
      foreignExist: props.store.result.isFlightResult(),
      shinkansenExist,
      hotelExist,
      travelers,
      submitting: false,
      errors: [],
      loading: true,
      termsAgree: true,
      tripForeign: true
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
      loadingError: null
    });
    try {
      const res = await Fetcher.get<ArrangementRequestNewResponse>('/arrangement_requests/new');
      const departments = res.departments.map(d => new Department(d));
      const projects = new ProjectList(res.projects);
      this.setState({
        members: res.members.map(m => new User(m)),
        requestInfo: new ArrangementRequestInfo({
          hotelExist: this.state.hotelExist,
          shinkansenExist: this.state.shinkansenExist,
          foreignExist: this.state.foreignExist,
          domesticAirExist: this.state.domesticAirExist,
          useBulkTicket: res.use_bulk_ticket,
          showEx: res.show_ex,
          travelers: this.state.travelers,
          user: this.state.user,
          departments,
          projects,
          carType: res.car_type,
          itemType: res.item_type,
          availableRequestForms: res.available_request_forms
        }),
        loading: false
      });
    } catch {
      this.setState({
        loading: false,
        loadingError: '通信エラーが発生しました。時間をおいて再度お試しください。'
      });
    }
  }

  async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({
      errors: [],
      submitting: true
    });
    try {
      const errors = this.validateParams();
      if (errors.length > 0) {
        this.setState({
          errors,
          submitting: false
        });
      } else {
        const res = await Fetcher.post<ArrangementRequestResponse>(
          '/arrangement_requests.json',
          this.submitParams()
        );
        location.href = res.redirect_path;
      }
    } catch (e) {
      if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.errors) {
        this.setState({
          errors: e.response.data.errors,
          submitting: false
        });
      } else {
        this.setState({
          errors: ['通信エラーが発生しました。時間をおいて再度お試しください。'],
          submitting: false
        });
      }
    }
  }

  handleAgreementTermsCheck = () => {
    this.setState({
      termsAgree: !this.state.termsAgree
    });
  };

  handleTripForeignCheck = () => {
    this.setState({
      tripForeign: !this.state.tripForeign
    });
  };

  setType(type: 'outtype' | 'hometype', value: string) {
    if (value !== 'arrival' && value !== 'departure') {
      throw new Error(`unexpected outtype: ${value}`);
    }
    if (type === 'hometype') {
      this.setState({ hometype: value });
    } else {
      this.setState({ outtype: value });
    }
  }

  handleTravelerTypeChange = (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'self') {
      const user = new Traveler(this.state.user);
      this.state.travelers.setTravelerAtIndex(i, user);
    } else {
      this.state.travelers.setTravelerTypeAtIndex(i, e.target.value);
    }
  };

  handleTravelerSelect = (i: number, traveler: Traveler) => {
    this.state.travelers.setTravelerAtIndex(i, traveler);
  };

  handleNotifiedUserAdd = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (this.state.requestInfo) {
      const users = this.state.requestInfo.notifiedUsers;
      users.push(undefined);
      this.state.requestInfo.setNotifiedUsers(users);
    }
  };

  handleNotifiedUserRemove = (e: React.MouseEvent<HTMLElement>, i: number) => {
    e.preventDefault();
    if (this.state.requestInfo) {
      const users = this.state.requestInfo.notifiedUsers;
      users.splice(i, 1);
      this.state.requestInfo.setNotifiedUsers(users);
    }
  };

  handleNotifiedUserSelect = (i: number, traveler: Traveler, _type: string) => {
    if (this.state.requestInfo) {
      const users = this.state.requestInfo.notifiedUsers;
      users[i] = traveler;
      this.state.requestInfo.setNotifiedUsers(users);
    }
  };

  handleChangeDate = (name: 'outdate' | 'homedate', date: string) => {
    switch (name) {
      case 'outdate': {
        this.setState({ outdate: date });
        const outdateMoment = moment(date);
        const homedateMoment = moment(this.state.outdate);
        if (outdateMoment >= homedateMoment) {
          this.setState({
            homedate: moment(outdateMoment).add(1, 'day').format('YYYY-MM-DD')
          });
        }
        break;
      }
      case 'homedate':
        this.setState({ homedate: date });
        break;
      default:
        throw new Error(`never: ${name}`);
    }
  };

  handleShowSearchResultUpdate() {
    this.setState({
      showSearchResult: !this.state.showSearchResult
    });
  }

  startDateCalendarInput = (kind: 'outdate' | 'homedate', ref_name: string, calendar_textbox_id: string) => () => {
    const transportDatePicker: any = this.refs[ref_name];
    switch (kind) {
      case 'outdate':
        transportDatePicker.initializeDate(this.state.outdate);
        break;
      case 'homedate':
        transportDatePicker.initializeDate(this.state.outdate);
        break;
      default:
        throw new Error(`never: ${name}`);
    }
    const element = document.getElementById(calendar_textbox_id);
    if (element) element.classList.add('selected-date-textbox');
  };

  getStayDaysText() {
    const days = moment(this.state.homedate).diff(this.state.outdate, 'days');
    return `${days}泊${days + 1}日`;
  }

  submitParams() {
    return {
      email: this.state.email,
      origin: this.state.origin,
      destination: this.state.destination,
      outdate: this.state.outdate,
      outhour: this.state.outhour,
      outmin: this.state.outmin,
      outtype: this.state.outtype,
      homedate: this.state.homedate,
      homehour: this.state.homehour,
      homemin: this.state.homemin,
      hometype: this.state.hometype,
      peoplenum: this.state.peoplenum,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      passport_number: this.state.passportNumber,
      passport_expire: this.state.passportExpire,
      passport_issued_country: this.state.passportIssuedCoutry,
      hotel_location: this.state.hotelLocation,
      memo: this.state.memo,
      itinerary_description: this.state.description,
      traveler_informations: this.state.travelers.getTravelersParam({ isRequestForm: true }),
      info: this.state.requestInfo!.submitParams(),
      search_query_id: this.props.store.result.searchQueryId,
      result: this.state.showSearchResult ? this.props.store.result.confirmParams() : null,
      is_foreign: this.props.store.result.items.some(item => !item.isDomestic),
      is_trip_foreign: this.state.tripForeign
    };
  }

  validateParams(): string[] {
    const errors = this.state.requestInfo!.validate(this.props.reserveRequestMailNotificationType);
    const travelerErrors: { [key: string]: string | undefined } = {};
    this.state.travelers.list.map(t => t.validate(travelerErrors, this.state.foreignExist));
    return errors.concat(_.compact(Object.keys(travelerErrors).map(k => travelerErrors[k])));
  }

  private static itineraryDeescription(summaryData: { [key: string]: string }): string {
    return Object.keys(summaryData)
      .map(key => `${key}: ${summaryData[key]}`)
      .join('\n');
  }

  private static getInitialTravelers(peoplenum: number, user: User, members: User[]) {
    const travelers = TravelerList.fromCount(peoplenum);
    travelers.setTravelerAtIndex(0, new Traveler(user));
    if (peoplenum > 1) {
      for (let i = 1, end = peoplenum - 1, asc = end >= 1; asc ? i <= end : i >= end; asc ? (i += 1) : (i -= 1)) {
        if (user.show_other_members && members.length > 0) {
          travelers.setTravelerTypeAtIndex(i, 'member');
        } else {
          travelers.setTravelerTypeAtIndex(i, 'companion');
        }
      }
    }
    return travelers;
  }

  travelerInputs() {
    const { user, travelers, members } = this.state;
    const isFlightCustomerInfoRequired = this.state.domesticAirExist || this.state.foreignExist;
    const isHotelInfoRequired = this.state.hotelExist;

    return travelers.list.map((traveler, i) => (
      <div key={i}>
        <p className="reserve-confirm__traveler__peoplenum">{i + 1}人目</p>
        <div className="reserve-confirm__traveler">
          {/* - user=reserveInfo.user */}
          <label className="reserve-confirm__traveler__type">
            <input
              type="radio"
              value="self"
              onChange={this.handleTravelerTypeChange(i)}
              checked={traveler.type === 'self'}
            />
            <span>自分の手配をする</span>
          </label>
          {traveler.type === 'self' && (
            <div className="reserve-confirm__traveler__from-member">
              <div className="select-travelers__selected-list__item">
                <div className="members-list__item__info">
                  {user.departmentName() && <p className="members-list__item__name">{user.departmentName()}</p>}
                  <p className="members-list__item__name">{user.name}</p>
                  <p className="members-list__item__birthday">({user.email})</p>
                </div>
              </div>
            </div>
          )}
          {user.show_other_members && members && members.length > 0 && (
            <>
              <label className="reserve-confirm__traveler__type">
                <input
                  type="radio"
                  value="member"
                  onChange={this.handleTravelerTypeChange(i)}
                  checked={traveler.type === 'member'}
                />
                <span>他の人の手配をする</span>
              </label>
              {traveler.type === 'member' && (
                <div className="reserve-confirm__traveler__from-member">
                  <SelectTravelers
                    travelers={travelers.list}
                    currentIndex={i}
                    onSelect={this.handleTravelerSelect}
                  />
                </div>
              )}
            </>
          )}
          <label className="reserve-confirm__traveler__type">
            <input
              className="companion-label"
              type="radio"
              value="companion"
              onChange={this.handleTravelerTypeChange(i)}
              checked={traveler.type === 'companion'}
            />
            <span>手動で入力</span>
          </label>
          {traveler.type === 'companion' && (
            <div className="reserve-confirm__traveler__from-member">
              <div className="reserve-confirm__traveler__input-area">
                <div className="reserve-confirm__label">氏名（ローマ字）</div>
                <div className="reserve-confirm__traveler__input-area-right">
                  <input
                    className="reserve-confirm__input-name last-name"
                    type="text"
                    placeholder="例) Yamada"
                    value={traveler.lastNameRoman}
                    onChange={travelers.handleTravelerInfoChange(i, 'setLastNameRoman')}
                  />
                  <input
                    className="reserve-confirm__input-name first-name"
                    type="text"
                    placeholder="例) Ichiro"
                    value={traveler.firstNameRoman}
                    onChange={travelers.handleTravelerInfoChange(i, 'setFirstNameRoman')}
                  />
                </div>
              </div>
              <div className="reserve-confirm__traveler__input-area">
                <div className="reserve-confirm__label">氏名（カナ）</div>
                <div className="reserve-confirm__traveler__input-area-right">
                  <input
                    className="reserve-confirm__input-name last-name"
                    type="text"
                    placeholder="例) ヤマダ"
                    value={traveler.lastNameKana}
                    onChange={travelers.handleTravelerInfoChange(i, 'setLastNameKana')}
                  />
                  <input
                    className="reserve-confirm__input-name first-name"
                    type="text"
                    placeholder="例) イチロウ"
                    value={traveler.firstNameKana}
                    onChange={travelers.handleTravelerInfoChange(i, 'setFirstNameKana')}
                  />
                </div>
              </div>
              {isFlightCustomerInfoRequired && (
                <div className="reserve-confirm__traveler__input-area">
                  <div className="reserve-confirm__label">誕生日</div>
                  <div className="reserve-confirm__traveler__input-area-right">
                    <input
                      className="reserve-confirm__input-birthday"
                      type="text"
                      placeholder="例) 1986-01-01"
                      value={traveler.birthday}
                      onChange={travelers.handleTravelerInfoChange(i, 'setBirthday')}
                    />
                  </div>
                </div>
              )}
              {(isFlightCustomerInfoRequired || isHotelInfoRequired) && (
                <div className="reserve-confirm__traveler__input-area">
                  <div className="reserve-confirm__label">性別</div>
                  <div className="reserve-confirm__traveler__input-area-right">
                    <label className="reserve-confirm__radio-label">
                      <input
                        className="reserve-confirm__radio-input"
                        type="radio"
                        value="male"
                        checked={traveler.flightGender === 'male'}
                        onChange={travelers.handleTravelerInfoChange(i, 'setFlightGender')}
                      />
                      <span>男性</span>
                    </label>
                    <label className="reserve-confirm__radio-label">
                      <input
                        className="reserve-confirm__radio-input"
                        type="radio"
                        value="female"
                        checked={traveler.flightGender === 'female'}
                        onChange={travelers.handleTravelerInfoChange(i, 'setFlightGender')}
                      />
                      <span>女性</span>
                    </label>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    ));
  }

  render() {
    const {
      serviceId,
      query,
      store,
      selectErrors,
      handleNextButtonClick,
      handleSelectItem,
      handleAdditionalSearch,
      additionalSearchErrors,
      handleOpenShowSearchBox,
      reserveRequestMailNotificationType,
      shortdistance
    } = this.props;
    const { showSearchResult } = this.state;
    try {
      const baseClass = 'arrangement-request-form';
      const elm = this.state.loading ? (
        <SimpleLoading />
      ) : this.state.loadingError ? (
        <ErrorText>{this.state.loadingError}</ErrorText>
      ) : (
        <form className={`${baseClass}`} onSubmit={e => this.handleFormSubmit(e)}>
          <FormWrap>
            <FormBox01>
              <div className={`${baseClass}__field`}>
                <label>検索条件</label>
                <SubInfo data={this.props.query.getSummaryData()} customFontSize="13px" />
              </div>
              {reserveRequestMailNotificationType === 'selectable_user' ? (
                <div className={`${baseClass}__field`}>
                  <label>リクエストに関するメール通知対象者の選択</label>
                  <div>
                    {this.state.requestInfo && (
                      <NotifiedUserSelectorTemplate
                        applicant={this.state.user}
                        users={this.state.requestInfo?.notifiedUsers ?? []}
                        onAdd={this.handleNotifiedUserAdd}
                        onSelect={this.handleNotifiedUserSelect}
                        onRemove={this.handleNotifiedUserRemove}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div className={`${baseClass}__field`}>
                    <label>出張者</label>
                    <div>{this.travelerInputs()}</div>
                  </div>
                  {this.state.requestInfo && (
                    <div className={`${baseClass}__field`}>
                      <ArrangementRequestInfoForm requestInfo={this.state.requestInfo} />
                    </div>
                  )}
                </>
              )}
              <div className={`${baseClass}__field`}>
                <label>備考欄（任意）</label>
                <textarea
                  rows={10}
                  onChange={e => this.setState({ memo: e.target.value })}
                  value={this.state.memo}
                />
              </div>
              <div className={`${baseClass}__field`}>
                <input
                  type="checkbox"
                  className="trip_foreign"
                  checked={this.state.tripForeign}
                  onChange={this.handleTripForeignCheck}
                />
                海外出張
              </div>
            </FormBox01>
            <FormBox02>
              {showSearchResult && (
                <OutlineWrapper>
                  {shortdistance ? (
                    <SelectOutlineWrapperShortdistanceTemplate
                      query={query}
                      store={store}
                      selectErrors={selectErrors}
                      packageErrors={store.packageErrors[store.result.airline]}
                      handleSelectItem={handleSelectItem}
                      handleAdditionalSearch={handleAdditionalSearch}
                      additionalSearchErrors={additionalSearchErrors}
                      handleNextButtonClick={handleNextButtonClick}
                      submitting={this.state.submitting}
                      isRequestForm
                      handleOpenShowSearchBox={handleOpenShowSearchBox}
                    />
                  ) : (
                    <SelectOutlineWrapperTemplate
                      query={query}
                      store={store}
                      selectErrors={selectErrors}
                      packageErrors={store.packageErrors[store.result.airline]}
                      handleSelectItem={handleSelectItem}
                      handleAdditionalSearch={handleAdditionalSearch}
                      additionalSearchErrors={additionalSearchErrors}
                      handleNextButtonClick={handleNextButtonClick}
                      submitting={this.state.submitting}
                      isRequestForm
                      handleOpenShowSearchBox={handleOpenShowSearchBox}
                    />
                  )}
                </OutlineWrapper>
              )}
              <label htmlFor="showSearchResult">
                <input
                  type="checkbox"
                  id="showSearchResult"
                  checked={showSearchResult}
                  onChange={() => this.handleShowSearchResultUpdate()}
                />
                この旅程をリクエストに表示する。
              </label>
            </FormBox02>
          </FormWrap>
          <div className={`${baseClass}__field`}>
            <small>{`※こちらのリクエストフォームからご依頼いただいた場合、別途手数料として${utils.formatPrice(
              this.state.setting.fee
            )}が発生します。`}</small>
          </div>
          {this.state.errors.length > 0 && (
            <div className={`${baseClass}__field`}>
              {this.state.errors.map((e, i) => (
                <ErrorText key={i}>{e}</ErrorText>
              ))}
            </div>
          )}
          <Small className={`${baseClass}__field`}>
            <>
              予約（決済）完了後からご依頼いただいた各商品のキャンセル規程が適用されます。
              <br />
              ご依頼いただいた各商品について、在庫情報のタイムラグ等によりご希望に添えなかった場合は代替案をご連絡いたしますのでご了承ください。
              <br />
            </>
            {utils.isMynavi(serviceId) && (
              <>
                予約依頼時に、
                <A href="https://btm.mynavi.jp/terms/pdf/hojinkiyaku.pdf" target="_blank" rel="noopener">
                  マイナビBTM会員向け利用規約
                </A>
                、
                <A href="https://btm.mynavi.jp/terms/pdf/yakkan_arrange.pdf" target="_blank" rel="noopener">
                  標準旅行業約款
                </A>
                、
                <A href="https://btm.mynavi.jp/terms/pdf/privacypolicy.pdf" target="_blank" rel="noopener">
                  個人情報の取り扱い
                </A>
                について同意するものとします。
              </>
            )}
            {utils.isTabikobo(serviceId) && (
              <>
                また、予約（決済）時に弊社の
                <A href="https://www.tabikobo.com/policy/" target="_blank" rel="noopener">
                  個人情報保護方針
                </A>
                に同意するものとします。
              </>
            )}
            {utils.isAiTravel(serviceId) && (
              <>
                ユーザーは、キャンセル時に株主優待券を受領した場合、弊社に返却の必要があることをご了承ください。
                <br />
                <br />
                また、予約（決済）時に弊社の
                <A href="https://aitravel.cloud/tos/" target="_blank" rel="noopener">
                  利用約款
                </A>
                、
                <A
                  href="https://d14vxaqxdw6ji3.cloudfront.net/images/travel_terms.pdf"
                  target="_blank"
                  rel="noopener"
                >
                  旅行業約款
                </A>
                ・
                <A
                  href="https://d14vxaqxdw6ji3.cloudfront.net/images/requirements.pdf"
                  target="_blank"
                  rel="noopener"
                >
                  手配旅行条件書
                </A>
                、
                <A href="https://transferdata.co.jp/privacy-policy#a" target="_blank" rel="noopener">
                  プライバシーポリシー
                </A>
                に同意するものとします。
              </>
            )}
            <br />
            <label>
              <input
                type="checkbox"
                className="terms_agree"
                checked={this.state.termsAgree}
                onChange={this.handleAgreementTermsCheck}
              />
              上記に同意する
            </label>
          </Small>
          <div className={`${baseClass}__field`}>
            <Submit type="submit" value="送信" disabled={this.state.submitting || !this.state.termsAgree} />
          </div>
        </form>
      );
      return elm;
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const FormWrap = styled.div`
  display: flex;
  ${media.sp`
    display: block;
  `}
`;

const FormBox01 = styled.div`
  width: 100%;
`;

const FormBox02 = styled.div`
  width: 100%;
  align-self: flex-start;
  ${media.sp`
    background: ${props => props.theme.grayBgColor};
  `}
`;

const OutlineWrapper = styled.div`
  margin-bottom: 10px;
  background: #fff;
`;

const ErrorText = styled.p`
  color: ${props => props.theme.redColor};
`;

const Submit = styled.input`
  &&& {
    ${ButtonBase}
  }
`;

const Small = styled.div`
  font-size: 11px;
`;

import { Fetcher } from '@this/src/util';

export type TravelerCandidate = {
  id: number;
  birthday: string;
  email: null | string;
  first_name: string;
  first_name_kana: string;
  first_name_roman: string;
  gender: 'male' | 'female';
  last_name: string;
  last_name_kana: string;
  last_name_roman: string;
  middle_name_roman: string;
  organization_id: number;
  passport_expire: null | string;
  passport_number: null | string;
  mileage_numbers: (string | { carrier: string; number: string })[];
  tel: string;
};

export default class UserSearchRepositoty {
  static search(orgId: number, text: string): Promise<TravelerCandidate[]> {
    return Fetcher.get<TravelerCandidate[]>(`/arrangement/organizations/${orgId}/users/search.json`, {
      query: text
    });
  }
}

Order = require('../order')
NonOrderItem = require('../non_order_item').default
User = require('../user/user')
Hotel = require('../hotel/hotel').default
Project = require('../project/project').default
ProjectList = require('../project/project_list')
TravelerList = require('../traveler/traveler_list')
RentalCarList = require('../rental_car/rental_car_list')
MarginTypeList = require('../organization/margin_type_list').default
TripRuleError = require("./trip_rule_error").default
TripRuleDistanceTimeInfo = require('./trip_rule_distance_time_info').default
TripStatus = require('./trip_status').default
SnoozedTodo = require('../snoozed_todo').default
moment = require('moment-timezone')
TripApproveItemList = require('../trip_approve_item/trip_approve_item_list').default
roundString = require('../../util/string').roundString

makeLines = (str) ->
  if str
    str.split('\n')
  else
    []

class Trip
  constructor: (args = {}) ->
    _.assign @, args
    # in_advance_approval.status:4 は、queueステータスでまだ承認待ちの待ちステータス
    # 承認ルートを事前に作る実装を入れた結果、フロントエンドに送られてくるようになった値だが、
    # フロントエンドでは不要な情報なので、間引く
    @in_advance_approval = _.filter args.in_advance_approval, (i) -> i.status != 4
    @serviceId = parseInt(dig(args, 'user', 'organization', 'service_id'))
    @startTime = moment(args.start_time) if args.start_time
    @endTime = moment(args.end_time) if args.end_time
    @cacheStartTime = moment(args.cache_start_time) if args.cache_start_time
    @cacheEndTime = moment(args.cache_end_time) if args.cache_end_time
    @receivedAt = if args.received_at_in_tokyo then moment(args.received_at_in_tokyo)
    @showFee =
      if typeof args['showFee'] == 'undefined'
        if typeof dig(args, 'user', 'organization', 'show_fee') == 'undefined'
          true
        else
          dig(args, 'user', 'organization', 'show_fee')
      else
        args['showFee']
    @isInternalNumberRequired = args.isInternalNumberRequired
    @isProjectNameRequired = args.isProjectNameRequired
    @forbiddenRepositories = dig(args, 'user', 'organization', 'forbidden_repositories')
    @marginTypes = dig(args, 'user', 'organization', 'margin_types')
    @marginTypes = new MarginTypeList @marginTypes if @marginTypes
    @hotel = new Hotel(@hotel || @hotel_json)
    # TODO: ts化する時に@projectを削除しても良いかもしれない
    # 使われてない？
    @project =
      if @project
        new Project(@project)
      else
        new Project({ id: 0, code: '', name: '' })
    @projects = new ProjectList(@projects)
    @currentOrder = @current_order
    @order = new Order(_.merge(@current_order, @orderOptions()), @)
    @firstOrder = new Order(_.merge(@first_order, @orderOptions()), @)
    @approvedOrder = new Order(_.merge(@approved_order, @orderOptions()), @) if @approved_order
    @appliedOrder = new Order(_.merge(@applied_order, @orderOptions()), @)
    @nonOrderItems =
      if args.non_order_items
        _.map args.non_order_items, (raw) -> new NonOrderItem(utils.snakeToCamelObject(raw))
      else
        []
    @travelerInformations = new TravelerList(args.traveler_informations)
    @currentTravelerInformations = new TravelerList(args.current_traveler_informations)
    @travelers =
      if @currentTravelerInformations.list.length
        @currentTravelerInformations
      else
        @travelerInformations
    @rentalCars = new RentalCarList(@rentalcar_requests, @car_type_options)
    @status = args.status
    @statusStr = if @status then TripStatus[@status] else ''
    @approveStageCount = args.approve_stage_count
    @ruleErrors = _.map(args.cache_rule_errors, (e) -> new TripRuleError(e))  || []
    @ruleDistanceTimeInfo = _.map(args.trip_distance_and_time_info, (info) -> new TripRuleDistanceTimeInfo(info))  || []
    @progress = args.progress
    @internalNumber = args.internal_number || ''
    @departments = args.charging_departments || []
    @expenseAccountTypeId = args.order
    if args.applicant
      if args.applicant.department?.approval_authorities
        approval_authorities = args.applicant.department.approval_authorities
        args.applicant.department.approval_authorities = _.filter approval_authorities, (aa) -> aa.workflow_id == args.workflow_id
        @applicant = new User(args.applicant)
      else
        @applicant = new User(args.applicant)
    if args.orders
      @orders =
        args.orders
          .map((o) -> new Order(_.merge(o, { showFee: @showFee, marginTypes: @marginTypes, billing: args.billing })))
          .sort((o1, o2) -> if o1.createdAt > o2.createdAt then 1 else -1)
      for o in @orders
        o.trip = @
    @flightApproveExpiredAt = if args.flight_approve_expired_at
      moment(args.flight_approve_expired_at).tz("Asia/Tokyo").format('YYYY年MM月DD日HH時mm分')
    else
      null

    if @order.orderItems.present?
      @showDepartmentDetail = @checkHasShareOrAllDeptIdDifferent()
      @showProjectDetail = @checkHasShareOrAllProjectIdDifferent()
      @showExpenseDetail = @checkAllExpensesAccountTypeIdDifferent()
    else
      @showDepartmentDetail = args.show_department_detail
      @showProjectDetail = args.show_project_detail
      @showExpenseDetail = args.show_expenses_account_type_detail

    # for /arrangement/todo_list
    @latestUserMessage = args.latest_user_message
    if args.snoozed_todos
      @snoozedTodos = _.map args.snoozed_todos, (raw) -> new SnoozedTodo(raw)

    if args.trip_approve_item
      jsondata = JSON.parse(args.trip_approve_item.json)
      @tripApproveItem = new TripApproveItemList(jsondata)
    @isTicketingRequestable = args.ticketing_requestable
    @isSeatReservationEnable = args.seat_reservation_enable
    @privateUse = args.private_use

  textLength: 10

  orderOptions: ->
    serviceId: @serviceId
    showFee: @showFee
    marginTypes: @marginTypes
    trip: @

  dateRange: ->
    # OUTDATED: 全てのケースでtripにstart_timeとend_timeを含まれるように修正したらこれ消す
    # 旅程が更新されて日程に変更があった場合にtripのstart_timeとend_timeと実際の日程にズレが生じるため
    # orderの内容からその都度計算することになった
    start = @cacheStartTime || @startTime ||  @order.startDate()
    end = @cacheEndTime || @endTime ||  @order.endDate()
    try
      moment(start).format('Y/MM/DD') + '〜' + moment(end).format('Y/MM/DD')
    catch e
      if typeof bugsnagClient != 'undefined'
        bugsnagClient.notify e,
          metaData:
            tripId: @id
            start: start
            end: end
          severity: 'error'
      ''

  stayDays: ->
    o = @order
    return '' unless o

    s = o.startDate()
    e = o.endDate()
    retrun '' unless s && e

    days = e.diff(s, 'days')
    "#{days}泊#{days + 1}日"

  userName: ->
    dig(@user, 'name')

  traveledUsers: ->
    if @travelerInformations.list && @travelerInformations.list.length > 0
      @travelerInformations.list
    else
      [@user]

  travelerNames: ->
    if @travelerInformations.list && @travelerInformations.list.length > 0
      @travelerInformations.getTravelerNames()

  travelerShinkansenTypes: ->
    if @travelerInformations.list && @travelerInformations.list.length > 0
      @travelerInformations.getTravelerShinkansenTypes()

  departmentName: ->
    if @applicant
      dig(@applicant, 'department', 'name')

  setDepartureLocation: ->
    elements = []
    items = if @order.orderItems?.length > 0 then @order.orderItems else @nonOrderItems
    for oi in items
      Array.prototype.push.apply(elements, _.filter oi.elements, (e) -> e.from != undefined)

    @departure_location = ''
    if @order.orderItems?.length > 0
      @departure_location = if elements.length > 0 then elements[0].from.name else ''
    else if @nonOrderItems?.length > 0
      @departure_location = elements[0]?.from?.name || elements[0]?.boardingStation || ''

  originText: ->
    if !@departure_location
      @setDepartureLocation()
    roundString(@departure_location, 30)

  originTitle: ->
    if !@departure_location
      @setDepartureLocation()
    else if @departure_location.length > @textLength
      @departure_location

  setDistination: ->
    if !@departure_location
      @setDepartureLocation()
    from = @departure_location
    elements = []
    items = if @order.orderItems?.length > 0 then @order.orderItems else @nonOrderItems

    for oi in items
      Array.prototype.push.apply(elements, _.filter oi.elements, (e) -> e.to != undefined && e.to.name != from)

    @destination = ''
    if @order.orderItems?.length > 0
      @destination = if elements.length > 0 then (_.map elements, (e) -> e.to.name).join('、') else ''
    else if @nonOrderItems?.length > 0
      @destination = if elements.length > 0 then (_.map elements, (e) -> e.to?.name || e.arrivalStation).join('、') else ''

  destinationText: ->
    if !@destination
      @setDistination()
    roundString(@destination, 30)

  destinationTitle: ->
    if !@destination
      @setDistination()
    else if @destination.length > @textLength
      @destination

  outwordLines: ->
    makeLines(dig(@order, 'outword'))

  homewordLines: ->
    makeLines(dig(@order, 'homeword'))

  flightLines: ->
    makeLines(dig(@order, 'flight'))

  hotelName: ->
    dig(@order, 'hotel_name')

  hotelNameTitle: ->
    if @hotelName() && @hotelName().length > @textLength
      @hotelName()

  hotelCheckinDate: ->
    dig(@order, 'hotel_checkin_date') ||
      (dig(@search_query, 'departure_time') && moment(dig(@search_query, 'departure_time')))

  hotelCheckoutDate: ->
    dig(@order, 'hotel_checkout_date') ||
      (dig(@search_query, 'return_time') && moment(dig(@search_query, 'return_time')))

  hotelRoomType: ->
    dig(@hotel_json, 'room_type')

  outwordPrice: ->
    if @order.orderItems.length > 0
      @order.outwordItem.price.totalPrice()
    else
      dig(@order, 'outword_price') || @outword_price

  outwordDate: ->
    dig(@order, 'outword_date')

  homewordPrice: ->
    if @order.orderItems.length > 0
      @order.homewordItem.price.totalPrice()
    else
      dig(@order, 'homeword_price') || @homeword_price

  homewordDate: ->
    dig(@order, 'homeword_date')

  flightPrice: ->
    items = _.filter @order.orderItems, (item) -> item.orderItemCategory == 'foreign_air'
    _.sumBy items, (item) -> item.totalPriceWithAll()

  flightOutwordDate: ->
    dig(@order, 'flight_outword_date')

  flightHomewordDate: ->
    dig(@order, 'flight_homeword_date')

  hotelPrice: ->
    items = _.filter @order.orderItems, (item) -> item.orderItemCategory == 'hotel'
    _.sumBy items, (item) -> item.totalPriceWithAll()

  shinPrice: ->
    categories = ['shinkansen', 'express', 'railway_ticket']
    items = _.filter @order.orderItems, (item) -> _.includes(categories, item.orderItemCategory)
    _.sumBy items, (item) -> item.totalPriceWithAll()

  airPrice: ->
    items = _.filter @order.orderItems, (item) -> item.orderItemCategory == 'domestic_air'
    _.sumBy items, (item) -> item.totalPriceWithAll()

  otherPrice: ->
    categories = ['hotel', 'domestic_air', 'foreign_air', 'shinkansen', 'express', 'railway_ticket']
    items = _.filter @order.orderItems, (item) -> !_.includes(categories, item.orderItemCategory)
    _.sumBy items, (item) -> item.totalPriceWithAll()

  shinkansenFee: ->
    dig(@order, 'shinkansen_fee') || 0

  foreignAirFee: ->
    dig(@order, 'foreign_air_fee') || 0

  fee: ->
    domesticAir = dig(@order, 'domestic_air_fee') || 0
    foreignAir = dig(@order, 'foreign_air_fee') || 0
    shin = dig(@order, 'shinkansen_fee') || 0
    domesticAir + foreignAir + shin

  totalPrice: ->
    dig(@order, 'totalPrice') + @nonOrderItemsTotalPrice()

  totalPriceWithAll: ->
    dig(@order, 'totalPriceWithAll') || 0 + @nonOrderItemsTotalPrice()

  cancelFee: ->
    dig(@order, 'cancelFee')

  nonOrderItemsTotalPrice: (types = []) ->
    if @nonOrderItems
      items =
        if types.length == 0
          @nonOrderItems
        else
          _.filter @nonOrderItems, (item) -> item.isElementTypes(types)
      if items.length > 0
        return _.map(items, (item) -> item.totalPrice()).reduce((res, ele) -> res + ele)
    0

  totalPricePerTraveler: ->
    Math.ceil(@totalPrice() / @traveledUsers().length)

  hotelPricePerTraveler: ->
    Math.ceil(@hotelPrice() / @traveledUsers().length)

  flightPricePerTraveler: ->
    Math.ceil(@flightPrice() / @traveledUsers().length)

  airPricePerTraveler: ->
    Math.ceil(@airPrice() / @traveledUsers().length)

  foreignAirFeePerTraveler: ->
    Math.ceil(@foreignAirFee() / @traveledUsers().length)

  shinPricePerTraveler: ->
    Math.ceil(@shinPrice() / @traveledUsers().length)

  shinkansenFeePerTraveler: ->
    Math.ceil(@shinkansenFee() / @traveledUsers().length)

  otherPricePerTraveler: ->
    Math.ceil(@otherPrice() / @traveledUsers().length)

  outwordPriceStr: ->
    formatPrice(@outwordPrice())

  homewordPriceStr: ->
    formatPrice(@homewordPrice())

  flightPriceStr: ->
    formatPrice(@flightPrice())

  hotelPriceStr: ->
    formatPrice(@hotelPrice())

  feeStr: ->
    formatPrice(@fee())

  totalPriceStr: ->
    formatPrice(@totalPrice())

  reserveDate: ->
    moment(@receivedAt).format('YYYY-MM-DD')

  outwordType: ->
    t = dig(@order, 'outword')
    return unless t && t.length > 0
    if t.indexOf('新幹線') > -1
      'shin'
    else
      'air'

  homewordType: ->
    t = dig(@order, 'homeword')
    return unless t && t.length > 0
    if t.indexOf('新幹線') > -1
      'shin'
    else
      'air'

  pastTimeInfo: ->
    now = new Date()
    tripDate = new Date(@last_action_at || @updated_at)
    millisec = now - tripDate
    if millisec < 1000
      sinceLastUpdate: 'たった今'
      longTimeNoUpdateFlg: false
    else if millisec / 1000 < 60
      sinceLastUpdate: (Math.floor millisec / 1000) + '秒前'
      longTimeNoUpdateFlg: false
    else if millisec / 1000 / 60 < 60
      sinceLastUpdate: (Math.floor millisec / 1000 / 60) + '分前'
      longTimeNoUpdateFlg: false
    else if millisec / 1000 / 60 / 60 < 24
      sinceLastUpdate: (Math.floor millisec / 1000 / 60 / 60) + '時間前'
      longTimeNoUpdateFlg: false
    else
      sinceLastUpdate: (Math.floor millisec / 1000 / 60 / 60 / 24) + '日前'
      longTimeNoUpdateFlg: true

  startDate: (format) ->
    d = @order.startDate() || @startTime
    if d
      d.format(format || 'YYYY-MM-DD')
    else
      @checkin_date

  endDate: (format) ->
    d = @order.endDate() || @endTime
    if d
      d.format(format || 'YYYY-MM-DD')
    else
      @checkout_date

  isEnd: -> @endDate() && moment(@endDate()).isBefore(moment()) && @status == 7

  isNotOverEndTime: ->
    t = @cacheEndTime || @endTime
    if t
      t.isAfter(moment())
    else
      false

  isComplete: -> @status == 7

  isArchived: -> @isEnd() || @isCanceled() || @isCanceling() || @isApprovalCanceled()

  isOngoing: -> !@isArchived()

  isReserveRequied: -> @status == 3

  isFixed: -> @status == 4

  isCanceled: -> @status == 5

  # おそらく、orderItem にキャンセル情報を保存したタイミングで、trip.status の値を変更して、status でtrip のキャンセル状態を
  # 判定するのが、論理的には正しいと思われるが、過去のデータとの互換性を考えると対応が大変なため、
  # 一旦 order や orderItem の状態から、当該の trip がキャンセルされたか否かを判定する関数を生やす。
  isTripCanceled: ->
    dig(@order, 'isCanceled')

  isCanceling: -> @status == 6

  isFlightCanceled: -> @flight_status == 6

  isHomeWordCanceled: -> @homeword_status == 6

  isOutWordCanceled: -> @outword_status == 6

  isHotelCanceled: -> @hotel_status == 6

  isPostNumberMissing: ->
    result = false
    _.each @order.orderItems, (item) ->
      _.each item.elements, (el) ->
        if el.type == 'transport' && el.isRailway() && _.isEmpty(el.postingNumber)
          result = true
    result

  isAirNumbersMissing: ->
    result = false
    _.each @order.orderItems, (item) ->
      _.each item.elements, (el) ->
        if el.type == 'transport' && el.isDomesticAir() && el.isAirNumbersMissing()
          result = true
    result

  isTicketingRequested: ->
    @foreign_air && @ticketing_requested_at

  cancelFeeText: ->
    if @isTicketingRequested()
      if @order.isTicketingExpired()
        '取消料 + 5,000円'
      else
        '5,000円'
    else
      '0円'

  # 旅工房版のリクエストフォーム限定の機能
  isConfirmationRequestable: ->
    @currentOrder.traveler_confirmation_status == 'unrequested'

  projectId: ->
    @order.orderItems[0]?.orderItemMappings.first()?.projectId || null

  departmentId: ->
    @order.orderItems[0]?.orderItemMappings.first()?.chargingDepartmentId || null

  expensesAccountTypeId: ->
    @order.orderItems[0]?.orderItemMappings.first()?.expensesAccountTypeId || null

  tripReportId: ->
    tripReport = @orderedTripReport() || @nonOrderedTripReport()
    if tripReport && ['approved', 'self_approved'].includes(tripReport.status)
      tripReport.id

  orderedTripReport: ->
    tripReport = null
    _.each @order.orderItems, (item) ->
      if !tripReport
        tripReport = dig(item, 'price', 'orderItemPriceDetails', 0, 'tripReport') || null
    return tripReport

  nonOrderedTripReport: ->
    tripReport = null
    _.each @non_order_items, (item) ->
      if !tripReport
        tripReport = item.trip_report || null
    return tripReport

  isProjectMappingsExist: ->
    _.some @order.orderItems, (item) ->
      _.some item.orderItemMappings.list, (mapping) -> mapping.isProjectExist()

  isDepartmentMappingsExist: ->
    _.some @order.orderItems, (item) ->
      _.some item.orderItemMappings.list, (mapping) -> mapping.isChargingDepartmentExist()

  isExpensesAccountTypeMappingsExist: ->
    _.some @order.orderItems, (item) ->
      _.some item.orderItemMappings.list, (mapping) -> mapping.isExpensesAccountTypeExist()

  isProjectNonOrderItemMappingsExist: ->
    _.some @nonOrderItems, (item) ->
      _.some item.nonOrderItemMappings, (mapping) -> mapping.isProjectExistForNonOrderItemMappings()

  isDepartmentNonOrderItemMappingsExist: ->
    _.some @nonOrderItems, (item) ->
      _.some item.nonOrderItemMappings, (mapping) -> mapping.isChargingDepartmentExistForNonOrderItemMappings()

  isExpensesAccountTypeNonOrderItemMappingsExist: ->
    _.some @nonOrderItems, (item) ->
      _.some item.nonOrderItemMappings, (mapping) -> mapping.isExpensesAccountTypeExistForNonOrderItemMappings()

  checkAllExpensesAccountTypeIdDifferent: ->
    flg = false
    id = @expensesAccountTypeId()
    _.each @order.orderItems, (item) ->
      itemExpenseId = item.orderItemMappings.first()?.expensesAccountTypeId || null
      if itemExpenseId != id
        flg = true
    return flg

  checkHasShareOrAllDeptIdDifferent: ->
    flg = false
    id = @departmentId()
    _.each @order.orderItems, (item) ->
      itemDeptId = item.orderItemMappings.first()?.chargingDepartmentId || null
      itemDeptShareId = item.orderItemMappings.first()?.chargingDepartmentShareId || null
      if itemDeptId != id || itemDeptShareId != null
        flg = true
    return flg

  checkHasShareOrAllProjectIdDifferent: ->
    flg = false
    id = @departmentId()
    _.each @order.orderItems, (item) ->
      itemProjectId = item.orderItemMappings.first()?.projectId || null
      itemProjectShareId = item.orderItemMappings.first()?.projectShareId || null
      if itemProjectId != id || itemProjectShareId != null
        flg = true
    return flg

  projectName: ->
    dig(@projects.list, 0, 'name') || dig(@, 'project', 'name')

  projectCode: ->
    dig(@, 'project', 'code')

  internalNumber: ->
    dig(@, 'internal_number')

  internalNumberPlceHolder: ->
    if @isInternalNumberRequired
      '(必須)社内稟議番号など'
    else
      '(任意)社内稟議番号など'

  statusSt: ->
    approveStatus = dig(@in_advance_approval, @in_advance_approval.length - 1, 'status')
    switch approveStatus
      when 0
        '承認待ち'
      when 1, null
        switch @status
          when 1
            '旅程リクエスト中'
          when 3
            if @currentOrder == null and @nonOrderItems != null
              # non_order_itemのみの場合
              if approveStatus == 1
                '承認済み'
              else
                '旅程作成済'
            else
              # order_itemがある場合
              '手配中'
          when 4, 7
            '手配済み'
          when 5, 6
            'キャンセル'
      when 2
        '却下済み'
      when 3
        'キャンセル'

  statusAtApproval: (i) ->
    switch dig(@in_advance_approval, i, 'status')
      when 0
        '承認前'
      when 1
        '承認済み'
      when 2
        '却下済み'
      when 3
        'キャンセル'

  approvedAt: (i) ->
    approved_at = dig(@in_advance_approval, i, 'approved_at')
    if approved_at == null
      return ''
    moment(approved_at).tz("Asia/Tokyo").format('YYYY年MM月DD日HH時mm分')

  rejectedAt: (i) ->
    rejected_at = dig(@in_advance_approval, i, 'rejected_at')
    if rejected_at == null
      return ''
    moment(rejected_at).tz("Asia/Tokyo").format('YYYY年MM月DD日HH時mm分')

  submittedApproverLength: ->
    _.filter(@in_advance_approval, (app) -> app.status != 0).length

  hasRejected: ->
    _.filter(@in_advance_approval, (app) -> app.status == 2).length > 0

  createdAt: (format) ->
    moment(@created_at).tz("Asia/Tokyo").format(format || 'YYYY年MM月DD日HH時mm分')

  cenceldAt: ->
    if @canceled_at
      moment(@canceled_at).format('YYYY-MM-DD')
    else if @status == 5
      moment(@updated_at).format('YYYY-MM-DD')
    else
      ''

  setProject: (p) ->
    if p
      @projects.list[0] = p
    else
      @projects.list = []
    app.render()

  setDepartment: (d) ->
    @departments = if d then [d] else []
    app.render()

  setInternalNumber: (number) ->
    @internalNumber = number
    app.render()

  setFinalDestination: (finalDestination) ->
    @final_destination = finalDestination
    app.render()

  setPurpose: (purpose) ->
    @purpose = purpose
    app.render()

  setHotelReserveUrl: (url) ->
    @hotel_reserve_url = url
    app.render()

  updateParams: ->
    ids = _.compact _.map @projects.list, (p) -> p.id
    project_ids: ids

  isApproveCanceled: ->
    _.find @in_advance_approval, (in_advance_approval) -> in_advance_approval.status == 3

  currentApprover: (user_id) ->
    approversStage = @approvers
      .filter((approver) -> approver.id == user_id)
      .map((approver) -> approver.approve_stage)
    in_advance_approval = @in_advance_approval.filter (iaa) ->
      approversStage.includes(iaa.approve_stage)
    return @in_advance_approval.length - 1 unless in_advance_approval.length
    @in_advance_approval.indexOf(_.maxBy(in_advance_approval, "approve_stage"))

  currentApprovedApprover: (num) ->
    approver_id = dig(@in_advance_approval[num], 'approver_id')
    _.find @approved_member, (approver) -> approver.id == approver_id

  approvedName: (num) ->
    if dig(@approved_member[num], 'name')
      dig(@approved_member[num], 'name')
    else
      'AI Travel'

  nextStageApprovers: (approve_stage) ->
    nextApprovers = _.filter @approvers, (approver) -> approver.approve_stage == approve_stage + 1
    unless _.isEmpty nextApprovers
      _.map nextApprovers, (nextApprovers) -> nextApprovers.name

  isBackStageApprover: (user_id) ->
    approvers = @approvers.filter (approver) -> approver.id == user_id
    return false unless approvers.length
    minApproveStage = _.minBy(approvers, 'approve_stage').approve_stage
    lastIndex = @in_advance_approval.length - 1
    @in_advance_approval[lastIndex].approve_stage < minApproveStage

  approvalStatus: (num) ->
    dig(@in_advance_approval, num, 'status')

  tripDetailEditable: ->
    !@in_advance_approval || !dig(@in_advance_approval, 0, 'status')

  currentApprovalStatus: ->
    if (_.isEmpty(@currentUser) && @in_advance_approval)
      return @approvalStatus(@in_advance_approval.length - 1)
    return @approvalStatus(@currentApprover(@currentUser.id))

  isHotelCheckinExpired: () ->
    expired = false
    _.each @order.orderItems, (item) ->
      # _.each の中で、
      # return はcontinue
      # return false はbreak
      unless item.orderItemCategory == 'hotel'
        return
      unless item.elements && item.elements.length > 0
        return
      el = item.elements[0]
      unless el.checkinDate
        return

      if el.tooLate || !moment().isSameOrBefore(el.checkinDate, 'day')
        expired = true
        return false

    expired

  flightTooLateError: () ->
    !_.isEmpty(@flight_approve_expired_at) && (moment().isSameOrAfter(@flight_approve_expired_at))

  isApproved: (num) ->
    @approvalStatus(num) == 1

  isRejected: (num) ->
    @approvalStatus(num) == 2

  isApprovalCanceled: (num) ->
    @approvalStatus(num) == 3

  isOrderCanceled: ->
    @order.status == 3

  approveReason: (num) ->
    dig(@in_advance_approval[num], 'approve_reason')

  rejectReason: (num) ->
    dig(@in_advance_approval[num], 'reject_reason')

  cancelReason: () ->
    cancelApproval = _.find @in_advance_approval, (i) -> i.status == 3
    cancelApproval.cancel_reason

  approvalChannelId: ->
    checkedApproval = _.last _.sortBy(@in_advance_approval, 'id')
    if checkedApproval?.status == 1
      checkedApproval.approval_channel_id

  approverName: ->
    dig(_.last(@in_advance_approval), 'approver', 'name')

  seatName: ->
    switch @sheet
      when 'free'
        '自由席'
      when 'reserve'
        '指定席'
      when 'green'
        'グリーン席'
      when 'bulk_ticket'
        '回数券'

  oldTotalPrice: ->
    (dig(@firstOrder, 'totalPrice') || @appliedOrder.totalPrice()) + @nonOrderItemsTotalPrice()

  didTotalPriceChange: ->
    @oldTotalPrice() != @totalPrice()

  isSnoozing: ->
    snoozing = false
    if @snoozedTodos
      _.each @snoozedTodos, (todo) ->
        if todo.isSnoozing()
          snoozing = true
          return
    snoozing

  currentSnooze: ->
    snooze = null
    if @snoozedTodos
      _.each @snoozedTodos, (todo) ->
        if todo.isSnoozing()
          snooze = todo
          return
    snooze

  chargingDepartmentsName: ->
    if _.isEmpty @departments
      '未設定'
    else
      @departments[0].name

  setShowDepartmentDetail: (status) ->
    @showDepartmentDetail = status
    app.render()

  setShowProjectDetail: (status) ->
    @showProjectDetail = status
    app.render()

  setShowExpenseDetail: (status) ->
    @showExpenseDetail = status
    app.render()

  isEditableForUser: ->
    # 最初の承認がなされた時点で編集不可とする
    firstApprovalStatus = @approvalStatus(0)
    ![1, 2, 3].includes(firstApprovalStatus) # 承認前、承認なしの場合に編集可能

  getChargingDepartmentIDs: ->
    ids = []
    @departments.forEach (department) ->
      ids.push(department.id)
    return ids

  initialOrderItemFor: (orderItem) ->
    initialOrder = @approvedOrder || @firstOrder
    initialOrder.orderItems.find((item) -> item.traceId == orderItem.traceId)

  isOrderItemModified: (orderItem, path...) ->
    initialItem = @initialOrderItemFor(orderItem)
    diff = orderItem.diffWith(initialItem)
    # 右ナビでorder_itemを追加した場合はinitialOrderItemが存在せず、orderItemDiffもない
    # したがってその場合は全項目変更されたものとする
    if diff
      return _.has(diff, path)
    true

  isAllDone: ->
    return false unless @status == 7

    result = false
    _.each @order.orderItems, (item) ->
      result = true if item.status == 7
    result

  isAllOrderItemStatusComplete: ->
    noncompletelist = @order.orderItems.filter (item) -> item.status != 13 && item.status != 7
    return noncompletelist.length == 0


module.exports = Trip

/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import A from '@this/shared/atoms/a';

import type { OrderItemStatusKey } from '@this/domain/order_item/order_item_status';
import { OrderItemStatus, OrderItemStatusAvailable } from '@this/domain/order_item/order_item_status';
import type Trip from '@this/domain/trip/trip';
import type Order from '@this/domain/order';
import type OrderItem from '@this/domain/order_item';
import type TransportElement from '@this/domain/transport_element';
import type HotelElement from '@this/domain/hotel_element';
import type CancelChargeElement from '@this/domain/cancel_charge_element';
import type SupportFeeElement from '@this/domain/support_fee_element';
import type ShippingElement from '@this/domain/shipping_element';
import type WifiElement from '@this/domain/wifi_element';
import type KitElement from '@this/domain/kit_element';
import type RoomElement from '@this/domain/room_element';
import type TravelAuthorizationElement from '@this/domain/travel_authorization_element';
import type BulkTicket from '@this/domain/bulk_ticket2';
import type ShareholderTicketList from '@this/domain/shareholder_ticket_list/index';
import type TaxType from '@this/domain/tax_type';
import { TAX_TYPE_ID } from '@this/domain/tax_type';
import type SuppliedItem from '@this/domain/supplied_item/supplied_item';

import Modal from '@this/shared/modal/modal';
import Confirm from '@this/shared/confirm/confirm';
import Text from '@this/shared/text/text';
import { Text as DangerText } from '@this/shared/ui/data_displays/typography';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import BilledAtEditor from '@this/domain/billed_at_editor/billed_at_editor';
import { Button } from '@this/src/components/shared/ui/inputs/button';

import { Box } from '@material-ui/core';
import type { OrderItemJobTypeKey } from '@this/src/domain/order_item/order_item_job_type';
import { OrderItemJobType, OrderItemJobTypeKeys } from '@this/src/domain/order_item/order_item_job_type';
import type InsuranceElement from '@this/src/domain/insurance_element';
import type ChangeFeeElement from '@this/src/domain/change_fee_element';
import type PaymentMethodList from '@this/src/domain/payment_method/payment_method_list';
import type { ShareholderInfo } from '@this/domain/other_service_shareholder_info';
import type { CabinOption } from '@this/domain/cabin_option';
import type { IndividualTargetSuppliedItem } from '@this/domain/individual_target_supplied_item';
import { Fetcher, HTTPError } from '@this/src/util';
import type { IndividualDomesticAirSuppliedItem } from '@this/domain/individual_domestic_air_supplied_item';
import {
  ForeignFlightTicketIssuer,
  ForeignFlightTicketIssuerKeys
} from '@this/domain/order_item/foreign_flight_ticket_issuer';
import TransportElementForm from './transport_element_form/transport_element_form';
import HotelElementForm from './hotel_element_form/hotel_element_form';
import CancelElementForm from './cancel_element_form/cancel_element_form';
import SupportFeeElementForm from './support_fee_element_form/support_fee_element_form';
import ShippingElementForm from './shipping_element_form/shipping_element_form';
import WifiElementForm from './wifi_element_form/wifi_element_form';
import KitElementForm from './kit_element_form/kit_element_form';
import RoomElementForm from './room_element_form/room_element_form';
import TravelAuthorizationElementForm from './travel_authorization_element_form/travel_authorization_element_form';
import InsuranceElementForm from './insurance_element_form/insurance_element_form';
import ChangeFeeElementForm from './change_fee_element_form/change_fee_element_form';
import OrderItemPriceForm from './order_item_price_form/order_item_price_form';
import OrderItemStatusForm from './order_item_status_form/order_item_status_form';
import OrderItemWaitingForm from './order_item_waiting_form/order_item_waiting_form';
import { ExicItemDisplay } from './transport_element_form/exic_item_display';

export type PnrRetrieveAllResponseJson = {
  price_result: PnrRetrievePriceResponseJson[];
  flight_result: PnrRetrieveFlightResponseJson[];
};

export type PnrRetrievePriceResponseJson = {
  name: string;
  price: number;
  tax_type_id: number;
};

export type PnrRetrieveFlightResponseJson = {
  id: string;
  dep_date: string;
  dep_time: string;
  arr_date: string;
  arr_time: string;
  from: string;
  to: string;
  carrier: string;
  flight_number: string;
  d_datetime: string;
  a_datetime: string;
  airport_from_name: string;
  airport_to_name: string;
  from_time_zone: string;
  to_time_zone: string;
  cabin: string;
};

interface Props {
  index: number;
  order: Order;
  orderItem: OrderItem;
  selectedTrip: Trip;
  bulkTickets: BulkTicket[];
  shareholderTickets: ShareholderTicketList;
  taxTypes: TaxType[];
  serviceId: number;
  suppliedItems: SuppliedItem[];
  paymentMethods: PaymentMethodList;
  shareholderInfos: ShareholderInfo[];
  cabinOptions: CabinOption[];
  individualTargetSuppliedItems: IndividualTargetSuppliedItem[];
  individualDomesticAirSuppliedItems: IndividualDomesticAirSuppliedItem[];
  handleRemoveItem: (orderItem: OrderItem) => void;
  handleRemoveElement: (orderItem: OrderItem, i: number) => void;
  updateIsAirNumbersMissing: (orderItem: OrderItem) => void;
  reloadSelectedTrip: () => void;
  hotelElementProviderOptions: { [key: string]: string };
  updateForeignAirTransportElements: (
    orderItem: OrderItem,
    retrieveResult: PnrRetrieveFlightResponseJson[]
  ) => void;
  updatePriceDetailsFromPnr: (orderItem: OrderItem, retrieveResult: PnrRetrievePriceResponseJson[]) => void;
}

interface State {
  cancelingHotelId: number | null;
  pnrLoading: boolean;
  pnrLoadResult: { status: '' | 'success' | 'error'; message: string };
}

class PriceChangeFormItem extends React.Component<Props, State> {
  initialOrderItem: OrderItem | undefined;

  orderItemDiff: any | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      cancelingHotelId: null,
      pnrLoading: false,
      pnrLoadResult: { status: '', message: '' }
    };

    const initialItem = props.selectedTrip.initialOrderItemFor(props.orderItem);
    this.initialOrderItem = initialItem;
    this.orderItemDiff = props.orderItem.diffWith(initialItem);
  }

  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    this.orderItemDiff = nextProps.orderItem.diffWith(this.initialOrderItem);
    return true;
  }

  handleHotelCancelClick = (id: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({
      cancelingHotelId: id
    });
  };

  handleHotelCancelConfirm() {
    Fetcher.put(`/hotels/${this.state.cancelingHotelId}/cancel`, {}).then(
      _result => {
        this.setState({
          cancelingHotelId: null
        });
        this.props.reloadSelectedTrip();
      },
      e => {
        alert(
          e instanceof HTTPError && e.response?.data.error
            ? e.response.data.error
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        );
        this.setState({
          cancelingHotelId: null
        });
      }
    );

    this.setState({
      cancelingHotelId: null
    });
  }

  handleHotelCancelAlertClose() {
    this.setState({
      cancelingHotelId: null
    });
  }

  handleAirNumbersChange = (
    elementId: number,
    action: 'handleAirReservationNumberChange' | 'handleAirConfirmationNumberChange',
    value: string
  ) => {
    this.props.orderItem.elements.forEach((el: any) => {
      if (el.type === 'transport' && el.id === elementId) {
        el[action](value);
      }
    });
    this.props.updateIsAirNumbersMissing(this.props.orderItem);
  };

  handleIsForeignChange = (element: HotelElement, address: string) => {
    if (address === '') return;

    Fetcher.get<{ is_foreign: boolean }>(`/places/is_foreign?address=${address}`).then(({ is_foreign }) => {
      element.handleIsForeignChange(is_foreign);
      const price = this.props.orderItem.price;
      if (is_foreign) {
        price.setTaxTypeId(TAX_TYPE_ID.FUKAZEI_ID);
        price.priceDetails?.forEach(priceDetail => priceDetail.setTaxTypeId(TAX_TYPE_ID.FUKAZEI_ID));
        price.originalPrices?.forEach(originalPrice => originalPrice.setTaxTypeId(TAX_TYPE_ID.FUKAZEI_ID));
      } else {
        price.setTaxTypeId(TAX_TYPE_ID.TAXABLE_10_ID);
        price.priceDetails?.forEach(priceDetail => priceDetail.setTaxTypeId(TAX_TYPE_ID.TAXABLE_10_ID));
        price.originalPrices?.forEach(originalPrice => originalPrice.setTaxTypeId(TAX_TYPE_ID.TAXABLE_10_ID));
      }
    });
  };

  handleIsForeignLoad = (element: HotelElement) => {
    if (element.isForeign !== null && element.isForeign !== undefined) return;
    if (element.address === '') return;

    this.handleIsForeignChange(element, element.address);
  };

  classNameForModifiedField = (...path: (string | number)[]): string => {
    // 右ナビでorder_itemを追加した場合はinitialOrderItemが存在せず、orderItemDiffもない
    // したがってその場合は全項目変更されたものとする
    const modified = this.orderItemDiff ? _.has(this.orderItemDiff, path) : true;
    return modified ? 'virtual-counte-price-change-form__modified_field' : '';
  };

  isTransportElement(element: any): element is TransportElement {
    return element.type === 'transport';
  }

  handleReplaceForeignAirInfoFromPnr = (orderItem: OrderItem) => {
    const cabin = orderItem.transports
      .filter(el => el.type === 'transport' && el.isForeignAir())
      .map(el => el.cabin)
      .find(c => c !== undefined);

    const params = {
      pnr_number: orderItem.pnrId,
      people_num: orderItem.travelerInformations.length,
      cabin
    };

    this.setState({
      pnrLoading: true,
      pnrLoadResult: { status: '', message: '' }
    });
    Fetcher.post('/arrangement/amadeus_pnr_retrieve', params)
      .then((res: any) => {
        const responseFlights: PnrRetrieveFlightResponseJson[] = [];
        res.data.result.flight_result.forEach((flight: PnrRetrieveFlightResponseJson) => {
          responseFlights.push(flight);
        });
        this.props.updateForeignAirTransportElements(orderItem, responseFlights);

        const responsePrices: PnrRetrievePriceResponseJson[] = [];
        res.data.result.price_result.forEach((price: PnrRetrievePriceResponseJson) => {
          responsePrices.push(price);
        });
        this.props.updatePriceDetailsFromPnr(orderItem, responsePrices);

        let successMessage = `${res.data.time} PNRから情報を反映に成功しました。`;
        if (responseFlights.length === 0) {
          successMessage = `${successMessage} フライト情報が見つかりませんでした。`;
        }
        if (responsePrices.length === 1 && responsePrices[0].price === 0) {
          successMessage = `${successMessage} 料金情報が見つかりませんでした。`;
        }

        this.setState({
          pnrLoadResult: { status: 'success', message: successMessage }
        });
      })
      .catch(e => {
        let errorTime = '';
        let msg = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        if (e instanceof HTTPError) {
          if (e.response?.data.error.message) {
            msg = e.response.data.error.message;
          }
          errorTime = e.response?.data.error.time;
        }

        this.setState({
          pnrLoadResult: {
            status: 'error',
            message: `${errorTime} PNRから情報を反映に失敗しました。 ${msg}`
          }
        });
      })
      .finally(() => {
        this.setState({
          pnrLoading: false
        });
      });
  };

  render() {
    try {
      const {
        index,
        order,
        orderItem,
        selectedTrip,
        bulkTickets,
        shareholderTickets,
        taxTypes,
        serviceId,
        suppliedItems,
        paymentMethods,
        shareholderInfos,
        cabinOptions,
        individualTargetSuppliedItems,
        individualDomesticAirSuppliedItems,
        handleRemoveItem,
        handleRemoveElement,
        hotelElementProviderOptions
      } = this.props;
      const { cancelingHotelId, pnrLoading } = this.state;
      return (
        <div>
          <Box display="flex" alignItems="center" marginTop="20px">
            <h4 style={{ marginBottom: 0 }}>商品{index + 1}</h4>
            <Box marginLeft="10px">trace_id: {orderItem.traceId}</Box>
            <a
              className="virtual-counte-price-change-form__remove-button"
              onClick={() => handleRemoveItem(orderItem)}
            >
              この商品を削除×
            </a>
          </Box>

          <div className="virtual-counte-price-change-form__item-body">
            {orderItem.isEditing ? (
              <>
                <div style={{ marginBottom: '10px' }}>
                  <div style={{ display: 'flex' }}>
                    <span style={{ marginRight: '5px' }} className={this.classNameForModifiedField('jobType')}>
                      ジョブタイプ::
                    </span>
                    <select
                      onChange={e => orderItem.handleJobTypeChange(e.target.value as OrderItemJobTypeKey)}
                      value={orderItem.jobType}
                      style={{ marginBottom: '5px' }}
                    >
                      {OrderItemJobTypeKeys.map(key => (
                        <option key={key} value={key}>
                          {OrderItemJobType[key]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div style={{ display: 'flex' }}>
                    {/*　新しく作られるアイテムのステータス・請求対象日を変更する (order_itemのコピーが発生する) */}
                    <span style={{ marginRight: '5px' }} className={this.classNameForModifiedField('status')}>
                      ステータス:
                    </span>
                    <select
                      value={orderItem.status}
                      onChange={e => orderItem.handleStatusChange(parseInt(e.target.value, 10))}
                    >
                      {OrderItemStatusAvailable.map(key => (
                        <option key={key} value={key}>
                          {OrderItemStatus[key]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <label style={{ marginRight: '5px' }} className={this.classNameForModifiedField('billedAt')}>
                      請求対象日:
                    </label>
                    <DatetimePicker
                      value={orderItem.billedAt || undefined}
                      onChange={d => orderItem.handleBilledAtChange(d)}
                      showTime={false}
                      disabledDays={0}
                      showPast
                      border
                    />
                  </div>
                </div>
                {orderItem.orderItemCategory === 'arrangement_request' ? (
                  <div className="virtual-counte-price-change-form__element-block">
                    <div className="virtual-counte-price-change-form__input-wrapper">
                      <Text text="リクエストフォーム利用料" />
                    </div>
                  </div>
                ) : (
                  <>
                    {orderItem.elements.some(
                      element =>
                        this.isTransportElement(element) &&
                        (element.transportType === 'foreign_air' || element.transportType === 'domestic_air')
                    ) && (
                      <div style={{ display: 'flex' }}>
                        <label
                          style={{ marginRight: '5px', flexShrink: 0 }}
                          className={this.classNameForModifiedField('pnrId')}
                        >
                          PNR ID
                        </label>
                        <input
                          type="text"
                          value={orderItem.pnrId || ''}
                          onChange={e => orderItem.handlePnrIdChange(e.target.value)}
                        />
                      </div>
                    )}
                    {orderItem.elements.some(
                      element => this.isTransportElement(element) && element.transportType === 'foreign_air'
                    ) && (
                      <>
                        <ActionButton
                          disabled={orderItem.travelerInformations.length === 0}
                          loading={pnrLoading}
                          onClick={() => this.handleReplaceForeignAirInfoFromPnr(orderItem)}
                        >
                          PNRから情報を反映
                        </ActionButton>
                        {this.state.pnrLoadResult?.status === 'success' && this.state.pnrLoadResult?.message && (
                          <SuccessTextDiv>{this.state.pnrLoadResult.message}</SuccessTextDiv>
                        )}
                        {this.state.pnrLoadResult?.status === 'error' && this.state.pnrLoadResult?.message && (
                          <DangerText color="danger">{this.state.pnrLoadResult.message}</DangerText>
                        )}
                        {orderItem.travelerInformations.length === 0 && (
                          <DangerText color="danger">※利用者が設定されていません</DangerText>
                        )}
                        {utils.isAiTravel(serviceId) && (
                          <div style={{ display: 'flex', margin: '10px 0' }}>
                            <label
                              style={{ marginRight: '5px' }}
                              className={this.classNameForModifiedField('foreignFlightTicketIssuer')}
                            >
                              発券先
                            </label>
                            <select
                              value={orderItem.foreignFlightTicketIssuer}
                              onChange={e => orderItem.handleForeignFlightTicketIssuerChange(e.target.value)}
                            >
                              {ForeignFlightTicketIssuerKeys.map(key => (
                                <option key={key} value={key}>
                                  {ForeignFlightTicketIssuer[key]}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </>
                    )}
                    {orderItem.elements.map((element, j) => (
                      <div className="virtual-counte-price-change-form__element-block" key={j}>
                        <div className="virtual-counte-price-change-form__input-wrapper">
                          <label
                            className={`virtual-counte-price-change-form__label ${this.classNameForModifiedField(
                              'elements',
                              j,
                              'type'
                            )}`}
                          >
                            タイプ
                          </label>
                          <select
                            value={element.type}
                            onChange={e => orderItem.handleChangeType(j, e.target.value)}
                          >
                            <option value="transport">交通</option>
                            <option value="hotel">ホテル</option>
                            <option value="cancel">キャンセル料</option>
                            <option value="change_fee">変更手数料</option>
                            {utils.isMynavi(serviceId) && <option value="support_fee">出張サポート費</option>}
                            <option value="shipping">配送料</option>
                            <option value="wifi">WiFi</option>
                            <option value="room">会議室</option>
                            <option value="kit">検査キット</option>
                            <option value="travel_authorization">査証・渡航認証関連</option>
                            <option value="insurance">保険</option>
                          </select>
                          <a
                            className="virtual-counte-price-change-form__remove-button"
                            onClick={() => handleRemoveElement(orderItem, j)}
                          >
                            ×
                          </a>
                        </div>
                        {element.type === 'transport' ? (
                          <TransportElementForm
                            element={element as TransportElement}
                            index={j}
                            serviceId={serviceId}
                            orderItem={orderItem}
                            suppliedItems={suppliedItems}
                            cabinOptions={cabinOptions}
                            handleAirNumbersChange={this.handleAirNumbersChange}
                            classNameForModifiedField={this.classNameForModifiedField}
                            individualTargetSuppliedItems={individualTargetSuppliedItems}
                            individualDomesticAirSuppliedItems={individualDomesticAirSuppliedItems}
                          />
                        ) : element.type === 'hotel' ? (
                          <HotelElementForm
                            element={element as HotelElement}
                            providerOptions={hotelElementProviderOptions}
                            classNameForModifiedField={(...path) =>
                              this.classNameForModifiedField('elements', j, ...path)
                            }
                            handleIsForeignChange={this.handleIsForeignChange}
                            handleIsForeignLoad={this.handleIsForeignLoad}
                          />
                        ) : element.type === 'cancel' ? (
                          <CancelElementForm
                            element={element as CancelChargeElement}
                            traceId={orderItem.traceId}
                            traceItems={order.traceItemDescriptions()}
                            classNameForModifiedField={(...path) =>
                              this.classNameForModifiedField('elements', j, ...path)
                            }
                          />
                        ) : element.type === 'change_fee' ? (
                          <ChangeFeeElementForm
                            element={element as ChangeFeeElement}
                            traceId={orderItem.traceId}
                            traceItems={order.traceItemDescriptions()}
                            classNameForModifiedField={(...path) =>
                              this.classNameForModifiedField('elements', j, ...path)
                            }
                          />
                        ) : element.type === 'support_fee' ? (
                          <SupportFeeElementForm
                            element={element as SupportFeeElement}
                            classNameForModifiedField={(...path) =>
                              this.classNameForModifiedField('elements', j, ...path)
                            }
                          />
                        ) : element.type === 'shipping' ? (
                          <ShippingElementForm
                            element={element as ShippingElement}
                            classNameForModifiedField={(...path) =>
                              this.classNameForModifiedField('elements', j, ...path)
                            }
                          />
                        ) : element.type === 'wifi' ? (
                          <WifiElementForm
                            element={element as WifiElement}
                            classNameForModifiedField={(...path) =>
                              this.classNameForModifiedField('elements', j, ...path)
                            }
                          />
                        ) : element.type === 'room' ? (
                          <RoomElementForm
                            element={element as RoomElement}
                            classNameForModifiedField={(...path) =>
                              this.classNameForModifiedField('elements', j, ...path)
                            }
                          />
                        ) : element.type === 'kit' ? (
                          <KitElementForm
                            element={element as KitElement}
                            classNameForModifiedField={(...path) =>
                              this.classNameForModifiedField('elements', j, ...path)
                            }
                          />
                        ) : element.type === 'travel_authorization' ? (
                          <TravelAuthorizationElementForm
                            element={element as TravelAuthorizationElement}
                            classNameForModifiedField={(...path) =>
                              this.classNameForModifiedField('elements', j, ...path)
                            }
                          />
                        ) : element.type === 'insurance' ? (
                          <InsuranceElementForm
                            element={element as InsuranceElement}
                            classNameForModifiedField={(...path) =>
                              this.classNameForModifiedField('elements', j, ...path)
                            }
                          />
                        ) : null}
                      </div>
                    ))}
                    <div className="virtual-counte-price-change-form__element-block">
                      <div className="virtual-counte-price-change-form__input-wrapper">
                        <div className="flex">
                          <select
                            value={orderItem.additionalType}
                            onChange={e => orderItem.handleSelectAdditionalType(e.target.value)}
                          >
                            <option value="transport">交通</option>
                            <option value="hotel">ホテル</option>
                            <option value="cancel">キャンセル料</option>
                            <option value="change_fee">変更手数料</option>
                            {utils.isMynavi(serviceId) && <option value="support_fee">出張サポート費</option>}
                            <option value="shipping">配送料</option>
                            <option value="wifi">WiFi</option>
                            <option value="room">会議室</option>
                            <option value="kit">検査キット</option>
                            <option value="travel_authorization">査証・渡航認証関連</option>
                            <option value="insurance">保険</option>
                          </select>
                          <button type="button" onClick={e => orderItem.handleAddElementByType(e)}>
                            追加する
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {orderItem.elements.length > 0 || orderItem.orderItemCategory === 'arrangement_request' ? (
                  <OrderItemPriceForm
                    index={index}
                    serviceId={serviceId}
                    selectedTrip={selectedTrip}
                    orderItem={orderItem}
                    bulkTickets={bulkTickets}
                    shareholderTickets={shareholderTickets}
                    taxTypes={taxTypes}
                    suppliedItems={suppliedItems}
                    paymentMethods={paymentMethods}
                    shareholderInfos={shareholderInfos}
                    individualTargetSuppliedItems={individualTargetSuppliedItems}
                    classNameForModifiedField={this.classNameForModifiedField}
                  />
                ) : null}
                <div className="virtual-counte-price-change-form__element-block no-border">
                  <div className="virtual-counte-price-change-form__input-wrapper">
                    <button type="button" className="edit-button" onClick={() => orderItem.handleToggleEditing()}>
                      元に戻す
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                {orderItem.orderItemCategory === 'arrangement_request' ? (
                  <div className="virtual-counte-price-change-form__element-block no-border">
                    <div className="virtual-counte-price-change-form__input-wrapper">
                      <Text text="リクエストフォーム利用料" />
                    </div>
                  </div>
                ) : (
                  <>
                    {orderItem.elements.map((element, j) => (
                      <div className="virtual-counte-price-change-form__element-block no-border" key={j}>
                        <div className="virtual-counte-price-change-form__input-wrapper">
                          <Text text={element.description()} />
                        </div>
                        {(() => {
                          if (element.type === 'hotel') {
                            const hotel = element as HotelElement;
                            return (
                              <>
                                {hotel.provider === 'mynavi' && (
                                  <div className="virtual-counte-price-change-form__input-wrapper">
                                    {hotel.apiCanceledAt === null ? (
                                      <>
                                        <ReservationStatus status="reserved">予約中</ReservationStatus>
                                        <A onClick={e => this.handleHotelCancelClick(hotel.id, e)}>
                                          キャンセルする
                                        </A>
                                      </>
                                    ) : (
                                      <ReservationStatus status="canceled">キャンセル済み</ReservationStatus>
                                    )}
                                  </div>
                                )}
                                {hotel.hotelReservation && (
                                  <div>
                                    <p>ホテル予約：{hotel.hotelReservation.statusDescription()}</p>
                                    <p>{hotel.hotelReservation.error && hotel.hotelReservation.error}</p>
                                  </div>
                                )}
                              </>
                            );
                          }
                          if (element.type === 'transport') {
                            const transport = element as TransportElement;
                            return <ExicItemDisplay element={transport} orderItem={orderItem} />;
                          }
                          if (element.type === 'cancel') {
                            const cancel = element as CancelChargeElement;
                            const traceItems = order.traceItemDescriptions();
                            return (
                              <div>
                                ■キャンセル商品
                                <br />
                                {cancel.mappings.map((mapping, i) => (
                                  <p key={`mapping-${i}`}>
                                    trace_id-{mapping.originalTraceId}: {traceItems[mapping.originalTraceId || 0]}
                                  </p>
                                ))}
                              </div>
                            );
                          }
                          if (element.type === 'change_fee') {
                            const change_fee = element as ChangeFeeElement;
                            const traceItems = order.traceItemDescriptions();
                            return (
                              <div>
                                ■変更商品
                                <br />
                                {change_fee.mappings.map((mapping, i) => (
                                  <p key={`mapping-${i}`}>
                                    trace_id-{mapping.originalTraceId}: {traceItems[mapping.originalTraceId || 0]}
                                  </p>
                                ))}
                              </div>
                            );
                          }
                          return null;
                        })()}
                      </div>
                    ))}
                  </>
                )}
                <OrderItemStatusForm
                  orderItemId={orderItem.id}
                  jobType={orderItem.jobType}
                  status={orderItem.status as OrderItemStatusKey}
                  onJobTypeChange={value => orderItem.handleJobTypeChange(value)}
                  onStatusChange={value => orderItem.handleStatusChange(value)}
                  fetchData={this.props.reloadSelectedTrip}
                  showLabel
                  style={{ marginBottom: '5px' }}
                />
                <BilledAtEditor
                  billedAt={orderItem.billedAt}
                  orderItemId={orderItem.id}
                  style={{ marginBottom: '5px' }}
                  onChange={date => orderItem.handleBilledAtChange(date)}
                />
                <OrderItemWaitingForm orderItem={orderItem} showLabel style={{ marginBottom: '5px' }} />
                <Button size="small" onClick={() => orderItem.handleToggleEditing()}>
                  編集
                </Button>
              </>
            )}
            <a
              className="virtual-counte-price-change-form__copy-order-item-button"
              onClick={() => order.handleCopyItem(orderItem)}
            >
              この商品をコピー
            </a>
          </div>
          {cancelingHotelId && (
            <Modal show hideModal={() => this.handleHotelCancelAlertClose()}>
              <Confirm
                onConfirmed={() => this.handleHotelCancelConfirm()}
                onAborted={() => this.handleHotelCancelAlertClose()}
                message={`ホテルの予約をキャンセルしてよろしいですか？\n※この操作は取り消せません`}
              />
            </Modal>
          )}
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const ReservationStatus = styled.div<{ status: string }>`
  font-weight: bold;

  ${props =>
    props.status === 'canceled'
      ? `
    color: ${props.theme.redColor};
  `
      : props.status === 'reserved'
      ? `
    color: ${props.theme.accentColor};
  `
      : null}
`;

const ActionButton = styled(Button)`
  padding: 5px;
  margin-left: 5px;
  min-width: 60px;
`;

const SuccessTextDiv = styled.div`
  color: ${props => props.theme.successColor};
`;

export default PriceChangeFormItem;

import { Fetcher, objectToFormData } from '@this/src/util';
import React from 'react';
import { styled } from '@this/constants/themes';
import type { RouteComponentProps } from 'react-router-dom';
import type { SigninFormData } from './types';
import Header from './user_signin_header';
import Body from './user_signin_body';
import Footer from '../shared/footer/footer';
import Notification from '../../notification';

interface Props extends RouteComponentProps {
  serviceId: number;
  error?: string;
}

export type signInResponse = null | 'LoginFailed' | 'AccountLocked';

class UserSignin extends React.Component<Props, { isPasswordExpired: boolean }> {
  constructor(props: Props) {
    super(props);
    if (this.props.error) {
      Notification.error(this.props.error);
    }

    const params = props.location.search
      .slice(1) // ?key=bar&key2=foo の先頭の?を取り除く
      .split('&')
      .reduce<{ [k in string]: string }>((acc, item) => {
        const [k, v] = item.split('=');
        acc[k] = v;
        return acc;
      }, {});
    this.state = { isPasswordExpired: params.password_expired === 'true' };
  }

  signin = async ({ email, password }: SigninFormData): Promise<signInResponse> => {
    const params =
      location.pathname === '/admins/sign_in'
        ? {
            admin: this.signInParams(email, password)
          }
        : location.pathname === '/arrangers/sign_in'
        ? {
            arranger: this.signInParams(email, password)
          }
        : {
            user: this.signInParams(email, password)
          };
    try {
      const res = await Fetcher.post<{ redirect: string; type?: string }>(location.pathname, params);
      if (res.type === 'saml') {
        const form = document.getElementsByName('signinForm')[0] as HTMLFormElement;
        form.submit();
        return null;
      }
      location.href = res.redirect || '/dashboard';
      return null;
    } catch (e) {
      try {
        const errorText = JSON.parse(e.responseText).errors[0];
        if (errorText === 'AccountLocked') {
          return errorText;
        }
        return 'LoginFailed';
      } catch (_) {
        return 'LoginFailed';
      }
    }
  };

  resetPassword = async (email: string): Promise<boolean> => {
    const params =
      location.pathname === '/admins/sign_in'
        ? {
            'admin[reset_email]': email
          }
        : location.pathname === '/arrangers/sign_in'
        ? {
            'arranger[reset_email]': email
          }
        : {
            'user[reset_email]': email
          };
    try {
      await Fetcher.post<{ redirect: string }>(
        location.pathname.replace('sign_in', 'password/reset_mail'),
        objectToFormData(params)
      );
      return true;
    } catch (_) {
      return false;
    }
  };

  signInParams(email: string, password: string) {
    return {
      service_id: this.props.serviceId,
      email,
      password
    };
  }

  render() {
    const { serviceId } = this.props;
    return (
      <UserSignInArea>
        <Header />
        <Body
          signin={this.signin}
          resetPassword={this.resetPassword}
          serviceId={serviceId}
          isPasswordExpired={this.state.isPasswordExpired}
        />
        <Footer signedIn={false} serviceId={serviceId} />
      </UserSignInArea>
    );
  }
}

export const UserSignInArea = styled.div`
  background: url('/images/user_signin/bg.png') center / cover no-repeat;
  color: #fff;
`;

export default UserSignin;

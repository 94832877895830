import React, { useCallback, useMemo, useState } from 'react';

import { styled } from '@this/constants/themes';
import type Trip from '@this/domain/trip/trip';
import { getSpacing } from '@this/shared/ui/theme';
import { Button } from '@this/shared/ui/inputs/button';
import { Text } from '@this/shared/ui/data_displays/typography';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@this/shared/ui/feedbacks/modal';
import { useBoolean } from '@this/shared/hooks/use_boolean';
import Notification from '@this/src/notification';
import { Fetcher, HTTPError } from '@this/src/util';

type Props = {
  className?: string;
  trip: Trip;
  onCancel: () => void;
};

export const CancelArrangement = ({ className, trip, onCancel }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [on, { toggle }] = useBoolean(false);

  const disabled = useMemo(() => trip.status === 5, [trip.status]);

  const handleSubmit = useCallback(async () => {
    if (disabled || submitting) return;

    setSubmitting(true);
    try {
      await Fetcher.put(`/arrangement/trips/${trip.id}`, { status: 5 });
      onCancel();
    } catch (e) {
      const msg =
        e instanceof HTTPError && e.response?.data?.errors
          ? e.response.data.errors
          : '通信環境が不安定です。画面をリロードしてからもう一度お試しください。';

      Notification.error(msg);
    } finally {
      toggle();
      setSubmitting(false);
    }
  }, [disabled, submitting, trip.id, onCancel]);

  return (
    <Root className={className}>
      <h3>旅程のキャンセル</h3>
      <p> ※ステータスを変更するだけ。決済には影響ありません。</p>
      <ButtonContainer>
        <Button disabled={disabled} loading={submitting} onClick={toggle}>
          この予約をキャンセルする
        </Button>
      </ButtonContainer>
      {disabled && <Text color="danger">※既にキャンセルされています</Text>}

      <Modal open={on} disableBackdropClick={submitting} onClose={toggle}>
        <ModalHeader>手配依頼のキャンセル</ModalHeader>
        <ModalBody>本当にキャンセルしますか？</ModalBody>
        <ModalFooter>
          <Button color="sub" onClick={toggle} disabled={disabled}>
            キャンセル
          </Button>
          <Button onClick={handleSubmit} loading={submitting}>
            実行
          </Button>
        </ModalFooter>
      </Modal>
    </Root>
  );
};

const Root = styled.div``;

const ButtonContainer = styled.div`
  margin-top: ${getSpacing(2)}px;
`;

import React from 'react';
import Notify from 'notifyjs';
import { Fetcher, getParams, HTTPError, appendScript } from '@this/src/util';
import type { RouteComponentProps } from 'react-router-dom';
import TripList from '@this/domain/trip/trip_list';
import Trip from '@this/domain/trip/trip';
import Order from '@this/domain/order';
import ShareholderTicketList from '@this/domain/shareholder_ticket_list/index';
import type { BulkTicketArgs } from '@this/domain/bulk_ticket2';
import BulkTicket from '@this/domain/bulk_ticket2';
import type { TaxTypeArgs } from '@this/domain/tax_type';
import TaxType from '@this/domain/tax_type';
import SuppliedItem from '@this/domain/supplied_item/supplied_item';
import PaymentMethodList from '@this/domain/payment_method/payment_method_list';

import type { Message } from '@this/components/trips_management/trips/trips_chat/types';
import type { TicketOprResponseArgs } from '@this/domain/ticket_opr_response';
import type { MessageTemplate, OrderItemStatusLogInfo } from '@this/components/arrangement/virtual_counter/types';
import type { PaymentMethodArgs } from '@this/domain/payment_method/payment_method';
import type { CabinOption } from '@this/domain/cabin_option';
import type { ShareholderInfo } from '@this/domain/other_service_shareholder_info';
import type { IndividualTargetSuppliedItem } from '@this/domain/individual_target_supplied_item';
import type { IndividualDomesticAirSuppliedItem } from '@this/domain/individual_domestic_air_supplied_item';
import { throttle, merge } from 'lodash';
import { VirtualCounterTemplate } from './virtual_counter.template';

type TemplateProps = React.ComponentProps<typeof VirtualCounterTemplate>;
type Props = RouteComponentProps;
type State = {
  trips: TripList;
  tripError: boolean;
  pusherKey: string | null;
  pusherChannelPrefix: string | null;
  orderItemStatusLogInfo: OrderItemStatusLogInfo | null;
} & Omit<
  TemplateProps,
  'orderItemStatusLogInfo' | 'reloadSelectedTrip' | 'fetchMessages' | 'onReloadTrip' | 'searchByTripId'
>;

type VirtualCounterResponse = {
  signed_in: {
    signed_in: boolean;
    arranger: {
      id: number;
      email: string;
      first_name: string;
      last_name: string;
      created_at: string;
      updated_at: string;
      service_id: string;
      deleted_at: string | null;
    };
  };
  pusherKey: string;
  pusherChannelPrefix: string;
};

type SuppliedItemsResponse = {
  id: number;
  name: string;
  code: string;
  supplier_id: number;
  payment_method_id: number;
  disabled: boolean;
  created_at: string;
  updated_at: string;
  account_record_type: 'confirmed_at' | 'return_at' | 'ticketing_at' | 'ordered_at' | 'started_at';
  closing_day: string;
  supplier_name: string;
  supplier_code: string;
}[];

type MessageResponse = {
  success: boolean;
  receiptIssuable: boolean;
  messages: Message[];
  order_item_status_log_info: OrderItemStatusLogInfo;
};
type TripResponse = {
  trip: any;
  organization_plan: string;
  ticket_opr_response_price: TicketOprResponseArgs[] | null;
  hotel_element_provider_options: { [key: string]: string };
  shareholder_tickets: any[];
  message_templates: MessageTemplate[];
  bulk_tickets: BulkTicketArgs[];
  shipping_category_options: {
    yamato: string;
    sagawa: string;
    letter_pack: string;
    ecohai: string;
  };
  wifi_provider_options: {
    vision: string;
  };
  car_type_options: [string, string][];
  car_provider_options: [string, string][];
  fp_reservation_json: object | null;
  mynavi_reservation_json: object | null;
  payment_methods: PaymentMethodArgs[];
  shareholder_infos: ShareholderInfo[];
  cabin_options: CabinOption[];
  individual_target_supplied_items: IndividualTargetSuppliedItem[];
  individual_domestic_air_supplied_items: IndividualDomesticAirSuppliedItem[];
  tax_types: TaxTypeArgs[];
};

export default class VirtualCounter extends React.PureComponent<Props, State> {
  private pusher: any = null;

  private eventChannel: any = null;

  private tripChannel: any = null;

  private timeoutIds: number[] = [];

  constructor(props: Props) {
    super(props);

    const params = getParams();

    this.state = {
      initialTripId: params.trip_id ?? null,
      trips: new TripList(),
      tripError: false,
      pusherKey: null,
      pusherChannelPrefix: null,
      suppliedItems: [],
      selectedTrip: null,
      messages: [],
      orderItemStatusLogInfo: null,
      tripNotFindMessage: '',
      ticketOprResponse: [],
      planName: '',
      editingOrder: null,
      loadingSelectedTrip: false,
      loadingMessages: false,
      hotelElementProviderOptions: {},
      serviceId: 1,
      messageTemplates: [],
      bulkTickets: [],
      shareholderTickets: new ShareholderTicketList([]),
      paymentMethods: new PaymentMethodList([]),
      taxTypes: [],
      shareholderInfos: [],
      cabinOptions: [],
      individualTargetSuppliedItems: [],
      individualDomesticAirSuppliedItems: [],
      reservationJson: {
        fp: null,
        mynavi: null
      }
    };
  }

  componentDidMount() {
    appendScript('https://js.pusher.com/3.0/pusher.min.js');
    this.initVirtualCounter();
    this.fetchSuppliedItems();

    if (this.state.initialTripId) {
      this.searchByTripId(this.state.initialTripId);
    }

    if (Notify.needsPermission && Notify.isSupported()) {
      Notify.requestPermission();
    }
  }

  componentWillUnmount() {
    this.createTimeout();
  }

  private createTimeout() {
    this.timeoutIds.forEach(window.clearTimeout);
  }

  initVirtualCounter = () => {
    return Fetcher.get<VirtualCounterResponse>('/arrangement/virtual_counter.json').then(result => {
      const serviceId = result.signed_in.arranger.service_id;
      return this.setState(
        {
          serviceId: parseInt(serviceId, 10),
          pusherKey: result.pusherKey,
          pusherChannelPrefix: result.pusherChannelPrefix
        },
        this.initWebsocket
      );
    });
  };

  initWebsocket = () => {
    if (typeof Pusher !== 'undefined') {
      this.pusher = new Pusher(this.state.pusherKey, { encrypted: true });
      this.eventChannel = this.pusher.subscribe(`${this.state.pusherChannelPrefix}partner`);
      this.eventChannel.bind('message_received', this.handleMessageReceived);
      this.createTimeout();
    } else {
      this.timeoutIds.push(window.setTimeout(this.initWebsocket, 100));
    }
  };

  // setSearchType() {
  //   const params = new URLSearchParams(location.search);
  //   if (!_.isEmpty(params.get('trip_id'))) {
  //     return this.setState({
  //       searchTripId: params.get('trip_id')
  //     });
  //   }
  // }

  fetchSuppliedItems = () => {
    return Fetcher.get<SuppliedItemsResponse>('/arrangement/supplied_items.json?legacy=true').then(
      result => {
        return this.setState({ suppliedItems: result.map(raw => new SuppliedItem(raw)) });
      },
      error => {
        if (error instanceof HTTPError && error.response?.status !== 401) {
          this.setState({ tripError: true });
        }
      }
    );
  };

  searchByTripId = async (tripId: string): Promise<void> => {
    this.setState({
      selectedTrip: null,
      tripNotFindMessage: '旅程番号を入力してください'
    });

    if (!tripId) {
      return;
    }

    this.reloadSelectedTrip(tripId);
  };

  addQueryParams = () => {
    if (!this.state.selectedTrip) {
      return;
    }

    const query = [];
    const searchTripId = this.state.selectedTrip.id;
    query.push(`trip_id=${searchTripId}`);
    const selectedTripId = location.hash.substr(1);
    window.history.pushState({}, '', `${location.pathname}?${query.join('&')}`);
    if (this.state.trips.find(selectedTripId)) {
      location.hash = selectedTripId;
    }
  };

  fetchMessages = (trip: Trip) => {
    this.setState({
      loadingMessages: true
    });
    return Fetcher.get<MessageResponse>(`/trips/${trip.id}/messages.json`, { type: 'arranger' })
      .then(result => {
        return this.setState({
          messages: result.messages,
          orderItemStatusLogInfo: result.order_item_status_log_info,
          loadingMessages: false
        });
      })
      .catch(e => {
        return this.setState({
          loadingMessages: false
        });
      });
  };

  reloadSelectedTrip = (tripId: string): Promise<void> => {
    this.setState({
      editingOrder: null,
      loadingSelectedTrip: true,
      tripNotFindMessage: ''
    });
    if (!tripId) {
      return Promise.resolve();
    }

    return Fetcher.get<TripResponse>(`/arrangement/trips/${tripId}.json`)
      .then(
        result => {
          const trip = new Trip(result.trip);
          if (!trip) {
            return;
          }

          const orderOptions = {
            shippingCategoryOptions: result.shipping_category_options,
            wifiProviderOptions: result.wifi_provider_options,
            carTypeOptions: result.car_type_options,
            carProviderOptions: result.car_provider_options
          };
          const o = new Order(merge(trip.currentOrder, orderOptions), trip);
          if (o) {
            o.removeOldFees();
          }

          this.setState({
            loadingSelectedTrip: false,
            selectedTrip: trip,
            editingOrder: o,
            planName: result.organization_plan,
            ticketOprResponse: result.ticket_opr_response_price ?? [],
            hotelElementProviderOptions: result.hotel_element_provider_options,
            shareholderTickets: new ShareholderTicketList(result.shareholder_tickets),
            messageTemplates: result.message_templates,
            bulkTickets: (result.bulk_tickets || []).map(t => new BulkTicket(t)),
            reservationJson: {
              fp: result.fp_reservation_json,
              mynavi: result.mynavi_reservation_json
            },
            taxTypes: result.tax_types.map(t => new TaxType(t)),
            paymentMethods: new PaymentMethodList(result.payment_methods),
            shareholderInfos: result.shareholder_infos,
            cabinOptions: result.cabin_options,
            individualTargetSuppliedItems: result.individual_target_supplied_items,
            individualDomesticAirSuppliedItems: result.individual_domestic_air_supplied_items
          });
          this.fetchMessages(trip);
          this.subscribeTrip(trip.id.toString());

          this.addQueryParams();
        },

        error => {
          return this.setState({
            loadingSelectedTrip: false,
            tripNotFindMessage:
              error instanceof HTTPError && error.response?.status === 400 && error.response.data.errors
          });
        }
      )
      .catch(e => {
        return this.setState({
          loadingSelectedTrip: false
        });
      });
  };

  subscribeTrip = (tripId: string) => {
    if (this.pusher) {
      if (this.tripChannel) {
        this.pusher.unsubscribe(this.tripChannel.name);
      }
      this.tripChannel = this.pusher.subscribe(this.state.pusherChannelPrefix + tripId.toString());
      this.tripChannel.bind('new_message', throttle(this.receiveMessage, 60000));
      this.createTimeout();
    } else {
      this.timeoutIds.push(window.setTimeout(this.subscribeTrip.bind(this, tripId), 100));
    }
  };

  receiveMessage = () => {
    if (this.state.selectedTrip) {
      this.fetchMessages(this.state.selectedTrip);
    }
  };

  handleMessageReceived = (e: any) => {
    if (this.state.serviceId === e.trip.service_id) {
      const receivedMessage = e.user_message;
      let body = `メッセージを受信しました(予約番号：${e.trip.id})\n`;
      body += `内容：${receivedMessage.body}`;
      this.showDesktopNotification(body);

      $(`#newmark-trip-${e.trip.id}`).fadeIn();
    }
  };

  showDesktopNotification = (body: string) => {
    const myNotification = new Notify('AI Travel', {
      body,
      lang: 'ja',
      notifyClick: () => {
        window.focus();
      }
    });
    if (!Notify.needsPermission) {
      myNotification.show();
    }
  };

  render() {
    return (
      <VirtualCounterTemplate
        {...this.props}
        {...this.state}
        searchByTripId={this.searchByTripId}
        reloadSelectedTrip={this.reloadSelectedTrip}
        fetchMessages={this.fetchMessages}
      />
    );
  }
}

import React, { useCallback } from 'react';

import type SearchQueryItem from '@this/src/domain/search_query_item';
import { Flex } from '@this/src/components/shared/ui/layout/flex';
import { SendFeedback } from '../send_feedback/send_feedback';

interface Props {
  searchQueryItem: SearchQueryItem;
}

export const HotelSelectedReasonFeedback: React.FC<Props> = ({ searchQueryItem }) => {
  const rating = searchQueryItem.aiHotelRecommendationQuality;

  const handleDialogSubmit = useCallback(
    feedback => {
      searchQueryItem.setAiHotelRecommendationFeedback(feedback);
      searchQueryItem.commitAiHotelRecommendationFeedback();
    },
    [searchQueryItem]
  );

  return (
    <Flex justifyContent="flex-end">
      <SendFeedback
        defaultRating={rating}
        onChangeRating={searchQueryItem.setAiHotelRecommendation}
        onSubmitDetailFeedback={handleDialogSubmit}
      />
    </Flex>
  );
};

import _ from 'lodash';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import type { AirRouteDetail } from '@this/domain/select_repository';
import type Flight from '@this/domain/flight/flight';
import type MarginTypeList from '@this/domain/organization/margin_type_list';
import type NonOrderItem from '@this/domain/non_order_item';
import Hotel from '../hotel/hotel';
import Transit from '../transit/transit';
import type { RentalCarJson } from '../reserve_info';
import type ReservingTripItem from './reserving_trip_item';
import type TravelerList from '../traveler/traveler_list';
import type { SelectType } from '../select_store';
import type RentalCarLimitType from '../organization/rental_car_limit_type';

interface Args {
  packageType: SelectType;
  airline?: 'ANA' | 'JAL';
  items: (ReservingTripItem | undefined)[];
  peopleNum: number;
  rentalCars?: RentalCarJson[];
  showFee: boolean;
  marginTypes?: MarginTypeList;
  rentalCarLimitType?: RentalCarLimitType;
  startTime?: Moment;
  endTime?: Moment;
  nonOrderItems?: NonOrderItem[];
}

class ReservingTrip {
  packageType: SelectType | undefined;

  airline: 'ANA' | 'JAL' | undefined;

  items: (ReservingTripItem | undefined)[];

  nonOrderItems: NonOrderItem[];

  peopleNum: number;

  rentalCars: (RentalCarJson | undefined)[];

  showFee?: boolean;

  marginTypes?: MarginTypeList;

  rentalCarLimitType?: RentalCarLimitType;

  startTime?: Moment;

  endTime?: Moment;

  constructor(args?: Args) {
    this.packageType = args && args.packageType;
    this.airline = args && args.airline;
    this.items = (args && args.items) || [];
    this.peopleNum = (args && args.peopleNum) || 1;
    this.rentalCars = (args && args.rentalCars) || [];
    this.showFee = args && args.showFee;
    this.marginTypes = args && args.marginTypes;
    this.rentalCarLimitType = args && args.rentalCarLimitType;
    this.startTime = args && args.startTime;
    this.endTime = args && args.endTime;
    this.nonOrderItems = (args && args.nonOrderItems) || [];
  }

  static consignmentSaleDesc(tel_num: string) {
    return (
      '株式会社トランスファーデータ\n' +
      '東京都港区芝3丁目5-7\n' +
      '東京都知事登録旅行業第2-7247号\n' +
      '一般社団法人日本旅行業協会正会員\n' +
      '営業時間：平日9時-18時\n' +
      `連絡先　：${tel_num}\n` +
      '\n' +
      '総合旅行業務取扱管理者\n' +
      '澁谷 誠\n' +
      '\n' +
      '総合旅行業務取扱管理者とは、お客様の旅行を扱う\n' +
      '旅行会社・営業所での取引に関する責任者です。\n' +
      'この旅行契約に関し、担当者からの説明にご不明な点があれば、ご遠慮なく取扱管理者におたずねください。\n'
    );
  }

  static anaPackageDesc() {
    return (
      '楽天ANAトラベルオンライン株式会社\n' +
      '東京都世田谷区玉川一丁目14番1号 楽天クリムゾンハウス\n' +
      '観光庁長官登録旅行業　第1810号\n' +
      '一般社団法人日本旅行業協会正会員\n'
    );
  }

  static jalPackageDesc() {
    return (
      '株式会社JALパック\n' +
      '東京都品川区東品川2-4-11 野村不動産天王洲ビル\n' +
      '観光庁長官登録旅行業　第705号\n' +
      '一般社団法人日本旅行業協会正会員\n'
    );
  }

  isAirPackage() {
    return this.packageType === 'airPackage';
  }

  isAnaAirPackage() {
    return this.isAirPackage() && this.airline === 'ANA';
  }

  isJalAirPackage() {
    return this.isAirPackage() && this.airline === 'JAL';
  }

  isPackage() {
    return this.packageType === 'airPackage' || this.packageType === 'jrPackage';
  }

  // 手数料を除く一人分の料金を計算
  packagePrice() {
    return this.packageBasePrice() + this.packageOutwardPrice() + this.packageHomewardPrice();
  }

  // 手数料を含む一人分の料金を計算
  packageTotalPrice() {
    const price = this.packagePrice();
    return price ? price + this.packageMargin() : 0;
  }

  packageBasePrice() {
    const hotel = this.items
      .map(item => item && item.element)
      .find<Hotel>((element): element is Hotel => element instanceof Hotel);

    return (hotel && hotel.package_base_price) || 0;
  }

  private packageTransits() {
    const transits = this.items
      .map(item => item && item.element)
      .filter<Transit>((element): element is Transit => element instanceof Transit);
    transits.sort(
      (a, b) =>
        new Date(a.segments[0].legs[0].from.time_full).getTime() -
        new Date(b.segments[0].legs[0].from.time_full).getTime()
    );

    return transits;
  }

  packageOutwardPrice() {
    const [outword] = this.packageTransits();
    if (outword === undefined) return 0;
    // TODO: Transitに移動
    return outword.additionalPrice || 0;
  }

  packageHomewardPrice() {
    const [, homeword] = this.packageTransits();
    if (homeword === undefined) return 0;
    // TODO: Transitに移動
    return homeword.additionalPrice || 0;
  }

  // 一人分の料金を計算
  packageMargin() {
    if (!this.showFee) {
      return 0;
    }
    if (!this.marginTypes) {
      return 0;
    }
    const marginType = _.find(this.marginTypes.marginTypes, m => m.category === 'domestic_package');
    if (!marginType) {
      return 0;
    }
    return marginType.calcMarginAmount(this.packagePrice(), 1);
  }

  itemsTotalPrice() {
    const prices = _.map(this.items, item => (item && item.totalPrice()) || 0);
    return _.sum(prices);
  }

  itemsPriceTexts() {
    return _.map(this.items, item => (item && item.priceText()) || '');
  }

  itemsAirExists() {
    return this.items.some(item => !!item && !!item.airExists());
  }

  itemsFlightExists() {
    return this.items.some(item => !!item && item.isFlight());
  }

  itemsShinkansenExists() {
    return this.items.some(item => !!item && item.shinkansenExists());
  }

  itemsExpressExists() {
    return this.items.some(item => !!item && item.expressExists());
  }

  itemsHotelExists() {
    return this.items.some(item => !!item && item.isHotel());
  }

  itemsDomesticExists() {
    return this.items.some(item => !!item && item.isTransit() && item.isDomesticExist());
  }

  totalPrice() {
    if (this.isPackage()) {
      return this.packageTotalPrice() * this.peopleNum;
    }
    return this.itemsTotalPrice();
  }

  totalPriceShortDistance() {
    let price = 0;
    this.items.forEach(item => {
      if (item?.element instanceof Transit && item?.element.legsummaries_fromto_before) {
        price += item.element.legsummaries_typeprice_before.price;
      }
      if (item?.element instanceof Transit && item?.element.legsummaries_fromto_after) {
        price += item.element.legsummaries_typeprice_after.price;
      }
    });
    return price;
  }

  separateFinalPrice() {
    return this.itemsTotalPrice();
  }

  packageFinalPrice() {
    return this.packageTotalPrice() * this.peopleNum;
  }

  tooltipPackageText() {
    let text = `\
パッケージ：${utils.formatPrice(this.packageTotalPrice())} × ${this.peopleNum}名
└ 基本料金：${utils.formatPrice(this.packageBasePrice())}
└ 往路追加料金：${utils.formatPrice(this.packageOutwardPrice())}
└ 復路追加料金：${utils.formatPrice(this.packageHomewardPrice())}\
`;
    const margin = this.packageMargin();
    if (margin > 0) {
      text += `\n└ 手配手数料：${utils.formatPrice(this.packageMargin())}`;
    }
    return text;
  }

  tooltipText() {
    if (this.isPackage()) {
      return this.tooltipPackageText();
    }

    let texts: string[] = [];
    if (this.itemsTotalPrice() > 0) {
      texts = texts.concat(this.itemsPriceTexts());
    }
    return texts.join('\n');
  }

  isFlightCustomerInfoRequired() {
    return this.itemsAirExists();
  }

  isDomesticFlightCustomerInfoRequired() {
    return this.itemsAirExists() && this.itemsDomesticExists();
  }

  isForeignFlightCustomerInfoRequired() {
    return this.itemsFlightExists();
  }

  isShinkansenInfoRequired() {
    return this.shinkansenExists() || this.expressExists();
  }

  isHotelInfoRequired() {
    return this.itemsHotelExists();
  }

  shouldShowMileage() {
    return this.itemsAirExists();
  }

  shinkansenExists() {
    return this.itemsShinkansenExists();
  }

  expressExists() {
    return this.itemsExpressExists();
  }

  startDate() {
    let dates = _.compact(_.map(this.items, item => item && item.startDate()));
    if (dates.length === 0) {
      dates = _.compact(_.map(this.rentalCars, car => car && car.departure_time));
      if (this.startTime) {
        dates.push(this.startTime.format('YYYY-MM-DD'));
      }
    }
    return _.first(dates.sort());
  }

  endDate() {
    let dates = _.compact(_.map(this.items, item => item && item.endDate()));
    if (dates.length === 0) {
      dates = _.compact(_.map(this.rentalCars, car => car && car.return_time));
      if (this.endTime) {
        dates.push(this.endTime.format('YYYY-MM-DD'));
      }
    }
    return _.last(dates.sort());
  }

  rentalCarStartDate() {
    return moment(this.startDate()).hour(this.rentalCarLimitType!.hour).minute(0).format();
  }

  rentalCarEndDate() {
    return moment(this.endDate()).hour(this.rentalCarLimitType!.hour).minute(0).format();
  }

  bedType() {
    const hotel = this.items
      .map(item => item && item.element)
      .find<Hotel>((element): element is Hotel => element instanceof Hotel);

    return (hotel && hotel.bed_types) || [];
  }

  hotelRooms(applicants: TravelerList, roomNum: number) {
    const hotels = this.items
      .map(item => item && item.element)
      .filter<Hotel>((element): element is Hotel => element instanceof Hotel);
    if (hotels) {
      return hotels.map(hotel => {
        return {
          id: hotel.id,
          name: hotel.name,
          type: hotel.type,
          rooms: _.map([...Array(roomNum)], (_, i) => {
            return {
              hotelFirstName: applicants.list[i].firstNameRoman ?? '',
              hotelLastName: applicants.list[i].lastNameRoman ?? '',
              hotelFirstNameKana: applicants.list[i].firstNameKana ?? '',
              hotelLastNameKana: applicants.list[i].lastNameKana ?? '',
              hotelBirthday: applicants.list[i].flightBirthday ?? '',
              hotelGender: applicants.list[i].flightGender ?? '',
              hotelTel: applicants.list[i].tel || '',
              bedTypes: hotel.bed_types,
              bedTypeId: hotel.bed_types && hotel.bed_types[0] && hotel.bed_types[0].id,
              bedTypeDescription: hotel.bed_types && hotel.bed_types[0] && hotel.bed_types[0].description
            };
          }),
          plan_name: hotel.plan_name
        };
      });
    }
    return [];
  }

  hasLegSummaries() {
    let exists = false;
    this.items.forEach(item => {
      if (item?.element instanceof Transit && item?.element.legsummaries_fromto_jrair) {
        exists = true;
      }
    });
    return exists;
  }

  get airRouteDetail(): AirRouteDetail | undefined {
    const item: ReservingTripItem | undefined = this.items.find(item => !!item && item.isFlight());

    if (!item) {
      return undefined;
    }

    // air_route_detailは日本→海外のみ取得できるため
    const segment = (item.element as Flight).outword.segments[0];

    return segment.air_route_detail;
  }

  get isUnreservedSeatAvailable() {
    return this.items.some(item => item?.isUnreservedSeatAvailable);
  }
}

export default ReservingTrip;
